@import '../../../shared.scss';

.container{
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing:border-box;
  padding:10px 15px 5px;
  height: 40px;

  &.borderBottom{
    border-bottom: 1px solid #cacaca;
  }

  a{
    color: $E_blue;
    cursor: pointer;
    position: relative;
    bottom: 2px;
    font-size: 12px;
    line-height: 16px;
    margin:0;
    font-weight: 500;

    &:hover{
      color: $main-teal--dark !important;
    }
  }
  
  h5{
    color: #858585;
    font-weight: 700;
    margin:0 5px;
    line-height: 16px;
    font-size: 13px;
    
    &.current{
      font-weight: 500;
      font-size: 12px !important;
      margin:0;
      position: relative;
      bottom: 2px;
    }
  }

}