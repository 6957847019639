@import '../../../../../shared.scss';

.container{
  width: 40%;
  max-width: 500px;
  min-width: 200px;
  height: 100%;
  border-left:1px solid #cacaca;


  .radiosBar{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 80px;
    border-bottom: 1px solid #cacaca;
    padding: 0 15px;
    width:100%;
    box-sizing: border-box;

    label{
      width:24%;

      &.wide{
        width:32%
      }
    }

    .radioDiv{
      width:100%;
      height: 40px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;



      p{
        font-size: 14px;
        font-weight: 500;
        margin: 0;
        color: #4e4e4e;
      }
      
      .icon{
        height: 25px;
        width: 25px;
        background-size: contain !important;
        margin-right: 5px;
        margin-left: 3px;
        border-radius: 3px;
      }

      &.wordbank{
        .icon{
          width: 23px;
          height: 23px;
          background: url('./vector1.svg') no-repeat center;
        }
      }

      &.rooms{
        .icon{
          background: url('../../../../../assets/sidebarIcons/sidebar_rooms_blue.svg') no-repeat center;
        }
      }

      &.grading{
        .icon{
          background: url('../../../../../assets/sidebarIcons/sidebar_grading_blue.svg') no-repeat center;
        }
      }

      &.grammar{
        .icon{
          background: url('../../../../../assets/sidebarIcons/sidebar_grammar_blue.svg') no-repeat center;
        }
      }
    }


    //<--------------------------- SELECTED RADIO STATES
    input:checked + .radioDiv{
      background-color: #074B60;
      p{
        color: white;
      }

      &.wordbank{
        .icon{
          background: url('./vector.svg') no-repeat center;
        }
      }

      &.rooms{
        .icon{
          background: url('../../../../../assets/sidebarIcons/sidebar_rooms_white.svg') no-repeat center;
        }
      }

      &.grading{
        .icon{
          background: url('../../../../../assets/sidebarIcons/sidebar_grading_white.svg') no-repeat center;
        }
      }

      &.grammar{
        .icon{
          background: url('../../../../../assets/sidebarIcons/sidebar_grammar_white2.svg') no-repeat center;
        }
      }
    }
  }

  .mainSwitch{
    height: calc(100% - 80px);
    width: 100%;
  }

  @media (max-width:1460px){
    .radiosBar label .radioDiv p{
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(100% - 30px);
      white-space: nowrap;
    }
  }
  
  @media (max-width:1300px){
    .radiosBar label .radioDiv{

      p{
        font-size: 12px;

      }

      // .icon{
      //   width: 20px !important;
      //   height: 20px !important;
      // }
    }
  }


  &.synod{
    .radiosBar{
      display: none;
    }
  }
}