@import '../../../../shared.scss';

.container{
  cursor: pointer;
  display: flex;
  align-items: center;
  height:50px;
  width:100%;
  background-color: white;
  border-bottom: 1px solid $grey-unselected;
  transition: all .5s ease;

  // &:hover{
  //   // transform: scale(1.007);
  //   background-color:lighten($main-blue, 64%);
  // }

  *{
    margin-bottom: 0;
    margin-top: 0;
  }


  h5{
    color: rgba(0, 0, 0, 0.8);
    font-style: 14px;
    color:#2b2b2b;
    font-weight: 300;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .boomer{
    width:10%;
    height:100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    img{
      height:40px;
      width:40px;
      border-radius: 50%;
    }
  };
  .name{
    width:15%;
    text-align: left;
  };
  .email{
    width:15%;
    text-align: left;
  };
  .grade{
    width:10%;
    text-align: center;
  };
  .groupName{
    width: 20%;
    overflow-x: scroll;
    text-align: left;
  };
  .teacher{
    width:15%;
    text-align: left;
  };
  .parentName{
    width:15%;
    text-align: left;
  };
  .parentMail{
    width:15%;
    text-align: left;

    .noMail{
      background: url('../../../../assets/icon-mail--add.svg') no-repeat 10px center;
      position: relative;
      height:30px;
      line-height: 30px;
      text-align: center;
      width: 85%;
      box-sizing:border-box;
      padding:0 10px 0 35px;
      border-radius: 8px;
      border: 1px solid;
      border-color: #cacaca;
    
      p{
        line-height: 30px;
        margin:0;
        font-size: 14px;
        font-weight: 300;
        color: #858585;
        margin:0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    
      &:hover{
       p{text-decoration: underline;}
      }

    }
  }
  .dotsDiv{
    width: 5%;
    height: 100%;
    position: relative;
        
    .dots{
      cursor: pointer;
      position: absolute;
      bottom:10px;
      right:10px;
      margin-left: 0px;
      height:30px;
      width:30px;
      border-radius: 5px;
      background:url('../../../../assets/threeDots.png') no-repeat center;
      cursor: pointer;
      overflow: visible;
  
      &:hover{
        background-color: #f2f2f2;
      }
  
      .dotsDropdown{
        position: absolute;
        z-index: 1000;
        top:33px;
        left:-130px;
        height:fit-content;
        height: -moz-max-content;
        width: 165px;
        background-color: white;
        -webkit-box-shadow: 0px 0px 5px 4px rgba(0,0,0,0.12);
        -moz-box-shadow: 0px 0px 5px 4px rgba(0,0,0,0.12);
        box-shadow: 0px 0px 5px 4px rgba(0,0,0,0.12);
        border-radius: 5px;
        overflow: visible;
        box-sizing:border-box;
        padding:5px 0;
  
  
        &::after{
          content:'';
          position: absolute;
          top: -10px;
          right:15px;
          // left:0;
          // margin-left: calc(50% - 5px);
          border-width: 5px;
          border-style: solid;
          border-color:transparent transparent white transparent; 
        }
      
        
        .dropdownItem{
          margin: 0 auto;
          height:31px;
          line-height: 28px;
          text-align: left;
          box-sizing:border-box;
          font-size: 14px;
          width:155px;
          border-radius: 5px;
          color:#858585;
          background-size: 13px;
          font-weight: 300;
          box-sizing:border-box;
          padding:0 0 0 15px;
  
  
          &:hover{
            background-color: #e9e9e9;
          }
        }
      }
    }
  }

}

.inviteBtn{
  position: relative;
  display: inline-block;
  font-weight: 300;
  font-size:14px;
  height: 30px;
  line-height: 30px;
  padding:0px 15px;
  border:1px solid $main-green;
  background-color: white;
  color: $main-green;
  width: fit-content;
  width: -moz-max-content;
  min-width: 100px;
  border-radius: 8px;
  margin:0 auto;
  &:hover{
    .invite{
      text-decoration: underline;
    }
    .plus{
      text-decoration: none !important;
    }
  }

  .plus{
    position: relative;
    pointer-events: none;
    font-size: 23px;
    top:1px;
    font-weight: 900;
    line-height: 13px;
    margin-right:5px;
  }
}

@media (max-width:1100px) {

  .container {
    .boomer{
      width:10%;
    }
    .name {
      width: 25%;
    }
    .parentName{
      width:25%;
    }
    .grade{
      width: 10%;
    }
    .teacher {
      width: 25%
    }
  }
}
@media (max-width:600px){
  .container{
    height:100px;
    .boomer{
      height:60px;
      width:35%;
      background-size: contain;
    }
    .mobileHalf{
      width:60%;

     h5{
        margin:5px 0;
        font-size: 14px;
        font-weight: 300;
        color: grey;
      }
      h4{
        color: black;
        margin: 3px 0;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
      }
    }
  }
}