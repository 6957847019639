@import '../../../../shared.scss';

.container{
  text-align: center;
  box-sizing:border-box;
  padding:10px 20px;


  height:fit-content;
  width: 550px;
  background-color: white;

  *{
    font-family: 'Sofia pro';
  }
  
  .inner{
    position: relative;
    width:500px;
    height:225px;
    border-radius: 15px;
    background-color:#f8f8f8;
    margin: 30px auto 20px auto;
    box-sizing:border-box;
    padding:10px 50px;
    text-align: left;

    p{
      font-size: 17px;
      color: #858585;
      margin:10px 0;

      &:nth-of-type(2){
        margin:16px 0;
      }

      &.access{    
        display: flex;
        align-items: center;  
        span{
        &.code{
          display: flex;
          align-items: center;
          margin-left: 5px;
          position: relative;
          top:2px;
        }
      }

      }

      span{
        font-weight: 700;
        color: #333333;

        &.studentName{
          font-size: 23px;
          margin-left: 5px 0;
        }

        &.code{
          display: flex;
          align-items: center;
        }
      }
    }
    span.conf{
      p{
        margin: 0;
        font-size: 14px;
        font-weight: 500;
        color: $E_blue;
        position: relative;
        top: 10px;
      }
    }

    .copy{
      padding:10px 30px;
      color: $E_blue;
      background-color: $E_blue_light;
      border-radius: 5px;
      cursor: pointer;
      transition: .15s all ease-out;
      position: absolute;
      bottom: 10px;
      right:30px;
      width: fit-content;

      &:hover{
        background-color: $E_blue_light2;
      }
    }
  }



  .btn{
    padding:10px 35px;
    color: white;
    background-color: $E_blue;
    border-radius: 5px;
    cursor: pointer;
    transition: .15s all ease-out;
    width: fit-content;
    margin: 0 auto;

    &:hover{
      background-color: $E_blue_dark;
    }
  }
  @media (max-width:660px){
    width: calc(100vw - 20px);
    padding-bottom: 0 !important;
    box-sizing: border-box;

    .inner{
      width: 100%;
      height: fit-content;
      text-align: center;
      padding:5px 20px 25px 20px;

      .conf p{
        position: absolute !important;
        bottom: 8px !important;
        top:initial !important;
        left: 50%;
        transform: translateX(-50%);
        width: fit-content;
      }

      .access{
        justify-content: center;
      }

      .copy{
        position: relative;
        right: 0;
        bottom: 0;
        margin: 15px auto 10px auto;
      }
    }
  }
}