@import '../../../../shared.scss';

.container{
  height: 55px;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
  transition: .2s all ease-out;
  position: relative;
  .activity{
    height: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: .2s all ease-out;
    padding: 0 15px;
    border-radius: 5px;
      p{
      color: $E_blue !important;
      font-size: 14px;
      font-weight: bold;
      margin: 0;
      margin-right: 8px;
    }
    &:hover{
      background-color: $E_blue_light2 !important;
    }
  }

  &.hidden{
    display: none;
  }

  section{
    border:1px solid transparent
  }

  &:hover{
    background-color: $E_blue_light;

    .column:first-of-type{
      &:hover{
        .profileBtn{
          opacity: 1;
        }
      }
    }
    section{
      transition: .2s all ease-out;
      border:1px solid $E_blue_light2;
    }
  }

  .column{
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;

    p{
      font-size:14px;
      font-weight: 300;
      color: #858585;
    }

    .text{
      text-align: left;

      p.email{
        font-size: 11px;
      }
    }

    img{
      height: 15px;
      margin-right: 5px;
    }

    &.one{
      width: 40%;
      justify-content: flex-start;
      padding-left: 20px;

      &:hover{
        background-color: $E_blue_light2;
      }

      .profileBtn{
        opacity: 0;
        transition: .2s all ease-out;
        cursor: pointer;
        display: flex;
        position: absolute;
        right: 10px;

        p{
          font-size: 11px;
          color:#074B60;
          font-weight: 500;
          position: relative;
          top: 1px;
        }
        img{
          height: 17px;
          margin:0 10px;
        }
      }

      img{
        height: 55px;
        margin:0 20px;
      }
      p{
        font-size:14px;
        // font-weight: 500;
        color:#333333;
      }
    }


    &.last{
      width: 10%;
    }

    .dots{
      position: absolute;
      right: 15px;
      height: 40px;
      width: 40px;
      border-radius: 10px;
      background:url('../../../../assets/dots_grey.svg') no-repeat center;
      transition: .2s all ease-out;
      cursor: pointer;
      
      &:hover{
        background:url('../../../../assets/dots_blue.svg') no-repeat center, $E_blue_light2;
        
      }

      .dropdown{
        width: 220px;
        max-height: 0px;
        height: fit-content;
        opacity: 0;
        transition: .2s all ease-out;
        background-color: white;
        overflow: hidden;
        position: absolute;
        top: 40px;
        right: 0;
        // transform: translateX(-50%);
        border-radius: 8px;
        background-color: white;
        -webkit-box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.1); 
        box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.1);
        z-index: 20;
        
        &.show{
          max-height: 200px;
          opacity: 1;
        }
        
        .dropRow{
          height: 40px;
          display: flex;
          align-items: center;
          // justify-content: space-evenly;
          position: relative;
          cursor: pointer;
          box-sizing:border-box;
          padding:0 15px;

          img{
            height: 15px;
            
            &.key{
              height: 9px;
            }

            &.delete{
              height: 16px;
            }
          }
          p{
            color: #858585 !important;
            font-size: 14px;
            font-weight: 300;
            margin: 0;
            margin-left: 8px;
          }

          &:hover{
            background-color: #f2f2f2;
            .copy{
              opacity: 1;
            }
          }

          &.red{
            
            p{
              color: #FB3333 !important;
            }
            &:hover{
              background-color: #FED6D6;
            }
          }
        }
      }
    }

    @media (max-width:660px){
      display: none;

      &.one{
        display: flex !important;
        width: calc(100% - 190px) !important;

        img{
          display: none;
        }

        p.email{
          display: none;
        }
      }
      &.two{
        justify-content: center;
        align-items: center;
        display: flex !important;
        width: 190px !important;
      }
    }
  }

  .approveBtns{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    right: 0;

    p{
      color: $E_orange;
      font-style: italic;
      font-size: 14px;
    }

    .btns{
      display: flex;
      align-items: center;
      box-sizing:border-box;
      padding:0 30px;
    }

    .approveBtn,
    .rejectBtn{
      height: 35px;
      line-height: 35px;
      text-align: center;
      width: 90px;
      border-radius: 5px;
      transition: .15s all ease-out;
      cursor: pointer;
      font-size: 15px;
    }

    .approveBtn{
      background-color: $E_green;
      color: white;
      margin-right: 10px;

      &:hover{
        background-color: rgb(116, 186, 12);
      }
    }

    .rejectBtn{
      color: #FB3333;
      background-color: #FED6D6;

      &:hover{
        background-color: #f8bcbc;
      }
    }

    @media (max-width:660px){
      display: none;
    }
  }

  &.synod{
    .column{
      &.one{
        box-sizing:border-box;
        padding:0 50px !important;
        width: calc(100% - 100px) !important;
        pointer-events: none;
        .text{
          p{
            margin:5px 0;
          }
        }

        @media (max-width:700px){
          width: 100% !important;

          .text{
            width: 100%;
          }
        }
      }

      &.two,
      &:nth-of-type(2){
        display: none !important;
      }
    }
  }
}