@import '../../../shared.scss';

.container{
  display: flex;
  width: 100px;
  height: 26px;
  padding:0 10px;
  border-radius: 100px;
  color: #858585;
  background-color: transparent;
  transition: .3s all ease-out;
  background-color: #e9e9e9;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all !important;
  z-index: 10 !important;
  position: relative;


  &.small{
    width: 75px;
  }


  h5{
    margin:0;
    font-size: 13px;
    font-weight: 500;
  }

  &.reset{
    pointer-events: none !important;
  }


  .copyIcon{
    height: 15px;
    width: 20px;
    background: url('../../../assets/icon-copy--grey.svg') no-repeat center;
    background-size: contain;
    transition: .3s all ease-out;
    // margin-left: 5px;
    position: relative;
    left: 8px;
  }


  &.blue{
    border:1px solid $E_blue;
    background-color: white;
    width: 125px;

    h5{
      color: $E_blue;
      font-size: 16px;
      position: relative;
      bottom:1px;
    }

    .copyIcon{
      height: 18px;
      background: url('../../../assets/icon_copy_teal.svg') no-repeat center;
      background-size: contain;
    }

    &.confirm{
      background-color: $E_blue_light;
    }

    &:hover{
      background-color: $E_blue_light;
    }
  }

  &.transparant{
    background-color: rgba(255, 255, 255, 0.515);

    .copyIcon{
      display: none;
    }

    h5{
      color: white;
      font-weight: 300;
    }

    .tooltip{
      left: 80%;
    }
  }

  &.noBorder{
    border-color: transparent;
  }

  .tooltip{
    width: fit-content;
    width: 140px;
    padding: 4px 10px;
    background-color: white;
    -webkit-box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.1); 
    box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.1);
    position: absolute;
    top: -0px;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10 !important;
    transform: translateX(-50%);
    transition: .15s all ease-out;
    opacity: 0;
    pointer-events: none;

    p{
      color: #858585;
      font-size: 12px;
    }

    @media (max-width:660px){
      display: none;
    }

  }
  &:hover{
    .tooltip{
      top: 30px;
      opacity: 1;
    }
  }

  // &:hover{
  //   color: #333333;
  //   background-color: #e9e9e9;

  //   .copyIcon{
  //     background: url('../../../assets/icon-copy--black.svg') no-repeat center;
  //     background-size: contain;
  //   }
  // }

}