@import '../../../shared.scss';

.container{
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 510;




  // visiblity class ('.show') contained in Teacher.module.scss/Synod.module.scss
  // nested classes for the first 2 childeren (#handle and #mainBlock) also there
  
  section[id='mainBlock']{
    height: 100%;
    max-width: 500px;
    width: 30%;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: white;
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.2);
    box-sizing:border-box;
    padding:20px;
    position: absolute;
    right: 0;
    transform: translateX(550px);
    transition: .3s all ease-in-out;
    z-index: 10;

  }


  &.bannerPadding{
    box-sizing:border-box;
    padding:50px 0 0 0;
    
    section[id='mainBlock']{
      height: calc(100% - 50px);
    }
    
  }


  

  .loaderDiv{
    height: 100%;
    max-width: 500px;
    width: 30%;
    position: absolute;
    right: 0;
    opacity: 0;
    pointer-events: none;
  }

  div[id='handle']{
    height: 100%;
    width: 100%;
    background-color: #acacac8c;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
  }

  .mainBlock{
    overflow-y: auto;


    .close{
      height: 30px;
      width: 30px;
      background: url('../../../assets/close_square_grey.svg') no-repeat center;
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 15px;
      
      &:hover{
        background: url('../../../assets/close_square_grey_hover.svg') no-repeat center;

      }
    }


    >div{
      width: 100%;
      text-align: left;
      margin-bottom: 40px;
    }

    h1{
      font-size: 32px;
      margin:40px 0 30px 0;
    }

    h3, h2{
      color: #333333;
    }
    h2{
      font-weight: 500;
      margin-bottom: 20px;
    }

    .typeRadios,
    .getStarted{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      // margin-bottom: 40px;

      p.start{
        color: #858585;
        font-size: 13px;
        font-weight: 300;
        position: relative;
        bottom: 13px;
      }
    }

    .radios{
      width: 100%;
      // // height: 310px;
      // display: flex;
      // flex-direction: column;
      // justify-content: space-between;


      .radioDiv{
        width: 100%;
        // height: 90px;
        border-radius: 10px;
        border:1px solid #d2d2d2;
        display: flex;
        margin:1px;
        cursor: pointer;
        transition: .2s box-shadow ease-out,.2s -webkit-box-shadow ease-out, .2s -moz-box-shadow ease-out;
        margin: 15px 1px 1px 1px;
        position: relative;

        .imgDiv{
          height: 90px;
          width: 110px;
          position: absolute;
          bottom: 0;
          
          
          &.story{
            background: url('../../../assets/cover_story.svg') no-repeat center 15px;
            background-size: contain;
          }
          
          &.journal{
            background: url('../../../assets/cover_journal.svg') no-repeat center 15px;
            background-size: contain;
          }
          
          &.assignment{
            background: url('../../../assets/icon_assignment.svg') no-repeat center 2px;
            background-size: contain;
          }
        }

        .text{
          width: calc(100% - 110px);
          box-sizing:border-box;
          padding:0 20px 0 10px ;
          height: 100%;
          text-align: left;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 10px 20px 10px 10px;
          margin-left: 110px;

          h3{
            margin-bottom: 5px;
          }

          p{
            font-size: 12px;
            font-weight: 300;
            color: #858585;
            line-height: 21px;
          }
        }

        &.checked{
          border-width: 2px;
          border-color: $E_blue;
          margin:14px 0 0 0px;
          pointer-events: none;
        }

        &:hover{
          -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.2);
          -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.2);
          box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.2);
        }

        @media (max-width:1120px){
          flex-direction: column-reverse;
          align-items: center;

          .imgDiv{
            position: relative;
          }

          .text{
            width: 80%;
            margin-top: 10px;
            margin-left: 0;
            text-align: center;
          }
        
        }
      }
    }

    .startRadios{
      width: 100%;
      display: flex;
      justify-content: space-between;

      label{
        height: 220px;
        width:49%;
      }
      .startRadio{
        height: 100%;
        width:100%;
        border:1px solid #d2d2d2;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        margin: 1px;
        cursor: pointer;
        transition: .2s box-shadow ease-out,.2s -webkit-box-shadow ease-out, .2s -moz-box-shadow ease-out;
        text-align: center;
        position: relative;

        &.blocked{
          pointer-events: none;

          h5{
            position: absolute;
            top:50%;
            left:50%;
            transform: translate(-50%, -50%);
            color: #FF8258;
            font-size: 13px;
            padding-bottom: 30px;
            width: 100px;
          }

          .imgDiv,
          h3{
            opacity: .1;
          }
        }

        .imgDiv{
          height: 80px;
          width: 80px;

          &.fromScratch{
            background:url('../../../assets/penPaper.svg') no-repeat center;
            background-size: contain;
          }

          &.resource{
            background:url('../../../assets/books.svg') no-repeat center;
            background-size: contain;
            height: 70px;
            width: 70px;
          }
        }

        h3{
          margin-top: 20px;
          width: 160px;
          height: 14px;
        }

        .hoverDiv{
          display: none;
        }

        &.selectedItem{
          justify-content: space-between;
          align-items: flex-start;
          text-align: left;
          box-sizing: border-box;
          padding:15px;
          pointer-events: all !important;

          h4{
            color: #333333;
            font-size: 15px;
            margin-bottom: 8px;
          }

          p{
            font-size: 13px;
            color: #858585;
            box-sizing:border-box;
          }

          .subjectPill{
            width: fit-content;
            padding: 3px 12px;
            border-radius: 20px;
            background-color: $E_green;
            color: white;
            font-size: 13px;
            margin: 5px 0 8px 0;

            &.Sci{
              background-color: $E_purple;
            }

            &.His{
              background-color: $E_orange_dark;
            }

            &.Poe{
              background-color: $E_orange;
            }

            &.Mat{
              background-color: $E_blue;
            }
          }

      
            .hoverDiv{
              position: absolute;
              display: initial;;
              top: 0;
              left: 0;
              height: 100%;
              width: 100%;
              background-color: rgba(11, 158, 184, 0.289);
              -webkit-backdrop-filter: blur(5px);
              backdrop-filter: blur(5px);
              display: flex;
              align-items: center;
              justify-content: center;
              opacity: 0;
              transition: .15s opacity ease-out;
              border-radius: 10px;

              .changeBtn{
                padding: 5px 20px;
                border-radius: 5px;
                background-color: white;
                color: $E_blue;
                font-size: 13px;
                font-weight: 500;
                cursor: pointer;
                &:hover{
                  -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.2);
                  -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.2);
                  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.2);
                }
              }
            }

            &:hover{
              .hoverDiv{
                opacity: 1;
              }
            }

        }
        
        &.checked{
          margin: 0px;
          border-width: 2px;
          border-color: $E_blue;
          pointer-events: none;
        }

        &:hover{
          -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.2);
          -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.2);
          box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.2);
        }
      }

      @media (max-width:1400px){
        flex-direction: column;

        label{
          width: 100%;
          margin-bottom: 15px;
        }
      
      }
    }

    .name{
      position: relative;

      input[type=text]{
        width: 100%;
        height: 40px;
        border:1px solid #cacaca;
        border-radius: 5px;
        box-sizing:border-box;
        padding:0 10px;
        font-size: 16px;
        color: #333333;

        &.red{
          border-color: red;
        }
      }

      p.error{
        color: red;
        font-size: 12px;
        font-weight: 300;
        position: absolute;
        bottom: -23px;
        left: 1px;
      }
    }

    .grading{
      .sliderDiv{
        height: 40px;
        display: flex;
        align-items: center;
        box-sizing:border-box;
        padding:0 30px 0 0;
        position: relative;
        left:-10px;
    
        p{
          color: #858585;
          font-size: 15px;
          opacity: 1;
          transition: .2s all ease-out;
        }
    
        .slider{
          height: 35px;
          width: 55px;
          border-radius: 20px;
          background-color: #d2d2d2;
          margin: 0 10px;
          position: relative;
    
          .sliderInner{
            height: 31px;
            width: 31px;
            border-radius: 50%;
            background-color: white;
            position: absolute;
            left: 2px;
            top:2px;
            transition: .2s all ease-out;
            cursor: pointer;
    
            &.active{
              left:22px;
            }
          }
    
          &.active{
            background-color: $main-green;
          }
          
        }
      }

      .gradingRadios{
        display: flex;
        flex-wrap: wrap;
        margin-top: 15px;

        &.inactive{     
          opacity: .3;
          pointer-events: none;
        }
        .gradingRadio{
          padding: 5px 20px;
          border:1px solid #858585;
          border-radius: 20px;
          cursor: pointer;
          margin: 0 10px 8px 0;

          p{
            margin: 0;
            color: #858585;
          }

          &.checked{
            border-color: $E_blue;
            background-color: $E_blue;
            p{
              color:white
            }
          }
        }
      }
    }

    .createBtn{
      height: 45px;
      border-radius: 5px;
      background-color: $E_blue;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-bottom: 10px !important;
      transition: .2s all ease-out;

      p{
        margin:0;
        color: white;
      }

      &:hover{
        background-color: $E_blue_dark;
      }
    }
  }

  // @media (max-width:660px){
  //   display: none;
  // }


  &.mobile{
    height: 100vh;
    width: 100vw;
    pointer-events: all;

    .close{
      display: none;
    }

    section{
      transform: translateX(0px) !important;
      width: 100%;
    }

    p.error{
      bottom:-33px !important;
    }
    
    .radios{
      .radioDiv{
        height: 140px;
        flex-direction: row;
        overflow: hidden;
        justify-content: space-between;

        .text{
          text-align: left;
          width: calc(100% - 130px);
          p{
            margin:0;
          }
        }

        .imgDiv{
          height: 140px;
          position: relative;
          top: 8px;
          left: 25px;
          width: 90px;
        }
      }
      
      label:last-of-type{
        .radioDiv{
          .imgDiv{
            left: 0px;
            top: 0px;
            height: 140px;
            width: 130px;
            background-size: cover;
            background-position: -30px 0px;
          }
        }
      }
    }

    .startRadios{
      flex-direction: row !important;
      label{
        width:calc(50% - 5px);
        height: 180px;
      }
    }
    
  }
}