@import '../../../../shared.scss';

.container{
  padding:40px 55px;
  width: 1000px;

  *{
    font-family: 'Sofia pro';
  }

  .showRes{
    display: none;
  }

  h1{
    padding-left: 60px;
    background: url('../../../../assets/user_add.svg')no-repeat left center;
    background-size: 40px;
  }

  .main{
    height:480px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 10px;

    .topBar{
      width: 100%;
      box-sizing:border-box;
      padding:15px 0;

      h2{
        font-size: 16px;
        color: #333333;;
        font-weight: 500;
      }


      .search{
        height: 40px;
        width: 48%;
        border-radius: 20px;
        overflow: hidden;
        background-color: #F2F2F2;
        align-items: center;
        cursor: pointer;
        border:2px solid white;
        transition: .15s all ease-out;
        transition: .4s width ease-in-out;
        position: relative;
        display: flex;
        align-items: center;
        margin-top: 10px;
        

        .inner{
          height:calc(100% - 2px);
          width:calc(100% - 2px);
          background-color: white;
          border:1px solid  #cacaca;
          border-radius: 20px;
          overflow: hidden;
          position: absolute;
          z-index: 1;
          right: 0;
          display: flex;
          background-color: #f2f2f2 ;

        }
        input[type=text]{
          width:calc(100% - 50px);
          height: 30px;
          line-height: 30px;
          border:none;
          position: absolute;
          z-index: 5;
          right: 10px;
          top: 2px;
          z-index: 10;
          background-color: transparent;
        }
        
        .lupa{
          min-height:40px;
          max-height:40px;
          min-width:40px;
          max-width:40px;
          border-radius: 50%;
          background: url('../../../../assets/icon-lupa.png') no-repeat 11px 10px, #F2F2F2;
          background-size: 17px;
          transition: .15s all ease-out;
          position: relative;
          z-index: 10;
          pointer-events: none;
        }


        &.focus{
          border:2px solid #CDDFFA;
          .inner{
            border-color: #065FE5;
          }
        }
      }
    }

    .left{
      width: 48%;
      border:1px solid #cacaca;
      border-radius: 15px;
      -webkit-box-shadow: 0px 2px 9px 0px #00000025; 
      box-shadow: 0px 2px 9px 0px #00000025;
      overflow: hidden;
      height: calc(100% - 150px);

      .tableHeader{
        height:35px;
        width:100%;
        border-bottom:1px solid #cacaca;
        background-color: #f2f2f2;
        display: flex;
        align-items: center;
        box-sizing:border-box;
        padding:0 0 0 50px;

        p{
          color: #858585;
          font-size: 14px;
          text-transform: uppercase;
        }
      }


      .row{
        display:flex;
        height: 60px;
        border-bottom: 1px solid #cacaca;
        align-items: center;
        justify-content: space-between;
        box-sizing:border-box;
        padding:0 15px;
        

        .studentDetails{
          display: flex;
          align-items: center;
          img{
            margin-right: 10px;
            border-radius: 50%;
            height:50px;
            width: 50px;
            object-fit: contain;
          }

          h5{
            font-weight: 500;
            color: #333333;
            font-size: 15px;
          }
        }

        .add{
          display: flex;
          align-items: center;
          justify-content:space-between;
          box-sizing:border-box;
          padding:0 12px;
          height: 40px;
          width: 120px;
          border-radius: 5px;
          background-color: $E_blue_light;
          transition: .15s all ease-out;
          cursor: pointer;

          p{
            margin:0;
            color: $E_blue;
            font-size: 14px;
          }

          &:hover{
            background-color: $E_blue_light2;
          }
        }
      }

      .tableRows{
        height:calc(100% - 40px);
        overflow-y: scroll;
        position: relative;

        input{
          display: none;
        }
        
        
        label > input:checked + .row{
          display: none;

        }
      }
    }
  }


  .right{
    width: 48%;
    height: calc(100% - 150px);

    .placeholder{
      width: 100%;
      text-align: center;
      margin-top: 50px;

      h5{
        color: #cacaca;
        font-weight: 300;
        font-size: 15px;
      }
    }

    .rightInner{
      width: 100%;
      height: 100%;
      border:1px solid #cacaca;
      border-radius: 15px;
      -webkit-box-shadow: 0px 2px 9px 0px #00000025; 
      box-shadow: 0px 2px 9px 0px #00000025;
      overflow: hidden;


      .tableHeader{
        height:35px;
        width:100%;
        border-bottom:1px solid #cacaca;
        background-color: #f2f2f2;
        display: flex;
        align-items: center;
        box-sizing:border-box;
        padding:0 0 0 50px;

        p{
          color: #858585;
          font-size: 14px;
          text-transform: uppercase;
        }
      }


      .row{
        display:flex;
        height: 60px;
        border-bottom: 1px solid #cacaca;
        align-items: center;
        justify-content: space-between;
        box-sizing:border-box;
        padding:0 15px;

        .studentDetails{
          display: flex;
          align-items: center;
          img{
            margin-right: 10px;
            border-radius: 50%;
            height:50px;
            width: 50px;
            object-fit: contain;
          }

          h5{
            font-weight: 500;
            color: #333333;
            font-size: 15px;
          }
        }

        .remove{
          height: 35px;
          width: 35px;
          display: flex;
          align-items: center;
          justify-content: center;background-color: $E_blue_light;
          border-radius: 5px;
          cursor: pointer;
          transition: .15s all ease-out;

          .cross{
            height: 12px;
            width: 12px;
            background: url('../../../../assets/cross_blue.svg') no-repeat center;
            background-size: contain;
            pointer-events: none !important;
          }
          
          &:hover{
            background-color: #FED6D6;

            .cross{
              background: url('../../../../assets/cross_red.svg') no-repeat center;
              background-size: contain;
            }
          }
        }
      }

      .tableRows{
        height:calc(100% - 40px);
        overflow-y: scroll;
      }
    }

  }


  .buttons{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    .cancelBtn,
    .submitBtn{
      height: 40px;
      width: 130px;
      border-radius: 5px;
      cursor: pointer;
      transition: .15s all ease-out;
      display: flex;
      align-items: center;
      justify-content: center;

      &.loading{
        pointer-events: none;
      }
      
      img{
        height: 40px;
      }
    }

    .cancelBtn{
      background-color: $E_blue_light;
      color: $E_blue;
      margin-right: 10px;

      &:hover{
        background-color: $E_blue_light2;
      }
    }

    .submitBtn{
      background-color: $E_blue;
      color: white;

      &:hover{
        background-color: $E_blue_dark;
      }

      &.inactive{
        opacity: .3;
        pointer-events: none;
      }
    }
  }

  @media (max-width:660px){
    background-color: white;
    box-sizing: border-box;
    padding:20px;
    border-radius: 10px;
    width: calc(100vw - 10px);

    .hideRes{
      display: none;
    }
    .showRes{
      display: initial;
    }

    h1.title{
      font-size: 20px !important;
    }

    h2{
      text-align: center;
    }

    .topBar{
      padding:0 !important;
      margin-bottom: 15px;
    }

    .search{
      width: 100% !important;
    }

    .main{
      flex-direction: column;
      flex-wrap: nowrap;
      height: calc(100vh - 175px);

      .left,
      .right{
        width: 100%;
        margin-bottom: 15px;
        height: calc(50% - 50px);
      }
    }

    .row{
      padding:0 10px 0 5px !important;
      height: 50px !important;
      .studentDetails{
        img{
          margin-right: 5px !important;
        }
        h5{
          font-size: 13px !important;
        }

      }
      .add{
        width: 90px !important;
        p{
          position: relative;
          left: 5px;
        }
      }
    }


  }
}