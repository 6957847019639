@import '../../shared.scss';

.container{
  height:100%;
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
  background-color: $main-bluegrey;
  transition: .3s all ease-in-out;
  position: relative;
  // margin-left: 75px;
  
  @media (max-width:900px){
    margin: 0;
    padding: 1rem;
    padding-bottom: 9rem;
  }


  @media (max-width:660px){
    display: flex;
    flex-direction: column-reverse;
    height:-webkit-fill-available;
    min-height: initial;
    overflow: initial;
    padding: 0;
    padding-bottom: 9rem;

  }

  &.bannerPadding{
    box-sizing:border-box;
    padding:50px 0 0 0;
  }

  //class to display newActivityModal
  .show{
    pointer-events: all;

    section[id='mainBlock']{
      transform: translateX(0px);
    }

    div[id='handle']{
      opacity: 1 !important;
    }
    div[id='loaderDiv']{
      opacity: 1 !important;
    }
  }


  div * {
    margin: 0;
  }
}


@media (max-width:600px){
  .container{
    width: 100vw;
  }
}