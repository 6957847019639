@import '../../../../shared.scss';

.container{
  width:860px;
  height: fit-content;
  max-height: 900px;
  text-align: center;
  transition: .5s all ease-in-out;


  
  .header{

    h4{
      font-size: 17px;
      color:#333333;
      font-weight: 300;
    }
  }

  .main{
    display: flex;
    justify-content: center;

    .stepOneRadios{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 90%;
      // height: fit-content;
      max-height: 440px;
      margin:30px 0;
      flex-wrap: wrap;
      overflow-y: auto;

      &.narrow{
        width: 60%;
        // border:1px solid chartreuse;
      }

      &.two{
        width: 60%;
        // border:1px solid chartreuse;
      }

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 6px;
        background-color: $main-blue;
        border:1px solid #cacaca;
      }
    }

    .stepOneRadio{
      position: relative;
      min-width:250px;
      max-width:250px;
      height:205px;
      border-radius: 20px;
      border:1px solid #c2c2c2;
      margin-bottom: 8px;

      img{
        width:130px;
        height:120px;
        object-fit: contain;
        position: relative;
      }

      h3{
        color:#333333;
        font-weight: 700;
        position: relative;
        bottom:8px;
      }

      .btns{
        display:flex;
        height:35px;
        width:97%;
        margin: 0 auto;
        margin-top: 10px;;
        justify-content: space-evenly;

        .btn{
          height:35px;
          width:110px;
          text-align: center;
          line-height: 35px;
          cursor: pointer;
          transition: .3s all ease-out;
          border-radius: 8px;

          &:first-of-type{
            color:$main-blue--bright;
            background-color: white;
            border:1px solid $main-blue--bright;

            &:hover{
              color:white;
              background-color: $main-blue--bright;
            }
          }

          &:last-of-type{
            color:white;
            background-color: $main-blue--bright;
            border:1px solid $main-blue--bright;

            &:hover{
              color:$main-blue--bright;
              background-color: white;
            }
          }
        }
      }
    }

    .readViewDiv{
      height:500px;
      width:100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .stepTwoRadios{
        height:60px;
        width: 99%;
        margin:5px auto;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-evenly;

        input[type="radio"]{
          display: none;
        }
        
        input + .stepTwoRadio{
          display: flex;
          align-items: center;
          width: 275px;
          // border:1px solid chartreuse;
          height:60px;
          line-height: 60px;
          text-align: center;
          cursor: pointer;
          border-radius: 10px;

          &.narrow{
            width: 205px;
          }

          &.two{
            width:400px;
          }

          &:hover{
            background-color: #f2f2f2;
          }

          img{
            height:50px;
            object-fit: contain;
            // border:1px solid chartreuse;
            margin-right: 10px;
          }
        }
        input:checked + .stepTwoRadio{
          -webkit-box-shadow: 0px 0px 10px 1px rgba(0,94,255,0.6);
          -moz-box-shadow: 0px 0px 10px 1px rgba(0,94,255,0.6);
          box-shadow: 0px 0px 10px 1px rgba(0,94,255,0.6);
          background-color: white;
        }
      }

      .entryDiv{
        margin:25px 0 50px 0;
        height:430px;
        width:100%;
        border:1px solid #cacaca;
        overflow: hidden;
        border-radius: 15px;

        .entryText{
          height: 81%;
          width:98%;
          margin:0 auto;
          position: relative;
          top:3px;
          overflow-y: auto;
          background-color: white;
          font-size: 14px;
          line-height: 22px;
          color:#858585;
          font-weight: 300;
          // border:1px solid chartreuse;
        }
      }

      .selectBtn{
        width:275px;
        height:35px;
        line-height: 35px;
        text-align: center;
        color:white;
        background-color: $main-blue--bright;
        border:1px solid $main-blue--bright;
        cursor: pointer;
        margin: 0 auto;
        margin-top: 25px;
        border-radius: 8px;
        transition: .3s all ease-in-out;
        position: absolute;
        bottom:20px;
        left:0;
        right: 0;


        &:hover{
          color:$main-blue--bright;
          background-color: white;
        }
      }
    }
  }
}