@import '../../../shared.scss';

.container{
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 600px;
  position: relative;
  
  *{
    font-family: 'Sofia pro';
  }


  .close {
    cursor: pointer;
    opacity: 1;
    position: absolute;
    background-image: url('../../../assets/icon-close.png');
    height: 35px;
    width: 35px;
    right: -15px;
    top: -10px;
    z-index: 1500;
    transition: .3s all ease-out;
    pointer-events: initial;

    &:hover {
      opacity: 0.7;
      transform: scale(1.05);
    }
  }

  .sidebar{
    width: 320px;
    height: 100%;
    box-sizing:border-box;
    padding:25px 0 0 0;

    h1{
      padding-left: 15px;
      margin-bottom: 10px;
    }

    .search{
      height: 40px;
      // width: 40px;
      width: 100%;
      border-radius: 20px;
      overflow: hidden;
      background-color: #F2F2F2;
      align-items: center;
      cursor: pointer;
      border:2px solid white;
      transition: .15s all ease-out;
      transition: .4s width ease-in-out;
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      

      .inner{
        height:calc(100% - 2px);
        width:calc(100% - 2px);
        background-color: white;
        border:1px solid  #cacaca;
        border-radius: 20px;
        overflow: hidden;
        position: absolute;
        z-index: 1;
        right: 0;
        display: flex;
        background-color: #f2f2f2 ;

      }
      input[type=text]{
        width:calc(100% - 50px);
        height: 30px;
        line-height: 30px;
        border:none;
        position: absolute;
        z-index: 5;
        right: 10px;
        top: 2px;
        z-index: 10;
        background-color: transparent;

        &::placeholder{
          font-weight: 300;
          font-size: 15px;
          position: relative;
          top: 1px;
        }
      }
      
      .lupa{
        min-height:40px;
        max-height:40px;
        min-width:40px;
        max-width:40px;
        border-radius: 50%;
        background: url('../../../assets/icon-lupa.png') no-repeat 11px 10px, #F2F2F2;
        background-size: 17px;
        transition: .15s all ease-out;
        position: relative;
        z-index: 10;
        pointer-events: none;
      }

      &.open{
        border:2px solid #CDDFFA;
        
        .inner{
          border-color: #065FE5;
        }
      }
    }

    .randomize{
      height: 40px;
      width: 160px;
      border-radius: 8px;
      background-color: $E_blue_light;
      display: flex;
      align-items: center;
      // justify-content: center;
      box-sizing:border-box;
      padding:0 10px;
      transition: .15s all ease-out;
      cursor: pointer;
      margin-bottom: 10px;

      img{
        height: 17px;
        width: 17px;
        object-fit: contain;
        margin-right: 10px;
      }
      h5{
        color:$E_blue;
        font-size: 16px;
        position: relative;
        // top: 2px;
        font-weight: 500;
      }

      &:hover{
        background-color: $E_blue_light2;
      }

      &.inactive{
        opacity: .6;
        pointer-events: none;
      }
    }

    .studentList{
      width: 100%;
      height: calc(100% - 160px);
      overflow-y: auto;
      overflow-x: hidden;
      border: 1px solid #cacaca;

      h5.placeholder{
        width:100%;
        text-align: center;
        margin-top: 40px;
        font-weight: 300;
        color: #858585;
      }


    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width:5px;
      -webkit-appearance: none;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: #cacaca;;
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }
    // &::-webkit-scrollbar-track-piece:end {
    //     margin-bottom: 10px; 
    // }
    
    // &::-webkit-scrollbar-track-piece:start {
    //     margin-top: 10px;
    // }
      
      .studentBlock{
        height: 50px;
        border:solid #cacaca;
        border-width: 1px 0;
        border-bottom: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        box-sizing:border-box;
        padding:0 10px;
        cursor: pointer;
        position: relative;
        transition: .15s all ease-out, 0s border linear;


        img.avatar{
          min-height: 40px;
          max-height: 40px;
          min-width: 40px;
          max-width: 40px;
          border-radius: 50%;
          background-color: $E_blue_light;
          object-fit: contain;
          margin-right: 15px;
          transition: .15s all ease-out;
        }

        h5{
          font-weight: 500;
        }


        &:hover{
          background-color: $E_blue_light;

          img.avatar{
            background-color: $E_blue_light2;
          }
        }

        .roomTab{
          position: absolute;
          right:0;
          width: 0;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          transition: .15s all ease-out;
          overflow: hidden;

          &.display{
            width: 35px;
          }

          h5{
            margin:0px 0;
            color: white;
            font-weight: 500;
          }

          img{
            height: 15px;
            width: 15px;
          }
        }


        &:first-of-type{
          border-top: none;
          &.selected{
            border-top:1px solid $E_blue;
            margin-top: -0px;
          }
        }

        &:last-of-type{
          border-bottom: 1px solid #cacaca;
        }
        
        &.selected{
          height: 51px;
          border:1px solid $E_blue;
          border-color: $E_blue;
          margin-top: -1px;
          margin-bottom: 0px;
        }
      }
    }
  }

  .main{
    width: calc(100% - 340px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing:border-box;

    .roomsDiv{
      width: 100%;
      max-height: calc(100% - 50px);
      overflow-y: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      box-sizing:border-box;
      padding:5px;
    }

    .btns{
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

      .cancelBtn,
      .confirmBtn{
        height: 40px;
        width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        cursor: pointer;
        transition: .15s all ease-out;
        margin-left: 15px;
        opacity: .6;
        pointer-events: none;

        img{
          height: 40px;
        }

        &.loading{
          pointer-events: none;
          background-color: $E_blue !important;
        }

        &.active{
          opacity: 1;
          pointer-events: all;
        }
      }



      .cancelBtn{
        color: $E_blue;
        background-color: $E_blue_light;

        &:hover{
          background-color: $E_blue_light2;
        }
      }

      .confirmBtn{
        color: white;
        background-color: $E_blue;

        &:hover{
          background-color: $E_blue_dark;
        }
      }


  .confOverlay{
    position: absolute;
    top: -20px;
    left: -30px;
    height: calc(100% + 40px);
    width: calc(100% + 60px);
    background-color: rgba(0, 0, 0, 0.3);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border-radius: 15px;
    z-index: 15;
    display: flex;
    align-items: center;
    justify-content: center;
    

    .confModal{
      height: 200px;
      width: 450px;
      background-color: white;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      p{
        width: 80%;
        text-align: center;
        color: #858585;
        font-size: 15px;
        line-height: 23px;
        font-weight: 300;
      }

      .buttons{
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        top: 2px;

      }
    }
  }

}