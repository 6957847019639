@import '../../../../shared.scss';

.container{
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: .2s all ease-out;
  padding: 0 15px;
  border-radius: 5px;

  &:hover{
    background-color: $E_blue_light2 !important;
  }

  p{
    color: $E_blue !important;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    margin-right: 8px;
  }

  &.empty{
    background-color: $E_blue_light;

    @media (max-width:660px){
      width: 100px;
      justify-content: center;
      

      p span{
        display:none;
      }
    }
  }

  img.chevron{
    height: 6px !important;
    transform: rotate(180deg);
  }

  .dropdown{
    width: 220px;
    max-height: 0px;
    height: fit-content;
    opacity: 0;
    transition: .2s all ease-out;
    background-color: white;
    overflow: hidden;
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 8px;
    background-color: white;
    -webkit-box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.1); 
    box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.1);
    z-index: 20;
    
    &.show{
      max-height: 450px;
      opacity: 1;
    }
    
    .dropRow{
      height: 40px;
      display: flex;
      align-items: center;
      // justify-content: space-evenly;
      position: relative;
      cursor: pointer;
      box-sizing:border-box;
      padding:0 15px;

      img{
        height: 15px;

        &.copy{
          opacity: 0;
          transition: .2s all ease-out;
          position: absolute;
          right: 15px;

        }
      }
      p{
        color: #858585 !important;
        font-size: 14px;
        font-weight: 300;
        margin: 0;
        margin-left: 8px;
        width: 135px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &:hover{
        background-color: #f2f2f2;
        .copy{
          opacity: 1;
        }
      }

      &.addEmail{
        p{
          color: $E_blue !important;
          position: relative;
          left: -8px;
        }

        &:hover{
          background-color: $E_blue_light;
        }
      }
    }
  }

}