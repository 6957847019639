@import '../../../../shared.scss';


.container{ 
  height: 500px;
  overflow-y: scroll;
  margin: 10px 0;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: white;
    border:1px solid #cacaca;
  }

  .header{
    display: flex; 
    gap: 1rem;
    margin-top: 2rem;
  }

  h4{
    display: flex;
    align-items: center;
    gap: 8px;
    color: #064149;
    cursor: pointer;
  }
    
  .tableHeader{
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    margin: 1rem 0;

    .column{
      color: #858585;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 11%;
      line-height: 30px;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 1.04px;
      box-sizing:border-box;
      padding: 5px 0 0 0;
      text-align: center;
      &.wide{
        width:15%
      }

      &:first-of-type{
        width: 45%;
        justify-content: flex-start;
        box-sizing:border-box;
        padding-left: 80px;
        background: #fff;
        color: #333333;
        text-transform: capitalize;
        font-size: 12px;
        font-weight: 500;
        text-align: left;
      }
      span{
        border-radius: 15px;
        height: 20px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        &.red{
          border: 2px solid #ff3131;
          color: #ff3131;
        }
        &.orange{
          color: #FF8258;
          border: 2px solid #FF8258;
        }
        &.green{
          color:#638912 ;
          border: 2px solid #638912;
        }
      }

      .statusDot{
        height: 18px;
        width: 18px;
        border-radius: 50%;
  
        &.approved{
          background-color: #97D318;
        }
        &.pendingRes{
          background-color: $main-pink;
        }
        &.submitted{
          background-color: $main-blue--light;
        }
        &.started{
          background-color: $main-yellow;
        }
        &.notStarted{
          background-color: $grey-twotwo;
        }
      }

      .approvedSlider{
        height: 22px;
        width: 45px;
        border-radius: 14px;
        background-color: #d2d2d2;
        position: relative;
        cursor: pointer;
  
        &.disabled{
          pointer-events: none;
          opacity: .6;
        }
  
        .handle{
          height: 18px;
          width: 18px;
          border-radius: 10px;
          background-color: white;
          position: relative;
          top: 2px;
          left: 2px;
          z-index: 5;
        }
        
  
        .yes,
        .no{
          font-size: 11px;
          text-transform: uppercase;
          font-weight: 700;
          position: absolute;
          bottom: -15px;
          z-index: 1;
        }
  
        .yes{
          left: 4px;
          color: white;
          opacity: 0;
        }
  
        .no{
          right: 6px;
          color: #858585;
        }
  
  
  
        &.approved{
          background-color: #97D318;
  
          .handle{
            left: 25px;
          }
  
          .yes{
            opacity: 1;
          }
  
          .no{
            opacity: 0;
          }
        }
  
        *{
          transition: .15s all linear;
        }
      }

      .voted{
        height: 20px;
        width: 20px;
        background: url('../../../../assets/voted_no.svg') no-repeat center;
        
        &.yes{
          background: url('../../../../assets/voted_yes.svg') no-repeat center;
        }
      }
    }

    &.synod{
      .column{
        width: 100px;
        &.hide{
          display: none !important;
        }
  
        &:first-of-type{
          width: calc(100% - 300px);
        }
    
      }
    }
  }

}