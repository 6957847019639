@import '../../../../shared.scss';

.container{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  justify-content: flex-end;
  position:relative;

  &.noFlex{
    flex-direction: column;
    align-items: flex-end;
    
    .statusBtn{
      cursor: default;
    }
  }

  .blockedText{
    color:#858585;
    // position:absolute;
    // // top:50px;
    right:5px;
    font-weight:300;
    font-size:12px;
    white-space:nowrap;
    width: 100%;
    
    a{
      color:$E_blue;
      
      &:hover{
        color:#59b1c6 !important;
      }
    }
  }

  .statusBtn{
    height: 40px;
    width: 120px;
    border-radius: 8px;
    background-color: #CDE4EC;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 10px;
    transition: .15s all ease-out;
    position:relative;
    cursor: pointer;

    &:hover{
      background-color: #B5D8E3;
    }

    &.disabled{
      display: none;
      opacity: .6;
      pointer-events: none;
    }

    &.shownAndDisabled{
      opacity: .3;
      pointer-events: none;
    }

    &.setLive{
      background-color: $E_blue;
      h5{
        color:white !important
      }
      
      &.blocked{
        background-color: #077CA050;
        
        &:hover{
          background-color: #077CA050 !important;
        }
      }

      &:hover{
        background-color: #045b75;
        //class passed to <Tooltip/> component through props
        .setLiveTooltipHover{
          opacity: 1 !important;
          top: -75px !important;
        }
      }
    }

    &.unshare{
      background-color: transparent;
      &:hover{
        h5{
          text-decoration: underline;
        }
      }
    }

  }

  h5{
    color:$E_blue !important;
    margin-top: 0 !important;
  }

  @media (max-width:660px){
    position: relative;
    left: 5px;

    .statusBtn{
      height: 40px;
      width: fit-content;
      min-width: 50px;
      padding: 0 10px;
      margin-left: 5px;
      h5{
        font-size: 13px;
        position: relative;
        top: 1px;
      }

      // &.setLive{
      //   background-color: #55CFF5;

      //   h5{
      //     color: $E_blue_dark;
      //   }
      // }
    }
    
  }
}