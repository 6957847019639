@import '../../../../shared.scss';

.container{
  position: relative;
  // height: 100%;
  // width:100%;
  height:650px;
  width: 85vw;
  transition: .3s all ease-out;


  .lock{
    cursor: pointer;
    width: 15px;
    height: 15px;
    object-fit: contain;
  }

  input[type='radio']{display: none}
  
  h1{
    width:100%;
    text-align: center;
    font-size: 30px;
    color: #333333;
  }
  h2{
    font-size: 25px;
    color: #333333;
  }
  h3{
    font-size: 18px;
    color: #333333;
    font-weight: 700;
    margin:3px 0;
  }

  h6{
    margin:3px 0;
    color: #858585;
    font-size: 12px;
    font-weight: 300;
  }

  .colorTab{
    height:50px;
    width:10px;
    position: absolute;
    border-radius: 50px;
    left:-5px;
    top:0;
    bottom:0;
    margin:auto 0;
  }


  .topBar{
    height:80px;
    width: fit-content;
    width: -moz-max-content;
    min-width:500px;
    border-radius: 10px;
    box-sizing:border-box;
    padding:15px;
    display: flex;
    justify-content: space-between;
    -webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
    -moz-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
    box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
    overflow: hidden;
    position: relative;

    .text{
      width:60%;
      height:100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .author{
        display: flex;
        height:30px;
        align-items: center;
        h6{color:#333333}
    
        img{
          height:30px;
          width:30px;
          border-radius: 50%;
          object-fit: contain;
          margin-right: 6px !important;
          border:1px solid #f2f2f2;
        }
      }
    }

    .details{
      // position: absolute;
      // right: 10px;
      margin-left: 15px;
      max-width: 70%;
      height: 100%;
      float: right;

      .grades{
        display: flex;
        text-align: right;
        display: flex;
        justify-content: flex-end;

        h6{ color:#333333}
        .gradeDots{
          width: fit-content;
          width: -moz-max-content;
          // max-width:75%;
          height: 30px;
          overflow: hidden;   
          

          .dotsInner{
            width: fit-content;
            width: -moz-max-content;
            height: 30px;
            display: flex;
          }
        }
      }

      .typeSubject{
        margin-top: 3px;
        display: flex;
        justify-content: flex-end;
      }
    }


    .widePill, .gradeDot{
      background-color: $main-blue--bright;
      color:white;
      height:25px;
      line-height: 25px;
      text-align: center;
      font-size: 10px;
      margin:0 0 0 3px;
      display: inline-block;
    }
    .sophia{
      background: #1E9AAA;
      height: 18px;
      width: 100px;
      font-size: 12px;
      text-align: center;
      color: #fff;
      border-radius: 60px;
      display: flex;
      align-items: center;
      justify-content: center;

    }
    .widePill{
      height: 20px;
      line-height: 20px;
      border-radius: 60px;
      padding: 0 20px;
      font-size: 12px;
    }
    .gradeDot{
      border-radius: 50%;
      width: 25px;
    }
  }

  .bottom{
    display: flex;
    height: calc(100% - 100px);
    // justify-content: space-between;
    gap: 2rem;
    margin-top:25px;
    
    .sections{
      width:360px;
      height:100%;
      border-radius: 10px;
      box-sizing:border-box;
      padding:15px;
      -webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
      -moz-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
      box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);

      .sectionDivs{
        width:100%;
        height:calc(100% - 190px);
        margin:15px 0;
        // border:1px solid chartreuse;
        overflow-y: auto;

        .sectionDiv{
          position: relative;
          background-color: #f2f2f2;
          width: 230px;
          height:50px;
          display: flex;
          align-items: center;
          box-sizing:border-box;
          padding:10px;
          border:1px solid #cacaca;
          border-radius: 5px;
          margin:0 0 15px 0;
          font-size: 14px;
          color:#333333;
          transition: all .25s ease-in;
          cursor: pointer;
          overflow: hidden;
          .item{
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 4px;
            width: 100%;
          }

          .colorTab{
            height:35px;
            left: initial;
            right:-4px;
            opacity: 0;
            transition: .3s all ease;
          }

          &:hover{
            width:240px;
            height: 53px;
          }
        }

        input:checked+.sectionDiv{
          height:45px;
          width:270px;
          background-color: white;

          .colorTab{
            opacity: 1;
          }
        }
      }
      textarea, .text_area{
        width:100%;
        height:100px;
        background-color: #f2f2f2;
        border:none;
        resize: none;
        border-radius: 10px;
        box-sizing:border-box;
        padding:15px;
        margin-top: 5px;

        &*{

          font-size: 14px;
          color:#333333;
          line-height:20px;
        }
      }
    }

    .instructions{
      width: 100%;
      height:85%;
      border-radius: 10px;
      box-sizing:border-box;
      padding:15px;
      -webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
      -moz-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
      box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
      position: relative;
      overflow: hidden;

      textarea{
        width:100%;
        height:60%;
        background-color: #f2f2f2;
        border:none;
        resize: none;
        border-radius: 10px;
        box-sizing:border-box;
        padding:15px;
        margin-top: 5px;
        font-size: 14px;
        color:black;
        font-weight: 300;
      }

      .colorTab{
        top:12px;
        margin:initial;
        height:30px;
      }

      .imagesDiv{
        height:135px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        
        .pics{
          margin-top: 5px;
          height: 100px;
          overflow-x: auto;
          display: flex;
          flex-wrap: nowrap;

          h5{
            color: #858585;
            text-align: center;
          }

          .pic{
            position: relative;
            margin-right: 5px;
            height:95px;
            max-width: 170px;
            width: 30%;
            border-radius: 15px;
            border:1px solid #d2d2d2;
            cursor: pointer;

            .overlay{
              visibility: hidden;
              height:100%;
              width:100%;
              background-color: #d2d2d283;
              position: absolute;
              left:0;
              top:0;
              display: flex;
              align-items: center;
              justify-content: center;
              transition:.4s all ease-out;

              .preview{
                visibility: hidden;
                height:30px;
                width:90px;
                line-height: 30px;
                text-align: center;
                background-color: white;
                color: $main-blue--bright;
                border:1px solid $main-blue--bright;
                // cursor: pointer;
                transition:.2s all ease-out;
                border-radius: 5px;

                &:hover{
                  background-color: $main-blue--bright;
                  color:white;
                }
              }
            }

            &:hover{
              .overlay{
                visibility: visible;
                .preview{
                  visibility: visible;
                }
              }
            }
          }

          img{
            height: 100%;
            width:100%;
            object-fit: contain;
          }
        }
      }
    }

    .note{
      margin: 2.4rem 1.7rem 1rem 1rem;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      text-align: right;

     p{
      margin: 8px 1rem;
      font-size: 12px;
      font-style: italic;
      font-weight: 300;
      color: #4A4A4A;
      margin-right: 2rem;
     }
    }
  }

  .btn{
    // position: absolute;
    // right:-5px;
    // bottom:0px;
    background-color: $main-blue--bright;
    border:1px solid $main-blue--bright;
    color: white;
    height:40px;
    width:230px;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    border-radius: 8px;
    transition: .3s all ease-out;
    cursor: pointer;

    &:hover{
      background-color: white;
      color:$main-blue--bright;
    }
  }

  .assignment, .Assignment{
    background-color: $main-blue--light !important;
  }
  .journal, .Journal{
    background-color: $main-yellow !important;
  }
  .story, .Story{
    background-color: $main-orange !important;
  }
  .darkBlue{
    background-color: $main-blue !important;
  }
}

  textarea, .text_area{
        width:100%;
        height:91%;
        background-color: #f2f2f2;
        border:none;
        resize: none;
        border-radius: 10px;
        box-sizing:border-box;
        padding:15px;
        margin-top: 5px;
        font-size: 14px;
        color:#333333;
        line-height:20px;
        overflow-y: scroll;

        &::-webkit-scrollbar-track-piece {background-color: transparent};

        &::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 5px;
          background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: #d2d2d2;
          -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
        }

      }


   .loadingDiv{
    height: 100%;
    width:100%;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    box-sizing:border-box;
    padding:0 0 10px 0;

    img{
      height:60px !important;
      width: 60px !important;
      object-fit: contain;
    }
  }

  .errorDiv{
    height: 119%;
    width: 115%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    position: relative;
    padding: 0 0 10px 0;
    bottom: 30px;
    z-index: 5000;
    right: 39px;
    background-color: white;
  }



  .error{
    height:330px !important;
    width:540px !important;
    position: relative;
    h3{
      position: relative;
      bottom:15px;
      font-family: 'Sofia pro';
      font-size: 28px;
      font-weight: 700;
      line-height: 35px;
      margin:0;
      color: $main-blue;
      width:330px;
      text-align: center;
      // font-weight: 500;
    }

    .goBack{
      margin-top:15px;
      width:200px;
      height:50px;
      border-radius:8px;
      background-color:$E_blue;
      border:1px solid $E_blue;
      color:white;
      line-height: 50px;
      text-align: center;
      font-size:18px;
      cursor: pointer;
      transition: .3s all ease-out;
      font-weight: 700;

      &:hover{
        color:$E_blue;
        background-color: white;
      }
    }

    img.faceImg{
      position: relative;
      bottom:5px;
      height:160px;
      // width:20 5px;
      margin-bottom: 30px;
      object-fit: contain;
    }
  }

  .carouselOverlay{
    position: absolute;
    top: -7%;
    left: -5.5%;
    height: 114%;
    width: 110%;
    background-color: rgba(169, 169, 169, 0.93);
    z-index: 5000;
    display: flex;
    align-items: center;
    justify-content: center;

        
    .carousel {
      width: 730px;
      height: fit-content;
      img{
        width: 100%;
        height: auto;
      }
    }
    h6{
      color:$main-blue--bright;
      cursor: pointer;
      position: absolute;
      top:10px;
      right:35px;
      font-size: 16px;

      &:hover{
        text-decoration: underline;
      }
    }
  }