@import '../../../../shared.scss';

.container{
  display: none;
  height: calc(var(--viewportHeight) - 65px);
  width: 100%;
  background-color: white;
  flex-direction: column;


  // &.simplified{
  //   .topBar{
  //     background-color: #563B12 !important;
  //   }
  // }


  .topBar{
    width: 100%;
    height: 100px;
    box-sizing:border-box;
    padding: 0;
    overflow: hidden;
    position: relative;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: $E_blue_dark;

    .detailsBar{
      display: flex;
      justify-content: space-between;
      box-sizing:border-box;
      padding: 5px;

      .chevronBack,
      .chevronDown{
        height: 55px;
        width: 45px;
        background: url('../../../../assets/chevron_white.svg') no-repeat center;
        background-size: 10px;
      }

      .chevronDown{
        transform: rotate(-90deg);
        opacity: 0;
      }
    }


    a{
      height: 100%;
      width: calc(100% - 70px);
      display: flex;
      align-items: center;
    }


    .text{
      margin-top: 5px;
      
      p{
        font-size: 12px;
        color: rgba(255, 255, 255, 0.4);
        font-weight: 300;
        
        &.chapterName{
          margin-top: 3px;
          color: white;
          font-size: 15px;
        }
      }
    }
  }

  .tabs{
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;


    .radioDiv{
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 145px;
      cursor: pointer;
      transition: .3s all ease-out;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;

      &.noClick{
        pointer-events: none;
        background-color: white;
        p{
          color: $E_blue_dark;
        }
      }

      &.inactive{
        pointer-events: none;
        opacity: .4;
      }

      p{
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 1px;
        color: white;

        &.res{
          display: none;
        }
      }
    }

      .line{
        width: 100px;
        height: 2px;
        background-color: $E_blue;
        position: absolute;
        top: 38px;
        left: 0;
        transition: .2s all ease-out;

        &.responses{
          left: 100px;
        }
      }

      &.grey{
        .radioDiv{
          p{
            color: #4e4e4e;
          }

          &:hover{
            background-color: #cacaca;
          }
        }

        .line{
          background-color: #4e4e4e;
        }
      }

    .cogBtn{
      height: 40px;
      width: 40px;
      background: url('../../../../assets/icon-cog--white.svg') no-repeat center;
      background-size: 20px;
      background-color: $E_orange;
    }
  }

  .mainSwitch{
    width: 100%;
    background-color: white;

    h5{
      font-weight: 300;
      margin-top: 50px;
      color: #cacaca;
    }
  }


  @media (max-width:660px){
    display: flex;

    .mainSwitch{
      height: calc(var(--viewportHeight) - 165px);
      overflow: scroll;
      
      &.responses{
        overflow: hidden;
      }
    }
  }
}