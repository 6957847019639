  @import './styles/quillEditorStyles.scss';
  @import './styles/breakoutRoomsColours.scss';
  @import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

  :root{
    --viewportHeight : 100vh;
    --viewportMinus : 100vh;
  }


  $main-black:rgb(51, 51, 51);
  $main-blue: rgb(43, 78, 123);
  $main-blue--dark: #084B60;
  $main-blue--bright: rgb(6, 95, 229);
  $main-blue--light: rgb(7, 124, 160);
  $main-blue-brighter: rgb(55, 159, 247);
  $main-orange: rgb(255, 130, 88);
  $main-orange--bright: rgb(255, 168, 38);
  $main-green: rgb(151, 211, 24);
  $main-pink: #F26283;
  $main-pink: rgb(242, 98, 131);
  $main-yellow: rgb(255, 168, 38);
  $main-bluegrey: rgb(240, 244, 246);
  $grey-unselected: rgb(191, 202, 205);
  $grey: rgb(225, 229, 231);
  $grey-light: rgb(242, 242, 242);
  $grey-light2: rgb(249, 249, 249);
  $grey-two: rgb(131, 131, 131);
  $grey-twotwo: rgb(133, 133, 133);
  $grey-dark:rgb(78, 78, 78);
  $grey-three:rgb(210, 210, 210);
  $plus-purple: rgb(145, 75, 241);
  $main-purple: rgb(142, 43, 240);

  $faith-grey-pink: #fcf2ea;
  $faith-blue: #064149;
  $faith-pink:#FD7D73;
  $escribgreen: #97D318;

  $E_blue_dark: #074B60;
  $E_blue: #077CA0;
  $E_blue2: #1AB3E3;
  $E_blue_light:#E5F2F6;
  $E_blue_light2:#B5D8E3;
  $E_blue_light3:#CDE5EC;


  $E_orange: #FFA826;
  $E_orange_dark: #FF8258;
  $E_orange_light: #FFEED4;
  $E_orange_light2: #FDE0B4;

  $E_green: #97D318;
  $E_green_light: #E8F5CB;
  $E_green_light2: #D8F1A1;
  $E_green_dark: #57790E;

  $E_pink: #F26283;
  
  $E_purple: #8E00F0;
  
  $main-teal--dark: #084B60;
  
  $F_pink_deep: #FD7D73;

  @mixin borderButton ($color, $bg, $font-size) {
    cursor: pointer;
    position: relative;
    z-index: 30;
    display: inline-block;
    font-size:$font-size;
    padding:3px 15px;
    border:1px solid $color;
    background-color: $bg;
    color: $color;
    width: fit-content;
    border-radius: 8px;
    margin:0 5px;
    // &:hover{
    //   text-decoration: underline;
    // }
  }

  @mixin mainButton ($color, $bg, $font-size, $height, $width) {
    height:$height;
    width:$width;
    color:$color;
    font-size: $font-size;
    // background: url($imgUrl);
    background-color: $bg;
    border:1px solid $color;
    text-align: center;
    line-height: $height;
    cursor: pointer;
    transition: .3s all ease-out;
    border-radius: 8px;
    position: relative;
    background-repeat: no-repeat;
    
    &:hover{
      color:$bg;
      // background: url($hovImgUrl);
      background-color: $color;
      background-repeat: no-repeat;
      }
      
  }

  @mixin solidButton ($color, $bg, $font-size, $height, $width) {
    height:$height;
    width:$width;
    color:$color;
    font-size: $font-size;
    // background: url($imgUrl);

    background-color: $bg;
    border:1px solid $bg;
    text-align: center;
    line-height: $height;
    cursor: pointer;
    transition: .3s all ease-out;
    border-radius: 8px;
    position: relative;
    background-repeat: no-repeat;
    
    &:hover{
      // background: url($hovImgUrl);
      color:$bg;
      background-color: $color;
      border-color: $bg;
      background-repeat: no-repeat;
      }
      
  }


@mixin btn($color, $bg, $padding) {
  cursor: pointer;
  background: $bg;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: $color;
  text-align: center;
  padding: 0.75em $padding;
  margin: 0.5em 0em;
  display: inline-block;
  border: 1.5px solid $bg;
  transition: all 0.5s ease;

  &:hover {
    background-color: $color;
    color: $bg;
    border: 1.5px solid $bg;
  }
}


  @mixin shadow {
    -webkit-box-shadow: 3px 15px 20px -5px rgba(163, 163, 163, 0.3);
    -moz-box-shadow: 3px 15px 20px -5px rgba(163, 163, 163, 0.3);
    box-shadow: 3px 15px 20px -5px rgba(163, 163, 163, 0.3);
  }


body {
  min-height: 100vh;
  height: 100%;
  width: 100vw;
  margin: 0;
  padding: 0;
  text-align: center;
  font-family: 'DM Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  image-rendering: -webkit-optimize-contrast;
  overflow-x: hidden;

  @media (max-width:660px){
    height: 100%;
  }
  
}

*{
  font-family: 'DM Sans', sans-serif;
}

input{
  font-family: 'DM Sans', sans-serif;
}

label>input[type=radio]{
  display: none;
}

input:focus,
textarea:focus{
  outline: none;
}

a{
  text-decoration: none;
  color: inherit;
  font-family: 'DM Sans', sans-serif;


  &:hover{
    color: inherit; 
  }
}

h1{
  margin:3px 0;
  font-size: 26px;
  line-height: 33px;
  color: rgb(51, 51, 51);
}

div{
  font-family: 'DM Sans', sans-serif;
}

h2{
  margin:3px 0;
  font-size: 20px;
  line-height: 24px;
  color: rgb(51, 51, 51);
}

h3{
  margin:3px 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: grey;
}

h4{
  margin:3px 0;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: grey;
}

img{
  font-size:10px;
  color:#858585;
}

iframe{
  padding: 0 !important;
  border: none !important;
}

button{
  cursor: pointer;
}

.appWrapper{
  height: var(--viewportHeight);
  overflow-y: initial;
  // overflow-y: scroll;
  width: 100%;
  position: relative;
  // overflow: hidden;
}
.App{
  overflow-y: scroll;
  overflow-x: hidden;
  height:100%;
  width: 100%;
  display: flex;
  position: relative;

  @media (max-width:660px){
    overflow-x: hidden;
  }

  .tempNav{
    z-index: 3000;
    width:50px;
    height:140px;
    bottom: 40px;
    right:20px;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    .btn{
      display: flex;
      align-items: center;
      justify-content: center;
      height:40px;
      width: 40px;
      border-radius: 50%;
      line-height: 40px;
      color: white;
    }
    
        .text{
          position: absolute;
          color: $main-orange;
          transform: rotate(90deg);
          right:-75px;
          // border:1px solid chartreuse;
          width: 150px;
          text-align: center;
        }
        
    a:first-of-type > .btn{
      background: url('assets/nav/nav--admin.png') no-repeat center;
      background-size: 70%;
      background-color: $main-orange;
    }
    a:nth-of-type(2) > .btn{
      background: url('assets/nav/nav--teacher.png') no-repeat center;
      background-size: 70%;
      background-color: $main-orange;
    }
    a:last-of-type > .btn{
      background: url('assets/nav/nav--parent.png') no-repeat center;
      background-size: 70%;
      background-color: $main-orange;
    }
  }
}


.hide{
  opacity: 0;
  transition: all 1s ease;
}



/// NAV VISIBILITY CLASSES ///
.hidden{
  left: -270px;
  transition: .25s all ease-in-out;
}
.hideDesktop{
  display: none;
}
.showMobile{
  display: none;
}

.hideRes{
  display:initial;
}
.navVisible{
  left:70px !important;

}
.dropdownVisible{
  display: flex !important;
  height:170px !important;
  opacity: 1 !important;
}
/////////////



/// MODAL OVERLAY CLASSES ///
.modalOverlay{
  display: none;
  position: absolute;
  z-index: 550;
  width:100%;
  height: 100%;
  min-height: 700px;
  background-color: rgba(0, 0, 0, 0.686);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.overInner{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height:100%;
  width:calc(100% - 70px);
  float: right;
  z-index: 45;
}

.show{
  display: inline;
}
/////////////


/// PILL CLASSES FROM helpers/uiFunctions.js ///
.pill{
  font-size: 11px;
  box-sizing:border-box;
  padding:2px 10px;
  border-radius: 15px;
  color: white;
  margin-right: 5px;
  width: fit-content;
  min-width: fit-content;
  height: 18px !important;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.live{
  line-height: 11px;
  background-color: red;
}
.completed{
  line-height: 11px;
  background-color: $main-green;
}
.scheduled{
  line-height: 11px;
  background-color: $main-blue--bright;
}
.inApproval{
  position:relative;
  left:8px;
  line-height: 11px;
  background-color: $main-blue--light;
}
.draft{
  line-height: 11px;
  background-color: grey;
}
.voting{
  line-height: 11px;
  background-color: $main-orange;
}
.basic{
  background-color: $main-green;
}
.intermediate{
  background-color: $main-orange;
}
.advanced{
  background-color: red;
}
.approved{
  background-color: $main-green;
}
.pendingRevision{
  background-color: $main-pink;
}
.submitted{
  background-color: $main-blue--light;
}
.started{
  background-color: $main-yellow;
}
.notStarted{
  background-color: grey;
}

.wordbankUnused{
  height: 22px;
  margin:0 8px 8px 0;
  color: red;
  border: 1px solid red;
  font-size: 12px;
}

.wordbankUsed{
  display: inline-block;
  height: 22px;
  margin:0 8px 8px 0;
  color: $main-blue;
  background-color: white !important;
  border: 1px solid $main-blue;
  font-size: 12px;
  line-height: 12px;


  &.lg{
    font-size: 13px !important;
  }
}

.genre{
  background-color: $main-blue--light;
}

.disabled{
  opacity: .3 !important;
  pointer-events: none !important;
}

.errorBorder{
  border:1px solid red !important;
}


.shake{
  -webkit-animation: shake .4s ease-in-out 1;
  -moz-animation: shake .4s ease-in-out 1;
  -ms-animation: shake .4s ease-in-out 1;
  -o-animation: shake .4s ease-in-out 1;
  animation: shake .4s ease-in-out 1;
}

.shakeSlow{
  -webkit-animation: shake .5s ease-in-out 1;
  -moz-animation: shake .5s ease-in-out 1;
  -ms-animation: shake .5s ease-in-out 1;
  -o-animation: shake .5s ease-in-out 1;
  animation: shake .5s ease-in-out 1;
}

@keyframes shake{
  0%{
    transform:translateX(0)
  }
  20%{
    transform:translateX(5px)
  }
  40%{
    transform:translateX(-5px)
  }
  60%{
    transform:translateX(5px)
  }
  80%{
    transform:translateX(-5px)
  }
  100%{
    transform:translateX(0)
  }
}

.shakeSm{
  -webkit-animation: shakeSm .4s ease-in-out 1;
  -moz-animation: shakeSm .4s ease-in-out 1;
  -ms-animation: shakeSm .4s ease-in-out 1;
  -o-animation: shakeSm .4s ease-in-out 1;
  animation: shakeSm .4s ease-in-out 1;
}

@keyframes shakeSm{
  0%{
    transform:translateX(0)
  }
  20%{
    transform:translateX(2px)
  }
  40%{
    transform:translateX(-2px)
  }
  60%{
    transform:translateX(2px)
  }
  80%{
    transform:translateX(-2px)
  }
  100%{
    transform:translateX(0)
  }
}

////////////



@media (max-width:1100px){

  .tempNav{
    display: none !important;
  }

  .modalOverlay{
    width: calc(100% - 65px);
    left:70px;
  }
  .overInner{
    width:100%;
  }
  .hideRes{
    display: none;
  }
  .hideDesktop{
    display:block;
  }
}

@media (max-width:660px){
  .App{
    height: 100%;
  }
  .hideMobile{
    display: none !important;
  }
  .showMobile{
    display: initial !important;
  }
  .navVisible{
    left:0px !important;
  }  

  .modalOverlay{
    width: 100%;
    left:0;
    position: absolute;
    z-index: 550 !important;
  }
}

@media (max-width:400px){
  .hideSml{
    display:none !important;
  }
}



/// SCROLLBAR CLASSES ///
#teacherButtons::-webkit-scrollbar,
#gradeButtons::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

#gradeButtons::-webkit-scrollbar-track,
#teacherButtons::-webkit-scrollbar-track {
  background-color: $grey;
}
#gradeButtons::-webkit-scrollbar-thumb,
#teacherButtons::-webkit-scrollbar-thumb {
  background-color: $grey-unselected;
}

#assignmentsMain::-webkit-scrollbar,
#dashboardAssignments::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
 
#assignmentsMain::-webkit-scrollbar-track,
#dashboardAssignments::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
  box-shadow: inset 0 0 1px rgba(255, 1, 1, 0.3);
}
 
#assignmentsMain::-webkit-scrollbar-thumb,
#dashboardAssignments::-webkit-scrollbar-thumb {
  position: relative;
  z-index: 30;
  border-radius: 10px;
  background-color: $main-blue;
}


#activityListItems::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}


#activityListItems::-webkit-scrollbar-track {
  background-color: rgb(245, 245, 245);
}

#activityListItems::-webkit-scrollbar-thumb {
  background-color: $grey;
}

.__PrivateStripeElement{
  border:1px solid chartreuse !important;
}

.blueBtn{
  @include solidButton(white, $main-blue--bright, 15px, 35px, 120px);

}
.save{
  position: relative;
  bottom:-10px;
  margin: 0 auto;
}

.newFeature{
  position: absolute;
  top:-2px;
  right:-4px;
  width:30px;
  height:15px;
  line-height: 15px;
  text-align: center;
  border-radius: 5px;
  font-family: 'DM Sans', sans-serif !important;
  font-size: 11px;
  font-weight: 500;
  background: rgb(142,0,240);
  color:white !important;
  background: linear-gradient(360deg, rgba(142,0,240,1) 0%, rgba(6,95,229,1) 100%);

  &.offset{
    top:-10px;
    right: -8px;
  }

  &.notification{
    top:3px;
    right:3px;
    font-size: 16px;
    height: fit-content;
    width: fit-content;
    padding: 3px 10px;
  }

  &.calendar{
    top:5px;
    right:-3px;
    height:14px;
    width:25px;
    line-height: 14px;
    font-size: 10px;
  }
}

a.btn#b{
  opacity: 0 !important;
}

.faithlink{
  text-align: left;
}


.radioUnchecked{
  min-height: 20px;
  max-height: 20px;
  min-width: 20px;
  max-width: 20px;
  border-radius: 5px;
  border:2px solid #cacaca;
  margin: 0;
  position: relative;
  left: 0px;
}
.radioChecked{
  min-height: 22px;
  max-height: 22px;
  min-width: 22px;
  max-width: 22px;
  border-radius: 5px;
  border:2px solid transparent;
  margin: 0;
  background: url('./assets/checkbox-checked--blue.svg') no-repeat, center, 100%;
  position: relative;
  left: 2px;
}


// @media (max-width:800px){
//   .bodyDiv{
//     overflow-y: scroll;
//   }
// }