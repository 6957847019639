@import '../../../shared.scss';

.container{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  min-height: 250px;
  max-height: 90vh;
  overflow: auto;
  width: 95vw;
  max-width: 1000px;
  box-sizing: border-box;
  padding: 0px 50px;
  position: relative;
  background-color: white;
  padding:20px 15px !important;
  border-radius: 10px;


  .responsiveInfoInput{
    display: none;
  }

  .line{
    width: 100%;
    height: 2px;
    margin-top: 5px;
    border-bottom: 1px solid #cacaca;
  }

  h1{
    font-weight: 900;
    margin: 20px 0;
  }

  h3{
    font-weight: 400;
    font-size: 1.1rem;
    margin: 25px 0;
  }

  .typeRadios{
    display: flex;
    align-items: center;
    margin-top: 30px;

    .optionDiv{
      height: 100px;
      width: 330px;
      border-radius: 10px;
      border: 1px solid #cacaca;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0px 10px;
      cursor: pointer;
      transition: .2s all ease;
      position: relative;

      &:hover{
        border-color: #065FE5;
      }

      h2{
        font-size: 18px;
        font-weight: 500;
      }

      p.details{
        color: #858585;
        font-size: 12px;
        position: absolute;
        bottom: 18px;
        font-weight: 300;
        left: 123px;
        &.left{
          left: 115px;
        }
      }



      img{
        height: 60px;
        margin-right: 15px;
      }
    }
  }

  .main{
    width: 100%;
    position: relative;

    .selectedType{
      width: 270px;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      padding: 0 15px;
      border-radius: 10px;
      border: 1px solid transparent;
      // transition: .15s all ease;
      position: relative;

      &:hover{
        border: 1px solid #cacaca;
      }

      h5{
        font-size: 18px;
        color: #4e4e4e;
      }

      img.icon{
        height: 35px;
      }

      img.chevron{
        height: 8px;
        opacity: .85;
      }

      .typeDrop{
        width: 270px;
        height: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        padding: 15px;
        border-radius: 10px;
        border: 1px solid transparent;
        position: absolute;
        left: 0;
        top: 100%;
        z-index: 10;
        opacity: 0;
        background-color: white;

        &.open{
          opacity: 1;
          height: 45px;
          transition: .15s all ease;
          border: 1px solid #cacaca;
        }

        &:hover{
          background-color: #f2f2f2;
        }

        img.chevron{
          opacity: 0;
        }
      }
    }

    .infoBar{
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: 15px 0;
      height: 50px;

      .details{
        p{
          font-size: 15px;
          color: #FF8258;
          text-align: right;
          font-style: italic;
          margin: 5px 0;
        }
      }
    }

    .inputTable{
      margin-bottom: 8px;
  
      .tableHeader,
      .tableRow{
        display: flex;
        
        .column{
          box-sizing:border-box;
          padding:0 7px;
          width: 30%;
          position: relative;

          &:first-of-type{
            width: 15%;
          }
          &:nth-of-type(2){
            width: 25%;
          }

          img.delete{
            position: absolute;
            right: 12px;
            top: 12px;
            height: 18px;
            cursor: pointer;
            z-index: 10;
            opacity: .8;
            transition: .2s all linear;

            &:hover{
              opacity: 1;
            }
          }
        }
      }

      .tableHeader{
        height: 30px;
        line-height: 30px;
        background-color: #e9e9e9;
        border:1px solid #cacaca;
        color: #858585;
        font-weight: 300;
        text-transform: uppercase;
        font-size: 14px;

        span{
          font-size: 12px;
          text-transform: none;
          margin-left: 5px;
        }
      }

      .tableRow{
        height: 45px;
        line-height: 45px;
        border-bottom:1px solid #cacaca;

        .column{
          border-right:1px solid #cacaca;
          border-left:1px solid #cacaca;
          border-right: none;

          input{
            height: 80%;
            width: 90%;
            border:none;
            color: #333333;
            font-size: 14px;
            font-weight: 300;
          }
          
          &:last-child{
            border-right: 1px solid #cacaca;

          }
        }
      }
        
      &.synod{
        .column{
          width: 40% !important;
          &:first-of-type{
            width: 20% !important;
          }
        }
      }
    }

    img.plus{
      position: relative;
      left: 10px;
      cursor: pointer;
    }

    .classCheckboxes{
      margin-top: 15px;
      width: 100%;
      min-height: 100px;
      display: flex;
      flex-wrap: wrap;
      
      input[type=checkbox]{
        display: none;
      }

      .classDiv{
        height: 60px;
        width: 260px;
        border-radius: 10px;
        box-sizing:border-box;
        padding:0 20px 1px 20px;
        font-size: 16px;
        color: #333333;
        font-weight: 300;
        border:1px solid #cacaca;
        margin: 1px 9px 1px 1px;
        display: flex;
        align-items: center;
        cursor: pointer;
      }

      input:checked+.classDiv{
        border: 2px solid $E_blue;
        margin: 0 10px 0 0;
      }
    }

    h6.error{
      font-size: 12px;
      color: #d00000;
      text-align: center;
    }

    h6.error,
    .errorPlaceholder{
      height: 30px;
      margin:0;
      position: relative;
      top: 25px;
    }

    .buttons{
      display: flex;
      align-items: center;
      margin: 30px auto;
      width: fit-content;

      .cancelBtn{
        height: 40px;
        padding:0 20px;
        line-height: 40px;
        cursor: pointer;
        color: $E_blue;
        border-radius: 10px;
        transition: .2s all ease-out;
        font-size: 16px;
        margin-right: 15px;

        &:hover{
          background-color: #f2f2f2;
          // text-decoration: underline;
        }
      }

      .submitBtn{
        @include solidButton(white, $E_blue, 16px, 40px, 180px)
      }
    }

  }

  .confDiv{
    width: 100%;
    min-height: 300px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h6{
      margin: 0;
      position: relative;
      bottom: 75px;
      font-size: 14px;
      font-weight: 300;
    }
    
    h4{
      font-size: 16px;
      position: relative;
      bottom: 40px;
      font-weight: 300;
    }

    img{
      position: relative;
      bottom: 50px;
    }
  }


  .confTable{
    width: 100%;

    .tableHeader,
    .tableRow{
      display: flex;
      
      .column{
        box-sizing:border-box;
        padding:0 7px;
        width: 15%;

        &:nth-of-type(2){
          width: 20%;
        }
        &:nth-of-type(3){
          width: 20%;
        }
        &:last-of-type{
          width: 30%;
        }

        p{
          margin: 0;
        }

        p.emailSent{
          font-size: 15px;
          font-weight: 300;
          color: #077CA0;
          margin:0;
          display: flex;
          align-items: center;

          span{
            height: 18px;
            width: 18px;
            background: url("../../../assets/icon-circleTick.svg") no-repeat center;
            background-size: cover;
            margin-left: 10px;
            display: inline-block;
          }
        }
      }
    }

    .tableHeader{
      height: 30px;
      line-height: 30px;
      background-color: #e9e9e9;
      border:1px solid #cacaca;
      color: #858585;
      font-weight: 300;
      text-transform: uppercase;
      font-size: 14px;
    }

    .tableRow{
      height: 45px;
      line-height: 45px;
      border-bottom:1px solid #cacaca;

      .column{
        border-right:1px solid #cacaca;
        border-left:1px solid #cacaca;
        border-right: none;
        color: #333333;
        font-size: 14px;
        font-weight: 300;

        &:nth-of-type(4){
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &:last-child{
          border-right: 1px solid #cacaca;
        }
      }
    }
  }

  .downloadBtn{
    @include solidButton(white, $E_blue, 16px, 40px, 180px);
    margin: 30px 0 15px 0;
  }

  @media (max-width:660px){
    padding:0;
    overflow-x: hidden;
    min-height: initial;

    .responsiveInfoInput{
      display: initial;

      h3{
        color: #333333;
        font-size: 16px;
        margin: 15px 0;
      }

      label{
        display: block;
        margin-bottom: 15px;
        font-weight: 500;
        width: calc(100% - 5px);
        margin-left: 2.5px;

        span{
          color: #FF8258;
        }

        input{
          display: block !important;
          margin-top: 7px;
          width: calc(100% - 10px);
          border-radius: 5px;
          border:1px solid #cacaca;
          height: 35px;
          line-height: 35px;
          box-sizing:border-box;
          padding:0 10px;
          &::placeholder{
            font-weight: 300;
            color: #cacaca;
            font-size: 14px;
          }
        }
      }

      .addBtn{
        height: 35px;
        width: 125px;
        border-radius: 5px;
        line-height: 35px;
        background: url('../../../assets/icon_plus_blue.svg') no-repeat center left 10px;
        background-color: $E_blue_light;
        color: $E_blue;
        font-size: 15px;
        text-align: center;
        box-sizing:border-box;
        padding:0 0 0 15px;
      }
      p{
        font-size: 12px;
        color: #FF8258;
        text-align: left;
        font-style: italic;
        margin: 15px 0 0 0;
      }
    }

    .classDiv{
      width: calc(100vw - 60px) !important;
      flex-wrap: wrap;
      height: 65px !important;
      margin-bottom: 10px !important;
      justify-content: center;
      font-weight: 500 !important;

      .oval{
        height: 7px;
        width: 100%;
        border-radius: 5px;

      &.one{
        background-color: #97D318;
      }

      &.two{
        background-color: #F26283;
      }

      &.three{
        background-color: #FF8258;
      }

      &.four{
        background-color: #FFA826;
      }

      &.grey{
        background-color: #c4c4c4;
      }
      }
    }

    .hideRes{
      display: none !important;
    }

    .typeRadios{
      margin-top: 0;
      flex-direction: column;
      width: calc(100% - 20px);

      .optionDiv{
        height: 120px;
        width: 100%;
        margin-bottom: 10px;

        p{
          font-size: 11px !important;
          left: 100px !important;
        }
      }
    }

    .line{
      display: none !important;
    }

    .selectedType{
      padding: 0 !important;
      height: 40px !important;
      border-color: transparent !important;
      justify-content: flex-start !important;

      img{
        margin-right: 10px;
      }

      .chevron{
        display: none;
      }
    }

    .infoBar{
      align-items: center;
      margin: 5px 0 !important;
      width: calc(100% - 10px) !important;
    }

    h2{
      font-size: 17px;
    }

    .studentList{
      margin-top: 15px;

      .studentRow{
        width:calc(100% - 10px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        border-bottom: 1px solid #cacaca;
        box-sizing:border-box;
        padding:0 0 5px 0;

        &:last-of-type{
          border-bottom: none;
        }

        h5,p{
          margin: 0;
          font-weight: 300;
        }
        h5{
          font-size: 14px;
          color: #2b2b2b;
        }
        p{
          color: #858585;
          font-size: 12px;
        }

        .removeBtn{
          height: 40px;
          width: 40px;
          border-radius: 5px;
          background: url('../../../assets/cross_blue.svg') no-repeat center;
          background-color: $E_blue_light;
        }
      }
    }

    .details{
      p{
        width: 170px !important;
        font-size: 12px !important;
      }
    }

    .error{
      top: 10px !important;
    }

    .confDiv{
      height: fit-content !important;
      min-height: 150px !important;
      width: 95vw !important;

      img{
        bottom: 0;
      }
      h6{
        bottom: 45px;
      }

      h4{
        bottom: 0;
        margin-top: 30px;
      }
    }
    .downloadBtn{
      display: none;
    }

    .confTable{
      width: calc(100% - 30px);
      margin-top: 15px;
      margin-bottom: 10px;

      .tableRow{
        border-right: 1px solid #cacaca;
      }
    }
    .column{
      width: 33% !important;
    }

  }

}