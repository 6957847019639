@import '../../../../shared.scss';

.container{
  height: fit-content;
  width: 50%;
  border-radius: 15px;
  background-color: white;
  box-sizing:border-box;
  padding:25px;
  position: absolute;
  z-index: 120;
  -webkit-box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.1); 
  box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.1);
  text-align: left;
  cursor: default;
  pointer-events: none;
  opacity: 0;
  transition: .5s ease-in transform;

  &.responsive{
    left: 50% !important; 
    top: 55px !important;
    transform: translateX(-50%) !important;
    width: calc(100vw - 20px) !important;
  }

  h5{
    margin-top: 10px;
    font-weight: 500;
    font-size: 1.3rem;
    color: $E_blue;
  }

  .topRow{
    display: flex;
    gap: 10px;
    align-items: center;

    .chevron{
      min-height: 30px;
      max-height: 30px;
      min-width: 30px;
      max-width: 30px;
      background: url('../../../../assets/arrow_square_chevron.png') no-repeat center;
      transition: .3s ease-in transform;
      position: relative;
      top: 5px;
      cursor: pointer;
      pointer-events: all !important;

      &.open{
        background: url('../../../../assets/arrow_square_chevron_hover.png') no-repeat center;
        transform: rotate(180deg);
      }
      
      &:hover{
        background: url('../../../../assets/arrow_square_chevron_hover.png') no-repeat center;
      }
    }

  }

  .moreInfo {
    height: 0;
    opacity: 0;
    transition: .1s all ease-in;

    &.show {
      height: max-content;
      padding: 5px;
      opacity: 1;
    }
   
    p {
      font-weight: 300;
      font-size: 0.9rem;
      line-height: 1.3rem;
      color:#4e4e4e;
    }

    .linkDiv{
      margin: 20px auto 0 auto;
      height:100px;
      width:170px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: url('../../../../assets/photo1.png') no-repeat center/cover;
      cursor: pointer;

      .play{
        height:60px;
        width:60px;
        border-radius: 50%;
        background: url('../../../../assets/play_btn_red.svg') no-repeat center/contain;
      }
    }
  }

  //class for displaying this component (.showModal) is in TeacherDashboard.module.scss, at the bottom
  //it had to be there as the toggle function is contained in the dash component, so uses those styles

  .close{
    position: absolute;
    height: 20px;
    width: 20px;
    top:15px;
    right:15px;
    cursor: pointer;
    background: url('../../../../assets/icon-cross--grey.svg') no-repeat center;
    background-size: 12px;
  }
  p.grey{
    margin-top: 20px;
    font-size: 1rem;
    color: #858585;
    font-weight: 500;
    margin:5px;
  }

  input[type=text]{
    height: 40px;
    width: 100%;
    box-sizing:border-box;
    padding:0 10px;
    border-radius: 5px;
    border:1px solid #cacaca;
    margin: 15px 0;
    font-size: 16px;
    font-weight: 300;

    &::placeholder{
      color:#bababa;
      font-weight: 300;
    }
  }
  
  .createBtn{
    height: 45px;
    width: 100%;
    border-radius: 5px;
    background-color: $E_blue;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .2s all ease-out;

    &:hover{
      background-color: $E_blue_dark;
    }

    &.disabled{
      opacity: .4;
      pointer-events: none;
    }

    p{
      color: white;
      font-size: 15px;
      font-weight: 500;
    }
  }
}

.overlay{
  height: 100vh;
  width: 100vw;
  background-color: rgba(94, 94, 94, 0.501);
  backdrop-filter: blur(5px);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  opacity: 0;
  pointer-events: none;
}
