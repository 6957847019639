@import '../../../../shared.scss';

.container{
  height: 370px;
  width: 780px;
  border-radius: 15px;
  overflow: hidden;
  background: url('../../../../assets/gradientWave.svg') no-repeat center -5px;
  display:flex;
  flex-direction: column;
  align-items: center;
  box-sizing:border-box;
  position: relative;
  padding:10px 50px;
  background-color: #ffffff;
  transition: .3s ease-in-out all;

  &.conf{
    height: 240px;
    width: 550px;
  }

  *{
    font-family: 'Sofia pro';
  }

  h1{
    font-weight: 900;
    color:white;
  }

  .loadingOverlay{
    height: 100%;
    width: 100%;
    background-color: rgba(245, 245, 245, 0.741);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    top:0;
    left: 0;
    box-sizing:border-box;
    padding:50px 0 0 0;

    p{
      position: relative;
      bottom: 30px;
      box-sizing:border-box;
      padding:0 0 0 10px;
      color: $main-blue;
    }
  }

  .confDiv{
    height: 100%;
    display: flex;
    align-items: center;
  }


  .switchContainer {
    margin-top:45px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    padding-right: 50px;

    left: 2%;

      span{
          color:#858585;
      }

      .switch {
          display: flex;
          background-color: white;
          width: 75px;
          height: 35px;
          border-radius: 50px;
          align-items: center;
          cursor: pointer;
          border:1px solid #cacaca;


          .greenBall {
              width: 27px;
              height: 27px;
              background-color: $main-green;
              border-radius: 100%;
              margin-left: 0.25em;
              margin-right: 0.25em;
              transition: margin-left 0.5s ease; //ok
          }

          .right {
              margin-left: 2.75em;

          }



      }

      span {
          font-weight: bold;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.1em;
          padding: 0 1em;
      }
      .monthInput {
          position: absolute;
          display: flex;
          align-items: center;
          right:80px;
          label {
            color:$main-blue;
            font-size: 12px;
            font-weight: bold;
            margin-left: 10px;
          }
          input {
              width: 35px;
              height: 30px;
              line-height: 16px;
              border-radius: 5px;
              font-weight: bold;
              color: $main-blue;
              font-size: 16px;
              margin-left: 12px;
              border: 1px solid $grey-unselected;
              padding-left: 6px;
              outline: none;
          }
      }
  }

  .planRadios{
    display: flex;
    width:300px;
    justify-content: space-between;

    input{display:none}

    input+.planRadio{
      height:40px;
      width:145px;
      line-height: 40px;
      text-align: center;
      border:1px solid #cacaca;
      cursor: pointer;
      border-radius: 8px;
      color:#858585;
      font-size: 15px;
    }

    input:checked+.planRadio{
      color:#333333;
      font-size: 14px;
      -webkit-box-shadow: 1px 1px 8px 2px rgba(0, 0, 0, 0.15);
      -moz-box-shadow: 1px 1px 8px 2px rgba(0, 0, 0, 0.15);
      box-shadow: 1px 1px 8px 2px rgba(0, 0, 0, 0.15);
      position: relative;

      &::after{
        content: url('../../../../assets/icon-circleTick--sm.svg');
        position: absolute;
        right:-5px;
        top:-15px;
      }

      &.basicPlan{
        border-color: $main-pink;
      }

      &.completePlan{
        border-color: $main-yellow;
      }
    }
  }

  .subscriptionDiv{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top:0px;
    height:130px;
    width:580px;
    box-sizing:border-box;
    padding:15px 35px;
    border:2px solid;
    border-radius: 15px;
    -webkit-box-shadow: 1px 1px 8px 2px rgba(3, 19, 255, 0.15);
    -moz-box-shadow: 1px 1px 8px 2px rgba(3, 19, 255, 0.15);
    box-shadow: 1px 1px 8px 2px rgba(3, 19, 255, 0.15);
    overflow: hidden;


    .colorTab{
      position: absolute;
      left:-6px;
      top:0;
      bottom:0;
      margin:auto 0;
      content:'';
      width:10px;
      height:80px;
      border-radius: 25px;
    }

    &.basicPlan{
      border-color: $main-pink;

      .colorTab{
        background-color: $main-pink;
      }
    }
    &.completePlan{
      border-color: $main-yellow;

      .colorTab{
        background-color: $main-yellow;
      }
    }

    h4{
      font-size: 17px;
      color:#333333;
      font-weight: 700;
    }

    .subscriptionRow{
      width: 100%;
      display: flex;
      justify-content: space-between;

      .frequency{
        font-size: 12px;
      }

      p{
        margin: 0;
        font-size: 15px;
        color:#858585;
        font-weight: 500;
      }
    }
    p.blue{
      margin: 0;
      width: fit-content;
      line-height: 35px;
      font-size: 13px;
      color:$main-blue--bright;
      font-weight: 300;
      cursor: pointer;

      &:hover{
        color: rgba(6, 70, 167, 0.878)
      }
    }

  }


  .buttons{
    height:40px;
    width:100%;
    margin-top:20px;
    // position: absolute;
    // bottom:-45px;
    display: flex;
    justify-content: center;

    .btn{
      height:30px;
      width:120px;
      line-height: 30px;
      text-align: center;
      color:white;
      border-radius: 10px;
      transition: .3s all ease-out;
      font-size: 14px;
      font-weight:700;
      margin:0 5px;
      cursor: pointer;
      -webkit-box-shadow: 0px 1px 4px 0px #00000025; 
      box-shadow: 0px 1px 4px 0px #00000056;

      &.disabled{
        pointer-events: none;
        opacity: .4;
      }

      &:first-of-type{
        background-color: white;
        color:$main-blue--bright;
        border:1px solid $main-blue--bright;

        &:hover{
          background-color: $main-blue--bright;
          color:white;
        }
      }

      &:last-of-type{
        background-color: $main-blue--bright;
        border:1px solid $main-blue--bright;
        width:190px;

        &:hover{
          background-color: white;
          color:$main-blue--bright;
        }
      }
    }
  }

}