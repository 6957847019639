@import '../../../shared.scss';

.container{
  width: 100%;
  height: 60px;
  background-color: $E_blue_dark;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing:border-box;
  padding:0 20px;

  *{
    font-family: 'DM Sans', sans-serif !important;
  }


  h4{
    color: white;
    font-weight: 500;
    font-size: 20px;
  }

  .back{
    display: flex;
    align-items: center;
    cursor: pointer;

    h5{
      color: white;
      font-weight: 300;
    }
    img{
      height: 15px;
      margin-right: 10px;
    }
  }

  img{
    height: 25px;
  }

  .logoutBtn{
    height: 40px;
    width: 115px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing:border-box;
    padding:0 20px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.141);
    transition: .2s all ease;

    img{
      height: 20px;
    }

    p{
      margin: 0;
      color:white;
      font-size: 14px;
    }

    &:hover{
      background-color: rgba(255, 255, 255, 0.2);

    }
  }
}