@import '../../../../shared.scss';

.container{
  position: relative;

  p, li span{
    color:#333333;
    font-size: 16px;
    font-weight: 300;
    margin:5px 0;
  }
  li span{
    position: relative;
    bottom:5px;
  }
  li{
    position: relative;
    font-size: 30px;
    line-height: 16px;
  }
  p{
    padding-left: 40px;
  }

  h2{
    margin:0;

    // &:first-of-type{
    //   margin-top: 25px;
    // }
  }
  h6{
    font-size:12px;
    font-weight:300;
    color:#858585;
    margin:0;
  }
  .logo{
    padding-left: 40px;
    margin:0 0 15px 0;
  }  

  .header{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    left:-35px;
    margin-top: 30px;

    img{
      margin:0 25px;
    }

    &:first-of-type{margin-top:20px;}
  }

  .PDFbtn{
    cursor: pointer;
    margin:15px 0 15px 40px;
    font-weight:700;
    line-height: 33px;
    height:30px;
    width:120px;
    text-align: center;
    color:$main-blue--bright;
    background-color: white;
    border:1px solid $main-blue--bright;
    border-radius: 10px;
    transition: .3s all ease-out;

    &:hover{
      background-color: $main-blue--bright;
      color:white;
    }
  }

  .signUpBtn{
    cursor: pointer;
    background-color: $main-blue--bright;
    border:1px solid $main-blue--bright;
    color:white;
    width: 245px;
    height:40px;
    line-height: 43px;
    text-align: center;
    font-weight: 700;
    border-radius: 10px;
    margin:25px 0 20px 40px;
    transition: .3s all ease-out;

    &:hover{
      background-color: white;
      color:$main-blue--bright;
      border:1px solid $main-blue--bright
    }
  }
}