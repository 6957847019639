@import '../../../../shared.scss';

.container{
  min-height:230px;
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing:border-box;
  padding:5px 0px;



  // &.bookTip{
  //   height: 460px;
  //   width: 500px;
  //   padding-bottom: 40px;
  //   *{font-family:'Sofia pro'}
  // }

  h1{
    font-size: 29px;
    font-weight: 700;
    position: relative;
    top: 3px;
    color: #333333;
  }

  .warning{
    height:70px;
    width:435px;
    background: url('../../../../assets/info2.svg') no-repeat 20px center;
    background-size: 35px;
    background-color: #f2f2f2;
    border-radius: 15px;
    margin-top: 25px;
    box-sizing:border-box;
    padding:13px 10px 15px 70px;
    display: flex;
    align-items: center;

    p{
      margin: 0;
      line-height: 21px;
      font-size: 16px;
      font-style: italic;
      color: $main-orange;
    }

    &.blank{
      background: none;
      padding: 0;
      width: 500px;
      text-align: center;
      margin: 5px auto 0 auto;
      justify-content: center;
      p{
        font-style: normal;
        font-weight: 500;
        color: #858585;
      }
    }
  }

  .tip{
    display: flex;
    height: 140px;
    align-items: center;
    margin-top: 30px;

    img{
      height: 100px;
      width: 100px;
      object-fit: contain;
      margin-right: 25px;
    }

    .text{
      height: 120px;
      width: 80%;
      h6{
        margin: 2px 0;
        font-size: 14px;
        color:#333333;
        font-weight: 700;
      }
      p{
        margin: 2px 0;
        font-size: 14px;
        color:#333333;
        font-weight: 300;
      }
    }

  }

  .approved{
    margin: 20px 0;
    display: flex;
    align-items: center;
    background-color: transparent;

    p{
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      color: $main-green;
      margin: 0;
    }
    .pill{
      
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      font-size: 14px;
      text-align: center;
      padding: 13px 10px;
      background-color: $main-green;
      color: white;
      border-radius: 30px;
      margin-left: 10px;
    }
  }

  .buttons{
    display: flex;
    margin-top:10px;

    .cancel,
    .startVoting{
      height:40px;
      width:180px;
      border:1px solid;
      border-radius: 10px;
      line-height: 40px;
      font-size: 16px;
      text-align: center;
      margin:0 8px;
      cursor: pointer;
      transition: .15s all ease-in-out;
    }

    .cancel{
      border-color: $E_blue_light;
      color: $E_blue;
      background-color: $E_blue_light;
      
      &:hover{
        border-color: $E_blue_light2;
        background-color: $E_blue_light2;
      }
    }
    .startVoting{
      border-color: $E_blue;
      background-color: $E_blue;
      color: white;
      
      &:hover{
        border-color: $E_blue_dark;
        background-color: $E_blue_dark;
      }



      &.red{
        background-color: #FC7070;
        border-color: #FC7070;
        &:hover{
          border-color: #ee4848;
          background-color: #ee4848;
        }
      }
      
    }
    
  }

  @media (max-width:660px){
    width: 95vw;
    box-sizing:border-box;
    padding:10px 5px;

    h1{
      text-align: center;
      font-weight: 700;
      font-size: 20px;
    }

    .warning{
      width: calc(100% - 60px) !important;
      height: fit-content !important;

      p{
        font-weight: 300 !important;
        font-size: 15px !important;
        line-height: 18px !important;
      }
    }

    .cancel,
    .startVoting{
      width: 150px !important;
    }
  }
}