@import '../../../../../../shared.scss';


.container{
  display: flex;
  flex-direction: column;
  align-items: center;
  // height: 100%;
  width: 100%;
  box-sizing:border-box;
  padding:30px 40px;
  position: relative;

  .titleBar{
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      margin-right: 10px;
      width: 35px;
    }
  }

  h5{
    color:#333333;
    margin-top: 20px;
  }
  .sliderDiv{
    height: 80px;
    display: flex;
    align-items: center;
    box-sizing:border-box;
    padding:0 30px 0 0;

    p{
      color: #858585;
      font-size: 15px;
      opacity: 0;
      transition: .2s all ease-out;

      &.show{
        opacity: 1;
      }
    }

    .slider{
      height: 35px;
      width: 65px;
      border-radius: 20px;
      background-color: #d2d2d2;
      margin: 0 10px;
      position: relative;

      .sliderInner{
        height: 31px;
        width: 31px;
        border-radius: 50%;
        background-color: white;
        position: absolute;
        left: 2px;
        top:2px;
        transition: .2s all ease-out;
        cursor: pointer;

        &.active{
          left:32px;
        }
      }

      &.active{
        background-color: $main-green;
      }
      
    }
  }

  .main{
    height: 210px;
    width: 100%;
    opacity: 0;
    transition: .3s all ease-out;

    &.show{
      opacity: 1 !important;
    }
  }

  .rubricRadios{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    input[type=radio]{
      display: none;
    }

    .rubricDiv{
      display: flex;
      align-items: center;
      cursor: pointer;

      p{
        margin:0;
        color:#4e4e4e;
        font-size: 14px;
        font-weight: 300;
        margin-right:8px;
      }

      .checkbox{
        height: 10px;
        width: 10px;
        border:2px solid #D2D2D2;
        border-radius: 50%;
      }
    }

    input:checked + .rubricDiv{
      .checkbox{
        border-color: $main-blue--bright;
        position: relative;

        &:after{
          content: "";
          height: 6px;
          width: 6px;
          border-radius: 50%;
          background-color: $main-blue--bright;
          position: absolute;
          bottom:2px;
          left: 2px;
        }
      }
    }
  }

  .sharingDiv{
    margin-top: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .viewRubricBtn{
      height: 40px;
      width: 155px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color:#CDE4EC;
      border-radius: 8px;
      cursor: pointer;
      transition: .2s all ease-out;
      margin-top: 10px;

      img{
        width: 30px;
        margin-right: 10px;
      }

      p{
        color:#097CA0;
        font-size: 15px;
        margin: 0;
      }

      &:hover{
        background-color: #B5D8E3;
      }
    }
  }


  .submitBtn{
    background-color: $main-blue--light;
    height: 40px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 8px;
    margin-top: 10px;
    transition: .2s all ease-out;
    opacity: .4;
    pointer-events: none;
    position: absolute;
    right: 20px;
    bottom: 20px;

    &.enabled{
      opacity: 1;
      pointer-events: all;
    }

    &:hover{
      background-color: $main-blue--dark;
    }

    p{
      color: white;
    }

    span.error{
      color: #d00000;
      font-size: 14px;
      font-weight: 300;
      position: absolute;
      left: -150px;
    }

  }

  &.mobile{
    padding: 15px;
    overflow-y: auto;
    position: relative;

    h5{
      margin:15px 0 5px 0;
    }

    .sliderDiv{
      height: 40px;
      margin: 5px 0;
    }

    .main{
      opacity: .2;
      height: 280px;
      .rubricRadios{
        flex-direction: column;

        .rubricDiv{
          flex-direction: row-reverse;

          p{
            margin: 5px;
          }
        }
      }
    }

    .sharingDiv{
      margin-top: 10px;
      padding-bottom: 60px;
      // height: 90px;

      .viewRubricBtn{
        display: none;
      }
    }

    .submitBtn{
      bottom: 15px;
    }

  }
}