@import '../../shared.scss';

.container{
  height:100vh;
  min-height: 700px;
  width: 100%;
  overflow: hidden;
  background-color: $main-bluegrey;
  transition: .3s all ease-in-out;
  position: relative;


    //class to display newActivityModal
    .show{
      pointer-events: all;
  
      section[id='mainBlock']{
        transform: translateX(0px);
      }
  
      div[id='handle']{
        opacity: 1 !important;
      }
      div[id='loaderDiv']{
        opacity: 1 !important;
      }
    }
}