@import '../../../../../shared.scss';

.setupButtons{
  height: fit-content;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  // justify-content: space-evenly;
  box-sizing:border-box;
  padding:0 15px;

  .item{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70px;
    width: 70px;
    border:1px solid #e9e9e9;
    border-radius: 8px;
    margin: 5px;



    &.disabled{
      opacity: .5;
      pointer-events: none;
    }

    &.hidden{
      display: none;
    }

    .icon{
      height: 23px;
      width: 30px;
      margin-bottom: 5px;
    }

    p{
      font-size: 11px;
      font-weight: 500;
      margin:0;
    }

    
    &.deadline{
      .icon{
        background: url('../../../../../assets/sidebarIcons/sidebar_calendar_blue.svg') no-repeat center;
      }
      &.active{
        .icon{
          background: url('../../../../../assets/sidebarIcons/sidebar_calendar_white.svg') no-repeat center;
        }
        
      }
    }
  
    &.wordbank{
      .icon{
        background: url('../../../../../assets/sidebarIcons/sidebar_wordbank_blue.svg') no-repeat center;
      }
      &.active{
        .icon{
          background: url('../../../../../assets/sidebarIcons/sidebar_wordbank_white.svg') no-repeat center;
        }
        
      }
    }
  
    &.upload{
      .icon{
        background: url('../../../../../assets/sidebarIcons/sidebar_upload_blue.svg') no-repeat center;
      }
      &.active{
        .icon{
          background: url('../../../../../assets/sidebarIcons/sidebar_upload_white.svg') no-repeat center;
        }
        
      }
    }
  
    &.grammar{
      .icon{
        background: url('../../../../../assets/sidebarIcons/sidebar_grammar_blue.svg') no-repeat center;
      }
      &.active{
        .icon{
          background: url('../../../../../assets/sidebarIcons/sidebar_grammar_white.svg') no-repeat center;
        }
        
      }
    }
  
    &.rooms{
      .icon{
        background: url('../../../../../assets/sidebarIcons/sidebar_rooms_blue.svg') no-repeat center;
      }
      &.active{
        .icon{
          background: url('../../../../../assets/sidebarIcons/sidebar_rooms_white.svg') no-repeat center;
        }
        
      }
    }
  
    &.grading{
      .icon{
        background: url('../../../../../assets/sidebarIcons/sidebar_grading_blue.svg') no-repeat center;
      }
      &.active{
        .icon{
          background: url('../../../../../assets/sidebarIcons/sidebar_grading_white.svg') no-repeat center;
        }
        
      }
    }
  
    &.peer{
      .icon{
        background: url('../../../../../assets/sidebarIcons/sidebar_voting_blue.svg') no-repeat center;
      }
      &.active{
        .icon{
          background: url('../../../../../assets/sidebarIcons/sidebar_voting_white.svg') no-repeat center;
        }
        
      }
    }
  
    &.private{
      .icon{
        background: url('../../../../../assets/sidebarIcons/sidebar_private_blue.svg') no-repeat center;
      }
      &.active{
        .icon{
          background: url('../../../../../assets/sidebarIcons/sidebar_private_white.svg') no-repeat center;
        }
        
      }
    }
  }
  

}