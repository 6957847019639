@import '../../../../../../shared.scss';

.container{
  display: flex;
  flex-direction: column;
  align-items: center;
  // height: fit-content;
  width: 100%;
  box-sizing:border-box;
  padding:30px 20px;

  .titleBar{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .titleBar{
    img{
      margin-right: 10px;
      position: relative;
      bottom: 3px;
    }
  }

  .textDiv{
    margin-top: 10px;

    p,i{
      font-size: 16px;
      color: #858585;
      font-weight: 300;
      margin-bottom: 10px;
      
      &.small{
        font-size: 13px;
      }
    }

    i{
      position: relative;
      top: -6px;
    }
  }

  .filesDiv{
    height: 270px;
    width: 80%;
    margin-top: 10px;
    // border:1px solid chartreuse;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;


    .placeholder{
      color: #cacaca;
      margin-top: 20px;
    }

    .itemDiv{
      display: flex;
      align-items: center;
      position: relative;
      height: 40px;
      width: 370px;
      margin-bottom: 10px;

      img,
      .folderIcon{
        height:40px;
        width: 40px;
        border-radius: 8px;
        margin-right: 20px;
        object-fit: cover;
        // border:1px solid #e1e1e1;
      }
      
      .folderIcon{
        background: url('../../../../../../assets/file_blue.svg') no-repeat center;
      }

      p{
        font-size: 16px;
        color: $main-blue--bright;
        font-weight: 300;
        max-width: 270px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .deleteDiv{
        height: 40px;
        width: 40px;
        border-radius: 8px;
        cursor: pointer;
        background: url('../../../../../../assets/icon-delete_grey.svg') no-repeat center;
        background-size: 14px;
        position: absolute;
        right: 10px;

        &:hover{
          background:url('../../../../../../assets/icon-delete_red.svg') no-repeat center, #FED6D6;
        }
        
      }

      @media (max-width:660px){
        img,
        .folderIcon{
          margin-right: 5px;
        }
        p{
          max-width: calc(100% - 90px);
        }
      }
    }

    .loadingDiv{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #ffffffa8;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 100;

      img{
        height: 180px;
        width: 180px;
        position: relative;
        bottom: 15px;
      }

    }
  }

  input[type=file]{
    display: none;
  }

  .submitBtn{
    background-color: $main-blue--light;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0 15px;
    border-radius: 8px;
    margin-top: 10px;
    transition: .2s all ease-out;
    cursor: pointer;

    &:hover{
      background-color: $main-blue--dark;
    }

    p{
      color: white;
    }

  }

  &.mobile{
    padding: 15px;


    .textDiv{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      p, i{
        text-align: center;
        line-height: 18px;
        font-size: 15px;

        &.small{

        }
      }

    }

    .filesDiv{
      height: calc(100vh - 400px);
      overflow-y: auto;
      // overflow-x: hidden;

      .itemDiv{
        width: 100%;
        img{
          object-fit: contain;
        }
      }
    }

  }


}