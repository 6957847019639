

.container{
  position: absolute;
  width: 700px;
  left: -730px;
  height: fit-content;
  top: 50%;
  transform: translateY(-55%);
  background-color: white;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  -webkit-box-shadow: 1px 1px 20px 5px rgba(0,0,0,0.2);
  -moz-box-shadow: 1px 1px 20px 5px rgba(0,0,0,0.2);
  box-shadow: 1px 1px 20px 5px rgba(0,0,0,0.2);
  border-radius: 10px;

  &.calendar{
    height: 580px;
  }

  .closeBtn{
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    background: url('../../../../../assets/icon-close.png') no-repeat center;
    background-size: contain;
    cursor: pointer;
    z-index: 100;
    opacity: .8;

    &:hover{
      opacity: 1;
    }
  }

  &.display{
    left: -705px;
    opacity: 1;
    transition: .4s all ease-out;
  }
}