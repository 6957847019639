@import '../../../shared.scss';

.container{
  position: relative;
  top:20px;
  display: flex;
  width:calc(100vw - 120px);
  height: 700px;
  background-color: white;
  border-radius: 15px;

  *{font-family: 'Sofia pro'}


    .close {
    cursor: pointer;
    opacity: 1;
    position: absolute;
    background-image: url('../../../assets/icon-close.png');
    background-size: contain;
    height: 25px;
    width: 25px;
    right: 15px;
    top: 15px;
    z-index: 1500;
    transition: .3s all ease-out;

    &:hover {
      opacity: 0.7;
      transform: scale(1.1);
    }
  }


  .leftBar{
    width: 230px;
    height:100%;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    background-color: $E_blue_dark;
    box-sizing:border-box;
    padding:15px 0;
    position: relative;


    &.faithJournal{
      background-color: #563B12;
    }
    
    h2{
      color: white;
      font-size: 20px;
      font-weight: 700;
    }

    .radios{
      margin-top: 20px;
      height: 90%;

      .radiosInner{
        width: 99%;
        height: 100%;
        overflow-y: scroll;
  
        &::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 5px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: white;
          -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
        }

      }

      input{display: none}

      label>input+.classRadio{
        cursor: pointer;
        height:50px;
        width: 100%;
        text-align: left;
        box-sizing:border-box;
        padding:0 0 0 35px;
        display: flex;
        align-items: center;
        position: relative;

        .circleCont{display: none;}

        .keyLetter{
          height: 23px;
          width:23px;
          line-height: 23px;
          background-color: rgba(255, 255, 255, 0.363);
          color: $main-blue;
          border-radius: 50%;
          text-align: center;
          margin-left: 8px;
          position: absolute;
          right: 14px;

          p{
            margin:0;
            color:rgba(255, 255, 255, 0.589)
          }

          .toThePower{
            position: relative;
            bottom:3px;
            left:1px;
            sup{
              font-size: 10px;
            }
          }
        }

        &:hover{
          background-color: rgba(255, 255, 255, 0.025)
        }

        h3{
          color: rgba(255, 255, 255, 0.589);
          width: 75%;
          overflow:hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
  
        }
      }

      label>input:checked+.classRadio{
        background-color: rgba(255, 255, 255, 0.15);
        h3{
          color: white;
        }

        .circleCont{
          width:20px;
          height: 50px;
          overflow: hidden;
          // border:1px solid chartreuse;
          position: absolute;
          left: 0;
          display: inline;

          .circle{
            height: 250px;
            width: 250px;
            border-radius: 50%;
            background-color: white;
            position: relative;
            left: -243px;
            top: -100px;
          }
        }
      }

      
    }

  }

  .calendarDiv{
    position: relative;
    .arrows{
      display: flex;
      align-items: center;
      width:100px;
      height:40px;
      position: absolute;
      top:17px;
      left:260px;

      .left, .right{
        background: url('../../../assets/icon-calendar--arrow.svg') no-repeat center;
        height: 40px;
        width:50px;
        cursor: pointer;
        // border:1px solid chartreuse;
        &:hover{
          background-color: #f2f2f2;
        }
      }

      .left{background: url('../../../assets/icon-calendar--arrow.svg') no-repeat center;
        transform: rotate(180deg);
      }
      .left{background: url('../../../assets/icon-calendar--arrow.svg') no-repeat center;
      }
    }

    .legend{
      position: absolute;
      right: 0;
      top:15px;
      height:40px;
      width: 340px;
      display: flex;
      align-items: center;
      // border:1px solid chartreuse;

      .legendItem{
        width:fit-content;
        padding: 0 15px;
        height:40px;
        display: flex;
        align-items: center;
        justify-content: center;

        .dot{
          height:20px;
          width:20px;
          border-radius: 50%;
          margin-right: 8px;
          
        }
        &:first-of-type .dot{background-color: $main-yellow !important}
        &:nth-of-type(2) .dot{background-color: $main-blue--light !important}
        &:last-of-type .dot{background-color: $main-green !important}

        h5{
          font-size: 12px;
          font-weight: 300;
          color: #333333;
        }
      }
    }
  }
  .event{
    position: relative;
    border-radius: 7px !important;

    div.keyDot{
        color: $main-blue;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        line-height: 17px;
        background-color: white;
        left:7px;
        top:3px;
        width:17px;
        height: 17px !important;
        border-radius: 50%;
        position: absolute;
        box-sizing:border-box;
        padding:2px 0 0 1px;
        display: flex;
        align-items: center;
        justify-content: center;

        .toThePower{
          position: relative;
          bottom:1px;
          left:-1px;
          sup{
            font-size: 9px;
          }
        }


        //dots inside the 'ending-today' type event
        &:nth-child(3){
          box-sizing:border-box;
          padding:0 0 1px 0;

          .toThePower{
            bottom:2px;

          }
        }

    
      }
  }

  .classOne{
    background-color: $main-yellow !important;
  }
  .classTwo{
    background-color: $main-green !important;
  }
  .assignment{
    background-color: $main-yellow !important;
  }
  .story{
    background-color: $main-green !important;
  }
  .journal{
    background-color: $main-blue--light !important;
  }
  .live{
    &::after{
      content: 'live';
      color: red;;
      background-color: white;
      right:8px;
      top:4px;
      width:65px;
      height: 15px;
      line-height: 15px;
      font-size: 10px;
      border-radius: 10px;
      text-align: center;
      position: absolute;
    }
  }
  .in_approval{
    &::after{
      content: 'in approval';
      color: $main-blue--light;
      background-color: white;
      right:8px;
      top:4px;
      width:65px;
      height: 15px;
      line-height: 15px;
      font-size: 10px;
      border-radius: 10px;
      text-align: center;
      position: absolute;
    }
  }
  .draft{
    &::after{
      content: 'draft';
      color: grey;
      background-color: white;
      right:8px;
      top:4px;
      width:65px;
      height: 15px;
      line-height: 15px;
      font-size: 10px;
      border-radius: 10px;
      text-align: center;
      position: absolute;
    }
  }
  
}


