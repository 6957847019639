@import '../../../../shared.scss';

.container{
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  box-sizing:border-box;
  padding:40px 20px 20px;

  .teacherList{
    width: calc(100% - 360px);
    height: fit-content;
    min-height: 60px;
    border:1px solid #e9e9e9;
    max-width: 700px;
    margin-right: 20px;
    border-radius: 10px;
    overflow: hidden;

    .listHeader{
      height: 30px;
      display: flex;
      align-items: center;
      background-color: #f2f2f2;

    }

    .column{
      width: 35%;
      text-align: center;
      font-size: 12px;
      color: #858585;
      font-weight: 700;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;

      &:first-of-type{
        justify-content: flex-start;
        box-sizing:border-box;
        padding:0 0 0 60px;
      }

      &:last-of-type{
        width: 30%;
      }
    }

    .teacherRow{
      width: 100%;
      display: flex;
      align-items: center;
      height: 50px;
      position: relative;

      .column{

        p{
          color: #858585;
          font-weight: 300;
          text-transform: none;
        }
      
        &:first-of-type{
          justify-content: flex-start;
          box-sizing:border-box;
          padding:0 15px;

          img{
            height: 40px;
            width: 40px;
            border-radius: 50%;
            object-fit: cover;
            margin-right: 10px;
          }

          p{
            color: #333333;
            font-weight: 500;
          }
        }


        .statusPill{
          padding:5px 15px;
          border-radius: 3px;
          font-size: 13px;
          font-weight: 500;
          text-transform: none;
          border:1px solid transparent;
          color: white;

          &.lead{
            color: $E_blue;
            border-color: $E_blue_light2;
          }

          &.pending{
            color: red;
            border-color: red;
          }

          &.confirmed,
          &.approved{
            display: none;
          }
        }

      }

      .delete{
        height: 35px;
        width: 35px;
        border-radius: 5px;
        background: url('../../../../assets/icon-delete.svg') no-repeat center, #ffffff;
        transition: .3s background-color ease-out;
        cursor: pointer;
        background-size: 15px;
        // border: 1px solid #cacaca;
        display: flex;
        align-items: center;
        overflow: hidden;
        position: absolute;
        right: 5px;


        p{
          color: #FB3333;
          font-size: 15px;
          margin:0;
          font-weight: 500;
          transition: .3s all ease-out;
          opacity: 0;
          text-overflow: hidden;
          white-space: nowrap;
          position: relative;
          left: 40px;
        }
        
        &:hover{
          // width: 180px;
          background: url('../../../../assets/icon-delete_red.svg') no-repeat 10
          px center, #F9D1D1;
          background-size: 14px;
          p{
            opacity: 1;
          }
        }

        &.disabled{
          opacity: 1;
          pointer-events: none;
        }

      }
    }
  }
  
  .inviteDiv{
    -webkit-box-shadow: 1px 1px 10px 5px rgba(0, 174, 255, 0.13);
    -moz-box-shadow: 1px 1px 10px 5px rgba(0, 174, 255, 0.13);
    box-shadow: 1px 1px 10px 5px rgba(0, 174, 255, 0.13);
    min-height: 300px;
    height: fit-content;
    width: 330px;
    border-radius: 10px;
    text-align: left;
    overflow: hidden;
    
    .topBar{
      box-sizing:border-box;
      padding:30px 20px 20px 20px;
      background-color: $E_blue_dark;

      h4{
        max-width: 220px;
        font-size: 18px;
        font-weight: 500;
        line-height: 23px;
        color: white;
      }
    }

    .main{
      box-sizing:border-box;
      padding:30px 25px 15px 25px;
      position: relative;

      p{
        max-width: 220px;
        color: #858585;
        font-size: 15px;
        line-height: 20px;
        font-weight: 300;
        margin-bottom: 15px;
      }

      input[type=text]{
        width: calc(100% - 40px);
        border-radius: 3px;
        height: 30px;
        line-height: 30px;
        border:1px solid #cacaca;
        margin-bottom: 6px;
        font-weight: 300;
        font-size: 15px;
        padding-left:5px;
      }

      .addInvite{
        margin-top: 12px;
        cursor: pointer;
        color: $E_blue;

        span{
          font-size: 25px;
          position: relative;
          top: 2px;
        }

        &:hover{
          color: $E_blue_dark;
        }

        &.inactive{
          pointer-events: none;
          opacity: .3;
        }
      }


    }

    .bottom{
      border-top: 1px solid #cacaca;
      box-sizing:border-box;
      padding:15px 25px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;

      .resetBtn{
        padding:5px 10px;
        border-radius: 8px;
        background-color: white;
        color: $E_blue;
        font-size: 14px;
        font-weight: 300;
        cursor: pointer;
        transition: .15s all ease-out;
        margin-right: 8px;

        &:hover{
          background-color: $E_blue_light2;
        }
      }

      .submitBtn{
        padding:5px 10px;
        border-radius: 8px;
        background-color: $E_blue_light;
        color: $E_blue;
        font-size: 14px;
        font-weight: 300;
        cursor: pointer;
        transition: .15s all ease-out;

        &:hover{
          background-color: $E_blue_light2;
        }
      }

      p.error{
        color: red;
        font-size: 11px;
        font-weight: 300;
        position: absolute;
        // bottom: 15px;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

  }

  @media (max-width:660px){
    flex-direction: column;
    justify-content: flex-start;

    .teacherList,
    .inviteDiv{
      width: 100%;
      margin-bottom: 15px;
      min-height: initial;

      .topBar{
        text-align: center;
        padding:10px;
        display: flex;
        justify-content: center;
      }
    }

    .statusPill{
      padding: 5px !important;
      font-size: 10px !important;


      &.pending,
      &.confirmed{
        position: relative;
        margin-right: 5px;
      }
    }


    .delete{
      position: relative !important;
    }

    .column{
      &:first-of-type{
        width: 70%;
      }

      &:nth-of-type(3){
        justify-content: flex-end;
        padding-right: 10px;

      }
      
    }

    .teacherRow{
      .column:nth-of-type(2){
        display: none;
      }
    }
  }


}