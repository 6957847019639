@import '../../../../shared.scss';

.container{
    width: 100%;
    min-height: 100%;
    max-width: 1500px;
    position: relative;
    box-sizing: border-box;
    padding:0px 20px;

    .topBar{
      display: flex;
      align-items: center;
      justify-content: space-between;
      // overflow-x: auto;
      height: 80px;
      position: relative;
      z-index: 20;

      .filters{
        display: flex;
        align-items: center;

        .search{
          height: 40px;
          width: 350px;
          border-radius: 20px;
          overflow: hidden;
          background-color: #F2F2F2;
          align-items: center;
          cursor: pointer;
          border:2px solid white;
          transition: .15s all ease-out;
          transition: .4s width ease-in-out;
          position: relative;
          display: flex;
          align-items: center;
          

          .inner{
            height:calc(100% - 2px);
            width:calc(100% - 2px);
            background-color: white;
            border:1px solid  #cacaca;
            border-radius: 20px;
            overflow: hidden;
            position: absolute;
            z-index: 1;
            right: 0;
            display: flex;
            background-color: #f2f2f2 ;

          }
          input[type=text]{
            width:calc(100% - 50px);
            height: 30px;
            line-height: 30px;
            border:none;
            position: absolute;
            z-index: 5;
            right: 10px;
            top: 2px;
            z-index: 10;
            background-color: transparent;
          }
          
          .lupa{
            min-height:40px;
            max-height:40px;
            min-width:40px;
            max-width:40px;
            border-radius: 50%;
            background: url('../../../../assets/icon-lupa.png') no-repeat 11px 10px, #F2F2F2;
            background-size: 17px;
            transition: .15s all ease-out;
            position: relative;
            z-index: 10;
            pointer-events: none;
          }

          &.open{
            // width: 350px;
            border:2px solid #CDDFFA;

            .inner{
              border-color: #065FE5;
            }
          }
        }

        .radios{
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          margin-left: 10px;

          input[type=radio]{
            display: none;
          }

          .radioDiv{
            padding:0px 8px;
            margin-right: 5px;
            border-radius: 20px;
            font-size: 14px;
            border:1px solid #077CA0;
            cursor: pointer;
            color:#077CA0;
            white-space: nowrap;

          }

          input:checked+.radioDiv{
            background-color: #077CA0;
            color: white;
          }
        }

      }

      .addStudentBtn{
        height: 40px;
        width: 140px;
        background-color: $E_blue_light;
        cursor: pointer;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        position: relative;

        img{
          height:6px;
          transform: rotate(180deg);
        }

        p{
          margin:0;
          color: $E_blue;
          font-weight: 500;
          font-size: 14px;
        }

        &:hover{
          background-color: $E_blue_light2;
        }

        .addDropdown{
          width: 170px;
          border-radius: 8px;
          height: 0;
          opacity: 0;
          pointer-events: none;
          position: absolute;
          top: 45px;
          z-index: 20;
          transition: .2s all ease-out;
          background-color: white;
          background-color: white;
          -webkit-box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.1); 
          box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.1);
          overflow: hidden;

          &.show{
            height: 160px;
            opacity: 1;
            pointer-events: all;
          }

          .dropRow{
            font-size: 14px;
            color: #858585;
            font-weight: 300;
            text-align: center;
            justify-content: center;
            height: 40px;
            display: flex;
            align-items: center;
            cursor: pointer;
            transition: .2s all ease-out;
            // box-sizing:border-box;
            // padding:0 0 0 10px;

            &:hover{
              background-color: #f2f2f2;
            }
          }
        }
      }


      @media (max-width:660px){
        display: none;
      }
    }


    .tableCont{
        width: 100%;
        height: calc(var(--viewportHeight) - 310px);
        border-radius: 10px;
        border:1px solid #f2f2f2;
        // margin-bottom: 90px;
        position: relative;
        z-index: 15;
        overflow-y: auto;
        overflow-x: hidden;
        margin-top: 1rem;

  
        @media (max-height:700px){
          height: 430px;
        
        }
  
        @media (max-width:660px){
          margin-top: 15px;
          height: calc(var(--viewportHeight) - 190px);
        }
  
        .tableHeader{
          display: flex;
          background-color: #f2f2f2;
          align-items: center;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          .column{
            width: 15%;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
  
            &:first-of-type{
              width: 40%;
              justify-content: flex-start;
              box-sizing:border-box;
              padding:0 0 0 90px;
            }
  
            // &:last-of-type{
            //   width: 10%;
            // }
  
            p{
              color:#858585;
              font-size: 13px;
              text-transform: uppercase;
            }
  
            @media (max-width:660px){
              display: none;
  
              &:first-of-type{
                *{
                  display: none;
                }
                display: flex !important;
                width: 100%;
              }
            }
          }
  
      
        }
  
        .rowsContainer{
          height: calc(100% - 40px);
          overflow-y: auto;
          overflow-x: hidden;
          width: 100%;
  
  
          .placeholder{
            height:80px;
            width:100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 30px;
            
            h5{
              font-weight: 300;
              font-size: 15px;
              color: #858585;
              color: $E_blue;
            }
          }
        }
  
        &.synod{
          height: calc(var(--viewportMinus) - 310px);
  
          .tableHeader{
            .column:nth-of-type(2),
            .column:nth-of-type(3){
              display: none;
            }
          }
        }
      }
}
    // margin:0 auto !important;