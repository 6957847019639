@import '../../../../shared.scss';

.container{
  width:calc(100vw - 70px);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #F3F7FE;
  font-family: 'Sofia pro';
  box-sizing:border-box;
  padding:0 0 30px 0;

  .back{
    position: absolute;
    top:10px;
    left:90px;
    font-size:14px;
    color:$main-blue;
    cursor: pointer;
    transition: .3s all ease-out;

    &:hover{
      color: $main-blue--bright;
    }
  }

  p{
    font-size: 16px;
    color: #858585;
    line-height: 23px;
    margin:0;
    font-weight: 300;
  }


  .header{
    width:580px;
    height:100px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    img{
      width:45px;
      height:45px;
      border-radius: 50%;
      margin-right: 10px;
    }

    
    .text{
      .title{
        display: flex;
  
        h4{
          font-size: 20px;
          font-weight: 700;
          line-height: 23px;
          margin:0;
  
          &:first-of-type{
            color: $main-blue--bright;
          }
  
          &:last-of-type{
            color: black;
          }
        }
        p{
          font-weight: 700;
          font-size: 20px;
          margin:0 2px;
        }
      }
      p{
        font-size: 14px;
        font-weight: 300;
      }
    }

    
  }

  .main{
    width:580px;
    height:380px;
    background-color: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    -webkit-box-shadow: 1px 1px 5px 0px rgba(4, 35, 172, 0.39);
    -moz-box-shadow: 1px 1px 5px 0px rgba(4, 35, 172, 0.39);
    box-shadow: 1px 1px 5px 0px rgba(4, 35, 172, 0.39);
    box-sizing:border-box;
    padding:0 0 20px 0;
    position: relative;

    .row{
      width: 470px;
      height:100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      h3{
        font-size: 16px;
        font-weight: 700px;
        color:#333333;
        margin:5px 0 5px 10px;
      }
      
      input{
        height:40px;
        color:#333333;
        width:100%;
        border:1px solid #cacaca;
        border-radius: 5px;
        font-size: 15px;
        box-sizing:border-box;
        padding:0 10px;
        font-family: 'Sofia pro';
      }

      p{
        font-size: 14px;
        margin:3px 0;
      }

      &:first-of-type p{ 
        color:$main-blue--bright;
        cursor: pointer;
        &:hover{
          color: $main-blue;
        }
      }
      &:last-of-type{height:90px}
    }
    .error{
      font-size: 16px;
      font-weight: 300;
      color: red;
      position: absolute;
      bottom:20px;

    }
    .success{
      font-size: 16px;
      font-weight: 300;
      color: $main-green;
      position: absolute;
      bottom:20px;

    }
    
  }

  .button{
    position: relative;
    height:40px;
    line-height:40px;
    width:380px;
    border-radius: 8px;
    background-color: $main-blue--bright;
    color:white;
    border:1px solid transparent;
    transition: .3s all ease-out;
    cursor: pointer;
    margin-top:20px;

    &:hover{
      background-color: white;
      color:$main-blue--bright;
      border:1px solid $main-blue--bright
    }

    img{
      position: relative;
      bottom: 3px;
      height:45px;
      width:45px;
      object-fit: contain;
    }
  }
}

.revert{
  pointer-events: none;
  background-color: initial;
}

// .shake{
//   -webkit-animation: shake .4s ease-in-out 1;
//   -moz-animation: shake .4s ease-in-out 1;
//   -ms-animation: shake .4s ease-in-out 1;
//   -o-animation: shake .4s ease-in-out 1;
//   animation: shake .4s ease-in-out 1;
// }

// @keyframes shake{
//   0%{
//     transform:translateX(0)
//   }
//   20%{
//     transform:translateX(5px)
//   }
//   40%{
//     transform:translateX(-5px)
//   }
//   60%{
//     transform:translateX(5px)
//   }
//   80%{
//     transform:translateX(-5px)
//   }
//   100%{
//     transform:translateX(0)
//   }
// }

.hidden{
  display: none;
}