@import '../../../../shared.scss';
.thumbnail {
    width: 125px;
    height: 75px;
    border-radius:10px;
    position: relative;
    
    img{
        width: 100%;
        border-radius:10px;
    }
}

.button {
	background: $main-blue--bright;
	border-radius: 12px;
	color: #FFF;
	cursor: pointer;
	display: block;
 	font-size: 18px;
    padding: 0 10px;
    text-align: center;
    line-height: 34px;
    text-decoration: none;
    transition: background 0.1s ease, box-shadow 0.1s ease;
    &:hover {
        background: darken($main-blue--bright, 10%);
    }
    &:active {
        background: lighten($main-blue--bright, 10%);
    }
    &:focus {
        outline: none;
    }
    &:disabled {
        opacity: 0.5 !important;
        pointer-events: none;
    }
}

.hoverContainer {
    background: transparent;
    width: 125px;
    height: 75px;
    border-radius:10px;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(4px);

    &.hide{
        display: none;
    }

    .btnWrapper {
        height: 30px;
        width: 90px;
        .bordered {
            border: 4px solid #fcc62d;
            box-shadow: inset 0 -4px 0 rgba(0, 0, 0, 0.3);
            border-radius: 8px;
            font-size: 16px;
            line-height: 20px;
            padding: 1px 0px 1px 0px;
            &:hover {
                box-shadow: inset 0 0px 0 rgba(0, 0, 0, 0.3);
                padding: 2px 0px 0px 0px;
            }
        }
    }
}
