@import '../../../shared.scss';


.container_dark{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1.5rem;
    -webkit-box-shadow: 5px 5px 1px 2px rgba(0,0,0,0.1); 
    box-shadow: 5px 5px 1px 2px rgba(0,0,0,0.1);
    border-radius: 15px;
    padding: 1rem;
    width: fit-content;
    text-align: left;
    background: #fff;
    margin: 1rem auto;  

  }

  .cards{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 32px;
    margin: 1rem 0;

  }

  .trialBtn{
    background: $escribgreen;
    color: #fff;
    padding: 0.8rem 1.8rem;
    border-radius: 10px;
    cursor: pointer;
  }