@import '../../../../shared.scss';

.container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  text-align: center;
  box-sizing:border-box;
  padding:30px 100px;
  font-family: 'Sofia pro';
  position: relative;

  *{
    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, .5);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }
  }
  
  .back{
    font-size:14px;
    color:$main-blue;
    cursor: pointer;
    transition: .3s all ease-out;
    position: absolute;
    top:40px;
    left:100px;

    &:hover{
      color: $main-blue--bright;
    }
  }
  .main{
    display: flex;
    justify-content: space-between;
    height: 92%;
    width:100%;

    .left, .right{
      height:100%;
      width: 49%;
      // min-width: 500px;
      // border:1px solid chartreuse;

      .block{
        position: relative;
        background-color: white;
        text-align: left;
        width:100%;
        box-sizing:border-box;
        padding:20px 40px;
        border-radius: 15px;
        -webkit-box-shadow: 1px 1px 8px 2px rgba(3, 19, 255, 0.15);
        -moz-box-shadow: 1px 1px 8px 2px rgba(3, 19, 255, 0.15);
        box-shadow: 1px 1px 8px 2px rgba(3, 19, 255, 0.15);
        margin-bottom: 20px;

        h4{
          font-size: 16px;
          color:#333333;
          margin: 7px 0;
        }

        p, a{
          font-size: 15px;
          color:#858585;
          font-weight: 300;
          position: relative;

          &.cardNumber{

            // &::before{
            //   content:url('../../../../assets/icon-visa--logo.svg');
            //   position: relative;
            //   left:-5px;
            // }
          }
        }

        .row{
          width: 100%;
          height: 25px;
          line-height: 28px;
          display: flex;
          justify-content: space-between;
        }

        
        &.cardDetails{
          transition: .5s all ease-in-out;
          height:75px;
          overflow: hidden;
          position: relative;

          h4{
            cursor: pointer;
          }

          &.open{
            height: 380px;
          }

          h4{
            display: flex;
            position: relative;

            .chevron{
              position: relative;
              top:2px;
              display: inline;
              height:20px;
              width: 20px;
              margin-left: 20px;
              background: url('../../../../assets/icon-chevron-black.png') no-repeat center;
              cursor: pointer;
              transition: .5s all ease-in-out;

              &.open{
                transform:rotate(180deg)
              }
            }
          }

          .form{
            margin-top:25px;

            label, input{
              display: block;
            }
            label{
              padding-left: 15px;
              font-size: 14px;
              font-weight: 500;
              margin-bottom: 5px;
              margin-top: 15px;
            }

            input{
              font-family: 'Sofia pro';
              box-sizing:border-box;
              padding-left:20px;
              height: 35px;
              line-height: 35px;
              width: 100%;
              border-radius: 6px;
              border:1px solid #cacaca;


            }
            input[name="number"]{
              background:url('../../../../assets/icon-visa--card.svg') no-repeat 10px;
              padding-left: 50px;
            }

            input[type=number]::-webkit-inner-spin-button, 
            input[type=number]::-webkit-outer-spin-button { 
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                margin: 0; 
            }

            .bottom{
              display: flex;
              justify-content: space-between;
              label{
                display: block;
              }
              div{
                width: 47%;

              }
            }
          }
          .buttons{
            display:flex;
            justify-content: flex-end;
            width: 100%;
            margin-top:15px;
            position: relative;

            img{
              height:40px;
              width:40px;
              object-fit: contain;
              // border:1px solid chartreuse;
              position: absolute;
              left:0;
              top: -1px;
            }
            .conf{
              color:$main-green;
              position: absolute;
              left:0;
              top: 10px;
              font-size: 12px;
            }
            .error{
              color:red;
              position: absolute;
              left:0;
              top: 10px;
              font-size: 12px;
            }
            .btn{
              height:35px;
              width:180px;
              text-align: center;
              line-height: 35px;
              cursor: pointer;
              transition: .3s all ease-out;
              border-radius: 8px;
              border:1px solid $main-blue--bright;
              color:white;
              background-color: $main-blue--bright;
              position: relative;
              
              &:hover{
                color:$main-blue--bright;
                background-color: white;
                }
              }
          }
        }
      }

      .paymentHistory{
        .historyHeader, .historyRow{
          display: flex;
          justify-content: space-between;

          h4, p{
            line-height: 28px;
            text-align: left;
            margin: 0;

            &:first-of-type{
              width: 50%;
            }
            &:nth-of-type(2), &:last-of-type{
              width: 25%;
            }
          }

        }

        .historyRows{
          height:120px;
          overflow-y: auto;
        }
      }

      p.blue, a.blue{
        width: fit-content;
        line-height: 35px;
        color:$main-blue--bright;
        font-weight: 300;
        cursor: pointer;

        &:hover{
          color: rgba(6, 70, 167, 0.878)
        }
      }

      p.cancelSub{
        cursor: pointer;
        width:100%;
        text-align: right;

        &:hover{
          color:rgb(162, 162, 162);
        }
      }

      p.isCancelled{
        margin-top: 20px;
        color:#d00000;
        font-size: 14px;
      }
      p.subtext{
        font-size: 14px;
        color:#858585;
        width: 85%;
      }
    }
  }




  .loader{
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h6{
      color:$main-blue;
    }

    img{
      height:70px;
    }
  }
}

@media (max-width:1250px){

  .container{
    .main{
      flex-direction: column;

      .left, .right{
        width:100%
      }
    }
  }
}

@media (max-width:800px){
  .container{
    padding: 30px
  }
}

@media (max-width:660px){

  .container{
    height: calc(var(--viewportHeight) - 65px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 30px 15px;
  }
  
  .back{
    left: 30px !important;
    top: 35px !important;
  }

  .main{
    .block{
      padding:20px 25px !important; 
    }
  }

  h1.title{
    font-size: 20px !important;
  }

  .left{
    margin-bottom: 20px !important;
  }


  .historyHeader{
    h4:first-of-type{
      display: none;
    }
  }
  .historyRow{
    p:first-of-type{
      display: none;
    }
    p:nth-of-type(2){
      width: 75% !important;
    }
  }

}