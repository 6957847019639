@import '../../../../../../shared.scss';

.container{
  display: flex;
  flex-direction: column;
  align-items: center;
  // height: 100%;
  width: 100%;
  box-sizing:border-box;
  padding:30px 40px;

  .titleBar{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;

    img{
      margin-right: 10px;
      width: 35px;
    }
  }


  .textDiv{
    margin-top: 10px;

    p{
      font-size: 16px;
      color: #858585;
      font-weight: 300;
      width: 420px;
    }

  }

  .sliderDiv{
    height: 230px;
    display: flex;
    align-items: center;
    box-sizing:border-box;
    padding:0 30px 90px 0;

    p{
      color: #858585;
      font-size: 15px;
      opacity: 0;
      transition: .2s all ease-out;

      &.show{
        opacity: 1;
      }
    }

    .slider{
      height: 35px;
      width: 65px;
      border-radius: 20px;
      background-color: #d2d2d2;
      margin: 0 10px;
      position: relative;

      .sliderInner{
        height: 31px;
        width: 31px;
        border-radius: 50%;
        background-color: white;
        position: absolute;
        left: 2px;
        top:2px;
        transition: .2s all ease-out;
        cursor: pointer;

        &.active{
          left:32px;
        }
      }

      &.active{
        background-color: $main-green;
      }
      
    }
  }

  .submitBtn{
    background-color: $main-blue--light;
    height: 40px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 8px;
    margin-top: 10px;
    transition: .2s all ease-out;
    cursor: pointer;
    opacity: .4;
    pointer-events: none;

    &.enabled{
      opacity: 1;
      pointer-events: all;
    }

    &:hover{
      background-color: $main-blue--dark;
    }

    p{
      color: white;
    }

  }


}