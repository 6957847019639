@import '../../../shared.scss';

.container{
  box-sizing: border-box;
  padding: 15px;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  display: flex;
  position: relative;
  z-index: 100;
  background-color: white;
  overflow: hidden;

  .sophiaBtn{
    margin: 1rem 0;
    div{
      background-color: #E7E7E5;
      color:#1E9AAA;
      padding: 0.8rem 1.5rem;
      cursor: pointer;
      border-radius: 30px;
      font-size: 16px;
      text-transform: capitalize;
      display: flex;
      align-items: center;
      gap: 4px;
      font-weight: 600;
      user-select: none;
    }
    img{
      // width: 70px;
      object-fit: contain;
    }
  }

  *{font-family: 'DM Sans', sans-serif}
  input[type="radio"]{display:none}
  input[type="checkbox"]{display:none}

  h2{
    color: #333333;
    font-size: 18px;
  }
  
  h3{
    font-size: 14px;
    font-weight: 500;
    color: #333333;

  }

  p{
    color:#858585;
    font-size: 14px;
    font-weight: 300;
  }

  .pillRadio{
    height:20px;
    width: fit-content;
    line-height: 18px;
    text-align: center;
    box-sizing:border-box;
    padding:0px 15px;
    background-color: #e9e9e9;
    color:#333333;
    border-radius: 20px;
    cursor: pointer;
    font-size: 12px;
    margin: 0 4px 8px 0;
  }
  
  .circleCheck{
    margin: 0 4px 8px 0;
    font-size: 12px;
    height:25px;
    width: 25px;
    line-height: 25px;
    display: flex;
    justify-content: center;
    background-color: #e9e9e9;
    color:#333333;
    border-radius: 50%;
    cursor: pointer;
    border:1px solid transparent;
  }
  

  .sophiaBtn input[type='checkbox']:checked+div{     
    margin: 0 2px 8px 0;
    background: #1E9AAA;
    color: #fff;

  }
  input[type='radio']:checked+div{
    margin: 0 2px 8px 0;
    border:1px solid $main-blue;
    background-color: $main-blue;
    color:white;
  }
  input[type='checkbox']:checked+div{     
    margin: 0 2px 8px 0;
    border:1px solid $main-blue;
    background-color: $main-blue;
    color:white;
  }
  input[type='checkbox']:checked+.circleCheck{     
    margin: 0 4px 8px 0;
    border:1px solid $main-blue;
    background-color: $main-blue;
    color:white;
  }

  .left{
    width:22%;
    min-width:270px;
    height:100%;
    border:1px solid #cacaca;
    border-radius: 15px;
    box-sizing:border-box;
    padding:25px 20px;
    overflow-y: auto;
    margin-right: 15px;

    &::-webkit-scrollbar-track-piece {background-color: transparent};

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 5px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: #d2d2d2;
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }   
     &::-webkit-scrollbar-track-piece:end {
      margin-bottom: 10px; 
    }
    
    &::-webkit-scrollbar-track-piece:start {
        margin-top: 10px;
    }

    *{
      display: flex;
    }

    h2{
      margin:20px 0 7px 0;
      text-align: left;
      
      &:first-of-type{
        margin:0px 0 7px 0;
      }
    }

    .typeRadios, 
    .subjectChecks,
    .schoolRadios,
    .sectionsRadios,
    .specialFilters,
    .gradeChecks{
      width: 100%;
      justify-content: flex-start;
      height: fit-content;
      flex-wrap: wrap;
    }
  }

  .main{
    width:76%;
    // width:calc(100% - 325px);
    height:100%;
    &.faithJournal{
      width: 100%;
      box-sizing:border-box;
      padding:30px;
    }

    .header{
      display: flex;
      align-items: center;
      height:90px;
      text-align: left;

      .headerPic{
        height:90px;
        width:60px;
        background: url('../../../assets/cover_story.png') no-repeat  center;
        background-size: contain;
        
        &.faithJournal{
          background: url('../../../assets/cover_journal.png') no-repeat;
          position: relative;
          background-size: contain;
        }
      }
    }

    .headerText{
      margin-left: 25px;

      p.subhead{
        color:#858585;
        font-size: 16px;
      }
    }

    .search{
      margin-top:30px;
      text-align: left;
      display: flex;
      // justify-content: space-between;

      input[name='filterText'] {
        background: url('../../../assets/icon-lupa.png') no-repeat 5px center;
        min-height: 35px;
        max-height: 35px;
        max-width: 400px;
        width:75%;
        border: 1px solid $grey-three;
        border-radius: 5px;
        padding-left: 35px;
      }
      .submit {
        color: white;
        background-color: $main-blue--bright;
        height: 33px;
        line-height: 33px;
        text-align: center;
        width: 95px;
        // padding: 8px 20px;
        border-radius: 5px;
        margin-left: 10px;
        border:1px solid $main-blue--bright;
        transition: .3s all ease-out;
        cursor: pointer;

        &:hover{
          background-color: white;
          color:$main-blue--bright;
        }
      }
    }

    .itemsDiv{
      margin-top:30px;
      max-height:calc(100% - 195px);
      width:100%;
      display: flex;
      flex-wrap: wrap;
      overflow-y: auto;
      box-sizing:border-box;
      padding:3px 0 0 0;
      padding-bottom: 5rem;
      overflow-x: hidden;

      
      h5{
        font-size: 14px;
        color:#858585;
        font-weight: 500;
      }

      &::-webkit-scrollbar-track-piece {background-color: transparent};

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 5px;
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: #d2d2d2;
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
      }
      }
  }
}