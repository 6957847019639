@import '../../../../shared.scss';

.container{
  margin-right: 9px;
  margin-bottom: 10px;
  height:145px;
  width:calc(25% - 5px);
  min-width: 200px;
  margin-right: 5px;
  background-color:white;
  border-radius:10px;
  box-sizing:border-box;
  padding:10px;
  position: relative;
  border:1px solid #cacaca;
  transition:.2s all ease-out;

  @media (max-width:1250px){
    width: calc(33% - 5px);
  }
  @media (max-width:1080px){
    width: calc(50% - 5px);
  }
  @media (max-width:900px){
    width: 100%;
  }

  &.hoverable{
    &:hover{
      // transform: scale(1.02) translateY(-2px);
      transform: translateY(-2px);
      border:1px solid $main-blue--light;
    }
  }
  
  .inner{
    cursor: pointer;
    height:100%;
    width:100%;

    &.unconfirmed{
      cursor: default;
      opacity: .6;
      pointer-events: none;
    }
  }

  .groupDiv{
    height: 30px;
    width: 95px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    img{
      height: 21px;
      width: 21px;
      margin-right: 5px;
    }
    h1{
      font-size:15px;
      color:white;
      margin:0;
      position: relative;
      top: -1px;
    }
  }
  
  h3{
    font-size:13px;
    color:#333333;
    line-height:13px;
    font-weight: 300;
    margin:5px 0;
  }

  .boomers{
    position: absolute;
    bottom:5px;
    left:5px;
    height:35px;
    width:100%;
    display: flex;
    // justify-content: flex-end;

    .boomer{
      max-height:35px;
      max-width:35px;
      object-fit: contain;
      border-radius: 50%;
      overflow: hidden;
      border:3px solid white;
      display: flex;
      align-items:center;
      justify-content: center;
      position: relative;
      bottom:3px;
      background-color: $E_blue_light;

      &:first-of-type{
        // left:10px;
        z-index: 10;
      }
      &:nth-of-type(2){
        right:10px;
        z-index: 8;
      }
      &:nth-of-type(3){
        right:20px;
        z-index: 1;
      }
      img{
        height:30px;
        width:30px;
        object-fit: 30%;
      }

    }
  }

  .delete{
    cursor: pointer;
    position: absolute;
    bottom:16px;
    right:13px;
    height:17px;
    width:17px;
    background: url('../../../../assets/icon-delete.svg') no-repeat center;
    background-size: contain;
  }

  .unsaved{
    position: absolute;
    top:15px;
    right:15px;
    p{
      margin:0;
      font-size: 14px;
      color:$main-blue--light

    }
  }
}