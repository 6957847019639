@import '../../../shared.scss';

.container{
  // position: relative;
  // display:flex;
  // flex-direction: column;
  // justify-content: space-between;
  // text-align: left;
  // height:100%;
  // width: 100%;
  // box-sizing: border-box;
  // padding:10px;
  // // border:10px solid white;



  margin: 0 auto;
  height:100%;
  width:calc(100vw - 70px);
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  background-color: white;
  box-sizing:border-box;
  padding:20px 50px;
  overflow-y: auto;

  *{
    font-family: 'DM Sans';
  }

}
.overlay{
  position: absolute;
  z-index: 25;
  width:150%;
  height: 150%;
  background-color: rgba(128, 255, 0, 0);
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
}
.top{
  height:90px;
  
  .breadcrumb{
    display: flex;

    a{
      color:#858585;
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;
      
      &:hover{
        // color:#999999;
        text-decoration: underline;
      }
    }

    span{
      color:#858585;
      font-size: 16px;
      font-weight: 600;
      margin:0 5px;
    }

    h6{
      margin: 0;
      color:#4e4e4e;
      color:#333333;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .viewSelector{

    input[type="radio"]{display:none}

    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing:border-box;
    padding:4px;
    width: 226px;
    height: 46px;
    background-color: white;
    border-radius: 10px;
    -webkit-box-shadow: 1px 1px 8px 2px rgba(3, 19, 255, 0.15);
    -moz-box-shadow: 1px 1px 8px 2px rgba(3, 19, 255, 0.15);
    box-shadow: 1px 1px 8px 2px rgba(3, 19, 255, 0.15);

    input+.viewRadio {
      border-radius: 10px;
      height: 36px;
      width:105px;
      line-height: 36px;
      text-align: center;
      color: #858585;
      background-color: white;
      border:1px solid transparent;
      cursor: pointer;
      transition: .3s all ease-in-out;
      font-weight: 300;

      &:hover{
        border-color: $main-blue--bright;
        color:$main-blue--bright
      }
    }

    input:checked+.viewRadio{
      pointer-events: none;
      color:white;
      background-color: $main-blue--bright;
    }

  }
}











@media (max-width:600px){
  .top{
    height:130px;
    
    h1{
      font-size: 22px;
      padding-left:62px;
      line-height: 43px;
    }
  }
  .container{
    height: 100vh;
    min-height: 655px;
  }

  .filterButtons{
    display: none;
  }

  
}

@media (max-width:400px){
  .container{
    min-height: 0;
  }
  .top{
    h1{
      font-size: 15px;
    }
  }
}
