@import '../../../../shared.scss';

.container{
  position: relative;
  // height:710px;
  width: 780px;
  transition: .3s all ease-in-out;
  background-color: white;
  padding: 30px 70px;
  border-radius: 10px;

  *{
    font-family: 'Sofia pro';
  }

  &.simplified{
    height: 570px;
  }

  &.confirm{
    height:fit-content;
    width:fit-content;
    display: flex;
    align-items:center;
    justify-content: center;
  }


  input[type='radio']{display: none}
  
  h1{
    width:100%;
    text-align: center;
    font-size: 27px;
    color: #333333;
  }
  h2{
    font-size: 25px;
    color: #333333;
    margin:20px 0;
  }
  h3{
    font-size: 18px;
    color: #333333;
    margin-bottom: 10px;
    font-weight: 500;
    text-align: left;
    padding-left: 5px;
  }

  p.subtitle{
    color: #858585;
    font-weight: 300;
    width: 75%;
    margin: 10px auto 15px auto;
  }

  .inputRow, .subjectDiv{
    margin:10px 0;
    height: 60px;
  }

  p.labelText{
    margin:0 !important;
    color: #333333;
    width:fit-content;
    font-weight: 300;
    font-size: 15px;
  }

  label{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width:fit-content;
    cursor: pointer;
  }

  input[type=checkbox]{
    display: none;
  }
  .checkboxContainer{
    // height:20px;
    // width:20px;
    display: flex;
    align-items: center;
    justify-content:center;
    z-index: 10;
    cursor: pointer;
    position: relative;
    margin-left: 3px;
  }

  .checkbox{
    min-height: 10px;
    max-height: 10px;
    min-width: 10px;
    max-width: 10px;
    border:2px solid #D2D2D2;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
  }

  input:checked + .checkboxContainer .checkbox{
    border-color: $main-blue--bright;
    position: relative;

    &:after{
      content: "";
      min-height: 6px;
      max-height: 6px;
      min-width: 6px;
      max-width: 6px;
      border-radius: 50%;
      background-color: $main-blue--bright;
      position: absolute;
      bottom:2px;
      left: 2px;
    }
  }

  .inputRow{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 30px;

    .authorDiv{
      width:40%;
      margin-right: 15px;
      position: relative;

      img{
        position: absolute;
        right:3px;
        top:36px;
        height:25px;
        width:25px;
        object-fit: contain;
      }
    }

    .typeDiv{
      width:300px;
      margin-left: 40px;

      .radiosDiv{
        display: flex;
        justify-content: space-between;
        position: relative;
        left: 5px;
      }
    }
  }

  .subjectDiv{
    width:96%; 
    text-align: left;
    margin-bottom: 30px;

    .subjectRadios{
      position: relative;
      width:500px;
      margin-top: 20px;
      position: relative;
      left: 5px;
    }
  }

  input[type='text']{
    height:40px;
    width: 100%;
    border-radius: 5px;
    border:1px solid #cacaca;
    color:#858585;
    font-size: 16px;
    line-height: 30px;
    box-sizing:border-box;
    padding:0 0 0 10px;
    font-weight: 500;
  }

  .typeRadios, 
  .subjectRadios, 
  .genreRadios,
  .gradesRadios{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
  }


  .genreRadios{
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 20px;

    input[type=radio]{
      display: none;
    }

    .genrePill{
      height: 25px;
      line-height: 25px;
      padding: 0 15px;
      border-radius: 20px;
      color: $E_pink;
      border:1px solid $E_pink;
      cursor: pointer;
      margin:0 2px 5px 2px;
    }

    input:checked + .genrePill{
      background-color: $E_pink;
      color: white;
    }
  }

  .gradeRadios{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;

    .gradeRadio{
      height: 25px;
      line-height: 25px;
      padding:0 15px;
      color: $E_blue;
      border:1px solid $E_blue;
      cursor: pointer;
      border-radius: 20px;
      margin:0 3px 3px;
    }

    input:checked + .gradeRadio{
      background-color: $E_blue;
      color: white;
    }


  }



  .editorDiv{
    height:150px;
    border-radius: 5px;
    border:1px solid #cacaca;
    box-sizing:border-box;
    resize: none;
    width: 100%;
    font-size: 16px;
    color: #858585;
    overflow: hidden;
    
    .textEditor{

      p{
        margin:3px 0 !important;
      }
    }
  }

  .confDiv{
    height:265px;
    width:420px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    opacity: 0;
    transition: .3s all ease-in-out;

    &.show{
      opacity: 1;
    }

    p.sml{
      font-size: 12px;
      position: relative;
      bottom: 10px;
      color: #858585;
    }

    .btns{
      display: flex;
      align-items: center;
      justify-content: center;
      width:100%;

      .goBtn,
      .closeBtn{
        height: 35px;
        line-height: 35px;
        border-radius: 5px;
        padding:0 20px;
        margin:0 5px;
        cursor: pointer;
        transition: .15s all ease-out;
        font-size: 13px;
      }

      .goBtn{
        color: $E_blue;
        background-color: $E_blue_light;
        
        &:hover{
          background-color: $E_blue_light2;
          color: $E_blue !important;
        }
      }

      .closeBtn{
        color: white;
        background-color: $E_blue;

        &:hover{
          background-color: $E_blue_dark;
        }
      }

    }
  }

  .bottomRow{
    widows: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
    box-sizing:border-box;
    padding:0 0 0 10px;

    .includeAttachments{
      display: flex;
      align-items: center;

      .checkbox{
        border-radius: 4px;
        margin-right: 10px;
        min-height: 15px;
        max-height: 15px;
        min-width: 15px;
        max-width: 15px;
      }

      input:checked + .checkboxContainer .checkbox{
        background: url('../../../../assets/check_square_teal.svg') no-repeat center;
        background-size: 18px;
        border-color: $E_blue;
        // position: relative;
    
        &:after{
          content: "";
          display: none;
        }
      }

      label{
        color: #858585;
        font-size: 14px;
      }
    }
  }

  .bottomBtn{
    height:35px;
    line-height: 35px;
    text-align: center;
    width:230px;
    background-color: $E_blue;
    color:white;
    border-radius: 5px;
    border:1px solid $E_blue;
    cursor: pointer;
    transition: .4s all ease-out;
    position: relative;

    &:hover{
      background-color: $E_blue_dark;
    }
  }

  .error{
    position: absolute;
    white-space: nowrap;
    bottom:5px;
    right:240px;
    color:red;
    font-size: 13px;
    font-weight: 300;
  }
}