@import '../../../../shared.scss';

$one:#D00000;
$two:$main-orange;
$three:$main-green;
$four:#4d7321;

.container{

  .top{
    display:flex;
    margin-top:10px;

    .dropDown{
      height:30px;
      min-width:136px;
      border-radius: 8px;
      position: relative;
      border:1px solid #858585;
      cursor: pointer;
      margin-right: 50px;
      margin-left: 10px;
  
      .text{
        line-height: 30px;
        font-size: 14px;
        text-align: center;
        position: relative;
        right:10px;
        color:#858585;
      }
      
        .dropChevron{
          position: absolute;
          height:30px;
          width:30px;
          top:-2px;
          right: 0;
          background: url('../../../../assets/icon-chevron--down--black.png') no-repeat center 12px;
          // background: url('../../../../../assets/icon-chevron--down--black.png') no-repeat center 12px;
          background-size: 15px;
          pointer-events: none;
        }
        .dropDownOptions{
          position: absolute;
          top:31px;
          left:0px;
          height:fit-content;
          width:135px;
          font-size: 14px;
          border:1px solid #cacaca;
          background-color: white;;
          
          &.hidden{
            display: none;
          }
          input{
            display: none;
  
          };
          label>input+.gradeRow{
            width:135px;
            text-align: center;
            line-height: 30px;
            color:#858585;
            box-sizing:border-box;
            padding:0 15px 0 0;
            cursor: pointer;
  
            &:hover{
              background-color: #cacaca20;
            }
          }
  
          label>input:checked+.gradeRow{
            background-color: #cacaca20;
          }
        }
      }
    
    .scores{
      display:flex;

      .score{
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-size: 15px;
        font-weight: 700;
        letter-spacing: 3px;
        height:30px;
        width:245px;
        border-radius: 5px;
        margin:0 8px;
        text-align: center;
        line-height: 30px;
        color:white;

        &:nth-child(1){
          background-color: $four;
        }
        &:nth-child(2){
          background-color: $three;
        }
        &:nth-child(3){
          background-color: $two;
        }
        &:nth-child(4){
          background-color: $one;
        }
      }
    }
  }

  .main{
    margin-top:20px;
    overflow-y: auto;
    height: 570px;
    min-width: 1240px;
    .tableRow{
      width:1240px;
      display: flex;
      align-items: center;
      height:fit-content;
      min-height: 100px;
      background-color: #e9e9e9;
      // width:1000px;

      &:nth-of-type(2n){
        background-color: white;
      }
      .column{
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-sizing:border-box;
        padding:10px;
        width:270px;
        height:100%;

        p{
          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
          font-size: 12px;
          line-height:18px;
          color:#858585;
          margin: 3px;
        }

        &:first-of-type{
          margin: auto 0;
          width:200px;
          font-size: 16px;
          line-height: 18px;
          color:black;
        }
      }
    }
  }
}