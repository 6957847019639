@import '../shared.scss';




/////////
  .Calendar{
    box-shadow: none !important;
    background-color: transparent !important;

    &.Calendar__blocked{
      pointer-events: none !important;
    }

    &.openEnded{
      .Calendar__day{
        &.-selectedStart {
          background-color: transparent;
          color: rgba(0, 0, 0, 0.8);
        }
      }
    }

    .Calendar__section{
      padding: 0 3.3em !important;
    }

    .Calendar__monthText,
    .Calendar__yearText{
      font-size: 14px;
      color: #4e4e4e;
      font-weight: 500;
    }

    .Calendar__weekRow{
      margin-bottom: 1px !important;
    }

    .Calendar__weekDay{
      font-size: 14px;
      color: #333333;
      font-weight: 700;
    }
    
    .Calendar__monthArrowWrapper{
      // border:1px solid cyan !important; 
      height: 20px;
      width: 20px;
      background: url('../assets/Icons/arrow_square_chevron_hover.png') no-repeat center !important;
      transform: rotate(90deg);

      .Calendar__monthArrow{
        display: none;
      }
  
      &.-left{
        // border:1px solid cornflowerblue !important;
        transform: rotate(270deg);
      }
    }
    
    &:nth-of-type(2){
      
      .Calendar__monthArrowWrapper{
        // border:1px solid pink !important; 
    
        &.-left{
          // border:1px solid red !important;
        }

      }
      .Calendar__day.-today{
        font-weight: 300 !important;
        padding-bottom: 5px;
        // border:1px solid cyan;
      }
      .-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween)::after{
        background: transparent !important;
        height: 6px;
        width: 6px;
        border-radius: 50%;
        font-weight: 300 !important;
      }

    }

    .Calendar__day{
      font-weight: 300;
      
      &.-selectedStart {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        background-color: #077CA0;
      }
      &.-selectedBetween{
        background-color: #CDE4EC;
        color: #4e4e4e;
      }   
      &.-selectedEnd {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        background-color: #077CA0;
      }

      &:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover{
        background-color: #82DBF7 !important;
        border-radius: 8px;
      }

      &.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween){
        font-weight: 300 !important;

      }

      &.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween)::after{
        background: #FFA826;
        height: 6px;
        width: 6px;
        border-radius: 50%;
      }
      .Calendar__day.-today{
        padding-bottom: 5px;
        // border:1px solid cyan;
      }
    }

    @media (max-width:660px){
      width: 100vw;
      padding: 0;
    }


  }
////////



.react-datepicker {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
  text-transform: uppercase;
  padding:5px;
  margin-top: 5px;
  -webkit-box-shadow: 6px 9px 9px 0px rgba(0,0,0,0.43);
  -moz-box-shadow: 6px 9px 9px 0px rgba(0,0,0,0.43);
  box-shadow: 6px 9px 9px 0px rgba(0,0,0,0.43);
  position: relative !important;
  top:-10px !important;
  left:5px !important;
  position: relative;
  bottom: 20px;
  z-index: 1000 !important;

  button{
    z-index: 1000;
  }


  .react-datepicker__triangle {
    border-bottom-color: white !important;
  }
//  .react-datepicker__triangle{
//     margin-left: -2px !important;
//   }

  .react-datepicker__current-month{
    line-height: 1.6rem !important;
  }

  .react-datepicker__day--highlighted {
    background-color: transparent;
    position: relative;
  }
  .startDateSelector,
  .endDateSelector,
  .todaySelector,
  .publishPartySelector,
  .deadlineSelector{
    position: relative;
  }
  .startDateSelector::before,
  .endDateSelector::before,
  .todaySelector::before,
  .publishPartySelector::before,
  .deadlineSelector::before{
    content: '';
    height: 7px;
    width: 7px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 50%;
    top: 0px;
  }

  .publishPartySelector{
    background-color: #4e4e4e;
    color: white !important;
    border-radius: 5px;
  }

  .startDateSelector::before {
    background-color: $main-yellow;
  }
  .endDateSelector::before {
    background-color: red;
  }
  .todaySelector::before {
    background-color: $main-green;
  }
  .deadlineSelector::before {
    background-color: #077ca0;
    top:-3px !important
  }
  .publishPartySelector::before {
    background-color: $main-pink;
    top:-3px !important
  }
}


.react-datepicker__day {
  color: grey !important;
  width: 2.7rem !important;
  // border:1px solid chartreuse;
  margin: 3px 0 !important;
  line-height: 2rem !important;
  font-size: 0.9rem !important;
  font-weight: 700;
    position: relative;
    z-index: 1000;
}

// .react-datepicker__day {
//   width:2.3rem !important;
//   line-height: 2rem !important;
//   font-size: 0.9rem !important;
//   font-weight: 700;
//   position: relative;
//   z-index: 1000;
// }
.react-datepicker__day--selected {
  background-color: $main-blue--bright !important;
  color: white !important;
}

.react-datepicker__header {
  background-color: white !important;
  border: none !important;
}

.disabled-date{
  color: rgb(214, 214, 214) !important;
  pointer-events: none;
}

.intermediary-date{
  width: 2.7rem !important;
  // border:1px solid chartreuse;
  margin: 3px 0 !important;
  background-color: #d2d2d2 !important;
  color: #858585 !important;
}

.react-datepicker__month-container{
  margin-bottom: 40px !important;
}

.react-datepicker__day-name{
  width: 2.7rem !important;
  line-height:1.3rem !important;
  font-size: .7rem !important;
  font-weight: 700 !important;
  background-color: rgb(180, 180, 180);
  color: rgb(95, 95, 95) !important;
  margin: 10px 1px 5px 1px !important;
  border-radius: 2px !important;
}

.confirmDateBtn{
  height:25px;
  margin: 25px 0;
  // // border:1px solid chartreuse;
  // width:50px;
  width:100%;
  text-align: center;
  position: relative;
  top:-30px;
  height: 50px;
  cursor: pointer;

  span{
    font-size: 12px;;
    border-radius: 15px;
    height:30px;
    box-sizing:border-box;
    padding:5px 15px;
    color: white;
    background-color: $main-blue--bright;
  }

  &-inactive{
    width:100%;
    text-align: center;
    position: relative;
    top:-30px;
    height: 50px;
    pointer-events: none;
    span{
      border-radius: 15px;
      height:30px;
      box-sizing:border-box;
      padding:5px 15px;
      color: white;
      background-color: $main-blue--bright;
      opacity: .5;
    }
  }
}


.partyModalStepTwoCalendar, 
.partyCountdownCalendar{
  .react-datepicker__tab-loop{
    .react-datepicker-popper{

      // top:50px !important;

      div > .react-datepicker{
        min-height: 400px;
        max-height: 400px;
        // bottom: -20px !important;


        .calendarItems{
          min-height: 50px;
          max-height: 100px;
          width: 100%;
          position: relative;
          top:250px;
          z-index: 1;

          .hidden{
            opacity: 0;
          }

          h6{
            position: absolute;
            font-weight: 300;
            text-transform: none;
            font-size: 15px;
            line-height: 18px;
            left:5px;
            bottom:-70px;
            color: #858585;
            width: 290px;
          }

          p{
            position: relative;
            color: #858585;
            font-size: 14px;
            font-weight: 300;
            bottom: 280px;
            left: 10px;
            margin: 0;
            padding-left: 15px;
            text-transform: none !important;

            &:first-of-type::before{
              content:"";
              height: 10px;
              width: 10px;
              border-radius: 50%;
              position: absolute;
              left: -2px;
              top:315px;
              background-color: #077ca0;
            }
            &:last-of-type::before{
              content:"";
              height: 10px;
              width: 10px;
              border-radius: 50%;
              position: absolute;
              left: -2px;
              top:7px;
              background-color: $main-pink;
            }
          }

          .nextBtnInner{
            position: relative;
            text-transform: none;
            bottom: 320px;
            left: 190px;
            @include solidButton(white, $main-blue--bright, 16px, 40px, 100px);
            
            &.arrow{
              padding-right: 15px;
              background: url('../assets/icon-arrow--white.svg') no-repeat top 17px right 20px;
              background-color: $main-blue--bright;

              &:hover{
                background: url('../assets/icon-arrow--blue.svg') no-repeat top 17px right 20px;
              }
            }
            

            &.unavailable{
              opacity: .6;
              pointer-events: none;
            }
          }

        }
      }
    }
  }
}

.partyCountdownCalendar{
  .react-datepicker__tab-loop{
    .react-datepicker-popper{

      top:-0px !important;
    
    }
  }
}