@import '../../../../../shared.scss';


.container{
  width: 100%;
  height: 100%;
  background-color: #F9F9F9;
  position: relative;

  &.short{
    height: calc(100% - 85px);
  }


  
  .filesDiv{
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    box-sizing:border-box;
    padding:0 10px 0 0;

    .fileDiv{
      height: 80px;
      width: 115px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // overflow: hidden;
      margin-right: 15px;
      cursor: pointer;
      position: relative;

      *{
        border-radius: 10px;
      }

      &.pdfFile{
        border:2px solid #077CA0;
        box-sizing:border-box;
        padding:8px 0 0 0;
      }
      
      &.placeholder{
        border:1px solid #077ca075;
        background-color: rgba(128, 128, 128, 0.05);

        img{
          height: 80px;
          object-fit: contain;
        }
      }

      p{
        font-weight: 500;
        margin-top: 3px;
        font-size: 13px;
        color:#077CA0;
      }

      img{
        height: 100%;
        width: 100%;
        object-fit: cover;

        &.pdfFile{
          height: 30px;
          object-fit: contain;
        }
      }

      .hoverDiv{
        height: 100%;
        width: 100%;
        position: absolute;
        top:0;
        left:0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(255, 255, 255, 0.6);
        transition: .15s all ease-out;
        opacity: 0;

        
        
        .viewBtn{
          height: 30px;
          width: 90px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          background-color: rgba(255, 255, 255, 0.8);
          opacity: 0;
          pointer-events: none;
          transition: .15s all ease-out;

          p{
            margin:0;
            font-size: 14px;
            color: #077CA0;
            position: relative;
            top: 1px;
          }

          img{
            height: 18px;
            width: 30px;
            object-fit: contain;
            margin-right: 5px;
          }
        }
      }
      &:hover{
        .hoverDiv{
          opacity: 1;
          .viewBtn{
            opacity: 1;
            pointer-events: all;
          }
        }
      }
    }
  }

  @media (max-width:660px){

    .fileDiv{
      display: none;
    }
  }
}