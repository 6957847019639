@import '../../../../shared.scss';


.container{
    .header{
        position: relative;
        display: flex;
        align-items: center;
        gap: 1rem;
        .imgDiv{
            width: 100px;
            height: 100px;
            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        h4{
            font-weight: 500;
            font-size: 1.35rem;
            color: #2B2B2B;
        }
    }   
    
  .tableHeader{
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    background-color: #f2f2f2;

    .column{
      color: #858585;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      width: 11%;
      line-height: 30px;
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 1.04px;
      box-sizing:border-box;
      padding:3px 0 0 0;
      margin: 0 auto;

      &.wide{
        width:15%
      }

      &:first-of-type{
        width: 45%;
        justify-content: flex-start;
        box-sizing:border-box;
        padding-left: 80px;
        background: #fff;
      }
    }

    &.synod{
      .column{
        width: 100px;
        &.hide{
          display: none !important;
        }
  
        &:first-of-type{
          width: calc(100% - 300px);
        }
    
      }
    }
  }

}