@import '../../../shared.scss';

%contShared {
  position: relative;
  background-color: $main-bluegrey;
  text-align: left;
  height: 570px;
  width: 75%;
  min-width: 350px;
  box-sizing: border-box;
  padding: 30px 40px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .close {
    cursor: pointer;
    opacity: 1;
    position: absolute;
    background-image: url('../../../assets/icon-close.png');
    height: 35px;
    width: 35px;
    right: 15px;
    top: 15px;
    z-index: 1500;
    transition: .3s all ease-out;
    pointer-events: initial;
    background-repeat: no-repeat;

    &:hover {
      opacity: 0.7;
      transform: scale(1.05);
    }
  }
}

.noClose .close{
  display: none !important;
}

.noBG{
  background-color: transparent !important;
}

.containerPlaceholder{
  @extend %contShared;
  justify-content: center;
  align-items: center;
  h4{
    font-size: 17px;
  }
}

.containerStandard{
  @extend %contShared;
  background-color: white;
  height: fit-content;
  height: -moz-max-content;
  width: fit-content;
  width: -moz-max-content;
  justify-content: center;
  align-items: center;

  @media (max-width:660px){
    box-sizing: border-box;
    padding:15px 5px;
  }
}

.containerResSectionList{
  @extend %contShared;
  width: 100vw;
  height: 100vh;
  background-color: white;
  justify-content: center;
  align-items: center;
  padding: 0px 0px;


  .close{
    right: 15px;
    top:60px;
  }
    
}
.containerNoPadd{
  @extend %contShared;
  background-color: white;
  height: fit-content;
  height: -moz-max-content;
  width: fit-content;
  width: -moz-max-content;
  justify-content: center;
  align-items: center;
  padding: 0px 0px;

  &.offsetClose{
    .close{
      right: -45px;
      top:5px;
    }

  }


  @media (max-width:660px){
    background-color: transparent;
    min-width: initial;

    .close{
      top: 5px !important;
      right:2px !important;
      height: 30px;
      width: 35px;
      background-size: contain;
      background-position: center;
    }


    &.setupChapter{
      .close{
        top: 7px !important;
      }
    }

    &.chapterList{
      width: 90vw;
      height: calc(100vh - 90px);
      background-color: white !important;
      box-sizing:border-box;
      padding:10px 20px;

      .close{
        top: 10px !important;
        right: 5px !important;
      }
    }
    &.mobileClose{
      .close{
        top: 8px !important;
        right: 25px !important;
      }
      
    }
  }

}
.containerNewActivityModal{
  @extend %contShared;
  background-color: white;
  height:100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  padding: 0px 0px;

  .close{
    right: 15px;
    top: 35px;
  }

}

.containerCredits{
  @extend %contShared;
  background-color: white;
  height: fit-content;
  height: -moz-max-content;
  width: fit-content;
  width: -moz-max-content;
  justify-content: center;
  align-items: center;
  padding: 0;
  overflow: hidden;
}

.containerVideoPlayer{
  @extend %contShared;
  background-color: transparent;
  height: fit-content;
  height: -moz-max-content;
  width: fit-content;
  width: -moz-max-content;
  justify-content: center;
  align-items: center;
  .close{
    display: none;
    pointer-events: none;
  }
}

.containerNewActivity{
  @extend %contShared;
  background-color: white;
  padding: 5px 0px 30px 0px;
  height: 98%;
  width: 1350px;
  // width: 98%;

  .close{
    // transform: scale(.8);
    top:20px;
    right:20px;
    &:hover {
      opacity: 1;
      transform: scale(.8);
    }
  }
}

.containerStudent{
  @extend %contShared;
  background-color: white;


  .close{
    transform: scale(.8);
    top:5px;
    right:5px;
    &:hover {
      opacity: 1;
      transform: scale(.8);
    }
  }
}

.containerNewTeacher{
  @extend %contShared;
  background-color: white;
  padding: 15px 40px 30px 40px;
  height: 680px;
  width: 1250px;
}

.containerMiniYou{
  @extend %contShared;
  background-color: white;
  height:450px;
  width: 600px;
  box-sizing:border-box;
  padding:30px 50px 10px 30px;
}

.containerUpgradePlan{
  @extend %contShared;
  background-color: white;
  height:340px;
  width: 550px;
  box-sizing:border-box;
  padding:30px 30px 10px;
}

.containerSendParentInvite{
  @extend %contShared;
  background-color: white;
  height:220px;
  width: 500px;
  box-sizing:border-box;
  padding:30px 50px 10px 30px;

  .close{
    transform: scale(.7);
    top:5px;
    right:5px;
    &:hover {
      opacity: 1;
      transform: scale(.8);
    }
  }
}

.pdfGen{
  @extend %contShared;
  background-color: white;
  height:fit-content;
  height:-moz-max-content;
  width: 600px;
  box-sizing:border-box;
  padding:30px 10px 10px 10px;

  @media (max-width:650px){
    width: calc(100vw - 30px);
    height: 90vh;
  }
}
.containerMarkComplete{
  @extend %contShared;
  background-color: white;
  height:fit-content;
  width: fit-content;
  box-sizing:border-box;
  padding:0;
}

.containerResourceItem{
  @extend %contShared;
  background-color: white;
  // height:390px;
  height: auto;
  width: 650px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing:border-box;
  padding:20px 40px 10px 40px;
  transition: all .4s ease-out;
  overflow: hidden;

  &.step1{
    height:fit-content;
    width: fit-content;
    padding:40px;
  }

}
.containerWritingClubActivity{
  @extend %contShared;
  background-color: white;
  height:fit-content;
  height: -moz-max-content;
  width: fit-content;
  width: -moz-max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing:border-box;
  padding:0;
  transition: all .4s ease-out;
  overflow: hidden;
  .close{
    transform: scale(.9);
    &:hover {
      opacity: 1;
      transform: scale(1);
    }
  }
}

.containerActivateAccount{
  @extend %contShared;
  background-color: white;
  padding: 20px 50px;
  height: fit-content;
  width: fit-content;

  .close{
    transform: scale(.7);
    top:05px;
    right:05px;
    
    &:hover{
      transform: scale(.8);

    }
  }
}

.containerNewClass{
  @extend %contShared;
  background-color: white;
  position: absolute;
  top:80px;
  right:200px;
  height:300px;
  width: 690px;
  box-sizing:border-box;
  padding:30px 50px 10px 30px;
  box-sizing:border-box;
  padding:30px;

  @media (max-width:1200px){
    position: initial;
    width: 80vw;
    height: fit-content;
    text-align: center;
    p{margin:10px auto !important}
    *{justify-content: center}
  }
}

.containerNewClassOnboarding{
  @extend %contShared;
  background-color: white;
  height:280px;
  width: 550px;
  box-sizing:border-box;
  box-sizing:border-box;
  padding:20px;
  align-items: center;
  justify-content: center;
  text-align: left;

  @media (max-width:1200px){
    position: initial;
    width: 80vw;
    height: fit-content;
    text-align: center;
    p{margin:10px auto !important}
    *{justify-content: center}
  }
}

.containerCreateNewStudent{
  @extend %contShared;
  background-color: white;
  height:700px;
  width: 800px;
  box-sizing:border-box;
  padding:30px 50px 10px 30px;
}

.containerStudentReport{
  @extend %contShared;
  background-color: white;
  height:700px;
  width: 900px;
  box-sizing:border-box;
  padding:30px 50px 10px 30px;
}

.containerPreview{
  @extend %contShared;
  background-color: #4D3F6D;
  height: 500px;
  width: 420px;
  padding: 0px;
  justify-content: flex-start;
  .close {
    top:-15px;
    right:-15px;
    &:hover {
      opacity: 1;
      transform: scale(.9);
    }
  }
}

.containerPreviewCarousel{
  
  width: 1150px;
  height: 600px;
  position: relative;
  
  .close {
    cursor: pointer;
    opacity: 1;
    position: absolute;
    background-image: url('../../../assets/icon-close.png');
    height: 35px;
    width: 35px;
    right: 155px;
    top: -10px;
    z-index: 1000;

    &:hover {
      opacity: 0.7;
      transform: scale(1.05);
    }
  }
}

.containerRubric{
  @extend %contShared;
  overflow: scroll;
  background-color: white;
  padding: 15px 20px;
  height: 700px;
  width: 1300px;

  .close{
    transform: scale(.7);
    top:0px;
    right:0px;
    &:hover {
      opacity: 1;
      transform: scale(.8);
    }
  }
}

.containerBreakoutRooms{
  @extend %contShared;
  background-color:white;
  padding:20px 30px;
  height: 740px;
  width: 1250px;

  .close{
    transform: scale(.8);
    top:-15px;
    right:-15px;
    opacity: 0;
    pointer-events: none;
    &:hover {
      opacity: 1;
      transform: scale(.9);
    }
  }
}
.containerChat{
  @extend %contShared;
  border-radius: 5px;
  padding:0 !important;
  overflow: hidden;
  background-color:#F0F4F6;
  padding:20px 30px;
  height: 520px;
  width: 1000px;

  .close{
    transform: scale(.7);
    top:05px;
    right:05px;
    
    &:hover{
      transform: scale(.8);

    }
  }

  @media (max-width:660px){
    width: 100vw;
    height: calc(100vh - 65px);
  }
}

.containerShareTheNews{
  @extend %contShared;
  background-color: white;
  padding: 25px 40px;
  height: fit-content;
  height: -moz-max-content;
  width: fit-content;
  width: -moz-max-content;
  overflow: hidden;

  .close{
    z-index: 1;
  }
}

.containerChangeEmail{
  @extend %contShared;
  background-color:white;
  height:fit-content;
  width: fit-content;
  box-sizing:border-box;
  padding:0;

  .close{
    transform: scale(.7);
    top:05px;
    right:05px;
    
    &:hover{
      transform: scale(.8);

    }
  }
}

.containerPayment{
  @extend %contShared;
  background-color:#F0F4F6;
  height:fit-content;
  width: 550px;
  box-sizing:border-box;
  padding:0
}

.containerWritingTips{
  @extend %contShared;
  width:1240px;
  height:690px;
  background-color: white;
  padding: 30px 60px;
}

.containerBookPurchase{
  @extend %contShared;
  width:fit-content;
  height:fit-content;
  width:-moz-max-content;
  height:-moz-max-content;
  background-color: white;
  padding: 30px 60px;
}

.containerTieBreaker{
  @extend %contShared;
  width:fit-content;
  height:fit-content;
  background-color: white;
  padding: 30px 60px;
}

.containerAddTeacher{
  @extend %contShared;
  width:420px;
  height:480px;
  background-color: white;
  padding: 30px 40px;
  .close{
    height:30px;
    width:30px;
    top:10px;
    right:10px;
    background-size: contain;
    &:hover{
      transform: scale(1.1);
    }
  }
}

.containerConnectYourChild{
  @extend %contShared;
  width:fit-content;
  height:fit-content;
  width:-moz-max-content;
  height:-moz-max-content;
  background-color: white;
  padding: 0;
  .close{
    height:30px;
    width:30px;
    top:10px;
    right:10px;
    background-size: contain;
    &:hover{
      transform: scale(1.1);
    }
  }
}

.containerTutorials{
  @extend %contShared;
  overflow:hidden;
  width:fit-content;
  height:fit-content;
  width:-moz-max-content;
  height:-moz-max-content;
  background-color: white;
  padding: 0;
  .close{
    height:30px;
    width:30px;
    top:10px;
    right:10px;
    background-size: contain;
    display: none;
    &:hover{
      display: none;
      transform: scale(1.1);
    }
  }
}

.containerAddExistingTeacher{
  @extend %contShared;
  width:fit-content;
  width: -moz-max-content;
  height:fit-content;
  height: -moz-max-content;
  background-color: white;
  padding: 30px 40px;
  .close{
    height:30px;
    width:30px;
    top:10px;
    right:10px;
    background-size: contain;
    &:hover{
      transform: scale(1.1);
    }
  }
}

.containerAddParent{
  @extend %contShared;
  width:420px;
  height:400px;
  background-color: white;
  padding: 30px 40px;
  .close{
    height:30px;
    width:30px;
    top:8px;
    right:8px;
    background-size: contain;
    &:hover{
      transform: scale(1.1);
    }
  }
}

@media (max-width:1100px) {
  .containerStudent,
  .containerNewTeacher {
    width: 1250px;
    height: 700px;
  }

  .containerMiniYou{
    height:450px;
    width: 600px;
  }
}

.containerUpgradePlus{
  @extend %contShared;
  background-color: white;
  padding: 35px 50px 35px 70px;
  height: 620px;
  width: 1000px;
}

.containerManageSubscription{
  @extend %contShared;
  background-color: white;
  padding: 0;
  height: fit-content;
  height: -moz-max-content;
  width: fit-content;
  width: -moz-max-content;

  .close {
    height: 35px;
    width: 35px;
    right: -10px;
    top: -10px;

    &:hover {
      opacity: 1;
      transform: scale(1.05);
    }
  }
}

.containerCancelSubscription{
  @extend %contShared;
  background-color: white;
  padding: 25px 40px;
  height: fit-content;
  height: -moz-max-content;
  width: fit-content;

  .close{
    height:25px;
    width:25px;
    background-size: contain;
    &:hover{
      transform: scale(1.1);
    }
  }
}

.containerPricingOptions{
  @extend %contShared;
  width: 900px;
  height: auto;
  display: flex;
  background-color: white;
  padding:20px 30px;
}

.containerNotifications{
  @extend %contShared;
  position: absolute;
  left:10px;
  top:10px;
  border-radius: 8px;
  background-color: $main-blue;
  height: fit-content;
  height: -moz-max-content;
  width: fit-content;
  padding:0;
  transition: .3s all ease-in-out;

  &.navOpen{
    left: 150px;
  }

  .close{
    height:25px;
    width:25px;
    top:18px;
    background-size: contain;
    &:hover{
      transform: scale(1.1);
    }
  }
}

.containerPDFRender{
  @extend %contShared;
  height: fit-content;
  width: fit-content;
}




.containerJournalTemplate{
  @extend %contShared;
  background-color: white;
  height: 600px;
  width: 600px;
  box-sizing:border-box;
  padding:30px 50px 10px 30px;

  .close {
    transform: scale(.78);
    right: 5px;
    top: 5px;

    &:hover{
      transform: scale(.9);
    }
  }
}


.containerBookPreview{
  background-color: transparent;
  height:fit-content;
  width: fit-content;
  padding:none;

  .close {
    display: none;
  }
}

.containerAdminSubscription{
  @extend %contShared;
  background-color: white;
  width: 950px;
  height: 90vh;
  padding:20px 30px;
}



@media (max-width:900px) {
  
  .containerNewTeacher {
    width: 1250px;
    height: 900px;
  }
}

@media (max-width:600px) {
  .containerStudent,
  .containerNewTeacher {
    height: 100vh;
    width: 100vw;
    border-radius: 0;
    position: absolute;
    top: 0;
  }
  .containerNewTeacher{
    overflow: hidden;
  }

  .containerMiniYou{
    height:100vh
  }
}