@import '../../../../shared.scss';

.row {
  display: flex;
  justify-content: space-between;
  height: 48%;
  width: 100%;
}


.tooltip{
  z-index: 1000;
  position: absolute;
  height:25px;
  line-height: 25px;
  text-align: center;
  width:135px;
  border-radius: 5px;
  bottom:-30px;
  left: 50%;
  transform: translateX(-50%);
  margin: auto;
  background-color: #858585;
  color:white;
  display: none;
  font-size: 13px;
  transition: .3s all ease-out;


  &::after{
    content:'';
    position: absolute;
    top: -10px;
    left:0;
    margin-left: calc(50% - 5px);
    border-width: 5px;
    border-style: solid;
    border-color:transparent transparent #858585 transparent; 
  }
}


.block {
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid $grey-unselected;
  height: 100%;
  border-radius: 15px;


p{
  margin: 0;
  color:$main-green;
  line-height: 35px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  padding-right: 18px;
}

  *{font-family: 'Sofia pro'}
  h1{font-size: 35px !important}
  h3{font-size: 18px !important}
  h4{
    font-size: 14px !important;
    font-weight: 300;
  }
}

.boomerBlock {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  // justify-content: space-between;

  .boomer {
    background: url('../../../../assets/manage/boomermaleLG.jpg') no-repeat center;
    height: 70%;
    width: 25%;
    background-size: contain;
  }

  .details {
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    h4 {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      color: black;
      margin-bottom: 8px;
      ;
    }

    .detailsButtons {
      margin-bottom: 8px;

      .gradeBtn {
        @include borderButton(white, $main-blue--light, 13px);
        pointer-events: none;
        margin-left: 0;
        padding: 6px 20px;
      }
    }
  }

  .bucks {
    display: flex;
    align-items: center;

    .box {
      margin-right: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $main-green;
      color: white;
      font-size: 19px;
      line-height: 30px;
      font-weight: 300;
      height: 30px;
      width: 55px;
      border-radius: 10px;
    }

    h4{
      font-weight: 500;
      line-height: 32px;
      padding-top: 5px;
    }
  }
}

.topRight{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  position: absolute;
  right: 0;
  box-sizing:border-box;
  padding:20px 15px;

  .buttons{
    width: 100%;
    display: flex;
    flex-direction: row;

    .manageButton{
      @include solidButton(white, $main-blue--bright, 16px, 35px, 190px);
      line-height: 32px;
      box-sizing:border-box;
      padding:0 0 0 25px;
      margin-right: 8px;
      font-weight: 300;
      background:url('../../../../assets/icon-cog--white.svg');
      background-repeat: no-repeat;
      background-position: 10px center;
      background-color: $main-blue--bright;
      &:hover{
        background:url('../../../../assets/icon-cog--blue.svg');
        background-repeat: no-repeat !important;
        background-position:10px center !important;
      }
    }
    
    .portfolioButton{
      @include mainButton($main-blue--light ,white,  16px, 35px, 120px);
      line-height: 32px;
      box-sizing:border-box;
      margin-right: 8px;
      font-weight: 300;
    }

    .deleteButton{
      position: relative;
      height:40px;
      width:40px;
      background: url('../../../../assets/icon-delete.svg') no-repeat center;
      background-size: 40%;
      cursor: pointer;
      transition: all .3s ease-in;
      border-radius: 5px;
  
      &:hover{
        background-color: #e9e9e9;
  
        .tooltip{
          display: initial;
        }
      }
    }
  }
}


.loginCode{

  h4{
    text-align: right;
    color:#333333;
    font-size: 16px;
    margin-bottom: 10px;
  }
  .codeDiv{
    position: relative;
    cursor: pointer;
    height: 35px;
    line-height: 39px;
    width: 155px;
    box-sizing:border-box;
    padding:0 15px;
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    background: url('../../../../assets/icon-copy--black.svg') no-repeat 90% center;
    background-color: #e9e9e9;
    border-radius: 8px;

    
    &:hover{
      .tooltip{
        display: initial;
      }
    }
  }
}

.third {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 32%;

  .listRow {
    padding: 0px 20px;
    width: 100%;
    box-sizing: border-box;
    height: 40px;
    border-bottom: 1px solid $grey-unselected;

    &.copy{
      background: url('../../../../assets/icon-copy--black.svg') no-repeat 93% center;
    }
    .tooltip{
      bottom:-20px;
    }

    p{
      line-height: 40px;
    }
  }

  h4 {
    cursor: pointer;
    line-height: 40px;
    color: black;
    font-weight: 300;
    position: relative;

    &:hover{
      .tooltip{
        display: initial;
      }
    }
  }

  h3 {
    line-height: 40px;
    color: rgb(14, 14, 14);
  }

  // .viewBtn {
  //   @include borderButton(white, $main-green, 13px);
  //   margin: 10px auto;
  // }

  .viewBtn{
    @include solidButton(white, $main-green,  14px, 25px, 100px);
    margin: 10px auto;
  }
  .addParentBtn{
    @include mainButton($main-green, white, 14px, 30px, 160px);
    margin: 15px auto;
  }
}

@media (max-width:1100px) {
  .boomerBlock {
    width: 60%;
  }

  .row.one {
    flex-direction: column;
    height: 70%;
    align-items: center;
    margin-bottom: 15px;
  }

  .buttons {
    width: 80%;
    align-items: center;
  }
}

@media (max-width:600px) {
  .boomerBlock {
    width: 90%;

    .details {
      height: 98%;
    }
  }

  .row {
    overflow: scroll;
    flex-direction: column;

    .block {
      width: 90%;
      margin: 0 auto 10px auto;
    }
  }
}

@media (max-width:400px) {
  .row.one {
    height: 95%;

    .boomerBlock {
      height: 270px;
      text-align: center;
      flex-direction: column;

      .boomer {
        height: 75%;
        margin-top: 10px;
      }

      .bucks {
        justify-content: center;
      }
    }
  }
}

