@import '../../../../shared.scss';

.container {
  width:500px;
  height:max-content;

  .contents {
    box-sizing: border-box;
    background-color: white;
    padding: 10px 20px;
    border-radius: 10px;
    display: flex;
    gap: 5px;
    flex-flow: column nowrap;
    transition: .3s all ease-in-out;

    .topRow {
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: flex-start;
      margin: 10px 0;

      img {
        height: 25px;
      }

      h3 {
        color: #333;
        font-size: 1.2rem;
        line-height: 1.4rem;
        white-space: nowrap;
      }

      .forDate {
        margin: 0 -5px;
        font-style: italic;
        font-size: 0.9rem;
        line-height: 1.1rem;
        color: #858585;
        white-space: nowrap;
      }

      .previous {
        height: 30px;
        width: 30px;
        cursor: pointer;
        background: url('../../../../assets/arrow_square_chevron.png') no-repeat center;
        transform: rotate(90deg);
        opacity: 0.5;

        &:hover{
          background: url('../../../../assets/arrow_square_chevron_hover.png') no-repeat center;
          opacity: 0.9;
        }
      }

      .next {
        height: 30px;
        width: 30px;
        position: relative;
        top: -1px;
        cursor: pointer;
        background: url('../../../../assets/arrow_square_chevron.png') no-repeat center;
        transform: rotate(-90deg);
        opacity: 0.5;

        &:hover{
          background: url('../../../../assets/arrow_square_chevron_hover.png') no-repeat center;
          opacity: 0.9;
        }
      }
    }

    .quoteBody {
      color: #4e4e4e;
      font-size: 1rem;
      line-height: 1.4rem;
      text-align: left;
      padding: 0;
      margin-bottom: 20px;

      .sectionTitle {
        font-weight: bolder; 
        margin-top: 10px;

        span {
          margin-left: 10px;
          font-size: 0.7rem;
          font-style: italic;
        }
      }

      p {
        font-size: 1rem;
        font-weight: 300;
        margin-bottom: 5px;
      }

      &.visible {
        display: block;
      }

      em {
        font-style: italic;
        font-size: 0.7rem;
      }
    }

    button {
      background-color: $E_blue;
      color: white;
      font-size: 1rem;
      padding: 5px 10px;
      display: block;
      border-radius: 10px;
      border: none;
      width: 160px;
      cursor: pointer;
      margin-top: auto;

      &:hover {
        background-color: $E_blue_dark;
      }
    }
  }
}

