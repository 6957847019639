@import '../../../shared.scss';

.container{
  width: 100%;
  height:100%;
  box-sizing:border-box;
  position: relative;
  display: flex;
  justify-content:space-between;
  box-sizing:border-box;
  padding:70px 45px 20px 55px;


  h2{
    color: #333333;
    font-size: 18px;
  }
  
  h3{
    font-size: 14px;
    font-weight: 500;
    color: #333333;

  }

  p{
    color:#858585;
    font-size: 14px;
    font-weight: 300;
  }


  .breadcrumb{
    position: absolute;
    top:30px;
    left:60px;
    text-align: left;
    h5{
      font-size: 16px;
      color: rgb(51,51,51);
    }
    .link{
      color: grey;
      &:hover{
        text-decoration: underline;
      }
    }
  }

  .left, .mainInner{
    background-color: white;
    border-radius:15px;
    border-radius: 10px;
    -webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
    -moz-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
    box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
    box-sizing:border-box;
    padding:40px 20px;
  }
  
  .left{
    width:280px;
    height: 100%;
    
    .top{
      position: relative;
      width: 100%;
      text-align: left;

      img.boomer{
        position: relative;
        left:5%;
        width: 130px;
        height:150px;
        object-fit: contain;
      }
      
      .boomerBucks{
        width:110px;
        height:50px;
        border-radius: 10px;
        position: absolute;
        top:5px;
        right:5px;
        background-color: $main-green;
        display: flex;
        align-items: center;
        justify-content: center;

        h4{
          font-weight: 700;
          font-size: 16px;
          color:white
        }
        img{
          position: relative;
          bottom:1px;
          margin-left: 5px;
        }
      }
    }

    .detailsSummary{
      height:115px;
      width:100%;
      border-radius: 15px;
      background-color: #f9f9f9;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-evenly;
      box-sizing:border-box;
      padding:10px 20px;
      -webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
      -moz-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
      box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);

      h1,p{
        width:95%;
        text-align: left;
        overflow:hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      p{

        span{
          height:25px;
          width:25px;
          border-radius: 50%;
          background-color: $main-blue--light;
          color:white;
          text-align: center;
          line-height: 25px;
          padding: 0 5px;
          font-weight: 500;
        }
      }
    }

    .recentActivities{
      overflow: hidden;
      margin-top: 15px;
      min-height:225px;
      width:100%;
      border-radius: 15px;
      background-color: white;
      // box-sizing:border-box;
      // padding:10px 20px;
      -webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
      -moz-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
      box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);

      h5{
        color:#858585;
        margin-top:20px;
        width: 100%;
        text-align: left;
        box-sizing:border-box;
        padding:0 0 0 25px;
      }

      .row{
        height:50px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-bottom:1px solid #c4c4c4;
        text-align: left;
        box-sizing:border-box;
        padding: 0 20px;

        h2, h3, p{width:100%}
        p{font-weight: 500}

        h3, p{
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 200px;
          // border:1px solid chartreuse;
        }

        &:first-of-type{
          height:45px;
          background-color: #f9f9f9;
        }
      }

    }

    .genBtn{
      margin-top: 15px;
      width:100%;
      height:40px;
      line-height: 40px;
      text-align: center;
      color: $main-blue--light;
      border:1px solid $main-blue--light;
      background-color: white;
      transition: .3s all ease-out;
      cursor: pointer;
      border-radius: 8px;

      &:hover{
        color: white;
        background-color: $main-blue--light;;
      }
    }
  }

  .main{
    width: calc(100% - 310px);
    position: relative;

    .mainInner{
      border-top-left-radius: 0px;
      height: calc(100% - 30px);
      position: relative;
      z-index: 5;
    }
    %bar{
      position: absolute;
      height: 3px;
      left: 0px;
      top: 27px;
      z-index: 100;
      width: 127px;
      background-color: white;
    }
    .barOne{
      @extend %bar;
      height: 13px;
      left:1px;
    }
    .barTwo{
      @extend %bar;
      left:130px;
    }
    .barThree{
      @extend %bar;
      left:260px;
      width: 125px;
    }
    .barFour{
      @extend %bar;
      left:389px;
      width: 126px;
    }
    .column2Inner{
      position: relative;
      height:90%;
      width: 95%;
      border: 1px solid rgba(128, 128, 128, 0.35);
      z-index: 10;
    }

    .tabs{ 
      height:30px;
      position: relative;
      left:0;
      z-index: 1;
      label>input {
        visibility: hidden;
        position: absolute;
      }
    
      label>input+div {
        cursor: pointer;
        border: 2px solid transparent;
        transition: all .2s ease;
        margin:2px;
        width:100px;
      }
      label>input:checked+div{
        background-color: white;
        color: black;
        transform: scale(1.04);
      }
      label>input:not(checked)+div{
        background-color: #e9e9e9;
        color: $grey-two;
        transform: scale(1.04);
      }
     
      .tab{
        border: 1px solid rgba(128, 128, 128, 0.35);
        cursor: pointer;
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;
        float: left;
        height:130%;
        width:125px;
        font-size: 15px;
        line-height: 25px;
        background-repeat: no-repeat;
        box-sizing: border-box;
        padding-left: 10px;
        transition: 0s;
      }
      
       label:nth-child(1) > input:not(checked)+div{
        padding-left: 15px;
        background-image: url('../../../assets/icon-profile--inactive.svg');
        background-position: 15px 6px;
      }
       label:nth-child(1) > input:checked + div{
        padding-left: 15px;
        background-image: url('../../../assets/icon-profile.svg');
        background-position: 15px 6px;
      }
       label:nth-child(2) > .tab{
        background-image: url('../../../assets/icon-portfolio--inactive.svg');
        background-position: 12px 6px;
      }
       label:nth-child(2) > input:checked + div{
        background-image: url('../../../assets/icon-portfolio.svg');
        background-position: 12px 6px;
      }
       label:nth-child(3) > .tab{
        background-image: url('../../../assets/icon-gradebook--inactive.svg');
        background-position: 10px 4px;
      }
       label:nth-child(3) > input:checked + div{
        background-image: url('../../../assets/icon-gradebook.svg');
        background-position: 10px 4px;
      }
       label:nth-child(4) > .tab{
        background-image: url('../../../assets/icon-reports--inactive.svg');
        background-position: 15px 4px;
      }
       label:nth-child(4) > input:checked + div{
        background-image: url('../../../assets/icon-reports.svg');
        background-position: 15px 4px;
      }
    }

  }
}