@import '../../../../shared.scss';

.container{
  width: 100%;
  box-sizing:border-box;
  padding:0 15px;
  margin-top: 15px !important;

  *{
    font-family: 'Sofia pro';
  }

  .titleBar{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing:border-box;
    padding:0 15px;

    .pdfBtn{
      height: 20px;
      width: 20px;
      background: url('../../../../assets/pdf.svg') no-repeat center;
      background-size: contain;
      opacity: .6;
    }
  }
  
  .main{
    margin-top: 10px;
    width: 100%;
    box-sizing:border-box;
    padding:15px;
    -webkit-box-shadow: 0px 4px 10px 5px rgba(0, 99, 192, 0.2);
    -moz-box-shadow:    0px 4px 10px 5px rgba(0, 99, 192, 0.2);
    box-shadow:         0px 4px 10px 5px rgba(0, 99, 192, 0.2);
    border-radius: 8px;
    margin-bottom: 20px;
    
    .topRow{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 10px;
      
      h3{
        color: #333333;
      }
      
      .dots{
        background: url('../../../../assets/dots_grey.svg') no-repeat center;
        background-size: contain;
        transform: rotate(90deg);
        height: 17px;
        width: 17px;
      }
    }

    .chapterRows{
      width: 100%;

      .row{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 45px;

        &.selected{
          pointer-events: none;
          p{
            color:$E_blue;

            &.numberDot{
              color: $E_blue;
              background-color: $E_blue_light;
            }
          }
        }

        .left,
        .right{
          display: flex;
          align-items: center;
        }

        .right{
          flex-direction: column;
          align-items: flex-end;
          justify-content: center;

          p{
            font-size: 12px;
            line-height: 15px;
            height: fit-content;
            margin: 0;

            &.newEntries{
              color: red;
            }
          }
        }

        .left{
          width: calc(100% - 90px);
        }

        p{
          font-size: 13px;
          color:#858585;
          height: 40px;
          line-height: 40px;

          &.numberDot{
            font-weight: 700;
            color: #4a4a4a;
            height: 20px;
            line-height: 20px;
            min-width: 20px;
            max-width: 20px;
            padding-top: 1px;
            text-align: center;
            background-color: #e9e9e9;
            margin:0 10px 0 0;
            border-radius: 50%;
          }

          &.sectionName{
            max-width: calc(100% - 20px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
  
        &.draft{
          background-color: transparent !important;

          p.sectionName{
            opacity: .6;
          }

          .right{
            opacity: 0;
          }
        }
  
        &.Live{
        }
  
        &.Completed{

          p.sectionName{
            padding-right: 20px;
            background: url('../../../../assets/tick_green.svg') no-repeat right;
          }
        }
  
        &.storyStart{

          p.sectionName{
            color: $E_orange;
            padding-right: 25px;
            background: url('../../../../assets/rocket_yellow.svg') no-repeat right;
          }

          .right{
            opacity: 0;
          }

        }
      }
    }

    .newChapterBtn{
      box-sizing:border-box;
      padding: 12px 15px 12px 40px;
      height: 50px;
      width: 130px;
      border:1px solid $E_blue;
      color: $E_blue;
      border-radius: 10px;
      margin-top: 5px;
      background: url('../../../../assets/icon_plus_blue.svg') no-repeat center left 15px;
      background-size: 15px;
      
      &.loading{
        padding: 0;
        background: url('../../../../assets/spinner_teal.svg') no-repeat center;
        background-size: 60px;
      }
    }

    .showAllBtn{
      width: 100%;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-radius: 8px;
      background-color: $E_blue_light2;
      color: $E_blue;
      font-weight: 500;
      font-size: 14px;
      margin-top: 15px;

      &:active{
        background-color: $E_blue_light;
      }
    }

  }

  &.modal{
    height: 100%;
    padding: 0;
    margin-top: 5px !important;

    .titleBar{
      display: none;
    }
    .main{
      height: 100%;
      margin:0 !important;
      padding: 0;
      -webkit-box-shadow: none;
      -moz-box-shadow:    none;
      box-shadow:         none;

      .chapterRows{
        height: calc(100% - 100px);
        overflow-y: auto;
      }
    }

    .newChapterBtn{
      width: 100%;
      text-align: center;
      background: url('../../../../assets/icon_plus_blue.svg') no-repeat center left calc(50% - 80px);
      padding: 0;
      height: 45px;
      line-height: 43px;
      margin-top: 20px;
    }


  }
}