@import '../../../shared.scss';

.container{
  height:240px;
  width:220px;
  // max-width:320px;
  // flex-grow: 1;
  border-radius: 10px;
  border:1px solid #cacaca;
  box-sizing:border-box;
  padding:5px 10px 15px 15px;
  text-align: left;
  position: relative;
  margin-right: 15px !important;
  margin-bottom: 15px !important;
  transition:.3s all ease-in-out;

  &:hover{
    bottom:2px;
  }
}


.absolute{
  position: absolute;
  bottom: 15px;
  left: 6px;
  display: flex;
  align-items: center;
  gap: 8px;
  z-index: 10;
  svg{
    cursor: pointer;
    z-index: 1;
  }
  img{
    width: 15px;
    height: 15px;
    object-fit: contain;
    cursor: pointer;
    z-index: 1;
  }
  .question{
    position: relative;
    .tooltip{
      position: absolute;
      bottom: -130px;
      // left: calc(100% - 20px);
      font-size: 10px;
      line-height: 16px;
      text-align: center;
      width: 250px;
      padding:16px;
      border-radius: 8px;
      background-color: white;
      color: #4A4A4A;
      opacity: 0;
      transition: .3s all ease-out;
      -webkit-box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.2); 
      box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.2);
      pointer-events: none;
      z-index: 100;
    }

    &:hover{
      .tooltip{
        opacity: 1;
        left: calc(100% - 10px);
        z-index: 100;
      }
    }
  }
}

.author{
  height:40px;
  width:100%;
  display: flex;
  align-items: center;

  img{
    height:30px;
    width:30px;
    border-radius: 50%;
    // object-fit: contain;
    // border:1px solid chartreuse;
    margin-right: 6px;
  }

}

.resourceName{
  font-size: 16px;
  color: #333333;
  font-weight: 700;
  margin:3px 0 3px 0 !important;
  height:40px;
  // border:1px solid chartreuse;
  // white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

p.resourceP{
  font-size: 13px;
  color:#333333;
  margin:5px 0 10px 0;
  font-weight: 300;
}


.pills, .author{
  margin-bottom: 10px !important;
}

.grades{
  display: flex;

  p.resourceP{
    line-height: 20px;
    margin-right: 5px;
  }

  
}

.overlay{
  opacity: 0;
  position: absolute;
  z-index: 10;
  height:240px;
  width:220px;
  top:0;
  left:0;
  background-color: rgba(105, 105, 105, 0.4);
  -webkit-backdrop-filter: blur(2.5px);
  backdrop-filter: blur(2.5px);
  transition: .35s all ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover{
    opacity: 1;
  }

  .prevBtn{
    width:100px;
    height:35px;
    line-height: 35px;
    text-align: center;
    background-color: white;
    color:$main-blue;
    border:1px solid $main-blue;
    transition: .45s all ease-out;
    border-radius: 7px;

    &:hover{
      background-color: $main-blue;
      color:white;
    }
  }

}


.tab{
  height:75px;
  width:7px;
  position: absolute;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  left: 0px;
  top:8px;
  bottom:0;
  margin:auto 0;
}

.pills{
  display: flex;
  gap: 1rem;

}

.widePill, .gradeDot{
  background-color: $main-blue--bright;
  color:white;
  height:25px;
  line-height: 25px;
  text-align: center;
  font-size: 10px;
  margin:0 0 0 3px;
  display: inline-block;
  text-align: center;
}
.widePill{
  height: 20px;
  line-height: 20px;
  border-radius: 60px;
  padding: 0 20px;
  font-size: 12px;
}
.gradeDot{
  border-radius: 50%;
  width: 25px;
}
.assignment, .Assignment{
  background-color: $main-blue--light !important;
}
.journal, .Journal{
  background-color: $main-yellow !important;
}
.story, .Story{
  background-color: $main-orange !important;
}
.darkBlue{
  background-color: $main-blue !important;
  margin-left: 5px !important;
}
.faithJournal{
  background-color: #8b5e1b !important;
}
.sophia{
  background-color: #1E9AAA !important;
}
.delete{
  position: absolute;
  bottom:15px;
  right:15px;
  cursor: pointer;
  z-index: 100;

  img{
    height:15px;
    object-fit: contain;
    transition: .2s all ease-out;

    &:hover{
      transform: scale(1.25);
    }
  }
}