@import '../../../../shared.scss';

.container{
  display: flex;
  flex-direction: column;
  align-items: center;
  // height: 690px;
  width: 530px;
  transition: all .3s ease-in-out;
  padding-bottom: 5px;

  &.confStage{
    height:190px;
    width:440px;
  }

  *{font-family: 'DM Sans', sans-serif}
  
  h1{
    font-size: 28px;
    margin-bottom: 25px;
    font-weight: 500;
  }

  h4{
    font-size: 16px;
    font-weight: 300;
    color:#858585;
    line-height: 20px;
    // text-align: center;
  }

  h3.warning{
    font-size: 14px;
    font-weight: 300;
    color:red;
    position: absolute;
    bottom: 13px;
  }

  img{
    height:75px;
    width:75px;
    margin-bottom: 20px;
  }

  .checkboxes{
    width: 100%;
    text-align: left;
    margin-top:10px;
  }
  input {
    display: none;
  }
  
  label {
    display: block;
    padding: 2px 0;
    cursor: pointer;

    span {
      position: relative;
      line-height: 22px;
      font-size: 16px;
      font-weight: 300;
      color:#858585;

        &:before,
        &:after {
        content: '';
      }
    }
}
  label span:before {
    position: relative;
    top:5px;
    border: 1px solid #cacaca;
    background-color: #F0F4F6;
    width: 13px;
    height: 13px;
    margin-right: 10px;
    display: inline-block;
    vertical-align: top;
    border-radius: 3px;
  }
  
  label span:after {
    background: $E_blue;
    border-radius: 2px;
    width: 9px;
    height: 9px;
    position: absolute;
    top: 6px;
    left: 3px;
    transition: 300ms;
    opacity: 0;
  }
  
  label input:checked+span:after {
    opacity: 1;
  }


  textarea{
    width:100%;
    height:100px;
    background-color: #f2f2f2;
    border:none;
    resize: none;
    border-radius: 10px;
    box-sizing:border-box;
    padding:8px 15px;
    margin-top: 10px;
    font-weight: 300;
    font-size: 14px;
    color:#333333;
  }

  .btn{
    margin-top:30px;
    width:290px;
    height:40px;
    border-radius: 8px;
    color:white;
    background-color: $E_blue;
    border:1px solid $E_blue;
    cursor: pointer;
    text-align: center;
    line-height: 40px;
    transition: .3s all ease-out;

    &:hover{
      background-color: $E_blue_dark;
    }
  }

  p{
    font-size: 16px;
    font-weight: 300;
    color:$E_blue;
    height:20px;
    cursor: pointer;

    &:hover{
      text-decoration:underline;
    }
  }


  .confDiv{
    height:70%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    margin:auto;

    h3{
      color:#333333;
      font-size: 22px;
    }
    p{
      color:#858585;
      pointer-events: none;
    }

    .btn{
      margin-top:40px;
    }
  }
}