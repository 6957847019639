@import '../../../../shared.scss';

.container{
  min-height: 90px;
  max-height: 90px;
  width: 70vw;
  min-width: 500px;
  max-width: 800px;
  box-sizing:border-box;
  transition: .45s all ease-in-out;
  transition: .15s ease-in all;
  // padding:8px 0px;
  border-radius: 8px;
  cursor: pointer;
  opacity: 0;
  margin-bottom: 15px !important;
  position: relative;
  background-color: white;
  z-index: 40;

  &.bordered{
    -webkit-box-shadow: 0px 4px 10px 5px rgba(0, 99, 192, 0.15);
    -moz-box-shadow:    0px 4px 10px 5px rgba(0, 99, 192, 0.15);
    box-shadow:         0px 4px 10px 5px rgba(0, 99, 192, 0.15);
    margin-bottom: 8px;
    min-height: 90px;
    max-height: 90px;
    
    .main, .text, .iconDiv{
      height: 75px !important;
      // margin-bottom: 10px !important;

      .clickHandle{
        z-index: 10 !important;
      }
    }

    // .content{
    //   .topRow{
    //     .nameDiv{
    //       .editHover{
    //         pointer-events: none !important;
    //         z-index: 0 !important;
    //       }
    //     }
    //   }
    // }


    .iconDiv{
      position: relative;
      top: 5px;
    }
    
    .sectionsDiv,
    .dropdown{
      display: none !important;
    }
  }

  *{
    font-family: 'Sofia pro';
  }



  .dot{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 17px;
    width: 17px;
    border-radius: 50%;
    background: #fb3333;
    color: white;
    margin-left: 5px;
    position: absolute;
    top:-4px;
    right: -4px;
    pointer-events: none;
    transition: .2s all ease-in-out;

    &.open{
      opacity: 0;

    }

    p{
      margin: 0;
      color: white;
      line-height: 20px;
      font-size: 15px;
      font-weight: 500;
      // opacity: 0;
      transition: .2s all ease-in-out;
    }
  }


  &.display{
    bottom:0;
    opacity: 1;
  }

  .inner{
    overflow: hidden;
    min-height: 80px;
    max-height: 80px;
    transition: .3s ease-in all, .3s cubic-bezier(0.2,-0.1, 0.2, 2.2)bottom;
    position: relative;
  }


  &.open{
    max-height: 600px;
    opacity: 1;

    &:hover{
      transform: translateY(-0px) !important;
      -webkit-box-shadow: none !important;
      -moz-box-shadow:    none !important;
      box-shadow:         none !important;

    }

    .inner{
      max-height: 600px;

    }
  }

  .main{
    display: flex;
    height: 80px;
    width: 100%;
    padding:6px 8px;
    position: relative;

    *{
      position: relative;
      z-index: 10;
      pointer-events: none;
    }


    .clickHandle{
      height: calc(100% - 15px);
      width: calc(100% - 20px);
      z-index: 1 !important;
      pointer-events: all !important;
      position: absolute;
      top: 0;
      left: 0;
    }


  } 

  .content{
    width: calc(100% - 10px);
    height: 75px;
    display: flex;
    justify-content: space-between;
    box-sizing:border-box;
    padding: 0  15px 0 0;
    overflow: hidden;
    position: relative;

    // border:1px solid red;

    .iconDiv{
      width: 80px;
      height:100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon{
        width: 42px;
        height: 60px;

        &.one{
          background: url('../../../../assets/journal_blue.svg') no-repeat center;
        }
        &.two{
          background: url('../../../../assets/journal_orange.svg') no-repeat center;
        }
        &.three{
          background: url('../../../../assets/journal_pink.svg') no-repeat center;
        }
        &.four{
          background: url('../../../../assets/journal_green.svg') no-repeat center;
        }

        &.grey{
          background: url('../../../../assets/journal_grey.svg') no-repeat center !important;
        }
      }
    }

    .text{
      width: calc(100% - 85px);
      height: 100%;
    }

    .topRow{
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: calc(100% - 35px);
      z-index: 20;


    .nameDiv{
      width: calc(100% - 50px);
      height: 30px;
      position: relative;
      cursor: pointer;
      pointer-events: all !important;
      z-index: 25 !important;
      padding: 0 30px 0 15px;
      display: flex;
      align-items: center;
      left: -10px;

      h5{
        margin:0;
      }
      

      input[type=text]{
        height: 100%;
        width: 100%;
        // box-sizing:border-box;
        padding:0 10px;
        position: relative;
        left: -15px;
        top: 3px;
        z-index: 15 !important;
        pointer-events: all !important;
        font-size: 16px;
        color: #333333;
        position: relative;
        border:1px solid #f0f0f0;
      }

      .editHover{
        position: absolute;
        height: 35px;
        width: calc(100% - 50px);
        cursor: pointer;
        bottom: 35px;
        z-index: 15;
        border-radius: 5px;
        pointer-events: all !important;
        top: 0;
        left:0;
        
        &:hover{
          background: url('../../../../assets/icon-pencil--black.png') no-repeat center right 5px, #f2f2f2c5;

          //class passed to <Tooltip/> component through props
          .tooltipHover{
            opacity: 1 !important;
            top: 35px !important;
          }
        }
 


      }


      h5{
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
        // letter-spacing:1.02px;
        color: #333333;
        // width: ;
        position: relative;
        top: 3px;
        left: -5px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-align: left;
        z-index:  20 !important;
      }

    }

    .dots{
      min-height: 30px;
      max-height: 30px;
      min-width: 30px;
      max-width: 30px;
      background: url('../../../../assets/threeDots.png') no-repeat center;
      transition: .15s ease-in background-color;
      position: relative;
      right: 2px;
      cursor: pointer;
      transform:rotate(90deg);
      border-radius: 8px;
      pointer-events: all !important;
      z-index: 20;

      &:hover{
        background-color: #E9E9E9;
      }
    }

    }




    .bottomRow{
      height: 35px;
      width: calc(100% - 2px);
      display: flex;
      justify-content: space-between;
      z-index: 15 !important;

      .left{
        display: flex;
        align-items: center;

        p{
          font-size: 13px;
          color: #858585;
          font-weight: 300;
        }
      }
    
      .chevron{
        min-height: 30px;
        max-height: 30px;
        min-width: 30px;
        max-width: 30px;
        background: url('../../../../assets/arrow_square_chevron.png') no-repeat center;
        transition: .3s ease-in transform;
        position: relative;
        // top: 10px;
        cursor: pointer;
        pointer-events: all !important;
  
        &.open{
          background: url('../../../../assets/arrow_square_chevron_hover.png') no-repeat center;
          transform: rotate(180deg);
        }
        
        &:hover{
          background: url('../../../../assets/arrow_square_chevron_hover.png') no-repeat center;
        }
      }
    }
  }


  &.complete{
    // background-color: #A3CFDD !important;
    .sectionsDiv{
      background-color: white;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  &:hover{
    transform: translateY(-3px);
    -webkit-box-shadow: 0px 5px 10px -2px rgba(6, 0, 0, .06);
    -moz-box-shadow:    0px 5px 10px -2px rgba(6, 0, 0, .06);
    box-shadow:         0px 5px 10px -2px rgba(6, 0, 0, .06);
    .dot{
      p{
        opacity: 1;
      }
    }
    .deleteBtn{
      opacity: 1 !important;
      pointer-events:  all !important;
  
      &:hover{
        //class passed to <Tooltip/> component through props
        .deleteTooltipHover{
          opacity: 1 !important;
          top: 45px !important;
        }
      }
    }
  }


  .dropdown{
    width: 220px;
    height: 0px;
    background-color: white;
    border-radius: 8px;
    position: absolute;
    top: 30px;
    right: -5px;
    z-index: 125;
    transition: .15s all ease-in;
    opacity: 0;
    overflow: hidden;
    margin:20px;
    -webkit-box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.1); 
    box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.1);
    pointer-events: all !important;

    &.open{
      height: 80px;
      opacity: 1;

      &.archive{
        height: 80px;
      }
    }

    .dropRow{
      display: flex;
      align-items: center;
      height: 40px;
      cursor: pointer;
      transition: .15s all ease-in;

      &.disabled{
        opacity: .4;
        pointer-events: none;
        cursor: initial;
      }

      &:hover{
        background-color: #e9e9e9;
      }

      .icon{
        height: 40px;
        width: 40px;
        margin:0 15px 0 5px;
      }

      p{
        font-size: 15px;
        color: #858585;
        font-weight: 500;
      }

      &.edit{
        .icon{
          background: url('../../../../assets/icon-row-pencil.svg') no-repeat center;
          background-size: 20px;
        }
      }
      
      &.archive{
        .icon{
          background: url('../../../../assets/icon-row-archive.svg') no-repeat center;
          background-size: 20px;
        }
      }
      
      &.delete{
        .icon{
          background: url('../../../../assets/icon-row-delete.svg') no-repeat center;
          background-size: 17px;
        }
        
        &:hover{
          background-color: #FED6D6;
          .icon{
            background: url('../../../../assets/icon-row-delete-red.svg') no-repeat center;
            background-size: 17px;
          }
          p{
            color:#FB3333
          }
        }
      }
    }
  }

  @media (max-width:670px){
    width: 80vw;
    min-width: initial;
    max-width: 800px;

    .chevron,
    .dots{
      display: none;
    }

    .nameDiv{
      width: 100% !important;
    }


  }
}


.sectionsDiv{
  height: fit-content;
  max-height: 455px;
  overflow: scroll;
  box-sizing:border-box;
  border-top: 1px solid #074b603d;
  // padding:0 8px 8px 8px;

  .placeholder{
    height: 95px;
    min-height: 95px;
    max-height: 130px;
    border-radius: 8px;
    flex-grow: 1;
    max-width: 100%;
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border:1px solid #e9e9e9;
    margin-top: 8px;
    position: relative;

    h5.noSections{
      font-weight: 100;
      font-size: 14px;
      color: #858585;
      letter-spacing: 1px;
    }

  }

}
.sectionDiv{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  width: 100%;
  border-top: 1px solid #074b603d;
  box-sizing:border-box;
  transition: .2s all ease-in-out;
  cursor: pointer;
  background-color: white;

  .left{
    display: flex;
    align-items: center;
  }

  &:hover{
    background-color: #B5D8E3;
  }
  &:first-of-type{
    border-top: none;
  }

  .numberCircle{
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #CDE5EC;
    display: flex;
    align-items: center;
    justify-content: center;
    margin:0 10px 0 15px;

    p{
      color: #4a4a4a;
      font-size: 12px;
      font-weight: 700;
      margin:0;
      position: relative;
      top:1px;
    }

  }
  h5{
    font-size: 16px;
    font-weight: 300;
    width: calc(100% - 140px);
    color: #074B60;
    text-align: left;
    white-space: nowrap;
  }

  .submissions{
    margin-right: 15px;

    p{
      font-size: 12px;
      font-weight: 300;
      color: #858585;
      margin:0;
    }

    p.unseen{
      font-weight: 700;
      font-size: 13px;
      text-transform: uppercase;
      color: #FB3333;
      height: 30px;
      line-height: 30px;
    }
  }


  &.storyStart{
    background-color: #FFEED4;
    border-top: none;

    h5{
      padding-right: 25px;
      width: fit-content;
      color: $E_orange;
      background: url('../../../../assets/rocket_yellow.svg') center right no-repeat;
    }

    .numberCircle{
      background-color: #FFF5E5;

      p{
        color: $E_orange;
      }
    }

    &:hover{
      background-color: #ffe7c3;
    }
  }

  &.scheduled{

    h5{
      height: 30px;
      line-height: 30px;
      padding-right: 25px;
      width: fit-content;
      color: #858585;
      background: url('../../../../assets/calendar_blue.svg') center right no-repeat;
    }

  }

  &.completed{
    background-color: $E_green_light;

    h5{
      color:$E_green_dark;
    }

    .numberCircle{
      background-color: #F1F9E0;

      p{
        color:$E_green_dark;
      }
    }

    &:hover{
      background-color: #E0F2BA;
    }
  }

  &.draft{
    background-color: #F2F2F2;

    &:hover{
      background-color: #e1e1e1;
    }
  }

  &.archived{
    background-color:#ededed !important;
    .numberCircle{
      background-color: #e1e1e1;

      p{
        color:#858585;
      }
    }

    h5{
      color: #858585;
    }

    &:hover{
      background-color:#dddddd !important;
    }
  }

}
