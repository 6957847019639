@import '../../../../shared.scss';

.container{
  height: 240px;
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing:border-box;
  padding:15px 30px;

  *{
    font-family: 'Sofia pro';
  }


  .warning{
    height:70px;
    width:435px;
    background: url('../../../../assets/info2.svg') no-repeat 20px center;
    background-size: 35px;
    background-color: #f2f2f2;
    border-radius: 15px;
    margin-top: 25px;
    box-sizing:border-box;
    padding:13px 10px 15px 70px;
    display: flex;
    align-items: center;

    p{
      margin: 0;
      line-height: 21px;
      font-size: 16px;
      font-style: italic;
      color: $main-orange;
    }
  }
  .buttons{
    display: flex;
    margin-top:30px;

    .cancel,
    .addStudents{
      height:40px;
      width:150px;
      border:1px solid;
      border-radius: 10px;
      line-height: 40px;
      font-size: 16px;
      text-align: center;
      margin:0 8px;
      cursor: pointer;
      transition: .15s all ease-out;
    }

    .cancel{
      border-color: $E_blue_light;
      color: $E_blue;
      background-color:$E_blue_light;

      &:hover{
        background-color: $E_blue_light2;
      }
    }
    .addStudents{
      border-color: $E_blue;
      background-color: $E_blue;
      color: white;

      &:hover{
        background-color: $E_blue_dark;
      }
    }
  }
}