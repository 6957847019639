@import '../../../../../shared.scss';

.container{
  // box-sizing:border-box;
  transition: .3s all ease-in-out;
  overflow: hidden;

  padding:30px 50px;
  *{font-family:'Sofia pro'};

  h1{
    width: 100%;
    text-align: center;
    font-size: 30px;
  }

  h2{
    font-size: 18px;
    color: #333333;
  }

  h3{
    font-size: 20px;
    color: #333333;
  }

  p{
    font-size: 16px;
    line-height: 25px;
    color: #858585;
    margin: 5px 0;
    width: 80%;

    strong{
      color:#333333;
    }

    &.error{
      color:#D00000;
      font-size: 12px;
      position: absolute;
      top:95px;
    }

    &.blue{
      color:$main-blue--bright;
      font-size: 16px;
      line-height: 18px;
      margin: 6px 0;
      font-weight: 500;
      cursor: pointer;

      &:hover{
        text-decoration: underline;
      }
    }
  }


  input[type="text"]{
    height:40px;
    width: 80%;
    padding-left: 15px;
    font-size: 16px;
    border:1px solid #cacaca;
    border-radius: 5px;
  }

  &.details{
    height: 170px;
    width:570px;
    position: relative;
    h1{
      position: relative;
      bottom: 5px;
    }

    h1+p{
      margin-top: 15px;
    }


    strong{
      color: #333333;
    };

    .dashBtn{
      @include solidButton(white, $main-blue--bright, 17px, 35px, 180px);
      margin-top: 5px;
    }


    .loginRow{
      display: flex;
      align-items: center;
      height: 40px;
      line-height: 100%;
      position: relative;
      bottom:10px;

      p{
        width: fit-content !important;
      }

      p.conf{
        font-size: 13px;
        color:$main-green;
        position: absolute;
        bottom:3px;
        left:  185px;
      }
      
      .loginCode{

        h4{
          text-align: right;
          color:red;
          font-size: 16px;
          margin-bottom: 10px;
        }
        .codeDiv{
          position: relative;
          cursor: pointer;
          height: 35px;
          line-height: 39px;
          width: 95px;
          box-sizing:border-box;
          padding:0 8px;
          font-size: 18px;
          font-weight: 700;
          text-align: left;
          color: #333333;
          background: url('../../../../../assets/icon-copy--black.svg') no-repeat 90% center;
          // background-color: #e9e9e9;
          border-radius: 8px;
      
          
          &:hover{
            .tooltip{
              display: initial;
            }
          }
        }
      }
    }

  }


  &.init{

    width:570px;
    height:380px;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;

    .main{
      margin-top: 10px;
      height:100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      position: relative;

      input[type="text"]{
        margin-top: 15px;
      }

      .nextBtn{
        background: url('../../../../../assets/icon-arrow--white.svg') no-repeat right 10px center;
        padding-right: 5px;
        margin: 10px 0 5px 0;
        
        &:hover{
          background: url('../../../../../assets/icon-arrow--blue.svg') no-repeat right 10px center;
          
        }
        
      @include solidButton(white, $main-blue--bright, 17px, 35px, 100px);
      transition:none !important;
      width: 110px !important;
      padding: 0 !important;
    }
    
    .loadingBtn{
      background: url('../../../../../assets/spinner-white-sm.svg') no-repeat center;
      background-size: 40px;
      
      @include solidButton(white, $main-blue--bright, 17px, 35px, 100px);
      background-color: $main-blue--bright !important;
      transition:none !important;
      width: 110px !important;
      padding: 0 !important;
      margin: 10px 0 5px 0;
      pointer-events: none !important;
      }

      .links{
        height: 75px;
        display: flex;
        position: relative;
        top:10px;
        flex-direction: column;
        justify-content: flex-start;
      }
    }

  }
  .createChild{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    h1{
      position: relative;
      bottom: 8px;
    }

    h2 span{
      color:#858585;
      font-size: 11px;
      position: relative;
      bottom:1px;
      left: 5px;
      font-weight: 300;
    }
    .name{
      display: flex;
  
      .first,
      .second{
        height:90px;
        width: 50%;
        position: relative;
  
  
        input[type=text]{
          -webkit-appearance: none;
          outline: none;
          border:1px solid #cacaca;
          border-radius: 5px;
          width: 280px;
          height: 30px;
          box-sizing:border-box;
          padding:0 15px;
          margin-top: 5px;
        }
      }
    }
  
    .grade{
      height:80px;
      position: relative;
      bottom: 10px;
  
      .radios{
        margin-top: 10px;
        display: flex;
        align-items: center;
      }
      input[type=radio]{
        display: none;
      }
  
      label > input + .pill{
        cursor: pointer;
        height:35px;
        width:35px;
        line-height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border:1px solid $main-blue--bright;
        color: $main-blue--bright;
        font-size: 14px;;
      }
  
      // label > input:not(:checked) + .pill{
  
      // }
      label > input:checked + .pill{
        color: white;
        background-color: $main-blue--bright;
        border:1px solid transparent
      }
    } 

  
    input+.tickDiv{
      top:38px;
      right:8px;
      height:25px;
      width:25px;
      background: url('../../../../../assets/teacher/icon-circleTick.svg')center no-repeat;
      background-size: contain;
      position: absolute;
    }
  
    .grade{
      .tickDiv{
        // top:7px;
        // right:5px;
        margin-left: 5px;
        height:25px;
        width:25px;
        background: url('../../../../../assets/teacher/icon-circleTick.svg')center no-repeat;
        background-size: contain;
        // position: absolute;
      }
    }

    .email{
      height:90px;
      width: fit-content;width: -moz-max-content;
      position: relative;


      input[type=text]{
        -webkit-appearance: none;
        outline: none;
        border:1px solid #cacaca;
        border-radius: 5px;
        width: 380px;
        height: 30px;
        box-sizing:border-box;
        padding:0 15px;
        margin-top: 5px;
      }
      input+.tickDiv{
        top:38px;
        right:5px;
        height:25px;
        width:25px;
        background: url('../../../../../assets/teacher/icon-circleTick.svg')center no-repeat;
        background-size: contain;
        position: absolute;
      }
    
    }

    p.createEmail{
      width: 75%;
      font-size: 12px;
      line-height: 18px;
      color: #858585;
      position: relative;
      bottom: 10px;
    }

    .buttons{
      position: relative;
      // border:1px solid chartreuse;

      .createBtn{
        @include solidButton(white, $main-blue--bright, 17px, 40px, 160px);
      }
      
      .loadingBtnCreate{
        background: url('../../../../../assets/spinner-white-sm.svg') no-repeat center;
        background-size: 40px;
        @include solidButton(white, $main-blue--bright, 17px, 40px, 160px);
        background-color: $main-blue--bright !important;
        transition:none !important;
        pointer-events: none !important;

      }
      p.error{
        font-size: 14px;
        font-weight: 300;
        color: red;
        position: absolute;
        top:5px;
        left:170px;
      }
    }


  }


  .tooltip{
    z-index: 1000;
    position: absolute;
    height:25px;
    line-height: 25px;
    text-align: center;
    width:135px;
    border-radius: 5px;
    bottom:-30px;
    left: 50%;
    transform: translateX(-50%);
    margin: auto;
    background-color: #858585;
    color:white;
    display: none;
    font-size: 13px;
    transition: .3s all ease-out;
  
  
    &::after{
      content:'';
      position: absolute;
      top: -10px;
      left:0;
      margin-left: calc(50% - 5px);
      border-width: 5px;
      border-style: solid;
      border-color:transparent transparent #858585 transparent; 
    }
  }
  

  .errorDiv{
    width:100%;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h4{
      font-size: 16px;
      font-weight: 300;
    }

    span{
      color:$main-blue--bright;
      cursor: pointer;

      &:hover{
        text-decoration: underline;
      }
    }
  }

  &.find{
    width:1200px;
    height: 580px;
    text-align: left;
    position: relative;
    box-sizing:border-box;
    padding:30px 50px;

    h1{
      text-align: left
    }

    h2{
      font-size: 20px;
      font-weight: 500;
    }

    p{
      font-size: 16px;
      font-weight: 300;
      width: 100%;
      margin: 20px 0 40px 0;
    }

    .main{
      display: flex;
      justify-content: space-between;
      height: 450px;
      width: 100%;
      // border:1px solid chartreuse;
      
      .column{
        width: 40%;
        height: 100%;

        &:nth-child(2){
          width: 58%;
          .columnInner{
            p{
              width: 65%;
              margin-bottom: 20px;
            }
          }
        }
        
        h2{
          margin-bottom: 25px;
        }

       .columnInner{
         align-items: flex-start;
         width: 100%;
         height: 80%;
         display: flex;
        //  border:1px solid chartreuse;
         
         img{
           margin:0 10px 0 0;
         }
       }
      }
    }

    .doneBtn{
      @include solidButton(white, $main-blue--bright, 17px, 40px, 160px);
      position: absolute;
      bottom:35px;
      right: 60px;
    }
  }

  &.yourChild{
    box-sizing:border-box;
    padding:40px 50px;
    width:390px;
    height: 520px;

    h1{
      position: relative;
      z-index: 100;
      color: #333333;
    } 
    h2{
      font-size: 18px;
      font-weight: 500;
      color: #333333;
      margin:40px 0 30px 0;
    }

    .main{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 60px;

      .boomer{
        height: 170px;
        width:170px;
        background-color: $main-orange;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        img{
          // opacity: .4;
          position: relative;
          bottom:35px;
          width: 150%;
          object-fit: contain;
        }
      }
    }

    .btns{
      margin-top: 20px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .yesBtn{
        @include solidButton(white, $main-blue--bright, 17px, 35px, 180px);
        margin-bottom: 10px;
        font-weight: 300;
      }
      .noBtn{
        @include mainButton($main-blue--bright,white, 17px, 35px, 180px);
        font-weight: 300;
        border:none;

        &:hover{
          background-color: white;
          color: $main-blue--bright;
          text-decoration: underline;
        }
      }
    }
  }
  
}