@import '../../../../shared.scss';

.container{
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  position: relative;
  border: 1px solid #ddd;
  border-radius: 15px;
  overflow-y: auto;

  &.storyStart{
    border:1px solid #f2f2f2;
  }
  
  &.mobile{
    background-color: #ffffff;

    &.grey{
      background-color: #EEEEEE;
      -webkit-box-shadow: none !important;
      -moz-box-shadow:    none !important;
      box-shadow:         none !important;
    }

    width: 100% !important;
    height: 100%;
    margin:0 !important;

    &.preview{
      width: calc(100% - 20px) !important;
      height: calc(100vh - 270px) !important;
      margin: 10px auto !important;
      -webkit-box-shadow: 0px 4px 10px 5px rgba(0, 99, 192, 0.2);
      -moz-box-shadow:    0px 4px 10px 5px rgba(0, 99, 192, 0.2);
      box-shadow:         0px 4px 10px 5px rgba(0, 99, 192, 0.2);
    }
  }

  .separator {
    height: 2px;
    border-radius: 10px;
    border-bottom: 1px dotted #ddd;
    margin: 10px 0;
  }


  .mediaBtns{
    position: absolute;
    top: 15px;
    left: 535px;
    height: 40px;
    width: 120px;
    border-radius: 8px;
    border:1px solid #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    z-index: 10;
    background-color: white;
    -webkit-box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.1); 
    box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.1);

    &.hidden{
      display: none;
    }

    &:before{
      content: "";
      height: 30px;
      width: 2px;
      background-color: #f2f2f2;
      position: absolute;
      top: 5px;
      left: calc(33% - 1px);
    }
    &:after{
      content: "";
      height: 30px;
      width: 2px;
      background-color: #f2f2f2;
      position: absolute;
      top: 5px;
      right: calc(33% - 1px);
    }
    
    >div{
      height: 100%;
      width: 50%;
      transition: .1s all ease;
      cursor: pointer;
      position: relative;
      
      &.image{
        background: url('../../../../assets/icon_gallery.svg') no-repeat center;
        background-size: 18px;
        
        &:hover{
          background: url('../../../../assets/icon_gallery_blue.svg') no-repeat center;
          background-size: 18px;
          // top: 1px;
        }
      }
      
      &.video{
        background: url('../../../../assets/icon_play_btn.svg') no-repeat center;
        background-size: 18px;
        &:hover{
          background: url('../../../../assets/icon_play_btn_blue.svg') no-repeat center;
          background-size: 18px;
        }
      }
    }

    // &:hover{
    //   >div{      
    //     &.image{
    //       background: url('../../../../assets/icons/gallery_green.svg') no-repeat center;
    //       background-size: 18px;
    //     }
        
    //     &.video{
    //       background: url('../../../../assets/icons/play_btn_green.svg') no-repeat center;
    //       background-size: 18px;
    //     }
    //   }
    // }
  }


  .saveBtn{
    background-color:#E6F2F6;
    height: 40px;
    // width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 8px;
    margin-top: 10px;
    transition: .2s all ease-out;
    cursor: pointer;
    opacity: .4;
    pointer-events: none;
    position: absolute;
    top: 7px;
    right: 10px;
    z-index: 20;
    padding: 0 20px;
    // border: 1px solid $E_blue_light2;
    -webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.2);

    @media (max-width:1020px){
      top: initial;
      bottom: 20px;
    }

    img{
      height:20px;
      width:20px;
      margin-right: 10px;
      pointer-events: none;
    }

    &.hidden{
      display: none !important;
    }

    &.enabled{
      opacity: 1;
      pointer-events: all;
    }

    &:hover{
      background-color: #B5D8E3;
    }

    p{
      color: #097CA0;
      pointer-events: none;
    }

  }

  .nameInput{
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing:border-box;
    padding:0 20px;
    position: relative;
    top: 2px;

    input[type=text]{
      height: 40px;
      font-size: 16px;
      line-height: 40px;
      box-sizing:border-box;
      padding-left: 10px;
      width: 100%;
      border: 1px solid #cacaca;
      border-radius: 5px;
      position: relative;
      top: 2px;
    }

    .pencil{
      height:23px;
      width:23px;
      background: url('../../../../assets/pencil_grey.svg') no-repeat center;
      background-size: contain;
    }
    
    h2{
      margin:0 !important;
      line-height: 50px;
      color: #333333;
      font-weight: 500;
    }
  }

  .wordCount{
    position: absolute;
    top:25px;
    right:20px;

    &.left{
      left:20px;
      right: initial;
    }

    p{
      font-weight: 300;
    }
  }
  

  @media (max-width:1150px){
    .wordCount{
      top: initial;
      bottom: 2px;
      right:10px;


    &.left{
      top: 25px;
      bottom: initial;
      left:20px;
      right: initial;
    }
    }
  }

  @media (max-width:660px){
    width: calc(100vw - 30px);
    margin:10px auto !important;
    background-color: #f2f2f2;
    border-radius: 8px;

    .wordCount{
      display: none;
    }
    // height: calc(100vh - 275px);
    height: calc(100vh - 260px);
  }
}