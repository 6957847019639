@import '../../../../shared.scss';

.container{
  width:420px;
  min-height:310px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing:border-box;
  padding:15px 30px;
  text-align: center;

  .title{
    height:70px;
    width: 100%;
    // border:1px solid chartreuse;
  }


  h1{
    font-size: 35px;
    margin: 0;
  }

  h6{
    font-size: 19px;
    color:#858585;
    margin: 0;
    font-weight: 300;
  }

  .emails{
    box-sizing:border-box;
    padding:15px 0 0 0;
    text-align: left;
    min-height: 155px;
    height: fit-content;
    width: 100%;
    margin-bottom: 25px;
    // border:1px solid chartreuse;

    label{
      font-weight: 300;
      display: block;
      color:#333333;
      margin: 8px 0;
    }

    input[type="email"]{
      width: 96%;
      height:40px;
      font-size: 17px;
      line-height: 40px;
      border-radius: 5px;
      border:1px solid #cacaca;
      padding-left:15px;
      margin-bottom: 10px;
      font-weight:300;
    }

    p{
      font-size: 16px;
      color:#858585;
      cursor: pointer;
      line-height: 20px;
      position: relative;
      margin:3px 0;
      font-weight: 300;
      
      span{
        font-size: 25px;
        line-height: 20px;
        position: relative;
        top:1px;
        font-weight: 300;

      }
    }
  }

  .btns{
    height: 35px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    // border:1px solid chartreuse;

    .btn:first-of-type{
      @include mainButton($main-blue--bright, white, 14px, 40px, 170px)
    }
    .btn:last-of-type{
      @include solidButton(white, $main-blue--bright, 14px, 40px, 170px)
    }
  }
  
}