@import '../../../shared.scss';

.container{
  box-sizing:border-box;
  padding:100px 0 0 0;
  height: 100vh;
  width: 100%;
  background-color: grey;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: center;

  .inner{
    height: calc(100vh - 90px);
    width: calc(100vw - 10px);
    background-color: white;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    bottom: 50px;


    .close {
      cursor: pointer;
      opacity: 1;
      position: absolute;
      background-image: url('../../../assets/icon-close.png');
      height: 35px;
      width: 35px;
      right: 10px;
      top: 10px;
      z-index: 1500;
      transition: .3s all ease-out;
      pointer-events: initial;
    }

    .sectionsDiv{
      border-top:1px solid #cacaca;
      height: calc(100% - 100px);
      overflow: auto;
    }
  }


  .activityBar{
    width: calc(100% - 10px);
    height: 95px;
    display: flex;
    justify-content: space-between;
    box-sizing:border-box;
    padding: 0  15px 0 0;
    overflow: hidden;
    position: relative;

    // border:1px solid red;

    .iconDiv{
      width: 80px;
      height:100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon{
        width: 42px;
        height: 60px;

        &.one{
          background: url('../../../assets/journal_blue.svg') no-repeat center;
        }
        &.two{
          background: url('../../../assets/journal_orange.svg') no-repeat center;
        }
        &.three{
          background: url('../../../assets/journal_pink.svg') no-repeat center;
        }
        &.four{
          background: url('../../../assets/journal_green.svg') no-repeat center;
        }

        &.grey{
          background: url('../../../assets/journal_grey.svg') no-repeat center !important;
        }
      }
    }

    .text{
      width: calc(100% - 85px);
      height: 100%;
    }

    .topRow{
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: calc(100% - 35px);
      z-index: 20;


    .nameDiv{
      width: calc(100% - 50px);
      height: 30px;
      position: relative;
      cursor: pointer;
      pointer-events: all !important;
      z-index: 25 !important;
      padding: 0 30px 0 15px;
      display: flex;
      align-items: center;
      left: -10px;

      h5{
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
        // letter-spacing:1.02px;
        color: #333333;
        // width: ;
        position: relative;
        top: 3px;
        left: -5px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-align: left;
        z-index:  20 !important;
      }

    }

    }

    .bottomRow{
      height: 35px;
      width: calc(100% - 2px);
      display: flex;
      justify-content: space-between;
      z-index: 15 !important;

      .left{
        display: flex;
        align-items: center;

        p{
          font-size: 13px;
          color: #858585;
          font-weight: 300;
        }
      }
    }
  }
}