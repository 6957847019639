@import '../../../../shared.scss';

.container{
  position: relative;

  *{
    font-family: 'Sofia pro';
  }

  h6{
    font-size: 16px;
    margin: 5px 0;
    font-weight: 300;
    color:#858585;
  }
  
  h4{
    font-size: 19px;
    margin: 5px 0;
    color: #333333;
  }
  p{
    font-size: 12px;
    margin: 5px 0;
    color:#858585;
  }
  
  .top{
    height:110px;
    width:100%;
    border-bottom:1px solid #cacaca;
    box-sizing:border-box;
    padding:25px 30px;
    position: relative;

    .subRow{
      display: flex;
      height: 30px;
      align-items: center;
      position: relative;

      h6{
        white-space: nowrap;
      }

     
      
          .couponDiv{
            // position: absolute;
            // top:18px;
            // left:305px;
            position: relative;
            top:1px;
            height:30px;
            width:150px;
            display: flex;
            align-items: center;
            margin-left: 10px;
            // border:1px solid chartreuse;
      
            p{
              color:$main-blue--bright;
              font-size: 11px;
              font-weight: 700 !important;
              margin:0;
              text-decoration: underline;
              cursor: pointer;
              transition: all .2s ease-in-out;

              &.conf{
                pointer-events: none;
                text-decoration: initial;
              }
      
              &:hover{
                color: $main-blue;
              }

              &.error{
                color:red;
                position: absolute;
                left:0;
                top:30px;
                font-size: 11px;
                font-weight: 300 !important;
                text-decoration: initial;
              }
            }
      
            input[type='text']{
              border:1px solid #cacaca;
              min-height:22px;
              line-height: 22px;
              width:100px;
              box-sizing: border-box;
              padding-left: 8px;
              font-weight: 300;

              &::placeholder{
                font-size: 11px;
                font-weight: 300;
              }
            }
      
            .btn{
              @include solidButton(white, $main-blue--bright, 12px, 25px, 70px);
              border-radius: 2px;
              line-height: 22px;
              box-sizing:border-box;
              padding-left: 10px;
              padding-right: 10px;
              margin-left: 8px;
              min-width: 70px;
              position: relative;

              &.revert{
                background-color: $main-blue--bright;
                pointer-events: none;
              }

              img{
                height:25px;
                width: 25px;
                object-fit: contain;
                position: relative;
                bottom: 1px;
              }
            }
      
          }
    }
  }

  .details{
    padding:15px 30px;
    height: 130px;
    position: relative;
    p{
      margin-bottom: 13px;;
    }

  }

  .bottom{
    padding:5px 30px;

    h6{
      font-size: 15px;
    }
  }

  .errorMsg{
    margin:7px 0 0 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color:red;
    font-weight: 300;
    font-size: 14px;
  }

  .loadingOverlay{
    position: absolute;
    height:320px;
    width:100%;
    top:0;
    left:0;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
    visibility: hidden;

    &.show{
      visibility: visible;
    }
    p{
      margin: 0;
      position: relative;
      top:-10px;
    }
  }
    .confMsg{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-radius: 15px;
      height:320px;
      width:100%;
      position: absolute;
      top:0;
      left:0;
      background-color: #F0F4F6;
      visibility: hidden;
      
        &.show{
      visibility: visible;
    }
  }
}