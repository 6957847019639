@import '../../../shared.scss';

*{
  font-family: "DM Sans", sans-serif;
}

.containerx{
  width: 100%;
  height: 100%;
  padding: 2rem 0;

  h1{
    margin: 10px 0 0;
    margin-left: 100px;
    line-height: 70px;
    font-size: 26px;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    @media screen and (max-width: 800px) {
      margin-left: 1rem;
      padding-top: 1rem;
    }
  }
  a{
    text-align: left;
    display: flex;
    align-items: flex-start;
    justify-content: left;
    width: 100%;
  }
  .container{
    margin: 1rem auto;  
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    -webkit-box-shadow: 5px 5px 1px 2px rgba(0,0,0,0.1); 
    box-shadow: 5px 5px 1px 2px rgba(0,0,0,0.1);
    border-radius: 15px;
    padding: 1rem 2rem;
    width: fit-content;
    text-align: left;
    background: #fff;

  }
  .container_dark{
      margin: 0 auto;  
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 3rem;
      -webkit-box-shadow: 5px 5px 1px 2px rgba(0,0,0,0.1); 
      box-shadow: 5px 5px 1px 2px rgba(0,0,0,0.1);
      border-radius: 15px;
      padding: 1rem 2rem;
      width: fit-content;
      text-align: left;
      background: #2B4E7B;
    
  }

  .cards{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 32px;
    margin: 1rem 0;
    text-align: left;
  }

  .trialBtn{
    background: $escribgreen;
    color: #fff;
    padding: 0.8rem 1.8rem;
    border-radius: 5px;
    text-transform: capitalize;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover{
      border: 2px solid $escribgreen ;
      color: $escribgreen;
      background: none;
    }
 
  }


}
