@import '../../../../../../shared.scss';

.container{
  height: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing:border-box;
  padding:0 0 10px 0;

  .dates{
    height: 70px;
    width: 100%;
    border-top:1px solid #cacaca;
    display: flex;
    position: relative;
    margin-top: -15px;

    label{
      width: 50%;
      height: 70px;

      .dateDiv{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        box-sizing:border-box;
        padding:0 15px;
        position: relative;

        &.blocked{

          p, h5{
            opacity: .3;
          }
        }

        *{
          position: relative;
          z-index: 20;
        }

        .textDiv{
          display: flex;
          align-items: center;

          p.sml{
            color: $E_blue;
            margin-left: 8px;
          }


          .padlock{
            height: 15px;
            width: 15px;
            background: url('../../../../../../assets/padlock_grey.svg') no-repeat center;
            background-size: contain;
            cursor: pointer;
            transition: .3s all ease-out;
            margin-left: 5px;
            position: relative;
            bottom: 1px;
          }
        }


        p{
          margin:0;
          color: #858585;
          font-size: 12px;
          font-weight: 300;
        }
        h5{
          margin: 2px 0;
          font-weight: 500;
          color: #333333;
          font-size: 16px;
        }

        .selectedBlock{
          height: 60px;
          width: calc(100% - 15px);
          border-radius: 5px;
          position: absolute;
          left:50%;
          top:50%;
          transform: translate(-50%, -50%);
          background-color: $E_blue_light;
          z-index: 1 !important;
          opacity: 0;
          transition: .15s all ease-out;
        }


      }


      &:first-of-type{
        .dateDiv{
          border-right: 1px solid #cacaca;
        }
      }
    }

    input:checked + .dateDiv{

      p{
        color: $E_blue_dark;
      }

      h5{
        color: $E_blue;
      }

      .selectedBlock{
        opacity: 1;
      }
    }

  }

  .btn{
    width: calc(100% - 20px);
    margin: 0 auto;
    height: 45px;
    line-height: 45px;
    border-radius: 8px;
    background-color: $E_blue;
    color: white;
    text-align: center;
    font-size: 16px;
  }
}