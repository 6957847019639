@import '../../../../shared.scss';

.container{
  width: 100%;
  height:100%;
  box-sizing:border-box;
  padding:5px 20px;
  display: flex;
  justify-content: space-between;

  .row{
    height:50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border-bottom:1px solid #c4c4c4;
    text-align: left;
    box-sizing:border-box;
    padding: 0 25px;

    &:first-of-type{
      background-color: #f9f9f9;
    }
  }
  
  .left{
    width:65%;
    margin-right: 20px;
    height:100%;
    text-align: left;
    
    .editStudent{
      border:1px solid #cacaca;
      height:275px;
      width: 100%;
      overflow: visible;
      border-radius: 15px;
      box-sizing: border-box;
      padding:5px 25px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      -webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
      -moz-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
      box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);

      .inputs{
        height:150px;
        width: calc(100% - 50px);
        border-radius: 15px;
        padding:15px 25px;
        background-color: #f9f9f9;
        border:1px solid #cacaca;
        display: flex;
        align-items: center;

        form{
          height:120px;
          display: flex;
          flex-wrap: wrap;

          label{
            display: inline-block; 
            width:50%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            
            &:nth-of-type(3){
              width: 70%;
              margin-right: 3%;
              input{
                width:100%;
              }
            }
            &:nth-of-type(4){
              width: 25% !important;
              position: relative;
              overflow:visible;

              input[type='radio']{display: none;}

             .gradeDropdown{
              position: absolute;
              top:52px;
              left:3px;
              width:90px;
              height: fit-content;
              text-align: center;
              background-color: white;
              display: flex;
              flex-direction: column;
              border:1px solid #cacaca;
              border-radius: 5px;
              z-index: 100;
              }
              label{
                width:100%;
              }
              label>input+.grade{
                width: 90px;
                height: 22px;
                line-height: 22px;
                color: #858585;
                cursor: pointer;
                transition: .3s all ease-out;

                &:hover{
                  color: white;
                  background-color: $main-blue--bright;
                }
              }
              label>input:checked+.grade{
                pointer-events: none;
                background-color: #f2f2f2;
              }

              input{
                cursor: pointer;
                width:60px;
                background: url('../../../../assets/icon-chevron--down--black.png') no-repeat  90% center;
                background-color: white;
                background-size: 15px;
                margin:3px 0;
              }
            }
          }
          };
          input{display: block; color:#858585};
          input[type='text']{
            margin: 3px 0 3px 0;
            height:30px; 
            width:96%;
            box-sizing:border-box;
            padding:0 0 0 15px;
            line-height: 30px;
            border:1px solid #cacaca;
            border-radius: 5px;

        }

      }

      .editBtns{
        display: flex;
        width: 100%;
        justify-content: flex-end;
        position: relative;

        .editBtn{
          height:30px;
          box-sizing:border-box;
          padding:0 25px;
          border-radius: 10px;
          line-height:30px;
          text-align: center;
          font-size: 14px;
          cursor: pointer;
          transition: all .3s ease-out;

          &:first-of-type{
            color:#858585;
            border:1px solid #858585;
            background-color: white;
            margin-right: 8px;

            &:hover{
              color:white;
              background-color: #858585;
            }
          }

          &:last-of-type{
            color:white;
            border:1px solid $main-blue--bright;
            background-color: $main-blue--bright;

            &:hover{
              color:$main-blue--bright;
              background-color: white;
            }
          }
        }

        .confMsg{
          position: absolute;
          left:10px;
          top:8px;
          color:$main-blue--light;
          font-weight: 500;
        }
        .errorMsg{
          position: absolute;
          left:10px;
          top:8px;
          color:red;
          font-weight: 500;
        }
        .loadingMsg{
          position: absolute;
          left:15px;
          top:-2px;
          color:#858585;
          font-weight: 300;
          height:35px;
          width: 35px;
          object-fit: contain;
        }
      }
    }

    .guardians,
    .login{
      overflow: hidden;
      margin-top: 15px;
      height: fit-content;
      height: -moz-max-content;
      // max-height: 250;
      // min-height:150px;
      width:100%;
      border-radius: 15px;
      background-color: white;
      // box-sizing:border-box;
      // padding:10px 20px;
      -webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
      -moz-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
      box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);

      .row{
        position: relative;

        h1{width:100%}
        p{
          box-sizing:border-box;
          padding:0 0 0 50px;
          background: url('../../../../assets/icon-mail--single.svg') no-repeat 15px center;
          font-weight: 500;

          &.noIcon, &.link{
            background: none;
            padding: 0;
            max-width: calc(100% - 30px);
            margin-left: 12px;
          }
          
          &.link{
            max-width: calc(100% - 180px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: $main-blue--bright;
          }
        }

        &.high{
          height: fit-content;
          max-height: 250px;
          display: initial;
          box-sizing:border-box;
          padding:10px;
          border: none;

          p{
          }
        }

        img{
          position: absolute;
          right:15px;
          top:15px;
          height: 17px;
          object-fit: contain;
          cursor: pointer;
          transition: all .3s ease-out;
          transform-origin: center;

          &:hover{
            height: 20px;
            right:14px;
            top:14px;
          }
        }


        .noMail{
          background: url('../../../../assets/icon-mail--add.svg') no-repeat 15px center;
          background-color: #f2f2f2;
          height:30px;
          line-height: 30px;
          text-align: center;
          width: 220px;
          box-sizing:border-box;
          padding:0 10px 0 35px;
          border-radius: 10px;
          border: 1px solid transparent;
          cursor: pointer;
      
          h5{
            font-size: 14px;
            color:#858585;
            font-weight: 500;
          }
      
          &:hover{
            border: 1px solid #cacaca;
          }
        }

        .linkDiv{
          display: flex;
          margin-top: 10px;
          span.conf{
            p{
              margin: 0;
              font-size: 14px;
              font-weight: 500;
              color: $main-blue--bright;
            }
          }
      
          .copy{
            @include borderButton($main-blue--bright,white, 14px);
            box-sizing:border-box;
            padding:0 15px;
            text-align: center;
            height:30px;
            line-height: 30px;
            width: fit-content;
            min-width:120px;
            position: relative;
            bottom:5px;
            margin-left: 30px;
            transition: .2s all ease-out;

            &:hover{
              background-color: $main-blue--bright;
              color: white;
            }
          }
        }
      }

    }
  }

  .studentClasses{
    // width:calc(100% - 140px);
    width:35%;
    min-width: 150px;
    height:100%;
    border:1px solid #cacaca;
    border-radius: 15px;
    -webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
    -moz-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
    box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
    box-sizing:border-box;
    // padding:80px 0 0 0;
    position: relative;
    overflow-y: scroll;

    &::-webkit-scrollbar-track-piece {background-color: transparent};

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 5px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: #d2d2d2;
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }
    &::-webkit-scrollbar-track-piece:end {
      margin-bottom: 10px; 
  }
  
  &::-webkit-scrollbar-track-piece:start {
      margin-top: 80px;
  }

    .row{
      height: 60px;

      h3, h4{margin:0; line-height:17px}
      &:first-of-type{
        height:80px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        position: sticky;
        top: 0%;
      }
    }
  }
}