@import '../../../../../shared.scss';

.container{
    height: calc(100% - 20px);
    overflow-y: auto;
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 2px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: white;
        border:1px solid #cacaca;
      }

  .header_info{
    p{
        color: #064149;
    }
  }
  .editBtn{
    background: #077CA0;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    width: fit-content;
    cursor: pointer;
    transition: all 0.3 ease;
    &:hover{
        opacity: 0.8;
    }
  }
  .showMethodBtn{
    background: #F9F9F9;
    color: #064149;
    display: flex;
    align-items: center;
    gap: 1rem;
    width: fit-content;
    padding: 15px 25px;
    border-radius: 5px;
    margin-top: 2rem;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover{
        border: 2px solid #111;
    }
  }

  .institution{
    margin: 2rem 0;
    h4{
        color: #064149;
        font-weight: 700;
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }
  }

  .tablecontainer{
    margin: 1rem 0; 
    padding: 1rem;
    border-radius: 5px;
    border: 1px solid #D2D2D2;
    h4{
      color: #333;
      font-weight: bold;
      font-size: 1.1rem;
      margin-bottom: 1rem;
    }
  }
  
  .table{
    width: 100%;
    .number{
      position: relative;
    }
    .details{
        background-color: #F2F2F2;
        color: #858585;
        height: 35px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        border-radius: 8px;
    }
    .tableHeader{
      height: 35px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      border-radius: 8px;
      background-color: $faith-blue;
      color: #fff;

      .column{
        h5{
          color: #fff;
          margin:0;
          text-transform: uppercase;
          font-size: 12px;
        }
      }
    }

    .sophia{
      background: #1E9AAA;
    }


    .tableRow{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 2rem;
      

      input{
        border: none;
        font-size: 16px;
        text-align: center;
        color: #1E9AAA;
        width: 80px;
        background: none;
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
             -webkit-appearance: none;
              margin: 0;
      }

      input[type=number] {
          -moz-appearance: textfield;
      }
      img{
        width: 60px;
      }
      .sophiahead{
        color: #1E9AAA;
        font-weight: 600;
        text-transform: capitalize !important;
        margin-right: 20px;

      }

      p{
        color: #858585;
        font-size: 16px;
        font-weight: 300;
        margin:15px 0;

        &.price{
          font-size: 17px;
          color: $E_blue;
          font-weight: 700;

          &.light{
            font-weight: 500;
            font-size: 15px;
          }
        }
      }
    }


    &.current{
      .details,
      .tableHeader,
      .tableRow{
        .column{
          width: 15.5%;
          display: flex;
          justify-content:center;
          
          &:first-of-type{
            justify-content:flex-start;
            box-sizing:border-box;
            padding:0 0 0 20px;
            width: 30%;
          }
         
        }
        .input{
          border: 1px solid #111;
          border-radius: 5px;
          height: 40px;
          width: 100px;
          margin: 0 auto;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
        }
        .input_work{
          height: 40px;
          width: 100px;
          margin: 0 auto;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    &.history{
      .placeholder{
        width: 100%;
        text-align: center;
        line-height: 45px;
        margin:5px 0 0 0;
        color: #cacaca;
        font-weight: 300;
      }
      .tableHeader{
        background-color: #e6e6e6;
        color: #858585  !important;
      }

      .tableHeader,
      .tableRow{
        .column{
          width: 15%;
          display: flex;
          justify-content:center;
          h5{
            color: #858585;
          }

          
          &:first-of-type{
            justify-content:flex-start;
            box-sizing:border-box;
            padding:0 0 0 20px;
            width: 55%;
          }
        }
      }
      .tableRow{
        border-bottom: 1px solid #f2f2f2;
      }
    }

    .statusPill{
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      font-size: 12px !important;
      font-weight: 500 !important;
      margin:0;
      padding:5px 10px;
      border-radius: 5px;
      text-transform: uppercase;

      &.pending{
        color: #FF8258;
        background-color: #FFE6DE;
      }

      &.paid{
        color: #97D318;
        background-color: #EAF6D1;
      }

      &.error{
        color: #FB3333;
        background-color: #FED6D6;
      }
    }
  }


}