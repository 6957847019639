

  .additionalButtons{
    display: flex;
    width:220px;
    height: 35px;
    // margin-top: 15px;
    align-items: center;
    justify-content: space-evenly;
    position: absolute;
    bottom: 25px;
    left: 45%;
    transform: translateX(-50%);

    &.hidden{
      display: none !important;
    }

    .icon{
      height:25px;
      width:25px;
      margin-right: 5px;
      position: relative;
      cursor: pointer;
      border:5px solid transparent;

      &:last-child{
        margin-right: 0;
      }

      &.blocked{

        img{
          opacity: .5;
        }
      }



      .tooltip{
        // visibility: hidden;
        position: absolute;
        top:35px;
        left:-60px;
        height:30px;
        min-width: 190px;
        box-sizing:border-box;
        padding:5px 8px;
        border-radius: 5px;
        color: #858585;
        background-color: white;
        -webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
        -moz-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
        box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
        font-size: 12px;
        text-align: center;
        // line-height: 20px;
        z-index: 1000;
        pointer-events: none;
        bottom: 0%;
        left: 0;
        left:-60px;
        transition: .2s all ease-out;
        opacity: 0;
        transform: translateY(-15px);
      }


      
      &:hover{
        background-color:  #E9E9E9 !important;
        border:5px solid #E9E9E9;
        border-radius: 5px;

        .tooltip{
          bottom: 55px;
          transform: translateY(-75px);
          opacity: 1;
        }

      }

      &:first-of-type{
        .tooltip{
          left:-10px !important;
          min-width:135px !important;
          &::after{
            left:-50px;
          }
        }
      }

      &:nth-of-type(3){
        .tooltip{
          left:-55px !important;
          min-width:135px !important;
          &::after{
            left:-50px;
          }
        }
      }
      &:last-of-type{
        .tooltip{
          left:-140px;

          &.wide{
            width:300px
          }
          &::after{
            left:30px;
          }
        }
      }
    
        &:hover ~.topDeetz{
          background-color: #f2f2f2;
        }
        // &:hover >.tooltip{
        //   transition: .15s all ease-out;
        //   visibility: visible;
        //   bottom:50px !important;
        //   border:1px solid chartreuse;
        // }
      
    }
    .icon:nth-of-type(2){
      background: url('../../../../assets/icon-duplicate--grey.svg') no-repeat center;
      background-size: calc(100% - 6px);
    }

    .icon:nth-of-type(3){
      background: url('../../../../assets/icon-downloadPdf--grey.svg') no-repeat center;
      background-size: contain;  

    }
  
    .icon:nth-of-type(4){
      display: flex;
      align-items: center;
      justify-content: center;
      // background: url('../../../../assets/icon-shareResource--grey.svg') no-repeat center;
      // background-size: 95%;    
    }

  }