@import '../../../../../../shared.scss';

.container{

  width: 100%;
  box-sizing:border-box;
  padding:20px;
  // border:1px solid purple;
  overflow: scroll;
  height: 100%;

  .placeholderDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 60px 40px 0;
    width: calc(100% - 80px);
    text-align: center; 

    h2{
      font-weight: 500;
    }
    
    p{
      font-size: 17px;
      color: #858585;
      font-style: italic;
      width: 90%;
      margin-top: 20px;
    }
  }
  
  .gradePicker{
    margin-bottom: 10px;

    .titleBar{
      display: flex;
      align-items: center;
      width: 100%;
      min-height: 40px;
      border-radius: 10px;
      position: relative;
      box-sizing:border-box;
      padding:0 10px;
      background-color: #F2F2F2;
      cursor: pointer;
      transition: .15s all ease-out;

      *{
        pointer-events: none;
      }

      .scoreBlock{
        height: 23px;
        width: 23px;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        // border:1px solid cyan;
        margin-right: 10px;
        background-color: white;
        
        h4{
          font-size: 14px;
          color: #858585;
        }
      }

      h5{
        width: calc(100% - 70px);
        // border:1px solid cyan;
        margin:10px 0;
        font-weight: 500;
      }

      .chevron{
        height: 20px;
        width: 20px;
        background: url('../../../../../../assets/chevron_black.svg') no-repeat center;
        position: absolute;
        right: 13px;
        transform: translateY(-50%);
        top: 50%;
        transition: .15s all ease-out;
      }

      &.openDiv{
        h5{
          color: white;
        }
        .chevron{
          background: url('../../../../../../assets/chevron_solid_white.svg') no-repeat center;
          transform:translateY(-50%) rotate(180deg);
        }
      }
    }

    .content{
      width: 100%;
      max-height: 0;
      overflow: hidden;
      transition: .25s all ease-out;
      box-sizing:border-box;
      padding:0px 10px;
      
      &.openContent{
        // transition: .4s all ease-out;
        padding:20px 10px 0px 10px;
        max-height: 650px;
        overflow-y: scroll;
        margin-top: 5px;

        &::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 6px;
          height: 6px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: #f2f2f2;
          -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
        }
      }
    }

    input[type=radio]{
      display: none;
    }

    .criteriaRow{
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      cursor: pointer;
      transition: .2s all ease-out;
      padding:10px;
      border-radius: 8px;

      .checkbox{
        height: 10px;
        width: 10px;
        border:2px solid #D2D2D2;
        border-radius: 50%;
        margin-right: 8px;
        cursor: pointer;
        // position: absolute;
      }

      .numberDiv{
        height: 23px;
        min-width: 23px;
        max-width: 23px;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        background-color: white;
        
        h4{
          font-size: 14px;
          color: #858585;
        }
      }

      .text{
        p{
          margin:1px 0;
          font-size: 12px;
          line-height: 17px;
          font-weight: 300;
          color:#858585;
          // border:1px solid cyan;
          // width: calc(100% - 100px);
        }
      }

      &:hover{
        background-color: #fafafa;
      }
    }
    
    input:checked + .criteriaRow .checkbox{
      border-color: $main-blue--bright;
      position: relative;

      &:after{
        content: "";
        min-height: 6px;
        max-height: 6px;
        min-width: 6px;
        max-width: 6px;
        border-radius: 50%;
        background-color: $main-blue--bright;
        position: absolute;
        bottom:2px;
        left: 2px;
      }
    }
  }

  .grey{
    background-color: #aaaaaa !important;
    color: #858585 !important;
    >p, >h4{
      color: white !important;
    }
  }

  .green{
    background-color: #4D7321 !important;
    color: #4D7321 !important;
    >p, >h4{
      color: white !important;
    }
  }

  .greenLight{
    background-color: #97D318 !important;
    color: #97D318 !important;
    >p, >h4{
      color: white !important;
    }
  }

  .orange{
    background-color: #FF8258 !important;
    color: #FF8258 !important;
    >p, >h4{
      color: white !important;
    }
  }

  .red{
    background-color: #D00101 !important;
    color: #D00101 !important;
    >p, >h4{
      color: white !important;
    }
  }


  .greyNeg{
    background-color:transparent !important;
    color: #858585 !important;
  }

  .greenNeg{
    background-color:transparent !important;
    color: #4D7321 !important;
  }

  .greenLightNeg{
    background-color:transparent !important;
    color: #97D318 !important;
  }

  .orangeNeg{
    background-color:transparent !important;
    color: #FF8258 !important;
  }

  .redNeg{
    background-color:transparent !important;
    color: #D00101 !important;
  }

  h4.red,
  h4.orange,
  h4.greenLight,
  h4.green,
  h4.grey,
  h4.redNeg,
  h4.orangeNeg,
  h4.greenLightNeg,
  h4.greenNeg,
  h4.greyNeg {
    background-color: transparent !important;
  }
}