@import '../../../shared.scss';

.container{
  width: 320px;
  border-radius: 15px;
  background-color: white;
  -webkit-box-shadow: 5px 5px 1px 2px rgba(20,20,20,0.1); 
  box-shadow: 5px 5px 1px 2px rgba(20,20,20,0.1);
  box-sizing:border-box;
  padding: 30px;
  margin: 0;
  height: fit-content;
  display: initial;
  display: flex;
  flex-flow: column nowrap;
  text-align: left;

  &.blue{
    background-color: #97D318;

    h4{
      color: #fff;
    }

    p.listItem{
      background: url('../../../assets//Icons/check-light-blue.svg') no-repeat center left;
    }
  }

  h3 {
    font-size: 44px;
    color: $faith-blue;
  }

  h4{
    font-size: 28px;
    margin: 0;
    color: $faith-blue;
  }
  h6{
    font-size: 20px;
    margin: 20px 10px 10px;
    font-weight: 700;
    color: $faith-blue;
  }

  .priceDiv{
    margin: 10px 0px;
    display: flex;
    align-items: flex-end;
    box-sizing:border-box;

    h5{
      margin-left: 15px;
    }

    span{
      position: relative;
      top: 6px;
      color: #82A0A4;
      font-weight: 700;
    }

    *{
      margin: 0;
    }
  }

  .benefitsList{
    margin: 0;
  }

  p.listItem{
    margin: 10px 0;
    position: relative;
    line-height: 25px;
    font-size: 14px;
    padding-left: 30px;
    background: url('../../../assets/check-orange.svg') no-repeat center left;
  }

  .pinkBtn{
    padding: 12px 20px;
    border-radius: 8px;
    background-color: #077CA0;
    cursor: pointer;
    color: white;
    width: fit-content;
  }
}