@import '../../../../../../shared.scss';

.container{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 420px;
  width: 100%;
  box-sizing:border-box;
  padding:30px 40px;

  .titleBar{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .titleBar{
    img{
      margin-right: 10px;
      width: 35px;
    }
  }


  .textDiv{
    margin-top: 10px;

    p{
      font-size: 16px;
      color: #858585;
      font-weight: 300;
      width: 300px;
      // margin-bottom: 10px;
    }

  }

  .sliderDiv{
    height: 150px;
    display: flex;
    align-items: center;
    box-sizing:border-box;
    padding:0 30px 0 0;

    p{
      color: #858585;
      font-size: 15px;
      opacity: 0;
      transition: .2s all ease-out;

      &.show{
        opacity: 1;
      }
    }

    .slider{
      height: 35px;
      width: 65px;
      border-radius: 20px;
      background-color: #d2d2d2;
      margin: 0 10px;
      position: relative;

      .sliderInner{
        height: 31px;
        width: 31px;
        border-radius: 50%;
        background-color: white;
        position: absolute;
        left: 2px;
        top:2px;
        transition: .2s all ease-out;
        cursor: pointer;

        &.active{
          left:32px;
        }
      }

      &.active{
        background-color: $main-green;
      }
      
    }
  }

  .howItWorks{
    text-align: left;
    height: 160px;

    h5{
      color: #4e4e4e;
      margin-bottom: 5px;
    }
    p{
      font-size: 16px;
      color: #858585;
      font-weight: 300;
      padding-right: 150px;
    }
  }


  .submitBtn{
    background-color: $main-blue--light;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0 15px;
    border-radius: 8px;
    margin-top: 10px;
    transition: .2s all ease-out;
    cursor: pointer;
    pointer-events: none;
    opacity: .5;

    &:hover{
      background-color: $main-blue--dark;
    }

    p{
      color: white;
    }

    &.enabled{
      opacity: 1;
      pointer-events: all;
    }

  }

  &.mobile{
    padding: 15px;

    .textDiv{
      p{
        text-align: center;
        font-size: 15px;
        line-height: 20px;
        width: 100%;
      }
    }

    .sliderDiv{
      height: 50px;
    }


    .howItWorks{
      *{
        padding: 0;
        text-align: center;
        font-size: 14px;
        line-height: 20px;
      }
    }

  }
}