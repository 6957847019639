@import '../../../../../../shared.scss';

.container{
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 300px;
  width: 100%;
  box-sizing:border-box;
  padding:30px 20px;

  .titleBar,
  .inputDiv{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .titleBar{
    position: relative;
    margin-bottom: 20px;
    img{
      margin-right: 10px;
      position: relative;
      bottom: 2px;
    }

    p{
      position: absolute;
      font-size: 12px;
      width: 400px;
      color: #858585;
      font-weight: 300;
      bottom: -40px;
    }
  }

  .inputDiv{
    width: 350px;
    height: 44px;
    border:1px solid #cacaca;
    box-sizing:border-box;
    padding:0 1px;
    border-radius: 5px;
    margin-top: 35px;

    input{
      width: calc(100% - 44px);
      border:none;
      padding: 0 10px;

      &::placeholder{
        color: #D2D2D2;
        font-size: 18px;
      }
    }
    .plusBtn{
      background: url('../../../../../../assets/Icons/plus_square_blue.svg') no-repeat center;
      height: 40px;
      width: 40px;
    }
  }

  .wordsDiv{
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;

    .placeholder{
      color: #cacaca;
    }

    .wordBankItem{
      display: flex;
      align-items: center;
      height: 28px;
      border-radius: 5px;
      padding:0 15px;
      background-color: $main-green;
      margin:3px;

      p{
        font-size: 14px;
        color: white;
        margin: 0;
      }

      .deleteWordBtn{
        background: url('../../../../../../assets/icon-cross--white.png');
        height: 10px;
        width: 10px;
        cursor: pointer;
        margin-left: 10px;
      }

    }
  }

  &.mobile{
    .inputDiv{
      width: 100%;
      margin-top: 20px;
    }

    .wordsDiv{
      margin-bottom: 20px;

      p.placeholder{
        font-size: 14px;
      }
    }
  }

}