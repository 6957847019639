@import '../../../../shared.scss';

.container{
  width:780px;
  height:350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing:border-box;
  padding:0 20px 15px 20px;
  position: relative;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;

  *{
    font-family: 'Sofia pro';
  }

  h2{
    box-sizing:border-box;
    padding-left:35px;
    background-size: 25px;
    height:50px;
    line-height: 55px;
  }

  .loaderDiv{
    z-index: 100;
    visibility: hidden;
    display:flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: white;

    h3{
      margin-top:0px;
    }

    &.show{
      visibility: visible !important;
    }
  }

  p{
    font-size: 15px;
    line-height: 18px;
    color:#858585;
    margin-bottom: 10px;
    font-weight: 300;
    width:88%;
  }

  .classBlocks{
    height:100px;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    box-sizing:border-box;
    padding:7px 5px;
    margin-bottom: 20px;

    img{
      position: relative;
      right:-35px;
      height:50px;
      width:50px;
    }

    label > input[type=radio]{
      display: none;
    }

    label > input + .classBlock{
      cursor: pointer;
      margin:4px 1px;
      width:225px;
      height:34px;
      border-radius: 5px;
      border:1px solid #cacaca;
      line-height: 37px;
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      text-align: center;

      p{
        font-size: 14px;
        line-height: 34px;
        box-sizing:border-box;
        // padding:0 0 0 15px;
        margin: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: #333333;
        text-align: left;
        box-sizing:border-box;
        padding:0 20px 1px 20px;

      }
    }
    label > input:checked + .classBlock{
      border:3px solid #97D318;
      -webkit-box-shadow: 0px 1px 4px 0px #00000025; 
      box-shadow: 0px 1px 4px 0px #00000056;
      width:220px;
      height:36px;
      position: relative;
      margin:1px 1px;
      
      p{
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 5px 17px;
      }

      &:after{
        content: '';
        background: url('../../../../assets/teacher/icon-circleTick--small.svg');
        font-size: 15px;
        position: absolute;
        height: 15px;
        width: 15px;
        top: -10px;
        right: -10px;
        color: chartreuse;
      }
    }

    .newJournal {
      color: $main-blue--dark;
      background-color: #FFA826;
    }
  }

  .classBlocks::-webkit-scrollbar {
    width: 6px;
  }
   
  .classBlocks::-webkit-scrollbar-track {
    background-color: $grey-light;
  }
   
  .classBlocks::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }

  .buttons{
    display: flex;
    margin-top:10px;

    .okBtn,
    .cancelBtn{
      height:40px;
      width:150px;
      border:1px solid;
      border-radius: 10px;
      line-height: 40px;
      font-size: 16px;
      text-align: center;
      margin:0 8px;
      cursor: pointer;
      transition: .4s all ease-out;

    
    }

    .cancelBtn{
      border-color: $main-blue--bright;
      color: $main-blue--bright;
      background-color: white;

      &:hover{
        background-color: $main-blue--bright;
        color:white;
      }
    }
    .okBtn{
      border-color: $main-blue--bright;
      background-color: $main-blue--bright;
      color: white;
      border:1px solid transparent;

      &:hover{
        background-color: white;
        color:$main-blue--bright;
        border:1px solid $main-blue--bright
      }
    }
  }
}