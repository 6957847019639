@import '../../../../../shared.scss';

.container{
  height: 100%;
  width: 100%;
  background: rgb(12,167,216);
  background: linear-gradient(135deg, rgba(12,167,216,1) 12%, rgba(46,102,174,1) 86%);
  display: flex;
  justify-content: center;
  position: relative;

  .text{
    height: 100%;
    // width: fit-content;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 15px;
    position: relative;
    left: 0px;
    box-sizing:border-box;

    h1{
      font-size: 40px;
      line-height: 43px;
      font-weight: 700;
      text-align: left;
      margin: 5px 0;
      color: white !important;
    }
  }
  
  .winnerBlocks {
    display: flex;
    width: fit-content;
    justify-content: center;
    align-items: flex-end;
    box-sizing: border-box;
    padding: 25px 15px;
    height: 100%;
    width: 70%;
    width: calc(100% - 350px);
    max-width: 650px;
    margin-right: 20px;
    // position: relative;
    // left: 30px;

    .box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 130px;
      width: 30%;
      max-width: 180px;
      border-radius: 15px;
      background-color: white;
      position: relative;
      margin: 0 10px;

      @media (max-width:660px){
        transform: scale(.9);
      }

      h3{
        font-size: 14px;
        font-weight: 700;
        color: #4e4e4e;
        position: relative;
        bottom: 15px;
      }
      h3+h3{
        color: #FFA826;
      }
      .boomer{
        height:125px;
        object-fit: cover;
        position: relative;
        top:8px
      }

      &.second {

        &::after {
          content: '';
          height: 45px;
          width: 45px;
          position: absolute;
          right: -10px;
          top: -10px;
          background: url('../../../../../assets/teacher/leaderboard-second.svg')no-repeat center;
        }
      }

      &.winner {
        height: 170px !important;
        width: 33%;
        max-width: 220px;
        box-sizing:border-box;
        padding:20px 0 0 0;
        height: 135px;
        background-color: white;
        box-sizing:border-box;
        padding:5px 0 0 0;


        .confetti{
          position: absolute;
          top: 5px;
          left: 50%;
          transform: translateX(-50%);
          height:130px;
          width: 85%;
          max-width: 150px;
          background: url('../../../../../assets/teacher/confetti.png')no-repeat center 10px;
          background-size: contain;

        }
        .boomer{
          height:130px;
          position: relative;
          top: 5px;
        }

        h3{
          bottom: 10px;
        }

        .after {
          // content: '';
          position: relative;
          height: 45px;
          width: 45px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          right: -10px;
          top: -10px;
          background: url('../../../../../assets/teacher/leaderboard-first.svg')no-repeat center;
        }
        
        &::after {
          content: '';
          height: 45px;
          width: 45px;
          position: absolute;
          right: -10px;
          top: -10px;
          background: url('../../../../../assets/teacher/leaderboard-number1.svg')no-repeat center;
        }
      }

      &.third {


        &::after {
          content: '';
          height: 45px;
          width: 45px;
          position: absolute;
          right: -10px;
          top: -10px;
          background: url('../../../../../assets/teacher/leaderboard-third.svg')no-repeat center;
        }
      }
    }
  }

  @media (max-width:660px){
    width: 100vw;

    .winnerBlocks{
      width: 100%;
    }

    .text{
      display: none;
    }
  }

}