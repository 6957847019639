@import '../../../shared.scss';

.center{
    display: flex;
    justify-content: center;
    margin: 0 auto; 
    position: fixed;
    top: 50%;
    left: 45%;
    transform: translate(-50%, -45%);
    z-index: 50;

    *{
        font-family: "DM Sans", sans-serif;
    }
} 

.container{
    background: #fff;
    -webkit-box-shadow: 5px 5px 1px 2px rgba(0,0,0,0.1); 
    box-shadow: 5px 5px 1px 2px rgba(0,0,0,0.1);
    margin: 0 auto;
    padding: 16px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 2rem;
    flex-direction: column;
    justify-content: center;
    max-width: 600px;
    position: relative;
    h4{
        font-size: 1rem;
        font-weight: 300;
        margin-top: 2rem;
        padding: 0 1rem;
        line-height: 1.5rem;
        span{
            color: #1E9AAA;
        }
    }
    p{
        font-size: 14px;
        font-weight: 400;
        color: #4E4E4E;
    }
    .image_div{
      width: 60px;
      height: 55px;
      margin-right:  -10px ;
    }
    img{
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
    .groupx{
        margin-right: 1rem;
    }
    .times{
        cursor: pointer;
        opacity: 1;
        position: absolute;
        background-image: url('../../../assets/icon-close.png');
        height: 35px;
        width: 35px;
        right: 10px;
        top: 15px;
        z-index: 1500;
        transition: .3s all ease-out;
        pointer-events: initial;
        background-repeat: no-repeat;
    
        &:hover {
          opacity: 0.7;
          transform: scale(1.05);
        }    
    }
    .box{
        display: flex;
        gap: 1.5rem;
        text-align: left;
        position: relative;
    }
    .btns{
        display: flex;
        gap: 1rem;
        align-items: center;
        div{
          padding: 10px 1rem;
          border-radius: 5px;
          cursor: pointer;
          font-size: 12px;
          transition: all 0.3s ease;
          &:hover{
            opacity: 0.9;
            scale: 0.95;
          }
        }
        .first{
          background: #077CA0;
          color: #fff;
        }
        .second{
          background: #fff;
          color: #077CA0;
          border: 1px solid #077CA0;
        }
      }
      .write{
        width: 200px;
      }
}