@import '../../../../shared.scss';

.noStudents {
  height: 100%;
  width: 100%;
  position: relative;
  padding-top: 30px;

  .close {
    cursor: pointer;
    opacity: 1;
    background-image: url('../../../../assets/icon-close.png');
    height: 35px;
    width: calc(100% - 50px);
    z-index: 1500;
    transition: .3s all ease-out;
    pointer-events: initial;
    background-repeat: no-repeat;
    background-position: right center;
    position: relative;
    top: 30px;
    display: none;
  }

  .boxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 30px;
    width: 80%;
    max-width: 1200px;
    margin: 40px auto;

    .box {
      width: 100%;
      // min-width: 300px;
      height: 220px;
      border: 1px solid #cacaca;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      // justify-content: center;
      text-align: left;
      margin-bottom: 10px;
      box-sizing: border-box;
      padding: 25px 20px;
      position: relative;
      cursor: pointer;
      transition: .2s bottom ease;
      bottom: 0;
      overflow: hidden;

      img {
        height: 30px;
        margin-bottom: 15px;
      }

      &:hover {
        -webkit-box-shadow: 0px 1px 9px 0px #00000050;
        box-shadow: 0px 1px 9px 0px #00000050;
        bottom: 2px;

        .colorTab {
          display: initial;
        }
      }

      h2 {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 5px;

        span {
          font-size: 20px;
          font-weight: 700;
          color: #333333;
          margin-left: 0px;
        }
      }

      p {
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        color: #858585;
      }

      &.csv {
        p.hide {
          display: initial;
        }

        p.unavailable {
          color: #FF8258;
          display: none;
        }
      }

    }

    &.synod {
      align-items: center !important;
      justify-content: center;
      height: calc(100% - 100px) !important;
      max-width: 800px;

      .box {
        width: 50%;
        min-height: 250px;
        max-width: 350px;
        margin: 0 10px;
      }
    }
  }

  @media (max-width:660px) {
    box-sizing: border-box;
    padding: 30px 0;

    &.windowed {
      padding: 0px 20px;
    }

    .close {
      display: block !important;
      margin-top: -20px;
    }

    .boxes {
      padding: 5px 20px 35px 20px;
      margin-top: 20px;

      .box {
        flex-direction: row;
        flex-wrap: wrap;
        height: 150px;
        height: fit-content;

        img {
          margin-right: 10px;
        }

        h2 {
          position: relative;
          top: 2px;
        }

        p {
          width: 100%;
        }

        &.csv {
          pointer-events: none;

          p.hide {
            display: none;
          }

          p.unavailable {
            display: initial;
          }

          h2,
          img {
            opacity: .5;
          }
        }
      }
    }
  }
}