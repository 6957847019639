@import '../../../../../../shared.scss';

.container{
  box-sizing:border-box;
  padding:10px 15px;
  height: 70vh;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;

  .sectionTitle{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 50px;

    .dot{
      height: 25px;
      width: 25px;
      border-radius: 50%;
      background-color: #97D318;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;

      p{
        font-size: 13px;
        font-weight: 500;
        margin: 0;
        color: white;
      }
    }

    p{
      color:#4e4e4e;
      font-size: 17px;
      font-weight: 500;
      margin:0;
    }
  }

  .itemsDiv{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    padding-left: 5px;

    .wordBlock{
      display: flex;
      align-items: center;
      height: 27px;
      border-radius: 5px;
      padding:0 10px;
      background-color: #97D318;
      margin: 0 8px 5px 0;

      p{
        font-size: 15px;
        color: white;
        font-weight: 500;
        margin:0;
      }
    }
  }

  .instructions{
    width: 100%;
    height: auto;
    img{
      width: 300px;
    }
    .instructions_div{
      width: 85%;
    }
  }



  .unused{
    background-color: #fb3333 !important;
  }

}