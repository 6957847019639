@import '../../../../shared.scss';

.container{
  position: relative;
  box-sizing:border-box;
  padding:5px 10px 15px 10px;

  h1{
    font-size: 26px;
    font-weight: 500;
  }

  h5{
    font-size: 18px;
    font-weight: 300;
    margin: 15px 0 10px 0;
    color: #333333;
  }

  .textDiv{
    width:100%;
    height: 220px;
    border-radius: 10px;
    background-color: #e9e9e9;
    box-sizing:border-box;
    padding:35px;
    position: relative;
    margin-bottom: 15px;

    &.edit{
      padding:5px 20px 15px 20px;


      textarea{
        box-sizing:border-box;
        padding:5px;
        width: 100%;
        height: 150px;
        background-color: transparent;
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
      }

      .editBottom{
        box-sizing:border-box;
        padding:8px 0 0 0;
        border-top: 1px solid #cacaca;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        p{
          width:100px;
          text-align: center;
          color: $main-blue--light;
          font-size: 14px;
          cursor: pointer;

          &:hover{
            text-decoration: underline;
          }
        }

        .saveBtn{
          @include solidButton(white, $main-blue--light, 14px, 35px, 130px)
        }
      }

    }

    .editBtn{
      position: absolute;
      top:10px;
      right: 10px;
      height: 30px;
      width: 30px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      border:1px solid transparent;
      cursor: pointer;

      img{
        height:17px;
        width: 17px;
        object-fit: contain;
      }


      .tooltip{
        display: none;
        font-size:12px;
        line-height:17px;
        position:absolute;
        top:95%;
        z-index: 100;
        left: 50%;
        transform: translateX(-50%);
        width: 80px;
        height: fit-content;
        height: -moz-max-content;
        padding:10px 20px;
        background-color: #858585;
        border-radius:5px;
        color:white;
        text-align: center;
      }


      &:hover{
        border:1px solid #cacaca;

        .tooltip{
          display: initial;
        }
      }
    }
    
  }
  
  p{
    color: #333333;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    margin: 0;

  }
  p.link{
    color:$main-blue--bright;
    font-size: 18px;
    font-weight: 300;
    margin:5px 0;
    cursor: pointer;

    &:hover{
      text-decoration: underline;
    }
  }


  .codeDiv{
    position: relative;
    top:10px;
    left:-5px;
    width:100px;
    height:30px;
    line-height: 29px;
    text-align: left;
    box-sizing:border-box;
    padding:0 10px;
    background: url('../../../../assets/icon-copy.svg') no-repeat  87% center;
    background-color: #e9e9e9;
    color:#333333;
    font-size: 18px;
    font-weight: 500;
    border-radius: 10px;
    cursor:pointer;

    &:hover{
      .tooltip{
        visibility: visible;
      }
    }

    p{
      line-height: 30px;
      text-align: left;
      margin:0;
      padding-left: 7px;
    }


    .tooltip{
      visibility: hidden;
      position: absolute;
      top:32px;
      left:10px;
      height:25px;
      width:70px;
      box-sizing:border-box;
      padding:5px 8px;
      border-radius: 5px;
      color: #858585;
      background-color: white;
      -webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
      -moz-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
      box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
      font-size: 12px;
      text-align: center;
      line-height: 18px;
      z-index: 1000;

      &::after{
        content:'';
        position: absolute;
        top: -10px;
        left:0px;
        margin-left: calc(50% - 5px);
        border-width: 5px;
        border-style: solid;
        border-color:transparent transparent white transparent; 
      }
    }
  }

  .bookImg{
    position: relative;
    left: -30px;
    margin-top: 30px;
    width:110px;
    height: 120px;  

    &.bee{
      background: url('../../../../assets/bookCovers-bee.png') no-repeat 10px;
      background-size: contain;
      top: -10px;
      left: 0px;
      width:140px;
    }
    
    &.story{
      background: url('../../../../assets/book_new.png') no-repeat -10px;
      background-size: contain;
    }
    
    &.journal{
      background: url('../../../../assets/bookCovers-journal.svg') no-repeat 10px;
      background-size: 85%;
      top: -10px;
      width:140px;
      // height:140px;
    }
  }

  .backBtn{
    @include solidButton(white, $main-blue--bright, 14px, 35px, 200px);
    position: absolute;
    bottom:20px;
    right:0px;
  }

  .block{
    height:60px;
    width:60px;
    background-color: white;
    position: absolute;
    top:-20px;
    right: -35px;
    z-index: 20;
  }
}