@import '../../../shared.scss';

.container{
  position: relative;
  overflow: visible !important;
}

img.close{
  height: 20px;
  width: 20px;
  position: absolute;
  top:-10px;
  right:-10px;
  z-index: 100;
  cursor: pointer;
  transition: .3s all ease-in-out;

  &:hover{
    transform: scale(1.3);
  }
}

.flipPage{
  width:900px !important;
  height: 600px !important;
  cursor: pointer !important;
  font-family: 'Sofia pro';

}

p.hint{
  width:100%;
  text-align: center;
  margin-top: 20px;
  position: relative;
  font-family: 'Sofia pro';
  font-size: 12px;
  font-weight: 300;
}


article {
  display: flex;
  width: 900px;
  height: 100%;;

  .page {
    width: 50%;
    height: 100%;
    box-sizing:border-box;
    padding:20px 30px;

    &:nth-of-type(even){
      border-left: 1px solid #cacaca;;
    }
  }
}
