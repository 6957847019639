@import './../../../../../../shared.scss';

.container{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 300px;
  width: 100%;
  box-sizing:border-box;
  padding:30px 40px;
  position: relative;


  .titleBar{
    display: flex;
    align-items: center;
    justify-content: center;
    // border:1px solid chartreuse;
  }

  .titleBar{
    img{
      margin-right: 10px;
      width: 35px;
      position: relative;
      bottom: 3px;
    }
  }


  .textDiv{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // border:1px solid chartreuse;
    height: 125px;

    h3{
      font-size: 19px;
      color: #FB3333;
      font-weight: 500;
      margin:10px 0 15px 0;
      background: url('../../../../../../assets/warning_red.svg') left center no-repeat;
      padding-left: 30px;
      background-size: contain;
    }

    p{
      font-size: 16px;
      color: #858585;
      font-weight: 300;
      width: 400px;
  }
}

  .sliderDiv{
    // border:1px solid cyan;
    height: 90px;
    display: flex;
    align-items: center;
    box-sizing:border-box;
    padding:0 30px 0 0;

    p{
      color: #858585;
      font-size: 15px;
      opacity: 0;
      transition: .2s all ease-out;

      &.show{
        opacity: 1;
      }
    }

    .slider{
      height: 35px;
      width: 65px;
      border-radius: 20px;
      background-color: #d2d2d2;
      margin: 0 10px;
      position: relative;

      .sliderInner{
        height: 31px;
        width: 31px;
        border-radius: 50%;
        background-color: white;
        position: absolute;
        left: 2px;
        top:2px;
        transition: .2s all ease-out;
        cursor: pointer;

        &.active{
          left:32px;
        }
      }

      &.active{
        background-color: $main-green;
      }
      
    }

    &.blocked{
      pointer-events: none;
      opacity: .3;
      margin-top: 10px;
    }
  }

  .submitBtn{
    background-color: $main-blue--light;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0 15px;
    border-radius: 8px;
    margin-top: 10px;
    transition: .2s all ease-out;
    cursor: pointer;
    pointer-events: none;
    opacity: .5;
    position: absolute;
    right: 30px;
    bottom:30px;

    &:hover{
      background-color: $main-blue--dark;
    }

    p{
      color: white;
    }

    &.enabled{
      opacity: 1;
      pointer-events: all;
    }

  }


  &.mobile{

    .sliderDiv{
      height: 70px;
    }

    .textDiv{
      margin-top: 0;
      p{
        width: 100%;
        font-size: 14px;
        text-align: center;
      }
    }
    
  }
}
