@import '../../../../shared.scss';

.container{
  display:flex;
  justify-content: space-between;
  position: relative;
  height: 94%;

  .close {
    cursor: pointer;
    opacity: 1;
    position: absolute;
    background-image: url('../../../../assets/icon-close.png');
    height: 35px;
    width: 35px;
    right: -15px;
    top: -10px;
    z-index: 1500;
    transition: .3s all ease-out;
    pointer-events: initial;

    &:hover {
      opacity: 0.7;
      transform: scale(1.05);
    }
  }

  h1, h4{
    margin:0;
  }

  .loaderDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .left,.main{
    box-sizing:border-box;
    padding:15px 30px;
  }

  .left{
    background-color: white;
    height:640px;
    width:380px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 1px 4px 0px #00000025; 
    box-shadow: 0px 1px 4px 0px #00000056;
    // box-sizing:border-box;
    // padding:10px 15px;

    .activityTitle{
      box-sizing:border-box;
      padding:0 10px;
      margin-bottom: 15px;;
    }

    .groupBlock{
      width:100%;
      height:100px;
      border-radius: 5px;
      background-color: $plus-purple;
      color: white;
      box-sizing:border-box;
      padding:10px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      position: relative;

      h2{
        font-size: 20px;
        color:white;
      }

      p{
        color:white;
        font-size: 12px;
        margin:4px 0;
      }

  .boomers{
    position: absolute;
    bottom:10px;
    right:5px;
    height:35px;
    width:100%;
    display: flex;
    justify-content: flex-end;

    .boomer{
      height:35px;
      width:35px;
      border-radius: 50%;
      overflow: hidden;
      border:2px solid $plus-purple;
      display: flex;
      align-items:center;
      justify-content: center;
      position: relative;
      bottom:3px;
      background-color: white;

      &:first-of-type{
        // left:10px;
        z-index: 10;
      }
      &:nth-of-type(2){
        right:10px;
        z-index: 8;
      }
      &:nth-of-type(3){
        right:20px;
        z-index: 1;
      }
      
      img{
        height:30px;
        width:30px;
        object-fit: 30%;
      }

    }
  }

    }

    .chat{
      position: relative;

      .titleBar{
        display:flex;
        justify-content: space-between;
        align-items: center;
        height:50px;
        margin-top:20px;
        // border:1px solid chartreuse;

        .title{
          display:flex;
          align-items: center;

          .chatIcon{
            height:20px;
            width:20px;
            background: url('../../../../assets/icon-chat.svg') no-repeat center ;
            background-size: contain;
            margin: 0 15px 0 0;
          }
        }          
        .chevron{
          cursor: pointer;
          transform: rotate(0deg);
          height:18px;
          width:18px;
          background: url('../../../../assets/icon-chevron--black.svg') no-repeat center;
          background-size: contain;
          transition: .4s all ease-out;
          // border:1px solid chartreuse;

          &.closed{
            transform: rotate(180deg); 
          }
        }
      }

      .chatBox{
        width:100%;
        height:390px;
        border-radius: 10px;
        background-color: #e9e9e9;
        border:1px solid #cacaca;
        overflow: hidden;
        transition: .4s height ease-out;

      .placeholder{
        position: absolute;
        top:50px;
        left:1px;
        height: calc(100% - 50px);
        border-radius: 10px;
        width:100%;
        background-color: #b1b1b1a3;

        h5{
          font-family: 'Sofia pro';
          width:100%;
          text-align: center;
          margin-top: 35%;
          font-size: 20px;
          font-weight: 300;
          color:white;
          opacity: 1;
          transition: all .3s ease-in-out;
        }

      }

        &.closed{
          height:0px;
          .placeholder h5{
            opacity: 0;
          }
        }
        &.hide{
          opacity: 0;
        }
        // &.noBorder{
        //   border:1px solid transparent;
        // }
        
        .messages{
          height:334px;
          width:100%;
          overflow-y: scroll;
          // border:1px solid chartreuse;
          margin:5px 0;
          box-sizing:border-box;
          padding:10px;
          position: relative;
          display:flex;
          flex-direction: column;

          .message{
            align-self: flex-end;
            height:fit-content;
            padding:10px;
            width:120px;
            background-color: $main-blue--bright;
            border-radius: 10px;
            border-bottom-right-radius: 0px;
            color:white;
            margin:5px 0;
            position: relative;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
            font-size: 13px;
            -webkit-box-shadow: 0px 1px 4px 0px #00000025; 
            box-shadow: 0px 1px 4px 0px #00000056;
          }
        }

        .messageInput{
          height:40px;
          width:97%;
          margin:0 auto;
          background-color: white;
          border:1px solid #cacaca;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          box-sizing:border-box;
          padding:0 3px;

          input[type=text]{
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
            height:38px;
            width: calc(100% - 105px);
            line-height: 38px;
            box-sizing:border-box;
            padding:0 8px;
            border:none;
            font-size: 14px;

            &::placeholder{
              font-style: italic;
              // color: red;
            }
          }

          .sendBtn{
            cursor: pointer;
            height:30px;
            width:100px;
            line-height: 30px;
            text-align: center;
            color:white;
            background-color: $main-blue--bright;
            border-radius: 5px;
          }
        }
      }
    }
  }

  .main{
    background-color: white;
    height:640px;
    width:800px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 1px 4px 0px #00000025; 
    box-shadow: 0px 1px 4px 0px #00000056;
    // box-sizing:border-box;
    // padding:10px 15px;

    .top{
      height:80px;
      width:100%;
      display: flex;

      .topLeft, .topRight{
        width:49%;
        height:100%;
        // border:1px solid chartreuse;
        display: flex;
        align-items: center;
      }

      .topRight{
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        -webkit-box-shadow: 0px 1px 4px 0px #00000025; 
        box-shadow: 0px 1px 4px 0px #00000056;
        border-radius: 10px;
        box-sizing:border-box;
        padding:20px;

   
      }

      .roomTitle{
        display:flex;
        align-items: center;

        .dot{
          height:30px;
          width:30px;
          border-radius: 50%;
          line-height: 32px;
          text-align:center;
          background-color: $plus-purple;
          color:white;
          margin-left: 8px;
          font-size: 26px;
          font-weight: 700;;
        }
      }

    }
    .bottom{
      display:flex;
      margin-top: 15px;
      overflow-y: auto;
      overflow-x: visible;
      padding-bottom: 20px;
      
      .entry{
        height:530px;
        min-width:360px;
        border:1px solid #CACACA;
        -webkit-box-shadow: 0px 1px 4px 0px #00000025; 
        box-shadow: 0px 1px 4px 0px #00000056;
        margin-right: 10px;
        border-radius: 10px;
        box-sizing:border-box;
        padding:15px;
        h3{
          font-size: 16px;
          font-weight: 500;
          color: $plus-purple;
        }
        .header{
          height: 18px;
          display: flex;
          justify-content: space-between;
          overflow-y: visible;
          margin-bottom: 20px;
        }

        .text{
          margin-top: 0px;
          resize: none;
          // min-width:325px;
          width:100%;
          height:450px;
          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
          font-size: 13px;
          line-height: 18px;
          color:#858585;
          border:none;
        }


        &.slim{
          height:530px;
          min-width:335px;
          margin-right: 10px;
        }
      }
    }
  }

  .backBtn{
    z-index: 100;
    cursor: pointer;
    height:40px;
    line-height:40px;
    width:210px;
    text-align:center;
    border-radius: 5px;
    background-color: $E_blue;
    color:white;
    position: absolute;
    bottom:-45px;
    left:0;
    border:1px solid $E_blue;
    transition: .3s all ease-out;

    &:hover{
      background-color: $E_blue_dark;
    }
  }


}