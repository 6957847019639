@import '../../../../shared.scss';

.container{
  min-height: 100px;
  max-height: 140px;
  width: 100%;
  box-sizing:border-box;
  transition: .45s all ease-in-out;
  padding:0;
  border-radius: 8px;
  cursor: pointer;
  -webkit-box-shadow: 0px 4px 15px -2px rgba(0, 99, 192, 0.3);
  -moz-box-shadow:    0px 4px 15px -2px rgba(0, 99, 192, 0.3);
  box-shadow:         0px 4px 15px -2px rgba(0, 99, 192, 0.3);
  opacity: 0;
  margin-bottom: 15px;
  position: relative;
  background-color: white;

  .groupActionsDot{
    height: 19px;
    width: 19px;
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: red;
    border-radius: 50%;
    font-size: 13px;
    line-height: 19px;
    text-align: center;
    color: white;
    z-index: 3;
    font-weight: 700;
    border: 1px solid white;
    transition: .25s all ease;
    opacity: 0;

    &.visible{opacity: 1;}
  }


  &.noGlow{
    -webkit-box-shadow: none;
    -moz-box-shadow:    none;
    box-shadow:         none;
  }

  .dropdown{
    width: 220px;
    height: 0px;
    background-color: white;
    border-radius: 8px;
    position: absolute;
    top: 30px;
    right: -5px;
    z-index: 150;
    transition: .15s all ease-in;
    opacity: 0;
    overflow: hidden;
    margin:20px;
    -webkit-box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.1); 
    box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.1);

    &.open{
      height: 160px;
      opacity: 1;

      &.archive{
        height: 80px;
      }
    }

    .dropRow{
      display: flex;
      align-items: center;
      height: 40px;
      cursor: pointer;
      transition: .15s all ease-in;

      &.hide{
        display: none;
      }

      &.disabled{
        opacity: .4;
        pointer-events: none;
        cursor: initial;
      }

      &:hover{
        background-color: #e9e9e9;
      }

      .icon{
        height: 40px;
        width: 40px;
        margin:0 15px 0 5px;
      }

      p{
        font-size: 15px;
        color: #858585;
        font-weight: 500;
      }

      &.edit{
        .icon{
          background: url('../../../../assets/icon-row-pencil.svg') no-repeat center;
          background-size: 20px;
        }
      }
      
      &.duplicate{
        .icon{
          background: url('../../../../assets/icon-row-duplicate.svg') no-repeat center;
          background-size: 18px;
        }
      }
      
      &.archive{
        .icon{
          background: url('../../../../assets/icon-row-archive.svg') no-repeat center;
          background-size: 20px;
        }
      }
      
      &.delete{
        .icon{
          background: url('../../../../assets/icon-row-delete.svg') no-repeat center;
          background-size: 17px;
        }
        
        &:hover{
          background-color: #FED6D6;
          .icon{
            background: url('../../../../assets/icon-row-delete-red.svg') no-repeat center;
            background-size: 17px;
            // position: relative;
            // right:1px;
            // bottom:1px;
          }
          p{
            color:#FB3333
          }
        }
      }
    }
  }

  &.display{
    bottom:0;
    opacity: 1;
  }

  .inner{
    overflow: hidden;
    min-height: 120px;
    max-height: 120px;
  }

  &.open{
    max-height: fit-content;
    opacity: 1;

    .inner{
      max-height: 330px;
      overflow-y: auto;
    }
  }

  .main{
    display: flex;
    height: 100px;
    width: 100%;
    position: relative;

    .clickHandle{
      height: 100%;
      width: calc(100% - 35px);
      z-index: 1 !important;
      pointer-events: all !important;
      position: absolute;
      top: 0;
      left: 0;

    }
  } 

  .content{
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing:border-box;

    .topRow{
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      height: 60px;
      width: 100%;
      box-sizing:border-box;
      padding:5px;

      &.one{
        background-color: rgba(151, 211, 24, 1);
      }

      &.two{
        background-color: rgba(242, 98, 131, 1);
      }

      &.three{
        background-color: rgba(255, 130, 88, 1);
      }

      &.four{
        background-color: rgba(255, 168, 38, 1);
      }

      &.grey{
        background-color: rgba(196, 196, 196, 1);
      }

      .dots{
        height: 30px;
        width: 30px;
        margin-left: 3px;
        background: url('../../../../assets/threeDots_white.svg') no-repeat center;
        transition: .15s ease-in background-color;
        position: relative;
        cursor: pointer;
        border-radius: 8px;
        pointer-events: all !important;

        &:hover{
          background-color: #e9e9e983;
        }
      }
    }

    .nameDiv{
      flex-basis: 64%;
      overflow: hidden;
      height: 35px;
      position: relative;
      top: 2px;
      box-sizing:border-box;
      padding:0 7px;


      input[type=text]{
        height: 30px;
        // box-sizing:border-box;
        padding:0 5px;
        position: relative;
        bottom: -3px;
        left:-1px;
        z-index: 15 !important;
        pointer-events: all !important;
        font-size: 16px;
        color: #333333;
        width: calc(100% - 15px);
        position: relative;
        border:1px solid #f0f0f0;
      }

      h5{
        font-size: 1.1rem;
        font-weight: 500;
        letter-spacing:1.02px;
        color: white;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
      }
    }

    .bottomRow{
      height: 35px;
      width: calc(100% - 2px);
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      box-sizing:border-box;
      padding:10px;
      margin-bottom: 5px;
      position: relative;
      top: -2px;

      .left{
        display: flex;
        align-items: center;
        
        .icons{
          display: flex;
          align-items: center;
          width: 100%;

          img{
            margin: 0 5px 0 10px;
            width: 17px;

            &:first-of-type{
              margin-left: 0;
            }
          }

          p{
            font-size: 0.9rem;
            color: $main-blue;
            font-weight: 500;
            white-space: nowrap;

            &.participants{
              position: relative;
              top: 5px;
            }
          }
        }
      }
      
      .teachers{
        width: 70px;
        display: flex;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;

        .circleImg{
          opacity: 1;
          display: initial;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          min-height: 25px;
          min-width: 25px;
          max-height: 25px;
          max-width: 25px;
          border:2px solid white;
          border-radius: 50%;
          position: relative;
          img{
            height: 25px;
            width: 25px;
            border-radius: 50%;
            border:2px solid #f2f2f2;
          }
        }
      }

      .teachers{

        .circleImg{
          background-color: white;
          &:nth-last-of-type(2){
            left: -12px;
          }
          &:nth-last-of-type(3){
            left: -25px;
          }

          .placeholder{
            height: 25px;
            width: 25px;
            border-radius: 50%;
            background-size: cover !important;
            position: relative;
            left: 2px;
            
            &.one{
              background: url('../../../../assets/nav/defaultPic1.svg') no-repeat center;
            }
            
            &.two{
              background:  url('../../../../assets/nav/defaultPic2.svg') no-repeat center;
            }
            
            &.three{
              background:  url('../../../../assets/nav/defaultPic3.svg')  no-repeat center;
            }
            
            &.four{
              background:  url('../../../../assets/nav/defaultPic1.svg')  no-repeat center;
            }
          }
        }
      }
    
      .chevron{
        height: 30px;
        width: 30px;
        background: url('../../../../assets/arrow_square_chevron.png') no-repeat center;
        transition: .3s ease-in transform;
        cursor: pointer;
        pointer-events: all !important;
  
        &.open{
          background: url('../../../../assets/arrow_square_chevron_hover.png') no-repeat center;
          transform: rotate(180deg);
        }
        
        &:hover{
          background: url('../../../../assets/arrow_square_chevron_hover.png') no-repeat center;
        }
      }
    }
  }

  .activitiesDiv{
    max-height: fit-content;
    width: calc(100% + 5px);
    position: relative;
    left: -4px;
    overflow: hidden;
    box-sizing:border-box;
    margin-top: 5px;
    padding:8px 15px 8px 8px;

    .placeholder,
    .dashActivityDiv{
      height: 95px;
      min-height: 95px;
      max-height: 130px;
      border-radius: 8px;
      flex-grow: 1;
      max-width: calc(100% - 5px);
      left: 6px;
      min-width: calc(100% - 5px);
      display: flex;
      align-items: center;
      justify-content: center;
      border:1px solid #e9e9e9;
      background-color: white;
      margin-top: 8px;
      position: relative;

      h5.noActivities{
        font-weight: 100;
        font-size: 14px;
        color: #858585;
        letter-spacing: 1px;
      }

      .iconDiv{
        width: 70px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon{
          width: 42px;
          height: 60px;

          &.one{
            background: url('../../../../assets/journal_blue.svg') no-repeat center;
          }
          &.two{
            background: url('../../../../assets/journal_orange.svg') no-repeat center;
          }
          &.three{
            background: url('../../../../assets/journal_pink.svg') no-repeat center;
          }
          &.four{
            background: url('../../../../assets/journal_green.svg') no-repeat center;
          }

          &.grey{
            background: url('../../../../assets/journal_grey.svg') no-repeat center;
          }
        }
      }

      .text{
        width: calc(100% - 70px);
        text-align: left;
        box-sizing:border-box;
        padding:0px 10px;
        height: 70px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h5{
          color: #333333;
          font-size: 14px;
          font-weight: 500;
          // height: 40px;
        }

        p{
          color: #858585;
          font-size: 13px;
          font-weight: 300;
        }
      }
    }
    .dashActivityDiv{
      justify-content: space-between;
      box-sizing:border-box;
      padding:10px;

      .actionCount{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 14px;
        width: 14px;
        border-radius: 50%;
        background: #FB3333;
        margin-left: 5px;
        position: absolute;
        top:-5px;
        right: -5px;

        p{
          margin: 0;
          color: white;
          line-height: 19px;
          font-size: 14px;
          position: relative;
        }
      }
    }
  }

  @media (max-width:660px){
    min-height: 130px;
    max-height: 130px;

    .main{
      padding: 0;
    }

    .inner{
      min-height: 120px;
      max-height: 120px;
    }

    .copyPillDiv{
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
    }

    .content{
      height: 110px;

      .topRow{
        position: relative;
        height: 35px;

        
        input[type=text]{
          width: calc(100% - 80px);
          margin:0 auto;
          text-align: center;
        }

      }

      .nameDiv{
        h5{
          text-align: center;
          width: 100%;
        }
      }
      .bottomRow{
        justify-content: center !important;
        
        .left{
          
          .icons{
            justify-content: center;
          }
          
          .teachers{
            display: none;
          }
        }
        
        .chevron{
          display: none;
        }
      }
    }
  }
}
