@import '../../../shared.scss';

.container{
  width: 100%;
  box-sizing:border-box;
  padding:5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  z-index: 300;

  .button{
    height: 40px;
    box-sizing:border-box;
    padding:0 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;

    h5{
      color: white;
      font-size: 14px !important;
      margin:0 !important;
      white-space: nowrap;
    }

    &.orange{
      background: rgb(255,116,38);
      background: linear-gradient(336deg, rgba(255,116,38,1) 12%, rgba(255,168,38,1) 86%);
    }
  }


  &.blue{
    background: rgb(46,102,174);
    background: linear-gradient(336deg, rgba(46,102,174,1) 12%, rgba(12,167,216,1) 86%);

    h5{
      color:white;
      font-size: 15px;
      font-weight: 700;
      margin: 0 50px;
      line-height: 22px;
    }
  }

}