@import "./../../../../shared.scss";

.previewModal {
    overflow-y: auto;
    width: 100%;
    border-radius: 15px;

    .cardHeader {
        background-color: #ffce89;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        color: #4e406e;
        padding: 0.75em 0em;
        border-radius: 15px 15px 0px 0px;
        text-align: center;
        margin-bottom: 2em;
    }

    .infoContainer {
        padding: 0.75em 40px 0.75em 40px;
        display: flex;
        h3 {
            font-weight: bold;
            font-size: 16px;
            line-height: 19px;
            color: #ffce89;
            span {
                font-size: 14px;
                line-height: 17px;
                color: white;
            }
        }
        textarea {
            margin: 3px 0;
            font-size: 14px;
            line-height: 17px;
            color: #ffffff;
            background-color: transparent;
            border: none;
            resize: none;
            width: 100%;
            // height: 200px;
            font-family: 'Sofia pro';
        }

        .wordContainer {
            margin-left: -5px;
            padding: 0.5em 0;
            .word {
                display: inline-block;
                background: #422566;
                border-radius: 10px;
                padding: 4px 8px;
                color: #ffce89;
                margin-right: 5px;
                font-size: 14px;
                line-height: 17px;
            }
        }

        .rubric {
            color: white;
            border-radius: 10px;
            width: 80%;
            display: flex;
            justify-content: space-between;
            background-color: $main-blue-brighter;
            .rubricName {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                text-transform: capitalize;
            }
            p {
                margin: 1px 1em;
            }
        }

        .imgWrapper {
            display: flex;
            align-items: center;
            padding-right: 14px;
            &.up {
                display: block;
            }
        }
        .imageUpload {
            width: 100%;
            .carouselWrapper {
                margin-top: 20px;
                .buttons {
                    margin-top: 1em;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #ffce89;
                    .btn {
                        background: #ffce89;
                        width: 40px;
                        height: 40px;
                        border-radius: 100px;
                        background-image: url("./../../../../assets/modalContent/carouselArrow.png");
                        background-repeat: no-repeat;
                        background-position: center;
                        &.left {
                            transform: rotate(-180deg);
                        }
                    }

                    .totalSlides {
                        width: 100px;
                        text-align: center;
                    }
                }
            }
        }
    }
}
