@import '../../../../shared.scss';

.container {
  width:500px;
  height:fit-content;
  box-sizing:border-box;
  padding: 20px;
  background-color: white;
  border-radius: 10px;

  .line {
    margin: 5px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;

    h3 {
      color: #333;
      font-size: 1.4rem;
      line-height: 1.6rem;
      white-space: nowrap;
    }

    img {
      height: 25px;
    }

    p {
      max-width: calc(100% - 40px);
      color: #858585;
      font-size: 1rem;
    }

    .copyQuestion {
      margin-left: auto;
      min-height: 40px;
      max-height: 40px;
      min-width: 40px;
      max-width: 40px;
      background: url('../../../../assets/icon_copy_grey2.svg') no-repeat center;
      cursor: pointer;
      pointer-events: all;
    }
  }
}