@import '../../../shared.scss';

.container{
  display: flex;
  width: 90px;
  height: 26px;
  border-radius: 100px;
  color: #858585;
  background-color: transparent;
  transition: .3s all ease-out;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  h5{
    margin:0;
    font-size: 14px;
    font-weight: 300;
  }

  .copyIcon{
    height: 15px;
    width: 20px;
    background: url('../../../assets/icon-copy--grey.svg') no-repeat center;
    background-size: contain;
    transition: .3s all ease-out;
  }

  &:hover{
    color: #333333;
    background-color: #e9e9e9;

    .copyIcon{
      background: url('../../../assets/icon-copy--black.svg') no-repeat center;
      background-size: contain;
    }
  }

  &.bold{
    h5{
      font-weight: 500
    }
  }

  &.smallRadius{
    border-radius: 5px;
  }

  &.blue{
    padding:2px 2px;
    background-color: $E_blue_light;

    h5{
      color: $E_blue;
    }
    .copyIcon{
      background: url('../../../assets/icon_copy_teal.svg') no-repeat center;
      background-size: contain;
    }
  
  }

}