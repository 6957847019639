@import '../../../shared.scss';

.container{
  width: 100%;
  height:100%;
  box-sizing:border-box;
  position: relative;
  justify-content:space-between;
  box-sizing:border-box;
  background-color: white;


  h5{
    font-size: 16px;
    font-weight: 500;
    position: relative;
    bottom: 1px;

    &.activityTitle{
      margin:0 10px;
    }
  }
  
  .topBar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    box-sizing:border-box;
    padding:10px 15px;
    
    >div{
      display: flex;
      align-items: center;
    }

    .backBtn{
      height: 30px;
      width: 30px;
      border-radius: 5px;
      background: url('../../../assets/Icons/arrow_square2.png') no-repeat center;
      cursor: pointer;
      margin-right: 5px;

      &:hover{
        background: url('../../../assets/Icons/arrow_square_hover2.png') no-repeat center;
      }
    }

  .additionalButtons{
    display: flex;
    width:220px;
    height: 35px;
    // margin-top: 15px;
    align-items: center;
    justify-content: space-evenly;
    // border:1px solid chartreuse;

    &.hidden{
      display: none !important;
    }

    .icon{
      height:25px;
      width:25px;
      margin-right: 5px;
      position: relative;
      cursor: pointer;
      border:5px solid transparent;

      &:last-child{
        margin-right: 0;
      }
      
      &:hover{
        background-color:  #E9E9E9 !important;
        border:5px solid #E9E9E9;
        border-radius: 5px;
      }

      &:first-of-type{
        .tooltip{
          left:-5px;
          min-width:135px !important;
          &::after{
            left:-50px;
          }
        }
      }
      &:last-of-type{
        .tooltip{
          left:-140px;
          &::after{
            left:30px;
          }
        }
      }
    
        &:hover ~.topDeetz{
          background-color: #f2f2f2;
        }
        &:hover >.tooltip{
          transition: .15s all ease-out;
          visibility: visible;
          top: calc(100% + 11px);
        }
      
    }

    .tooltip{
      visibility: hidden;
      position: absolute;
      top:35px;
      left:-60px;
      height:30px;
      min-width: 190px;
      box-sizing:border-box;
      padding:5px 8px;
      border-radius: 5px;
      color: #858585;
      background-color: white;
      -webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
      -moz-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
      box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
      font-size: 12px;
      text-align: center;
      // line-height: 20px;
      z-index: 1000;
      pointer-events: none;


      top: 80%;
      left: 0;
      left:-60px;
     

      // &::after{
      //   content:'';
      //   position: absolute;
      //   top: -10px;
      //   left:-20px;
      //   margin-left: calc(50% - 5px);
      //   border-width: 5px;
      //   border-style: solid;
      //   border-color:transparent transparent white transparent; 
      // }
    }

    .icon:nth-of-type(2){
      background: url('../../../assets/icon-duplicate--grey.svg') no-repeat center;
      background-size: calc(100% - 6px);
  
      
      // &.disabled{
      //   background: url('../../../assets/icon-duplicate--grey.svg') no-repeat center;
      //   opacity:.3;
      //   pointer-events: none;
      // }
    }

    .icon:nth-of-type(3){
      background: url('../../../assets/icon-downloadPdf--grey.svg') no-repeat center;
      background-size: contain;  

      // &.disabled{
      //   background: url('../../../assets/icon-downloadPdf--grey.svg') no-repeat center;
      //   opacity:.3;
      //   pointer-events: none;
      // }
    }
  
    .icon:nth-of-type(4){
      background: url('../../../assets/icon-shareResource--grey.svg') no-repeat center;
      background-size: 95%;    

      // &.disabled{
      //   background: url('../../../assets/icon-shareResource--grey.svg') no-repeat center;
      //   opacity:.3;
      //   pointer-events: none;
      // }
    }

  }


  }

  .sectionRadios{
    box-sizing:border-box;
    padding:3px 10px;
    border:1px solid #cacaca;
    width: 100%;
    display: flex;

    .newSectionBtn{
      height: 40px;
      width: 40px;
      border-radius: 10px;
      margin-right: 4px !important;
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: .3s all ease-out;
      z-index: 15;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23858585FF' stroke-width='3' stroke-dasharray='5%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      border-radius: 10px;

      .loading{
        height:55px;
        width:55px;
        // border:1px solid chartreuse;
        background:url('../../../assets/Spinner858585.svg') no-repeat center;
        background-size:cover;
      }
    }
  }

  .mainDiv{
    width: 100%;
    height: calc(100% - 40px);
    display: flex;

    .content{
      width: calc(100% - 85px);
      height:  calc(100% - 2px);
      

      .viewRadios{
        height: 50px;
        width: 100%;
        display: flex;
        align-items: center;
        // border-bottom: 1px solid #cacaca;
        border-bottom: none;
        box-sizing:border-box;
        padding:0 20px;
        position: relative;

        // &.simplified{
        //   border-bottom: none;
        // }
        
        input[type=radio]{
          display: none;
        }

        label.inactive{
          pointer-events: none;
        }


        .viewRadio{
          height: 50px;
          width: 150px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          cursor: pointer;

          p{
            transition: .2s all ease-out;
            color: #858585;
            font-size: 13px;
            font-weight: 500;
          }

          .icon{
            height: 25px;
            width: 25px;
            margin-right: 5px;
            background: url('../../../assets/cog_grey.svg')no-repeat center;
            background-size: 20px;
            
            &.responses{
              background: url('../../../assets/response_grey.svg')no-repeat center;
              background-size: 22px;
            }
          }
          
          &:hover{
            .icon{
              background: url('../../../assets/cog_black.svg')no-repeat center;
              background-size: 20px;
              
              &.responses{
                background: url('../../../assets/response_black.svg')no-repeat center;
                background-size: 22px;
              }
            }
            p{
              color: #4E4E4E;
            }
          }

          &.inactive{
            opacity: .4;
            pointer-events: none !important;
          }
        }

        input:checked+.viewRadio{
          p{
            color: $E_blue;
          }

          .icon{
            background: url('../../../assets/cog_blue.svg')no-repeat center;
            background-size: 20px;
            
            &.responses{
              background: url('../../../assets/response_blue.svg')no-repeat center;
              background-size: 22px;
              
            }

          }
        }

        .line{
          width: 150px;
          height: 2px;
          position: absolute;
          top: calc(100% - 2px);
          left: 25px;
          background-color: $E_blue;
          transition: .2s all ease-out;

          &.responses{
            left: 175px;
          }
        }

      }
      
      .viewSwitch{
        width: 100%;
        height:  calc(100% - 135px);
        overflow:scroll;
        position: relative;

        &.noScroll{
          overflow:hidden;
        }
      }

      .bottom{
        width: 100%;
        height: 95px;
        display: flex;
        justify-content: space-between;
        box-sizing:border-box;
        flex-wrap: wrap;
        padding:10px;
        // border-top:1px solid #CACACA;

        &.simplified{
          border-top: none;
        }

        .left{
          display: flex;
          align-items: center;
        }

        .delete{
          height: 40px;
          width: 40px;
          border-radius: 5px;
          background: url('../../../assets/icon-delete.svg') no-repeat 11px center, #ffffff;
          transition: .3s all ease-out;
          cursor: pointer;
          background-size: 17px;
          border: 1px solid #cacaca;
          display: flex;
          align-items: center;
          overflow: hidden;


          p{
            color: #FB3333;
            font-size: 15px;
            margin:0;
            font-weight: 500;
            transition: .3s all ease-out;
            opacity: 0;
            text-overflow: hidden;
            white-space: nowrap;
            position: relative;
            left: 40px;
          }
          
          &:hover{
            width: 160px;
            background: url('../../../assets/icon-delete_red.svg') no-repeat 11px center, #F9D1D1;
            background-size: 17px;
            p{
              opacity: 1;
            }
          }

          &.disabled{
            opacity: 1;
            pointer-events: none;
          }

        }

        .right{
          width: fit-content;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: flex-end;

          @media (max-width:950px){
            p.phaseText,
            p.message{
              display: none;
            }
          }

          .topRow{
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .modalContainer {
              position: absolute;
              text-align: left;
              width: 500px;
              right: 10%;
              bottom: 13%;
              height: fit-content;
              background-color: white;
              opacity: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              z-index: 100;
              -webkit-box-shadow: 1px 1px 20px 5px rgba(0,0,0,0.2);
              -moz-box-shadow: 1px 1px 20px 5px rgba(0,0,0,0.2);
              box-shadow: 1px 1px 20px 5px rgba(0,0,0,0.2);
              border-radius: 10px;
          
              &.display{
                bottom: 10%;
                opacity: 1;
                transition: .4s all ease-out;
              }
            
              .closeBtn{
                position: absolute;
                top: 10px;
                right: 10px;
                width: 30px;
                height: 30px;
                background: url('../../../assets/icon-close.png') no-repeat center;
                background-size: contain;
                cursor: pointer;
                z-index: 100;
                opacity: .8;
            
                &:hover{
                  opacity: 1;
                }
              }
            }          

            .stageExplanation {
              margin-left: 10px;
              width: 20px;
              height: 20px;
              cursor: pointer;
            }

            p.ended{
              font-style: italic;
              color: #858585;
              font-size: 14px;
              margin-right: 20px;
              text-align: right;
              line-height: 17px;

              span{
                display: block;
                font-size: 12px;
              }
            }

            p.phaseText{
              font-size: 14px;
              font-style: italic;
              color: #858585;

              &.Live{
                color: $E_pink;
              }

              &.Draft{
                background-color: transparent;
              }
              span{
                
                &.Draft{
                  font-weight: 700;
                  padding:6px 35px 6px 3px;
                  background:url('../../../assets/icon_draft.svg') no-repeat right 5px top 10px;
                  background-color: transparent;
                  // border:1px solid chartreuse;
                }

                &.Completed{
                  text-transform: uppercase;
                  font-style: normal;
                  color: white;
                  padding:6px 25px 6px 8px;
                  background:url('../../../assets/tick_green_dark.svg') no-repeat right 5px center;
                  background-color: $E_green;
                  border-radius: 5px;
                  margin-left: 3px;
                }
                &.Live{
                  text-transform: uppercase;
                  font-style: normal;
                  color: white;
                  padding:4px 7px;
                  background: rgb(242,98,131);
                  background: linear-gradient(139deg, rgba(242,98,131,1) 0%, rgba(254,135,95,1) 100%);
                  border-radius: 5px;
                  margin-left: 3px;
                }
              }
            }
          }
        }

        p.message{
          font-size: 13px;
          font-style: italic;
          width: 100%;
          text-align: right;
          color: #858585;
          opacity: 0;
          pointer-events: none;

          &.visible{
            opacity: 1;
          }
        }
        
      }
    }
    
  }

  @media (max-width:660px){
    display: none;
  }

}