@import '../../../../shared.scss';

.pageCount{
  min-height: 130px;
  width: 200px;
  padding:0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  input[type="number"]{
    height: 35px;
    padding: 0 10px;
    margin:5px 0 10px 0;
  }

  label{
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .checkbox{
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  

  .coverSelect{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    margin-bottom: 10px;
  }

  button.button{
    @include solidButton(white, $main-blue--bright, 14px, 35px, 120px);

    &.disable{
      opacity: .6;
      pointer-events: none;
    }
  }
}