@import '../../../../shared.scss';

.container {
  width: 100%;
  min-width: 950px;
  height: 100%;
  min-height: 700px;
  display: flex;
  box-sizing: border-box;
  padding: 10px 20px;
  background-color: #f3f7fe;
  overflow: auto;

  * {
    font-family: 'Sofia pro';
  }


  h1 {
    font-size: 27px;
    color: white;
    margin-bottom: 20px;

    &.black {
      color: #333333;
      margin-bottom: 10px;
    }

    &.totalCreditsNumber {
      font-size: 70px;
      margin: 20px 0 35px 0;
    }
  }

  h4 {
    font-size: 18px;
    color: white;
    font-weight: 300;
    line-height: 22px;
  }

  h5 {
    font-size: 14px;
    color: white;
    font-weight: 500;
    line-height: 22px;
  }

  .left {
    width: 60%;
    box-sizing: border-box;
    padding: 0 20px 0 0;


    .creditBanner {
      border-radius: 15px;
      width: 100%;
      background: url('../../../../assets/credit-header-coin.svg') no-repeat 10px bottom, rgb(12, 167, 216);
      background: url('../../../../assets/credit-header-coin.svg') no-repeat 10px bottom, linear-gradient(156deg, rgba(12, 167, 216, 1) 35%, rgba(46, 102, 174, 1) 76%);
      text-align: left;
      box-sizing: border-box;
      padding: 20px 50px 20px 170px;
      margin-bottom: 30px;

      h1 {
        margin-bottom: 10px;
      }
    }

    .leftLower {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;

      .totalCredits {
        margin-right: 30px;
        width: 35%;
        height: fit-content;
        -moz-max-content: fit-content;
        box-sizing: border-box;
        padding: 10px 20px 20px 20px;
        background: rgb(6, 95, 229);
        border-radius: 15px;
        background: linear-gradient(180deg, rgba(6, 95, 229, 1) 0%, rgba(142, 0, 240, 1) 95%);
        text-align: center;
        position: relative;

        h5.totalHeader {
          position: relative;
          left: 10px;
          width: 140px;
          margin: 0 auto;
          height: 35px;
          line-height: 35px;
          background: url('../../../../assets/credit-small-coin.svg') no-repeat left 11px;
        }

        .useCreditBtn {
          @include solidButton(white, $main-yellow, 18px, 40px, 80%);
          margin: 0 auto;
        }

        @media (max-width:1150px){
          h1{
            font-size: 45px;
          }

          .useCreditBtn{
            font-size: 14px;
          }
        }
      }

      .creditSummaryBlock {
        width: calc(65% - 35px);
        text-align: left;
        background-color: white;
        border-radius: 15px;
        box-sizing: border-box;
        padding: 20px 30px 40px 30px;
        -webkit-box-shadow: 0px 0px 11px 1px rgba(6, 95, 229, 0.32);
        -moz-box-shadow:    0px 0px 11px 1px rgba(6, 95, 229, 0.32);
        box-shadow:         0px 0px 11px 1px rgba(6, 95, 229, 0.32);

        .row {
          display: flex;
          height: 35px;
          font-weight: 300;
          font-size: 15px;

          .column .creditsHeader {
            margin:0 auto;
            text-align: center;
            width: 85px;
            background: url('../../../../assets/credit-small-coin.svg') no-repeat left 6px;
          }

          .column:first-of-type {
            width: 60%
          }

          .column:nth-of-type(2) {
            text-align: center;
            width: 15%
          }

          .column:last-of-type {
            text-align: center;
            width: 25%;

            div.creditsCount {
              height: 20px;
              width: 40px;
              margin: 0 auto;
              border-radius: 5px;
              line-height: 20px;
              font-weight: 500;
              background-color: #065fe54b;
            }
          }
        }

      }

      .linkBlock {
        width: calc(65% - 35px);
        text-align: left;
        background-color: white;
        border-radius: 15px;
        box-sizing: border-box;
        padding: 20px 30px 40px 30px;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        -webkit-box-shadow: 0px 0px 11px 1px rgba(6, 95, 229, 0.32);
        -moz-box-shadow:    0px 0px 11px 1px rgba(6, 95, 229, 0.32);
        box-shadow:         0px 0px 11px 1px rgba(6, 95, 229, 0.32);

        h3 {
          color: #333333;
          font-size: 18px;
          // margin-bottom: 15px;
          font-weight: 500;
        }
        p{
          color: #333333;
          font-size: 15px;
        }

        .linkBox {
          height: 40px;
          width: 100%;
          display: flex;
          align-items: center;

          input[type='text'] {
            height: 38px;
            width: 410px;
            border: 1px solid #cacaca;
            border-radius: 5px;
            font-family: 'Sofia pro';
            color: #858585;
            box-sizing: border-box;
            padding: 0 0 0 15px;
            font-size: 16px;
            line-height: 38px;
            font-weight: 300;
          }

          .linkBtn {
            height: 38px;
            width: 50px;
            border-radius: 5px;
            background-color: $main-blue--bright;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 10px;
            cursor: pointer;
            position: relative;


            p {
              color: white;
              font-size: 12px;
            }

            .tooltip {
              // visibility: hidden;
              position: absolute;
              top: 42px;
              left: -18px;
              height: 20px;
              width: 90px;
              border-radius: 5px;
              color: #858585;
              background-color: white;
              -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.39);
              -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.39);
              box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.39);
              font-size: 12px;
              text-align: center;
              line-height: 20px;

              &::after {
                content: '';
                position: absolute;
                top: -10px;
                left: 0;
                margin-left: calc(50% - 5px);
                border-width: 5px;
                border-style: solid;
                border-color: transparent transparent white transparent;
              }
            }

          }
        }

    .pills{
      display: flex;
      margin-top: 20px;
      justify-content: space-around;

      .pill{
        height: 40px;
        width:40px;
        border-radius: 10px;
        background-color: blue;
        display: flex;
        align-items: center;
        transition: .3s all ease-out;
        position: relative;
        cursor: pointer;

        &:hover{
          transform: scale(1.04);
        }

        &>button{
          height: 40px;
          width: 40px;
          position: absolute;
          left: 0;}

        &:nth-child(1){background-color: #4267B2;}

        &:nth-child(2){background-color: #3DA9F5;}
        &:nth-child(3){
          background-color: #4CAF50;
          box-sizing:border-box;
          padding:0 9px;
        }
        
        &:nth-child(4){background-color: #2B4E7B;}
        &:nth-child(4)

        img{
          height:22px;
          width:22px;
          object-fit: contain;
          
        }

        h5{
          font-size: 16px;
          width:80px;
          text-align: center;
        }
      }
    }

      }


    }
  }

  .breakdown {
    width: 40%;

    .breakdownSection {
      box-sizing: border-box;
      padding: 10px 30px 20px 30px;
      text-align: left;
      border-radius: 15px;

      &.getCredits {
        background-color: $main-blue--light;
        margin-bottom: 20px;
      }

      &.spendCredits {
        background-color: $main-blue;
      }

      .breakdownSubhead {
        font-size: 14px;
        color: white;
        opacity: .5;
      }

      .row {
        height: 40px;
        line-height: 40px;
        width:95%;
        margin:0 auto;
        border-bottom: 1px solid white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        &:last-of-type {
          border-bottom: none;
        }

        p {
          color: white;
          font-size: 15px;
          font-weight: 500;
          margin-right: 10px;
          text-overflow: ellipsis;
          overflow: hidden;
          // width: 90%;
          white-space: nowrap;
          max-width: 220px;
          // border:1px solid chartreuse;

          span.creditNumber {
            display: inline-block;
            font-size: 18px;
            max-width: 70px;
          }
        }

        span {
          display: flex;
          align-items: center;

          .query {
            height: 22px;
            width:22px;
            cursor: pointer;        
            background: url('../../../../assets/credit-query.svg') no-repeat center;
            position: relative;

            .tooltip{
              display: none;
              font-size:12px;
              line-height:17px;
              position:absolute;
              top:89%;
              z-index: 100;
              left: 50%;
              transform: translateX(-50%);
              width: 250px;
              height: fit-content;
              height: -moz-max-content;
              padding:10px 20px;
              background-color: #858585;
              border-radius:5px;
              color:white;
              text-align: center;
  
        
            }
  
            &:hover{
              .tooltip{
                display:initial;
              }
            }
          }
        }
      }

      i{
        color:white;
        font-size: 12px;
        font-weight: 500;
        line-height: 5px;
        position: relative;
        left: 10px;
        bottom:8px;
      }
    }
  }

}