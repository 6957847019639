@import '../../../../../shared.scss';


.statusDiv{
  width: 100%;
  max-height: 75px;
  margin-bottom: 15px !important;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  box-sizing:border-box;
  padding:0 15px;

  p.message{
    font-size: 11px;
    font-style: italic;
    width: 100%;
    text-align: left;
    color: #858585;
    display: none;
    pointer-events: none;
    position: relative;
    margin-bottom: 5px;

    span{
      color: $E_blue;
      font-weight: 700;
    }

    &.visible{
      display: initial;
    }
  }

  .bottom{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    position: relative;

    p{
      font-size: 12px;
      font-style: italic;
      color: #858585;
      span{

        &.Draft{
          background-color: transparent;
          font-weight: 700;
        }

        &.Completed{
          text-transform: uppercase;
          font-style: normal;
          color: white;
          padding:4px 7px;
          background-color: $E_green;
          border-radius: 5px;
          margin-left: 3px;
        }
        &.Live{
          text-transform: uppercase;
          font-style: normal;
          color: white;
          padding:4px 7px;
          background: rgb(242,98,131);
          background: linear-gradient(139deg, rgba(242,98,131,1) 0%, rgba(254,135,95,1) 100%);
          border-radius: 5px;
          margin-left: 3px;
        }
      }
    }
  }

}


.editorDiv{
  height:var(--viewportMinus);
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;

  .topRow{
    height: 40px;
    width: 100%;
    box-sizing:border-box;
    padding:0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .chevron{
      height: 30px;
      width: 30px;
      background: url('../../../../../assets/chevron_blue2.svg') no-repeat center;
      background-size: 13px;
    }

    h3{
      color: $E_blue;
      margin: 0;
    }
  }

  .inputDiv{
    height: calc(100% - 40px);
    width: 100%;
  }
}