@import '../../../../shared.scss';

.container{
  width: 100%;
  height: calc(100% - 200px);
  padding: 1rem 0;
  position: relative;
  overflow-y: scroll;
  z-index: 0;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: white;
    border:1px solid #cacaca;
  }

  .noClassPlaceholder{
    position: absolute;
    top: 100px;
    width: 100%;
    height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .grey {
      font-size: 1rem;
      font-weight: 300;
    }

    .arrowRes{
      display: none;
    }

    .face{
      height: 140px;
      width: 140px;
      background:url('../../../../assets/circle_avatars.svg') no-repeat center;
      margin-bottom: 30px;
    }

    h1{
      color: $E_blue;
      font-size: 50px;
      font-weight: 500;
      margin-bottom: 15px;
    }
  }

  @media (max-width:820px){
    height: fit-content;
  }

  @media (max-width:660px){
    height: fit-content; // calc(100vh - 160px) !important;
    box-sizing:border-box;

    .noClassPlaceholder{
      top: 120px !important;
      transform: translateY(-50%);

      .arrowRes{
        display: initial;
        position: absolute;
        top: 240px;
        left: 40%;
        transform: translateX(-50%);
        // height: 37vh;
        height: calc(var(--viewportHeight) - 410px);
        object-fit: contain;
      }

      .face{
        height: 90px;
        background-size: contain;
        width: 90px;
      }

    }
  }

  .modalOverlay{
    opacity: 0;
    display: flex;
    pointer-events: none;
    align-items: center;
    justify-content: center;
    height:100%;
    width:100% !important;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: transparent !important;

    &.display{
      opacity: 1;
      pointer-events: all;
    }
  }

}