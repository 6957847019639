@import 'shared.scss';

.container {
  box-sizing: border-box;
  margin: 0 auto;
  height:100%;
  width:100%;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  background-color: #E5F2F6;
  box-sizing:border-box;
  padding:20px 50px;
  overflow-y: auto;

  *{
    font-family: 'DM Sans';
  }

  h1{
    text-align: left !important;
  }
}

.loaderDiv{
  width: 100%;
  text-align: center;
  margin-top: 20%;
}

.inner {
  display: flex;
  height: 95%;
  width: 100%;
}

.folders {
  height: 100%;
  width: 60%;
  box-sizing:border-box;
  padding:0 20px 0 0;

}

.folder {
  cursor: pointer;
  box-sizing:border-box;
  padding:15px;
  height: 180px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  margin: 30px 0 !important;
  -webkit-box-shadow: 3px 9px 10px -5px rgba(163, 163, 163, 0.2);
  -moz-box-shadow: 3px 9px 10px -5px rgba(163, 163, 163, 0.2);
  box-shadow: 3px 9px 10px -5px rgba(163, 163, 163, 0.2);
  display: flex;
  align-items: center;
  transition: .1s all ease-in-out;
  position: relative;
  bottom: 0;

  div.soon{
    display: flex;
    align-items: center;
  }

  h3{
    font-weight: 300;
  }



  &:hover{
    bottom:3px;
    background-color: rgb(248, 248, 248);
  }

  &.inactive{
    pointer-events: none;
    position: relative;

    .folderImg,
    h3,
    h1{
      opacity: .6;
    }

  }

  h1+span{
    color: $main-blue--bright;
    margin-left: 15px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700 !important;
    opacity: 1 !important;
    position: relative;
    top:2px;

    &.reports{
      color:$main-green;
    }

    &.resources{
      color:$main-pink;
    }
  }


}


.folderImg{
  height:100px;
  width:100px;
  margin:15px;
  background-position: center;
  background-repeat: no-repeat;
}
.folderImg.manage{
  background-image: url('../../../assets/dashboard/folder-manage.jpg');
}
.folderImg.reports{
  background-image: url('../../../assets/dashboard/folder-reports.jpg');
}
.folderImg.subscription{
  background-image: url('../../../assets/dashboard/folder-subscription.svg');
}
.folderImg.resources{
  background-image: url('../../../assets/dashboard/folder-resources.jpg');
}

.details {
  box-sizing:border-box;
  padding:30px 0;
  height: 100%;
  width: 40%;
}

.schoolInfo {
  display: flex;
  align-items: center;
  box-sizing:border-box;
  padding:10px;
  border-radius: 10px;
  width: 100%;
  height: 180px;
  background-color: $E_blue_dark;


  &.faithJournal{
    background-color: #563B12;
  }
}
.schoolLogo{
  background-color: white;
  border-radius: 10px;
  height:150px;
  width:150px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;



  .changeLogo{
    position: absolute;
    top:0;
    left:0;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 10px;
    height:150px;
    width:150px;
    display: none;
    cursor: pointer;

    input[type="file"]{
      display:none;
    }

    h5{
      width:80%;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      color: #333333;
  
    }
  }

  &:hover{
    .changeLogo{
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  img.logoSpinner{
    height:80px;
    width:80px;
    object-fit: contain;
    margin: auto;
  }

  input[type="file"]{
    display:none;
  }
  
  &.default{
    background: url('../../../assets/admin-defaultPic.svg') no-repeat center;
    background-color: #f2f2f2;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    h5{
      width:80%;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      display: none;
      transition: .3s all ease-in-out;
      color: #333333;
    }
    
    &:hover{
      background: url('../../../assets/admin-defaultPic--hover.svg') no-repeat center;
      background-color: #f2f2f2;

      h5{
        display: initial;
      }
    }
  }

  img{
    height: 100%;
    width:100%;
    object-fit: contain;
  }
}
.schoolInfoText{
  box-sizing:border-box;
  padding:20px;
  h2{
    font-size: 28px;
    color: white;
    line-height: 33px;
    font-weight: 700;
  }
  h4{
    color: white;
  }
}
.statsDiv{
  justify-content: space-between;
  box-sizing:border-box;
  padding:20px 0 0 0;
  height:230px;
  width:100%;
  display: flex;
  flex-wrap: wrap;
}
.statBubble{
  display: flex;
  // flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  width: 48%;
  height: 45%;
  background-color: $E_blue_dark;
  h3{
    color:white;
    font-weight: 700;
    padding-top:5px;
  }

  &.faithJournal{
    background-color: #563B12;
  }
}
.number{
  width: fit-content;
  text-align: center;
  width:-moz-max-content;
  // text-align: right;
  margin:0 5px;
  color: $main-yellow;
  font-size: 35px;
  letter-spacing: -1.5px;
  font-weight: 700;
  padding:0 7px 0 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

@media (max-width:1300px){
    .number{
      font-size: 28px;
    }

}
@media (max-width:1100px){
  .schoolInfoText{
    
    h2{
      font-size: 15px;
    }
    h4{
      font-size: 11px;
    }
  }
}

@media (max-width:960px){
  .inner{
    flex-direction: column;
    overflow: hidden;
  }
  .folders,
  .details{
    width:100%;
    padding:0;
  }
  .folders{
    order:2;
  }
  .details{
    order:1;
  }
  .schoolInfo{
    height:140px;
  }
  .schoolInfoText{
    
    h2{
      font-size: 20px;
    }
    h4{
      font-size: 16px;
    }
  }
  .schoolLogo{
    height:120px;
    width:120px;
  }
  .folder{
    margin:10px 0;
    height:130px;
    h1{
      font-size: 23px;
      line-height: 26px;
    }
  }
  .details{
    height:320px;
  }
  .statsDiv{
    height:170px;
    padding-top: 10px;
  }
}

@media (max-width:660px){
  .container{
    overflow: auto;
    padding: 20px;
    height: var(--viewportMinus);
  }
  .inner{
    height: 85vh;
  }
  h1.pageTitle{
    line-height: 45px;
    margin-bottom:10px !important;
    width: 100%;
    text-align: center !important;
  }
  .details{
    height:220px;
  }
  .schoolInfo{
    height:100px;
  }
  .schoolLogo{
    height:80px;
    width:80px;
    
    &.default{
      background-size: 50px !important;
    }
  }
  .statsDiv{
    height:125px;
    padding-top: 5px;
  }
  .statBubble{
    height:47%;
    width:49%;
    .number{
      font-size: 24px;
      line-height: 28px;
    }
    h3{
      padding:0;
      margin:0;
      font-size: 14px;
    }
  }
  .folders{
    height:55%;
    padding-top: 10px;

  .folder{
    height:100px;
    margin:0 0 9px 0 !important;

    .folderImg{
      height:40px;
      width:40px;
      background-size: contain;
      margin-right: 20px;
    }

    h1{
      font-size: 15px;
      line-height: 20px;
    }
    h3{
      display: none;
      font-size: 13px;
      line-height: 13px;
    }
  }
}
}

@media (max-width:350px){
  .pageTitle{
    font-size: 20px;
    line-height: 48px;
    margin-bottom: 5px;
  }
  .inner{
    height: 95vh;
  }
  .folders{
    height:45%;
  }
}