@import '../../../../shared.scss';

.container{
  display:flex;
  height: 100%;
  width:100%;
  position: relative;

  *{
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 4px;
    }
    // &::-webkit-scrollbar-thumb {
    //   border-radius: 4px;
    //   background-color: $main-blue--bright;
    //   -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    // }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: white;
      border:1px solid #cacaca;
    }

  }

  .responsiveSearch{
    display: none;
  }

  .loaderOverlay{
    top:0;
    left:0;
    position: absolute;
    height: 100%;
    width:100%;
    // background-color: rgba(128, 128, 128, 0.554);
    background-color:rgba(255, 255, 255, 0.562);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img{
      position: relative;
      left:-5px;
    }
    h4{
      color:$main-blue;
      font-size: 15px;
      position: relative;
      top:-8px;
    }
  }

  ::placeholder{
    color: #858585;
    font-weight: 300;
  }



  .students{
    border-right:1px solid #cacaca;
  }

  .sidebar{
    height:100%;
    width:180px;
    background-color: $main-blue;
    box-sizing:border-box;
    padding:25px;
    display:flex;
    flex-direction: column;
    align-items: center;


    .classes{
      height: 400px;
      overflow-y: auto;
      margin-top:30px;
      width:179px;
      overflow-x: hidden;
      position: relative;
      left:-2px;

      &::-webkit-scrollbar-thumb {
        background-color: white;
      }


      input[type='radio']{
        display: none;
      }

      .classDiv{
        cursor: pointer;
        width:180px;
        height:40px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        box-sizing:border-box;
        padding:0 0 0 30px;
        transition: .3s all ease-out;
        overflow: hidden;
        position: relative;

        .circleCont{
          width:20px;
          height: 40px;
          overflow: hidden;
          // border:1px solid chartreuse;
          position: absolute;
          left: 0;

          .circle{
            height: 250px;
            width: 250px;
            border-radius: 50%;
            background-color: white;
            position: relative;
            left: -243px;
            top: -105px;
          }
        }

        .newMsgClass{
          height:8px;
          width:8px;
          border-radius: 50%;
          background-color: red;
          position: absolute;
          left:15px;
        }
      }
  
      input:checked + .classDiv{
        background-color: rgba(255, 255, 255, 0.1);
        overflow: hidden;
        h3{
          font-size: 14px;
          color:white;
        }
      }
      input:not(:checked) + .classDiv{
  
        &:hover{
          background-color: rgba(255, 255, 255, 0.2);
        }

        h3{
          font-size: 14px;
          color:white;
          opacity: .5;
        }
        .circle{
          display: none;
        }
      }
    }

  }

  .students{
    width:320px;
    height:100%;
    background-color: white;
    overflow-x: hidden;

    .search{
      height:70px;
      width:100%;
      display: flex;
      align-items: center;
      justify-content: center;

      input[type='text']{
        height:35px;
        width: 95%;
        border: none;
        border-radius: 5px;
        box-sizing:border-box;
        padding:0 0 0 40px;
        background: url('../../../../assets/icon-lupa.png') no-repeat 14px center;
        background-color: #e9e9e9;
        background-size: 7%;

       
      }
    }

    .studentList{
      height:455px;
      overflow-y: auto;
      overflow-x: hidden;
      width: 100%;

      input[type='radio']{
        display:none
      }

      p{
        width:100%;
        text-align: center;
        font-size: 12px;
        color: #858585;
      }
     
      .studentDiv{
        height:65px;
        width:100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: .3s all ease-in-out;
        position: relative;

        &:hover{
          background-color: #065ee307;
          transform: scale(1.03);
        }

        .colorTab{
          position: absolute;
          left: 4px;
          top: 2px;
          bottom: 0;
          margin: auto 0;
          content: '';
          width: 5px;
          height: 50px;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          visibility: hidden;
          background-color: $main-blue--bright;
        }

        .unreadCount{
          height:20px;
          width:20px;
          background-color:$main-blue--bright;
          color: white;
          position: absolute;
          right:20px;
          border-radius: 50%;
          font-size: 12px;
          font-weight: 700;
          display: flex;
          // align-items: center;
          justify-content: center;
          line-height: 22px;
        }

        h3{
          font-size: 16px;
          font-weight: 500;
          color:#333333;
        }

        img{
          height:35px;
          width: 35px;
          margin:15px;
          object-fit: contain;
        }
      }

      label > input:checked + .studentDiv{
        background-color: #065fe518;
        transform: scale(1.03);
        
        .colorTab{
          visibility: visible;
        }
        
        @media (max-width:660px){
          background-color: white;
          transform: scale(1);
          
          
          .colorTab{
            visibility: hidden;
          }
        }
      }

    }
  }



    .main{
      width: calc(100% - 320px);
      height:100%;
      background-color: white;
      box-sizing:border-box;
      padding:10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      
      .mainInner{
        width: 100%;
        max-height: calc(100% - 50px);
        display: flex;
        flex-direction: column-reverse;
        overflow: scroll;

        .datePill{
          margin:0 auto;
          height:15px;
          font-size: 10px;
          font-weight: 500;
          line-height: 11px;
          box-sizing:border-box;
          padding:2px 5px;
          border-radius: 15px;
          text-align: center;
          color: white;
          background-color: $main-blue;
        }


        .message{
          width:fit-content;
          width:-moz-max-content;
          height:fit-content;
          height:-moz-max-content;
          // min-height: 50px;
          min-width: 120px;
          max-width: 330px;
          box-sizing:border-box;
          padding:15px 15px 5px 15px;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          position: relative;
          margin:5px 0;

          // &:first-of-type{
          //   margin-top: auto;
          // }

          h4{
            color:white;
            font-size: 13px;
            margin-top:7px;
          }
          h6{
            color:white;
            position: absolute;
            top:-17px;
            right:8px;
          }
          
          &.self{
            border-radius: 10px;
            border-bottom-right-radius: 0px;
            background-color: $main-blue--bright;
            align-self: flex-end;
            position: relative;
          }

          &.external{
            border-radius: 10px;
            border-bottom-left-radius: 0px;
            background-color: #e9e9e9;
            align-self: flex-start;
            left:35px;
            img{
              height:35px;
              width: 35px;
              object-fit: contain;
              position: absolute;
              left:-40px;
              bottom:7px;
            }

    

              h4{
                color:#333333;
              }
              h6{
                color:#858585;

                &.from{
                  color:$main-green;
                  left:15px;
                  width: 65px;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                }
              }
          }

        }

    
    }

    .inputDiv{
      height:40px;
      width:100%;
      background-color: #e9e9e9;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;

      input{
        border: none;
        background-color: transparent;
        width:85%;
        margin-left: 20px;
        position: relative;
        top:1px;
      }

      img{
        cursor: pointer;
        height:30px;
        width:30px;
        object-fit: contain;
        margin-right: 7px;
      }
    }
  }

  &.standalone{
    .message{

      h6{
        top: 3px !important;
        text-align: right !important;
      }

      &.external{
        h4{
          text-align: left !important;
        }
      }
      &.self{
        h4{
          text-align: right !important;
        }
      }
    }
  }
  .responsiveConversation{

    position:absolute;
    height: 100%;
    width: 100vw;
    background-color: white;
    top: 0;
    left: 100vw;
    transition: .3s all ease-in;
    z-index: 100;
  
    &.open{left: 0;}

    .topBar{
      width: 100%;
      height: 90px;
      background-color: $E_blue_light;
      position: relative;
      z-index: 50;
      display: flex;
      align-items: center;
      box-sizing:border-box;
      padding:30px 15px 0 15px;

      img.arrow{
        height: 25px;
        width: 25px;
      }

      img{
        height: 50px;
      }

      h5{
        color: $E_blue;
        font-size: 17px;
      }
    }
  
  }

  @media (max-width:660px){
    height: calc( var(--viewportHeight) - 65px);

    .students{
      width: 100%;
      height: 100%;

      .search{
        display: none;
      }

      .responsiveSearch{
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing:border-box;
        padding:0 20px;

        h4{
          color: #333333;
          font-size: 25px;
        }

        .search{
          display: flex !important;
          width: calc(100% - 100px);
        }
      }
      
      .studentList{
        height: calc(100vh - 200px);
      }
    }
    .main{
      display: none;
    }

    .responsiveConversation{
      .main{
        width: 100vw;
        display: flex !important;
        height: calc(100% - 90px);

        .mainInner{
          max-height: calc(100% - 35px);
        }

        // .inputDiv{
        //   bottom: 10px;
        // }

      }
    }
  }
}