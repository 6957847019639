@import '../../../shared.scss';

.container{
  box-sizing:border-box;
  padding:15px 20px;
  height:100%;
  width: 100%;
  min-width: 450px;
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  z-index: 100;
  overflow: auto;
  background-color: #E5F2F6;

  transition: .3s background-color ease-out;

  *{
    font-family: 'DM Sans', sans-serif;
  }

  .hello{
    text-align: left;
    margin: 15px 0;
  }

  &.archive{
    background-color: #E9E9E9;
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none !important;
    width: 1px !important;
    background-color: transparent !important;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 1px !important;
    background-color: transparent !important;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0) !important;
  }

  .responsiveTeacherImg{
    min-height: 55px;
    max-height: 55px;
    min-width: 55px;
    max-width: 55px;
    border-radius: 50%;
    object-fit: cover;
    position: absolute;
    top: 10px;
    right: 25px;
    display: none;

    @media (max-width:660px){
      display: initial;
    }
  }

  .responsiveButton{
    position: fixed;
    right: 15px;
    bottom: 90px;
    height: 50px;
    width: 50px;
    border-radius: 10px;
    background: rgb(255,116,38);
    background: linear-gradient(328deg, rgba(255,116,38,1) 12%, rgba(255,168,38,1) 86%);
    z-index: 100;

    h5{
      display: none;
    }

    &::after{
      content: '+';
      font-weight: 300;
      position: absolute;
      top: 45%;
      left:53%;
      transform: translate(-50%,-50%);
      font-size: 35px;
      color: white;
    }

    display: none;

    @media (max-width:660px){
      display: initial;

      &.large{
        width: 140px;
        line-height: 50px;

        &::after{
          left: 22px;
        }

        h5{
          font-size: 17px;
          font-weight: 500;
          padding-left: 25px;
          display: initial;
          color: white;
        }
      }
    }
  }


  .main{
    width: calc(100% - 270px);
    // height: fit-content;
    margin-right: 20px;

    .topBar{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;

      .radios{
        display: flex;
        align-items: center;
        position: relative;
        gap: 10px;

        .radioDiv{
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 150px;
          cursor: pointer;
          white-space: nowrap;
          transition: .3s all ease-out;
 
          &.button {
            height: 35px;
            width: 120px;
            margin-left: 30px;
            border: 1px dotted $E_blue;
            border-radius: 10px;
            border: 2px dashed $E_blue;
            stroke-width: 5;
            stroke-dashoffset: 10;
          }

          &:hover{
            background-color: $E_blue_light2;
          }

          &.noClick{
            pointer-events: none;
          }

          &.hidden {
            opacity: 0;
            pointer-events: none;
          }

          p{
            font-size: 13px;
            text-transform: uppercase;
            font-weight: 500;
            letter-spacing: 1px;
            color: $E_blue;

            &.res{
              display: none;
            }
          }
        }

        .line{
          width: 140px;
          height: 2px;
          background-color: $E_blue;
          position: absolute;
          top: 38px;
          left: 5px;
          transition: .2s all ease-out;

          &.archive{
            left: 165px;
          }
        }

        &.grey{
          .radioDiv{
            p{
              color: #4e4e4e;
            }

            &:hover{
              background-color: #cacaca;
            }
          }

          .line{
            background-color: #4e4e4e;
          }
        }
      }
    }

    .bottomBar {
      width: 98%;
      padding: 5px;
      display: flex;


      .contactUs {
        opacity: 0;
      }

      @media (min-width:820px){
        position: fixed;
        bottom: 10px;

        .contactUs{
          opacity: 1;
          position: fixed;
          bottom: 20px;
          right: 30px;
          height: 50px;
          width: 65px;
          border-radius: 5px;
          background: url('../../../assets/icon-contact.svg') no-repeat 5px center/55px, #077CA0;
          transition: .3s all ease-out;
          cursor: pointer;
          display: flex;
          align-items: center;
          overflow: hidden;
          color: #fff;
          z-index: 25;
          
          a {
            font-size: 14px;
            margin-left: 60px;
            opacity: 0;
            white-space: nowrap;
          }
        
          &:hover{
            width: 160px;
            color: #fff;
            a {
              transition: .5s all ease-out;
              opacity: 1;
            }
          }
       }
      
      }
    }
  }

  .sideBar{
    width: 260px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;

    .mainBlock{
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: white;
      border-radius: 10px;
      padding-bottom: 15px;

      .blueBar{
        height: 50px;
        width: 100%;
        background: rgb(7,75,96);
        background: linear-gradient(142deg, rgba(7,75,96,1) 0%, rgba(7,124,160,1) 67%);
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
  
      img{
        margin-top: -25px;
        height: 50px;
        width: 50px;
        border-radius: 50%;
      }
  
      h5{
        font-size: 16px;
        font-weight: 500;
        margin: 20px 0 10px 0;
      }
  
      p{
        color:#858585;
        font-size: 12px;
        margin-bottom: 15px;
        font-weight: 300;
  
        &.blue{
          color: $E_blue;
          font-weight: 500;
        }
      }
  
      .row{
        width: calc(100% - 40px);
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .miniMeDiv{
        box-sizing:border-box;
        padding:30px 0 0 0;
        width:calc(100% - 30px);
        margin: 0 auto;
        border-top: 1px solid #cacaca;
        img{
          height: 180px;
          width: auto;
          position: relative;
          top: 5px;
        }
  
        .btnDiv{
          position: relative;
          display: flex;
          justify-content: center;
          bottom: 10px;
  
          .miniMeBtn{
            height: 35px;
            width: 150px;
            line-height: 35px;
            color: $E_blue;
            background-color: $E_blue_light;
            text-align: center;
            border-radius: 5px;
            transition: .2s all ease-out;
            cursor: pointer;
            position: relative;
            font-size: 14px;
  
            &:hover{
              background-color: $E_blue_light2;
            }
          }
  
          .tipDiv{
            right:calc(50% - 100px);
            top: 8px;
          }
        }
      }
    }

    .additionalBlock{
      width: 100%;
      height: fit-content;
      display: flex;
      margin-top: 5px;
      align-items: center;
      background-color: white;
      border-radius: 10px;
      box-sizing:border-box;
      padding:20px;
      position: relative;
      -webkit-box-shadow: 5px 5px 1px 2px rgba(0,0,0,0.1); 
      box-shadow: 5px 5px 1px 2px rgba(0,0,0,0.1);

      .btns{
        display: flex;
        gap: 8px;
        flex-direction: column;
        position: relative;

        .first{
          background: #077CA0;
          color: #fff;
          padding: 10px 1rem;
          border-radius: 5px;
          cursor: pointer;
          // position: fixed;
          // width: 100%;
          position: relative;
          bottom: 0px;
          z-index: 99;
          transform: all 0.3s ease;
          &:hover{
            transform: scale(0.9);
            transition: all 0.3s ease;
          }
        }
        .second{
          background: #fff;
          color: #077CA0;
          border: 1px solid #077CA0;
          padding: 10px 1rem;
          border-radius: 5px;
          cursor: pointer;
          // width: 100%;
          position: relative;
          bottom: 0px;
          z-index: 99;
          transform: all 0.3s ease;
          &:hover{
            transform: scale(0.9);
            transition: all 0.3s ease;
          }
        }
      }

      &.resource{
        padding: 16px 5px;
        overflow: hidden;
        transition: .1s all linear;
        background-color: $faith-grey-pink;
        flex-direction: column;
        box-sizing:border-box;
        padding:20px;
        position: relative;
        -webkit-box-shadow: 5px 5px 1px 2px rgba(0,0,0,0.1); 
        box-shadow: 5px 5px 1px 2px rgba(0,0,0,0.1);
        

        .imgDiv{
          img{
            object-fit: contain;
            // width: 80px;
            transform: scale(0.7);
          }
        }
      }

      .tipDiv{
        top: 8px;
        right:8px
      }

      .icon{
        height: 60px;
        width: 60px;
        background: url('../../../assets/foghorn.svg') no-repeat center;
        background-size: contain;
        margin-right: 20px;
      }

      h3{
        color: #333333;
        text-align: left;
      }
      
      p{
        color: #858585;
        margin: 5px 0;
        font-size: 13px;
        font-weight: 300;
        text-align: left;
      }

    }

    .tipDiv{
      position: absolute;
      height: 20px;
      width: 20px;
      background: url('../../../assets/query.svg') no-repeat center;
      cursor: pointer;

      .tooltip{
        position: absolute;
        top: 5px;
        opacity: 0;
        pointer-events: none;
        left: -204px;
        width: 250px;
        height: fit-content;
        box-sizing:border-box;
        padding:20px;
        background-color: #858585;
        color: white;
        font-size: 12px;
        line-height: 22px;
        font-weight: 300;
        border-radius: 10px;
        transition: .3s all ease-out;
        z-index: 100;

        &:after {
          content: '';
         position: absolute;
         right: 30px;
         top: -9px;
         width: 0;
         height: 0;
         border-left: 5px solid transparent;
         border-right: 5px solid transparent;
         border-bottom: 9px solid #858585;
        //  clear: both;
     
        }
      }

      &:hover{
        background: url('../../../assets/query_grey.svg') no-repeat center;

        .tooltip{
          top: 35px;
          opacity: 1;
        }
      }
    }

  }

  @media (max-width:820px){
    flex-direction: column;
    overflow: scroll;

    .sideBar{
      width: 85%;
      margin: 0 auto 30px auto;
      padding-bottom: 20px;
    }
    .main{
      width: 85%;
      margin: 0 auto;
      height: initial;
    }
  }

  @media (max-width:660px){
    padding:40px 0 0;
    min-width: initial;
    width: 100vw;
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100% - 80px);

    .radios{
      .radioDiv{
        width: 80px !important;
        p{
          display: none;

          &.res{
            display: initial !important;
          }
        }
      }
      .line{
        width: 70px !important;

        &.archive{
          left: 94px !important; 
        }
      }
    }

    .main{
      width: calc(100% - 40px);
    }

    .sideBar{
      display: none;
    }
  }

  &.synod{
    box-sizing:border-box;
    padding:10px 30px;
    .sideBar{
      display: none;
    }

    .main{
      width: 100%;
      .topBar{
        display: none;
      }
    }
  }
  
}

.showModal{
  top: 10%;
  left: 10%;
  opacity: 1 !important;
  pointer-events: all !important;
}
    
.showModalRes{
  opacity: 1;
  pointer-events: all;
}

.showOverlay{
  opacity: 1;
}
