@import '../../../../../shared.scss';

.container{
  position: relative;

  .tooltip{
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    padding:5px 15px;
    border-radius: 5px;
    font-size: 12px;
    white-space: nowrap;
    background-color: white;
    -webkit-box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.1); 
    box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.1);
    transition: .2s all ease;
    pointer-events: none;
    opacity: 0;
    z-index: 15;
    font-weight: 300;
  }

  &:hover{
    .tooltip{
      opacity: 1;
      bottom: -30px;
    }
  }
}
.linkBtn{
  height:38px;
  width:37px;
  border-radius: 8px;
  cursor: pointer;
  background: url('../../../../../assets/link_grey.svg') no-repeat center;
  background-size: 20px;
  transition: .3s all ease;
  
  
  &:hover{
    background: url('../../../../../assets/link_blue.svg') no-repeat center;
    background-size: 20px;
    // #88BDB5
  }
}

.modalContainer{
  height:100vh;
  width:100vw;
  display: none;
  z-index: 20;
  top: 0;
  left: 0;
  position: fixed;
  align-items: center;
  justify-content: center;

  &.display{
    display: flex;
  }

  .linkModal{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 8px;
    height:70px;
    width:400px;
    background-color: white;
    // position: fixed;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    -webkit-box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.2); 
    box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.2);
  
    p{
      font-size: 13px;
      font-weight: 300;
    }
    .confirmBtn{
      height:35px;
      width:80px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      cursor: pointer;
      color: white;
      background-color: $E_blue;
      transition: .3s all ease;

      &:hover{
        background-color: $E_blue_dark;
      }

      &.disabled{
        opacity: .4;
        pointer-events: none;
      }
  
    }
  
    input{
      border: 1px solid #cacaca;
      height: 30px;
      line-height: 30px;
      width: 200px;
      padding: 0 5px;
    }
  }
}
