
        .group_A,
        .group_M,
        .group_Y{
          background-color: #FFDCA8;
          
          &:hover{
            background-color: #FFA826;
          }
          &.noHover{
            pointer-events: none;
            background-color: #FFA826;
          }
        }
        .group_B,
        .group_N,
        .group_Z{
          background-color: #FAC0CD;
          
          &:hover{
            background-color: #F26283;
          }
          &.noHover{
            pointer-events: none;
            background-color: #F26283;
          }
        }
        .group_C,
        .group_O{
          background-color: #D299F9;
          
          &:hover{
            background-color: #8E00F0;
          }
          &.noHover{
            pointer-events: none;
            background-color: #8E00F0;
          }
        }
        .group_D,
        .group_P{
          background-color: #FFCDBC;
          
          &:hover{
            background-color: #FF8258;
          }
          &.noHover{
            pointer-events: none;
            background-color: #FF8258;
          }
        }
        .group_E,
        .group_Q{
          background-color: #D6EEA2;
          
          &:hover{
            background-color: #97D318;
          }
          &.noHover{
            pointer-events: none;
            background-color: #97D318;
          }
        }
        .group_F,
        .group_R{
          background-color: #AAB8CA;
          
          &:hover{
            background-color: #2B4E7B;
          }
          &.noHover{
            pointer-events: none;
            background-color: #2B4E7B;
          }
        }
        .group_G,
        .group_S{
          background-color: #9BBFF5;
          
          &:hover{
            background-color: #065FE5;
          }
          &.noHover{
            pointer-events: none;
            background-color: #065FE5;
          }
        }
        .group_H,
        .group_T{
          background-color: #A3EDED;
          
          &:hover{
            background-color: #18D2D2;
          }
          &.noHover{
            pointer-events: none;
            background-color: #18D2D2;
          }
        }
        .group_I,
        .group_U{
          background-color: #B3D5B4;
          
          &:hover{
            background-color: #409744;
          }
          &.noHover{
            pointer-events: none;
            background-color: #409744;
          }
        }
        .group_J,
        .group_V{
          background-color: #EB9DD1;
          
          &:hover{
            background-color: #CE0A8B;
          }
          &.noHover{
            pointer-events: none;
            background-color: #CE0A8B;
          }
        }
        .group_K,
        .group_W{
          background-color: #F3BA9B;
          
          &:hover{
            background-color: #E05304;
          }
          &.noHover{
            pointer-events: none;
            background-color: #E05304;
          }
        }
        .group_L,
        .group_X{
          background-color: #F1B5B5;
          
          &:hover{
            background-color: #DD4545;
          }
          &.noHover{
            pointer-events: none;
            background-color: #DD4545;
          }
        }