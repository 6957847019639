@import '../../../../shared.scss';

.container{
  height:calc(100% - 100px);
  width: 100%;
  position: relative;
}
.inner{
  position: absolute;
  z-index: 5;
  bottom:0;
  height:100%;
  width:100%;
  background-color: white;
  border-radius: 15px;
  // border-bottom-right-radius: 0px;
  border:1px solid $grey-unselected;
  display: flex;
  flex-direction: column;
}
.tabs{
  position: relative;
  .whiteBlock{
    position: absolute;
    z-index: 16;
    width:118px;
    height:20px;
    bottom:50px;
    left:1px;
    border:1px solid white;
    background-color: white;
  }
}
.tab{
  box-sizing:border-box;
  padding:3px 0 0 0;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  text-align: center;
  width:120px;
  height:34px;
  display: inline-block;
  background-color: white;
  border:1px solid $grey-unselected;
  border-bottom-width: 0px;
}
.tab.students{
  box-sizing: initial;
  position: relative;
  top:-9px;
  z-index: 1;
  padding-bottom: 50px;
  h3{
    color: rgba(0, 0, 0, 0.664);
  }
  
}
.tab.teachers{
  cursor: pointer;
  box-sizing: initial;
  position: relative;
  top:-9px;
  z-index: 1;
  background-color: $grey;
  padding-bottom: 50px;
}

.top{
  max-height:60px;
  min-height:60px;
  width: 100%;
  // border:1px solid chartreuse;
  display: flex;
  box-sizing:border-box;
  padding:0 30px;
  align-items: center;
  position: relative;

  .teacherDrop{
    min-width:180px;
    height:30px;
    line-height: 29px;
    text-align: center;
    box-sizing:border-box;
    padding:0 20px 0 0;
    border-radius: 8px;
    border:1px solid #858585;
    color:#858585;
    font-size: 14px;
    margin-right: 20px;
    position: relative;
    cursor: pointer;
    background: url('../../../../assets/icon-chevron--grey.png') no-repeat 90% center;
    
    p{
      margin:0;
      max-width: 87%;
      margin-left: 3%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .teacherDropMenu{
      z-index: 1000;
      position: absolute;
      top:28px;
      left:0px;
      width:180px;
      box-sizing:border-box;
      padding:5px;
      height: fit-content;
      height: -moz-max-content;
      background-color: white;
      border:1px solid #8585857e;
      border-radius: 8px;;

      input[type="radio"]{display: none}

      input+.teacherRow{
        cursor: pointer;
        width:100%;
        height:35px;
        line-height: 35px;
        text-align: center;
        color:#858585;


        &:hover{
          background-color: #f2f2f25f;

        }

        p{
          margin:0;
          margin-left: 2%;
          max-width: 95%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }


      input:checked+.teacherRow{
        background-color: #f2f2f2;
      }
    }
  }

  .radios{
    height:100%;
    display: flex;
    align-items: center;
  }
    
  label > input[type=radio]{
    display:none;
  }

  .pill{
    height:30px;
    width:30px;
    line-height: 30px;
    text-align: center;
    padding:0px;
    margin-right:8px;
    border-radius:50%;
    cursor: pointer;
    font-size: 14px;

    &.long{
      width:100px;
      border-radius: 15px;
    }
  }


    label > input:checked + .pill{
      color: white;
      border-color: $main-blue--light;
      background-color: $main-blue--light;
    }
    label > .pill{
      color: $main-blue--light;
      border:1px solid $main-blue--light;
      background-color: white;
    }


  input[type=text]{
    position: absolute;
    right:15px;
    color:black;
    width:30px;
    height:30px;
    border-radius: 10px;
    border:none;
    margin-left: 20px;
    box-sizing:border-box;
    padding:0 0 0 32px;
    background:url('../../../../assets/icon-lupa.png') no-repeat 8px 6px;
    background-size: 17px;
    background-color:#f2f2f2;
    transition: all .6s ease-in-out;
    border:1px solid #cacaca;
    cursor: pointer;

    &.open{
      width:340px;
      cursor: initial;
    }
  }
  
}
.container input{
  width:80%;
  height:35px;
  margin:0 15px 0 0;
  border: 1px solid $grey-unselected;
  font-size: 16px;
  padding:0 45px;
  -webkit-appearance: none; 
  -moz-appearance: none;
  border-radius: 5px;
  background: url('../../../../assets/manage/lupa.png') no-repeat 15px 7px;
  background-color: $main-bluegrey;
}
.addStudent{
  cursor: pointer;
  display:flex;
  align-items: center;
  justify-content: space-evenly;
  width:200px;
  height:35px;
  border-radius: 5px;
  background-color: $main-green;
  transition: all .3s ease;
  &:hover{
    transform: scale(1.04);
    h3{
      text-decoration: underline;
    }
  }
  h3{
    color: white;
    margin:0;
    line-height: 35px;
    font-size: 16px;
    font-weight: 700;
  };
  h1{
    color: white;
    margin:0;
    box-sizing:border-box;
    padding-bottom:3px;
  }
}

.tableHeader{
  display: flex;
  min-height:30px;
  width:100%;
  background-color: $grey;
  border: 1px solid $grey-unselected;
  border-width: 1px 0 1px 0;
  .boomer{
    width:10%;
    text-align: center;
  };
  .name{
    width:15%;
    text-align: left;
  };
  .email{
    width:15%;
    text-align: left;
  };
  .grade{
    width:10%;
    text-align: center;
  };
  .groupName{
    width: 20%;
    overflow-x: scroll;
    text-align: left;
  };
  .teacher{
    width:15%;
    text-align: left;
  };
  .parentName{
    width:15%;
    text-align: left;
  };
  .parentMail{
    width:15%;
    text-align: left;
  }
  .dotsDiv{
    width: 5%;
  }
  h5{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 10px;
    margin:0;
    line-height: 30px;
    text-transform: uppercase;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.4);
  }
}

.tableRows{
  overflow-y: scroll;
  overflow-x: hidden;
  flex-grow: 1;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;

  .loaderDiv{
    width: 100%;
    text-align: center;
    margin-top: 10%;
  }
  
}

@media (max-width:1100px) {

  .container {
    .boomer{
      width:10%;
    }
    .name {
      width: 25%;
    }
    .parentName{
      width:25%;
    }
    .grade{
      width: 10%;
    }
    .teacher {
      width: 25%
    }
  }
}

@media (max-width:600px){
  .container{
    height:80%;
  }
  .inner{
    border-top-left-radius: 0;
    height: 94%;
  }
  .top{
    flex-direction: column;
    min-height: 90px;

    input{
      box-sizing: border-box;
      width: 100%;
      height: 40px;
    }
    .addStudent{
      width: 100%;
      height: 40px;
      h1{
        position: absolute;
        right:10px;
      }
    }
  }
  .tabs{
    display: flex;
    .whiteBlock{
      width: 48.5%;
    }
  .tab{
    width:50%;
    height: 45px;
  }
}
}
@media (max-height:570px) and (max-width: 600px){
  .tabs{
    .whiteBlock{
      bottom: 57px;
      left: 1px;
    }
}
}

@media (max-width:380px){
  .container{
    height: 75%;
  }
}