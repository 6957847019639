@import '../../../../../shared.scss';

.container{
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
           -webkit-appearance: none;
            margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }
    
.arithmetic{
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 20px;
    top: 8px;
  }
  

  
    .form{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;
        margin: 2rem 0;
        .number{
            position: relative;
          }
        .input{
            width: 20%;
            label{
                margin-bottom: 10px;
                color: #858585;
                text-transform: capitalize;
            }
            input{
                border-radius: 5px;
                padding: 10px;
                margin-top: 5px;
                    border: 1px solid #111;
                    border-radius: 5px;
                    // height: 40px;
                    display: flex;
                    align-items: flex-start;
                    position: relative;
                  
            }
        }
        .lookinput{
            border: 1px solid black;
            padding: 10px  ;
            border-radius: 5px;
            height: 20px;
            display: flex;
            align-items: center;
            margin-top: 5px;
        }
        
        

    }
    .input_work{
        height: 40px;
        width: 100px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    .notes{
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin: 2rem 0;
        label{
                color: #858585;
                font-weight: bold;
                font-size: 1.1rem;
        }
        input{
            width: 300px;
            padding: 10px;
            border-radius: 5px;
        }
    }
    .btns{
        display: flex;
        gap: 1rem;
        align-items: center;
    }
    .saveBtn{
        background: #97D318;
        color: #fff;
        width: fit-content;
        padding: 10px 25px;
        border-radius: 5px;
        display: block;
        cursor: pointer;
    }
    .userRequest{
        border: 2px solid #97D318;
        border-radius: 5px;
        color: #97D318;
        padding: 10px 25px;
        cursor: pointer;
        transition: all 0.3s ease;
        &:hover{
            border: 4px solid #97D318;
        }
      }
      .check{
        position: relative;
        display: flex;
        align-items: center;
        font-size: 1rem;
        color: #858585;
        margin: 1rem 0;
      }
      .custom_checkbox {
        appearance: none;
        width: 25px;
        height: 25px;
        border: 2px solid #333;
        border-radius: 4px;
        margin-right: 8px;
        cursor: pointer;
        position: relative;
        margin-bottom: 1rem;
      }
      
      .custom_checkbox:checked {
        background-color: #97D318;
        border-color: #97D318;
        color: #fff;
        fill: #fff;
      }
}