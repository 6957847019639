@import '../../../../shared.scss';

.container{
  min-height: 180px;
  height: fit-content;
  min-width: 400px;
  border-radius: 10px;
  background-color: white;
  box-sizing:border-box;
  padding:20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;position: relative;


  h1{
    color: #333333;
    font-size: 22px;
  }

  .warning{
    height:70px;
    width:420px;
    background: url('../../../../assets/info2.svg') no-repeat 23px center;
    background-size: 35px;
    background-color: #f2f2f2;
    border-radius: 15px;
    margin: 10px 0 15px 0;
    box-sizing:border-box;
    padding:13px 10px 15px 75px;
    display: flex;
    align-items: center;

    p{
      margin: 0;
      line-height: 21px;
      font-size: 16px;
      font-style: italic;
      color: $main-orange;
      position: relative;
      top: 1px;
    }
  }

  p{
    font-size: 16px;
    color: #858585;
    font-weight: 300;
    position: relative;
    bottom: 5px;

    span{
      color: $E_blue;
    }
  }

  .okBtn{
    height: 35px;
    width: 100px;
    line-height: 35px;
    text-align: center;
    color: white;
    font-size: 14px;
    background-color: $E_blue;
    cursor: pointer;
    transition: .2s all ease-in-out;
    border-radius: 5px;

    &:hover{
      background-color: $E_blue_dark;
    }
  }
}