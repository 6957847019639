@import '../../../../../..//shared.scss';

.container{
  display: flex;
  height: 100%;
  width:calc(100% - 5px);
  // border:3px solid cyan;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;

  .loadingOverlay{
    height: 100%;
    width:calc(100% + 3px);
    position: absolute;
    top: 0;
    left:0;
    background-color: rgba(253, 253, 253, 0.686);
    display: flex;
    justify-content: center;
    box-sizing:border-box;
    padding:150px 0 0 0;
    z-index: 10;

    img{
      height: 150px;
    }
  }

  .topRow{
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
  }

  .countDiv{
    background-color: $E_blue_light;
    padding: 6px 13px;
    border-radius: 5px;
    height: fit-content;
    margin:8px;

    p{
      text-align: center;
      color: $E_blue_dark;
      font-size: 14px;
      margin:0;
    }
  }

  .mainDiv{
    width: 100%;
    height: calc(100% - 80px);

    .placeholderDiv{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

    }

    h2{
      width: 70%;
      max-width: 330px;
      margin-top: 30px;
      line-height: 30px;
      font-weight: 500;
    }
    
    p.grey{
      font-size: 16px;
      color: #858585;
      font-style: italic;
      width: 90%;
      margin-top: 30px;
    }
  }


  .generateBtn{
    margin: 15px auto 5px auto;
    padding: 8px 20px;
    border-radius: 8px;
    cursor: pointer;
    transition: .3s all ease-out;
    background-color: $E_blue;

    &.disabled{
      opacity: .4;
      pointer-events: none;
    }

    &:hover{
      background-color: $E_blue_dark;
    }

    p{
      color: white;
      font-weight: 16px;
      margin:0;
    }

    &.slim{
      padding: 6px 13px;
      border-radius: 5px;
      height: fit-content;
      margin:8px 0px;
      p{
        font-size: 14px;
      }
    }
  }
}