@import '../../../shared.scss';


.container{
  width: 100vw;
  height: 65px;
  max-height: 80px;
  background-color: $E_blue_dark;
  align-items: center;
  justify-content: center;
  display: none;
  // position: absolute;
  // bottom: 0;
  // left: 0;
  z-index: 200;
  align-items: center;
  justify-items: center;
  position: fixed;
  bottom: 0;
  left: 0;


  &.faithJournal{
    background-color: #563B12 !important;
  }

  .navItem{
    height: 100%;
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    z-index: 20;


    .new{
      height:10px;
      width: 10px;
      border-radius: 50%;
      position: absolute;
      top:10px;
      right:35px;
      background-color: $main-yellow;
      opacity:  1 !important;
      z-index: 50;
    }

    *{
      opacity: .6;
    }

    &:last-of-type{
      z-index: 5;
    }

    .icon{
      width: 35px;
      height: 35px;
      margin-bottom: 5px;

      &.home{
        background: url('../../../assets/newNavAssets/home.svg') no-repeat center;
      }
      &.chat{
        background: url('../../../assets/newNavAssets/chat.svg') no-repeat center;
      }
      &.notifications{
        background: url('../../../assets/newNavAssets/notifications.svg') no-repeat center;
      }
      &.profile{
        background: url('../../../assets/newNavAssets/profile.svg') no-repeat center;
      }
    }
    
    h5{
      color: white;
      margin: 0;
      font-family: 'Sofia pro';
    }
    
    &.activeItem{
      *{
        opacity: 1;
      }
    }
  }

  .popupMenu{
    position: absolute;
    right: 5px;
    top: 5px;
    width: 220px;
    box-sizing:border-box;
    border-radius: 10px;
    background-color: $E_blue_dark;
    padding:10px 0px;
    transition: .3s all ease-in-out;
    z-index: 105;
    height: 0px;
    overflow: hidden;
    opacity: 0 !important;
    
    &.open{
      opacity: 1 !important;
      top: -185px;
      height: 180px;
    }

    .popupRow{
      height: 40px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      box-sizing:border-box;
      padding:0 15px;

      .icon{
        height: 25px;
        width: 25px;
        margin-right: 8px;
      }

      h5{
        color: white;
        font-family: 'Sofia pro';
        font-size: 17px;
        font-weight: 300;
      }
      
      &:hover{
        background-color: rgba(186, 186, 186, 0.453);
      } 

      &.miniMe{
        .icon{
          background: url('../../../assets/pop_icon4.svg') no-repeat 2px center;
          background-size: 20px;
        }
      }

      &.settings{
        .icon{
          background: url('../../../assets/pop_icon2.svg') no-repeat center;
          background-size: 20px;
        }
      }
  
      &.subscription{
        .icon{
          background: url('../../../assets/pop_icon3.svg') no-repeat center;
          background-size: 20px;
        }
      }
      &.logout{
        .icon{
          background: url('../../../assets/pop_icon1.svg') no-repeat center;
          background-size: 18px;
        }
      }
    }

    .block{
      position: absolute;
      bottom: -10px;
      right: 0;
      height: 20px;
      width: 100%;
      background-color: red;
      opacity: 0;
    }

  }

  @media (max-width:660px){
    display: flex;
  
  }

}