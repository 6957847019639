@import '../../../shared.scss';

.container{
  width: 100%;
  height:100%;
  box-sizing:border-box;
  position: relative;
  display: flex;
  justify-content:space-between;
  box-sizing:border-box;
  background-color: white;
  position: relative;
  overflow: auto;



  .back{
    display: flex;
    align-items: center;
    position: absolute;
    top: 20px;
    left: 30px;
    cursor: pointer;

    .backBtn{
      height: 30px;
      width: 30px;
      border-radius: 5px;
      background: url('../../../assets/arrow_teal.svg') no-repeat center;
      cursor: pointer;
      margin-right: 5px;
      transition: .15s all ease-out;
    }

    p{
      font-size: 13px;
      color: $E_blue;
    }


    &:hover{
      .backBtn{
        background-color: $E_blue_light;
      }
    }
  }


  h1{
    font-size: 36px;
  }

  h3{
    color: #333333;
    font-size: 20px;
  }

  p.bucks{
    font-size: 14px;
    color: #858585;
    background: url('../../../assets/coins.svg') center right no-repeat;
    background-size: 13px;
    width: fit-content;
    padding-right: 17px;
    font-weight: 300;
    margin-top: 7px;
  }

  .left,
  .right{
    box-sizing: border-box;
    padding:80px 20px 40px 20px;
    height: fit-content;
    // border:1px solid chartreuse;
    overflow: visible;
  }

  .left{
    width: 400px;
    min-height: 100%;
    border-right:1px solid #d2d2d2;
    display: flex;
    flex-direction: column;
    align-items: center;


    .avatarDiv{
      margin-top: 40px;
      height: 120px;
      width: 100%;
      display: flex;
      box-sizing:border-box;
      padding:0 0 0 15px;
      
      img{
        height: 170px;
        // width: 170px;
        object-fit: cover;
        position: relative;
        bottom: 30px;      
      }

      .text{
        width: 100%;
        height:100%;
        // border:1px solid chartreuse;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        left: -10px;
        text-align: left;
      }
    }

    .generateBtn{
      padding:10px 25px;
      font-size: 16px;
      color: $E_blue;
      background-color: $E_blue_light;
      border-radius: 5px;
      transition: .15s all ease-out;
      cursor: pointer;
      width: fit-content;
      margin-top: 30px;

      &.res{display: none;}

      &:hover{
        background-color: $E_blue_light2;
      }
    }

    h3.title{
      margin-top: 50px;
      margin-bottom: 30px;
      text-align: left;
      width: 70%;
    }

    .classesDiv{
      // height:calc(100% - 320px);
      width: 100%;
      text-align: left;
      overflow-y: visible;
      box-sizing:border-box;
      padding:10px 20px;


  
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        background-color: $E_blue_light;
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: $E_blue_light2;
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
      }
      
      .classItem{
        width: 80%;
        box-sizing: border-box;
        padding:30px 10px;
        border-radius: 10px;
        margin:0 auto 10px auto;
        position: relative;
        overflow: hidden;
        -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 116, 240, 0.25);
        -moz-box-shadow: 0px 0px 15px 0px rgba(0, 116, 240, 0.25);
        box-shadow: 0px 0px 15px 0px rgba(0, 116, 240, 0.25);

        .clickHandle{
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          left:0;
          z-index: 20;
        }


        .oval{
          height: 15px;
          width: 100%;
          border-radius: 10px;

          &.one{
            background-color: #97D318;
          }

          &.two{
            background-color: #F26283;
          }

          &.three{
            background-color: #FF8258;
          }

          &.four{
            background-color: #FFA826;
          }

          &.grey{
            background-color: #c4c4c4;
          }
        }

        // &:after{
        //   content:"";
        //   height: 30px;
        //   width: 30px;
        //   border-radius: 50%;
        //   background-color: $E_orange_dark;
        //   position: absolute;
        //   top: -10px;
        //   left:-10px;
        // }
      }
    }
  }


  .right{
    height: 100%;
    width: calc(100% - 400px);

    .content{
      height: 100%;
      width: 100%;
      max-width: 850px;
      text-align: left;
      box-sizing:border-box;
      padding:0 0 0 50px;

      label{
        width: 50%;
        display: inline-block;
        text-align: left;
        margin-bottom: 30px;
        position: relative;
        input{
          display: initial;
          width: calc(100% - 20px);
          border:1px solid #cacaca;
          height: 50px;
          margin-top: 15px;
          border-radius: 5px;
          box-sizing:border-box;
          padding-left:10px;
          font-size: 18px;
          font-weight: 300;

          &:focus{
            border:1px solid $E_blue;
          }

          &.red{
            border-color: red !important;
          }

        }
        p.error{
          position:absolute;
          bottom: -20px;
          color: red;
          font-weight: 300;
          font-size: 12px;

        }
      }

      .buttons{
        display: flex;
        align-items: center;

        p.confirmation{
          color: $E_blue;
          font-weight: 300;
        }



        .cancelBtn,
        .submitBtn{
          height: 40px;
          width: 140px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          cursor: pointer;
          transition: .15s all ease-out;
          margin-right: 15px;
          opacity: .6;
          pointer-events: none;

          img{
            height: 40px;
          }

          &.loading{
            pointer-events: none;
            background-color: $E_blue !important;
          }

        }


        &.active{

          .cancelBtn,
          .submitBtn{
            opacity: 1;
            pointer-events: all;
          }
        }

        .cancelBtn{
          color: $E_blue;
          background-color: $E_blue_light;

          &:hover{
            background-color: $E_blue_light2;
          }
        }

        .submitBtn{
          color: white;
          background-color: $E_blue;

          &:hover{
            background-color: $E_blue_dark;
          }
        }
      }

      .emailsDiv{
        margin-top: 50px;

        .emails{
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          margin-top: 30px;

        }
      }

          
      .emailDiv{
        height: 45px;
        width: fit-content;
        padding:0 20px;
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        box-sizing:border-box;
        padding:0 30px 0 15px;
        border-radius: 5px;
        background-color: #f2f2f2;
        margin-right: 10px;
        margin-bottom: 10px;

        &.inactive{
          pointer-events: none;
        }

        *{
          pointer-events: none;
        }

        img{
          height: 15px;

          &.copy, 
          &.plus{
            transition: .2s all ease-out;
            position: absolute;
            right: 15px;
            margin-left: 10px;
          }
        }
        p{
          color: #858585;
          font-size: 16px;
          font-weight: 300;
          margin: 0;
          margin-left: 15px;
          width: 135px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          &.conf{
            // color: red;
            font-size: 19px;
            text-align: center;
            font-weight: 500;
            position: relative;
            right: 7px;
          }
        }

        &:hover{
          background-color: #e8e8e8;
          .copy{
            opacity: 1;
          }

          //class passed to <Tooltip/> component through props
          .tooltipHover{
            opacity: 1 !important;
            top: 40px !important;

            p{
              color: #858585 !important;
            }
          }
        }

        &.addEmail{
          color: $E_blue;
          background-color: $E_blue_light;
          position: relative;
          padding:1px 35px 0 20px;

          &:hover{
            background-color: $E_blue_light2;
          }
        }
      }

      .quickDiv{
        margin-top: 50px;
        padding-bottom: 50px;
        // border:1px solid red;
        
        .inner{
          margin-top: 30px;
          width: 550px;
          border-radius: 10px;
          overflow: hidden;
          -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 116, 240, 0.25);
          -moz-box-shadow: 0px 0px 15px 0px rgba(0, 116, 240, 0.25);
          box-shadow: 0px 0px 15px 0px rgba(0, 116, 240, 0.25);

          .top,
          .bottom{
            box-sizing:border-box;
            padding:20px 35px;
          }

          .top{
            background-color: #05313F;
            p{
              color: white;
              font-size: 18px;
              font-weight: 300;
            }
          }
          .bottom{
            display: flex;
            align-items: center;
            justify-content: space-between;

            p.link{
              width: calc(100% - 130px);
              overflow: hidden;
              text-overflow: ellipsis;
              color: $E_blue;
              white-space: nowrap;
            }

            .copyLink{
              width: 110px;
              height: 40px;
              text-align: center;
              line-height: 40px;
              color: $E_blue;
              background-color: $E_blue_light;
              cursor: pointer;
              transition: .15s all ease-out;
              border-radius: 8px;

              &:hover{
                background-color: $E_blue_light2;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width:660px){
    flex-direction: column;

    .left,
    .right{
      width: 100%;
    }

    .left{
      min-height: fit-content;
      height: fit-content;
      padding:80px 20px 10px 20px;

      .avatarDiv{
        margin-top: 25px;
        flex-wrap: wrap;
        height: 75px;

        .content{
          width: calc(100% - 80px);
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          .text{
            flex-direction: row;
            height: 30px;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            margin-bottom: 0px;
            left:0 !important;

            .bucks{
              margin-top: 1px;
              margin-left: 20px;
            }
          }
        }


        img{
          margin-left: -30px;
          height: 125px;
          position: relative;
          bottom: 24px;
          left: 10px;
        }
      }

      .generateBtn{
        margin-top: 5px;

        &.main{
          display: none;
        }

        &.res{
          display: initial;
        }
      }

      h3.title{
        margin:35px 0 20px 0;
        padding: 0;
        width: 100%;
      }

      .classesDiv{
        padding: 0;
        .classItem{
          width: 100%;
          padding:15px 10px;

          .oval{
            margin-bottom: 10px;
          }
        }
      }
    }

    .right{
      padding:40px 20px;
      .content{
        width: 100%;
        padding: 0;

        label{
          width: 100%;
        }
      }

      .quickDiv{

        .inner{
          width: 100% !important;

          .top{
            p{
              font-size: 15px !important;
            }
          }
        }
      }
    }
  }
  
}