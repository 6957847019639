@import '../../../../shared.scss';


.container{
  height: 100%;
  width: 85px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: 1px solid white;
  position: relative;
  background-color: white;

  .items,
  .viewRadios{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding:8px 0;
  }

  .items{
    // overflow: auto;
    padding-bottom: 20px;
    padding-top: 5px;
  }
  
  .viewRadios{
    padding:0px 0px;
    height:fit-content;
    box-sizing:border-box;
    // border-bottom: 1px solid #cacaca;
    background-color: white;
    height: 143px;

    .sidebarItem{
      min-height: 75px;
      max-height: 75px;
      &::before{
        content: "";
        height: 73px;
        width: 83px;
        position: absolute;
        top: -5px;
        left: -8px;
        z-index: 1 !important;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      &:hover{
        background-color: transparent;
        &::before{
          background-color: #C4E7F5;
        }
      }

    }
  }

  input[type=radio]{
    display: none;
  }


  // states of top 2 sidebar items (view radios) when checked
  input:checked + .sidebarItem{

    &.responses,
    &.details{
      &::before{
        background: rgb(46,102,174);
        background: linear-gradient(270deg, rgba(46,102,174,1) 0%, rgba(12,167,216,1) 87%);
      }


      &::after{
        content:"";
        height: 55px;
        width: 5px;
        background-color: #FFA826;
        position: absolute;
        left: -8px;
        top: 5px;
        z-index: 10;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }

    p{
      color:white
    }

    &.responses{
      .icon{
        background: url('../../../../assets/sidebarIcons/sidebar_radio_response_white.svg') no-repeat center;
      }
    }

    &.details{
      .icon{
        background: url('../../../../assets/sidebarIcons/sidebar_radio_details_white.svg') no-repeat center;
      }
    }
  }


  .sidebarItem{
    min-height: 60px;
    max-height: 60px;
    width: 63px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .3s all ease-out;
    margin: 3px 0;
    // background-color: #CDE4EC;
    position: relative;
    // border:1px solid red;

    *{
      position: relative;
      z-index: 5;
    }


      &:hover{
        background-color: #B5D8E3;
      }

      &.active{
        background-color: #077CA0 !important;
        p{
          color: white;
        }
      }
    
      &.settingsItem{
        // background-color: white;

        &:hover{
          background-color: #CDE4EC;
        }

        .greenPill{
          position: absolute;
          top: -3px;
          right:-1px;
          background-color: $main-green;
          padding:0 6px;
          height:20px;
          border-radius: 8px;
          display: flex;
          align-items: center;
          opacity: 0;
          transition: .2s all ease-out;

          &.display{
            opacity: 1;
          }

          p{
            color: white;
            font-size: 14px;
            font-weight: 700;
            margin: 0;
            position: relative;
            top:1px
            
          }

        }
      }

      &.disabled{
        opacity: .5;
        pointer-events: none;
      }

      &.hidden{
        display: none;
      }

      .icon{
        height: 23px;
        // border:1px solid chartreuse;
        width: 30px;
        margin-bottom: 5px;
      }

      p{
        font-size: 11px;
        font-weight: 500;
      }

      &.responses{
        .icon{
          background: url('../../../../assets/sidebarIcons/sidebar_radio_response_blue.svg') no-repeat center;
        }
      }

      &.details{
        .icon{
          background: url('../../../../assets/sidebarIcons/sidebar_radio_details_blue.svg') no-repeat center;
          background-size: contain !important;
        }
      }

      &.deadline{
        .icon{
          background: url('../../../../assets/sidebarIcons/sidebar_calendar_blue.svg') no-repeat center;
        }
        &.active{
          .icon{
            background: url('../../../../assets/sidebarIcons/sidebar_calendar_white.svg') no-repeat center;
          }
          
        }
      }

      &.wordbank{
        .icon{
          background: url('../../../../assets/sidebarIcons/sidebar_wordbank_blue.svg') no-repeat center;
        }
        &.active{
          .icon{
            background: url('../../../../assets/sidebarIcons/sidebar_wordbank_white.svg') no-repeat center;
          }
          
        }
      }

      &.upload{
        .icon{
          background: url('../../../../assets/sidebarIcons/sidebar_upload_blue.svg') no-repeat center;
        }
        &.active{
          .icon{
            background: url('../../../../assets/sidebarIcons/sidebar_upload_white.svg') no-repeat center;
          }
          
        }
      }

      &.grammar{
        .icon{
          background: url('../../../../assets/sidebarIcons/sidebar_grammar_blue.svg') no-repeat center;
        }
        &.active{
          .icon{
            background: url('../../../../assets/sidebarIcons/sidebar_grammar_white.svg') no-repeat center;
          }
          
        }
      }

      &.rooms{
        .icon{
          background: url('../../../../assets/sidebarIcons/sidebar_rooms_blue.svg') no-repeat center;
        }
        &.active{
          .icon{
            background: url('../../../../assets/sidebarIcons/sidebar_rooms_white.svg') no-repeat center;
          }
          
        }
      }

      &.grading{
        .icon{
          background: url('../../../../assets/sidebarIcons/sidebar_grading_blue.svg') no-repeat center;
        }
        &.active{
          .icon{
            background: url('../../../../assets/sidebarIcons/sidebar_grading_white.svg') no-repeat center;
          }
          
        }
      }

      &.peer{
        .icon{
          background: url('../../../../assets/sidebarIcons/sidebar_voting_blue.svg') no-repeat center;
        }
        &.active{
          .icon{
            background: url('../../../../assets/sidebarIcons/sidebar_voting_white.svg') no-repeat center;
          }
          
        }
      }

      &.private{
        .icon{
          background: url('../../../../assets/sidebarIcons/sidebar_private_blue.svg') no-repeat center;
        }
        &.active{
          .icon{
            background: url('../../../../assets/sidebarIcons/sidebar_private_white.svg') no-repeat center;
          }
          
        }
      }
  }
}