@import '../../../../../shared.scss';

.container{
  height: 100%;
  width: 63%;
  min-width: 450px;
  position: relative;



  .entryDiv{
    box-sizing:border-box;
    padding:15px 40px;
    height: 100%;

    .detailsBar{
      display: flex;
      align-items: center;
      justify-content: space-between;

      h5{
        font-size: 12px;
        font-weight: 500;
        color: #4e4e4e;
        margin: 0 0 8px 0;
      }

      h3{
        font-size: 18px;
        color: #4e4e4e;

        span{
          display: inline-flex;
          background-color: #CDDBDF;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          height: 20px;
          width: 20px;
          position: relative;
          bottom: 2px;
          margin-right: 3px;
          p{
            font-size: 12px;
            margin:0
          }
        }
      }

      .wordsGrade{
        display: flex;
        align-items: center;
        justify-content: flex-end;

        p{
          font-size: 13px;
          color: #4a4a4a;
          font-weight: 300;

          strong{
            font-weight: 700;
          }
        }

        .grade{
          display: flex;
          align-items: center;
          margin-left: 20px;
          position: relative;
          bottom: 2px;
          h5{
            font-size: 17px;
            color: $main-blue--bright;
            margin: 0;
          }

          .gradeDiv{
            margin-left: 10px;
            height: 35px;
            width: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            border: 2px solid $main-blue--bright;
          }
        }
      }
    }

    .editorDiv{
      width: 100%;
      height: calc(100% - 200px);
      position: relative;

      .privateModeOverlay{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.515);
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        z-index: 20;

        *{
          text-align: center;
        }

        img{
          height: 65px;
        }

        h1{
          font-size: 23px;
          font-weight: 700;

          span{
            font-size: 18px;
            color: white;
            background-color: $E_green;
            padding:5px 10px 3px 10px;
            border-radius: 10px;
            font-weight: 700;
            position: relative;
            bottom: 2px;
          }
        }

        p{
          width: 70%;
          color: #858585;
          font-weight: 300;
        }

      }
    }

    .buttons{
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 80px;
      width: 100%;

      .rightBtns{
        width: 350px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: relative;
        z-index: 20;

        .cancelBtn{
          font-size: 13px;
          color: #077CA0;
          cursor: pointer;
          position: relative;
          right: 15px;
          font-weight: 300;
          text-decoration: underline;
        }

        .editBtn,
        .approveBtn{
          height: 40px;
          width: 130px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: #E5F2F6;
          box-sizing:border-box;
          padding:0 15px;
          cursor: pointer;
          transition: .2s all ease-out;
          border-radius: 5px;
          position: relative;
          z-index: 20;

          &:hover{
            background-color: #B5D8E3;
          }

          p{
            font-size: 15px;
            color: #077CA0;
            position: relative;
            bottom: 1px;
          }

          &.disabled{
            opacity: .4;
            pointer-events: none;
          }
        }
        
        .approveBtn{
          width: 160px;
          background-color: #077CA0;
          margin-left: 10px;
          &.disabled{
            opacity: 1 !important;
            background-color: #c6e6f0;
            pointer-events: none;
          }
          
          p{
            color: white;
          }

          &:hover{
            background-color: rgb(8, 94, 121);
          }
        }
      }
    }
  }

  &.synod{
    .buttons{
      display: none;
    }
  }
  
}