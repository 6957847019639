@import '../../../shared.scss';

.container{
    max-height: 98vh;
    overflow: scroll;
    width: 100%;
    margin:0 auto;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    box-sizing:border-box;
    padding:20px;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;

          
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #cacaca;
      border:1px solid #cacaca;
    }

    .studentRow{
      width: 800px;
      min-height:60px;
      max-height:60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 0 15px;
      border:1px solid #cacaca;
      background-color: #ffffff;
      margin-bottom: 5px;

      .buttons{
        display: flex;

        .coverBtn{
          @include mainButton(white, $main-blue--bright, 12px, 30px, auto);
          padding: 0 10px;
          &:hover{
            border-color: $main-blue--bright
          }
        }

        .contentBtn{
          @include mainButton(white, $main-blue--light, 12px, 30px, auto);
          padding: 0 10px;
          &:hover{
            border-color: $main-blue--light
          }
          margin-left:20px;
        }
      }
    }
}