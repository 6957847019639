@import '../../../../shared.scss';

.container{
  transition: .3s all ease-out;

  &.main{
    height: 730px;
    width: 85vw;
  }

  &.sml, 
  &.sent{
    display:flex;
    justify-content: center;
    align-items: center;
    height:290px;
    width:575px;
    transition: .5s all ease-out;
  }

  .previewDiv{
    height: 600px;
    width: 460px;
  }

  &.sent{
    height:240px;
    width:500px;
  }

  text-align: left;
  *{font-family: 'Sofia pro'}
  
  h1{
    font-size: 26px;
    text-align: center;
  }
  h3{
    font-size: 18px;
    color:#333333;
    font-weight: 700;
  }
  h5{
    margin:0;
    font-size: 14px;
    color:#858585;
    font-weight: 500;
    width:100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .yellowBox{
    margin-top:20px;
    background-color: $main-yellow;
    width:100%;
    box-sizing:border-box;
    padding:10px 25px;
    border-radius: 8px;

    p{
      margin:0;
      font-weight: 300;
      font-size: 16px;
      line-height: 22px;
      color:white;
    }
  }

  .main{
    height:90%;
    width:100%;
    display: flex;
    justify-content: space-between;
    // border:1px solid chartreuse;

    p{
      font-size: 15px;
      color:#333333;
      line-height: 24px;
      font-weight: 300;
    }

    .left, .right{
      height:100%;
      box-sizing:border-box;
      padding:20px 0 0 0;
    }

    .left{
      width: 75%;
      padding:30px 0 0 0;
      p,h3, h6{
        padding-left: 25px;
        padding-right: 30px;
      }
      h3{
        margin:15px 0px;
      }

      .tableContainer{
        height:530px;
        width:100%;
        border:1px solid #cacaca;
        border-radius: 10px;
        overflow: hidden;

        .column{
          display: flex;
          justify-content: center;
          align-items:center;
          line-height: 30px;
          height: 100%;
          width:30%;

          &.left{
            justify-content: flex-start;
            align-items:center;
            padding:0;
          }

          &:first-of-type{
            width:10%;
          }
          // &:nth-of-type(2){
          //   width:35%
          // }
          // &:last-of-type{
          //   width: 50%;
          // }

          h6{
            line-height: 33px;
            margin:0;
            font-size: 11px;
            color:#858585;
          }

          p{
            font-size: 14px;
            color:#858585;
            font-weight: 500;
            width: 100%;
            text-align: center;
          }
        }

        .tableTitle{
          height:40px;
          text-align: center;
          h3{
            line-height: 40px;
          }
        }

        .tableHeader{
          display: flex;
          height: 30px;
          line-height: 30px;
          background-color: #f2f2f2;
          border-top:1px solid #c4c4c4;
          border-bottom:1px solid #c4c4c4;
        }
        .tableBody{
          height:calc(100% - 75px);
          overflow: auto;
        }

        .tableRow{
          width:100%;
          height:50px;
          display: flex;
          border-bottom:1px solid #c4c4c4;

          img{
            margin-left: 10px;
            height:45px;
            width:50px;
            object-fit: contain;
          }

          .column{
            p{
              width:90%;
              color:#2b2b2b;
              font-weight: 300;
              line-height: 50px;
              font-size: 15px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
        .singleMail,
        .singlePill,
        .multipleMail,
        .noMail{
          position: relative;
          height:30px;
          line-height: 30px;
          text-align: center;
          width: 90%;
          box-sizing:border-box;
          border-radius: 10px;
          border: 1px solid transparent;
          cursor: pointer;

          p{
            line-height: 30px;
            margin:0;
            color: $main-blue--light;
          }

          &:hover{
            border: 1px solid #cacaca;
          }
        }

        .singleMail{
          padding:0 40px 0 35px;
          background: url('../../../../assets/icon-mail--single.svg') no-repeat 10px center;

          &:hover{
            // background: url('../../../../assets/icon-mail--single.svg') no-repeat 10px center,
            // url('../../../../assets/icon-copy.svg') no-repeat 90% center;
            background: url('../../../../assets/icon-mail--single.svg') no-repeat 10px center;
            background-color: #f2f2f2;
          }
        }
        .multipleMail, .singlePill{
          padding:0 40px;
            background: url('../../../../assets/icon-mail--multiple.svg') no-repeat 10px center,
            url('../../../../assets/icon-chevron--grey.png') no-repeat 92% center;
          background-color: #f2f2f2;

          &:hover{
            // background: url('../../../../assets/icon-mail--single.svg') no-repeat 10px center,
            // url('../../../../assets/icon-copy.svg') no-repeat 90% center;
          }
          .emailsDropdown{
            box-sizing:border-box;
            padding:5px;
            width:235px;
            height: fit-content;
            height: -moz-max-content;
            background-color: white;
            // border: 1px solid #cacaca;
            border-radius: 10px;
            text-align: center;
            position: absolute;
            left:10px;
            z-index: 100;
            -webkit-box-shadow: 0px 1px 9px 0px #00000026; 
            box-shadow: 0px 1px 9px 0px #00000026;

            
            .singleMail,
            .multipleMail,
            .noMail{
              width: 100%;
              border-radius: 0px;
              background-color: transparent;

              &:hover{
                border:1px solid transparent;
                background-color: #f2f2f2;
                border-radius: 5px;
              }
            }
            .singleMail{
              h5{
                width:120%;
              }
            }
            .noMail{
              h5{
                padding-left: 10px;
              }

              &.darkText{
                background: url('../../../../assets/icon-mail--add.svg') no-repeat 10px center !important;
                h5{
                  color: #858585 !important;
                }
              }
            }
          }
        }

        .singlePill{
          padding:0 40px;
            background: url('../../../../assets/icon-mail--single.svg') no-repeat 10px center,
            url('../../../../assets/icon-chevron--grey.png') no-repeat 92% center;
          background-color: #f2f2f2;
        }

        .noMail{
          background: url('../../../../assets/icon-mail--add_white.svg') no-repeat 10px center;
          background-color: #98d318a4;
          h5{color: white;}

        }


        .dropDown{
          box-sizing:border-box;
          padding:5px;
          position: absolute;
          width:170px;
          height:fit-content;
          height:-moz-max-content;
          border-radius: 10px;
          background-color: white;
          -webkit-box-shadow: 0px 1px 9px 0px #00000026; 
          box-shadow: 0px 1px 9px 0px #00000026;
          right:0;
          top:30px;
          z-index: 100;

          h5{
            text-align: left;
            padding-left: 10px;
            &:hover{
              background-color: #f2f2f2;
              border-radius: 5px;
            }
          }
        }
      }
    }


    .right{
      width:23%;

      .summary{
        width: 100%;
        height: fit-content;
        filter: -moz-max-content;
        border: 1px solid #cacaca;
        border-radius: 10px;
        overflow: hidden;

        .summaryRow{
          width: 100%;
          height: 35px;
          line-height: 35px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          box-sizing:border-box;
          padding:0 20px;
          background-color: white;
          
          *{
            width: fit-content;
            width: -moz-max-content;
          }

          &:first-of-type{
            background-color: $main-blue;
            h5{
              font-size: 12px;
              font-weight: 500;
              color:white;
            }
          }

          &:last-of-type{
            border-top: 1px solid #cacaca;
          }

          h5{
            font-size: 14px;
            font-weight: 300;
            line-height: 35px;
            color: #2b2b2b;
          }
        }
      }

      .preview{
        overflow: hidden;
        height: 170px;
        width: 99%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        margin-top: 12px;
        position: relative;

        .prevBtn{
          width:180px;
          height: 35px;
          line-height: 35px;
          background-color: #ffffff43;
          color:white;
          font-size: 14px;
          border-radius: 8px;
          text-align: center;
          z-index: 15;
          position: relative;
          transition: .6s all ease-in-out;
          cursor: pointer;

          &:hover{
            text-decoration: underline;
          }
        }
        
        .overlay{
          background-color: #2b4e7baf;
          position: absolute;
          top:0;
          left: 0;
          height: 100%;
          width:100%;
          z-index: 10;
          -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
          border-radius: 8px;

        }

        .prevText{
          position: absolute;
          top:0;
          left: 0;
          height: 100%;
          width:100%;
          box-sizing:border-box;
          padding:15px;
          color:black;
          font-size: 11px;
          z-index: 1;
        }
      }
    }

  }


  .addMailModal{
    height:290px;
    width:575px;
    // border:1px solid chartreuse;

    h3{
      margin:15px 0;
      text-align: center;
      font-size: 26px;
      font-weight: 300;
    }

    input[type="email"]{
      border:1px solid #cacaca;
      border-radius: 5px;
      display:block;
      height:40px;
      line-height:40px;
      box-sizing:border-box;
      padding:0 20px;
      width:70%;
      margin:40px auto 35px auto;

    }
        ::placeholder{
          font-size: 16px;
          color:#858585;
          font-style: italic;
        }

    .checkDiv{
      box-sizing:border-box;
      padding:5px 0 0 0;
      width: 90%;
      margin:0 auto;
      position: relative;
      display: flex;
      align-items: flex-start;

      input[type="checkbox"]{
        cursor: pointer;
        position: relative;
        top:3px;
        height:22px;
        width:22px;
      }
      .circleTick{
        background: url('../../../../assets/icon-circleTick.svg') no-repeat center;
        position:absolute;
        top:-68px;
        right:67px;
        height:25px;
        width:25px;
        background-size: contain;
      }
      p{
        margin-top: 0;
        margin-left: 5px;
        width: 90%;
        font-size: 16px;
        line-height: 25px;
        font-weight: 300;
        color:#333333;
      }

      .errorMsg{
        font-size: 15px;
        position: absolute;
        top:-28px;
        color: red;
        left:27%;
      }
    }


    h4{
      cursor: pointer;
      position: absolute;
      bottom:7px;
      left:20px;
      color:$main-blue;

      &:hover{
        color:$main-blue--bright;
      }
    }
    .divvv{
      border:1px solid chartreuse;
    }
    .save{
      @include solidButton(white, $main-blue--bright, 15px, 35px, 150px)
    }
  }

  .sentModal{
    height:250px;
    width:500px;
    // height:275px;
    // width:530px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    transition: .3s all ease-in-out;

    &.show{
      opacity: 1;
    }
  }

  .bottomBtn, .closeBtn{
    height:40px;
    line-height: 40px;
    text-align: center;
    width:250px;
    background-color: $main-blue--bright;
    color:white;
    border-radius: 5px;
    border:1px solid $main-blue--bright;
    cursor: pointer;
    transition: .4s all ease-out;
    float: right;

    &:hover{
      color:$main-blue--bright;
      background-color: white;;
    }
  }


  
  .btn{
    width:130px;
    left:0;
    right:0;
    margin:auto;
    bottom:30px;
    height:40px;
    line-height: 40px;
    width:100%;
    background-color: $main-blue--bright;
    border:1px solid $main-blue--bright;
    color:white;
    text-align: center;
    border-radius: 8px;
    transition: .3s all ease-out;
    cursor: pointer;
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  
    &:hover{
      color: $main-blue--bright;
      background-color: white;
    }
  
    &.disabled{
      pointer-events: none;
      opacity: .5;
    }
  }
}
