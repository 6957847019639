@import '../../../../shared.scss';

.container{
  position: relative;
  height: 190px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  *{font-family: 'Sofia pro';}

  h1{font-weight: 500;}

  .loaderDiv{
    position: absolute;
    height: 110%;
    width:100%;
    background-color: rgba(255, 255, 255, 0.637);
    display: flex;
    align-items: center;
    justify-content: center;

    &.hidden{
      display: none;
    }

    img{
      height:80px;
      width:80px;
      object-fit: contain;
      z-index: 10;
    }
  }

  p{
    font-size: 14px;
    font-style: italic;
    line-height: 20px;
    color: #858585;
    margin:5px 0 10px 0;
    width: 100%;
    position: relative;
    bottom:10px;
    
    &:nth-of-type(2){
      margin: 10px 0;
      color: $main-orange;
      background-color: #f2f2f2;
      line-height: 37px;
      width: 461px;
      text-align: center;
      border-radius: 10px;
      font-style: italic;
    }
  }

  h1{
    font-size: 27px;
  }

  input[type=text]{
    height:40px;
    line-height: 38px;
    width:100%;
    border-radius: 5px;
    border:1px solid #cacaca;
    margin-top: 10px;
    font-size: 20px;
    padding-left: 10px;
    font-family: "Sofia pro";
    position: relative;
    bottom:15px;

    &::placeholder{
      font-weight: 300;
      color: #cacaca;
    }
  }

  .buttons{
    height:40px;
    width: 100%;
    display: flex;
    justify-content: center;

    .btn{
      position: absolute;
      bottom:-15px;
      right:-20px;
      text-align: center;
      line-height: 36px;
      height:40px;
      width: 100px;
      border-radius: 10px;
      background: url('../../../../assets/icon-arrow--white.svg') no-repeat 85% center;
      box-sizing:border-box;
      padding:0 20px 0 0;
      color: white;
      background-color: $main-blue--bright;
      border:1px solid $main-blue--bright;
      margin-right: 5px;
      cursor: pointer;

      &:hover{
        background: url('../../../../assets/icon-arrow--blue.svg') no-repeat 85% center;
        background-color: white;
        color:$main-blue--bright;
      }

      // &:last-of-type{
      //   color:$main-blue--bright;
      //   background-color: white;

      //   &:hover{
      //     color:white;
      //     background-color: $main-blue--bright;
      //     border:1px solid $main-blue--bright
      //   }
      // }
    }
  }
}