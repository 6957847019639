@import '../../shared.scss';

.container{

  .block{
    position: relative;
    background-color: white;
    text-align: left;
    width:100%;
    box-sizing:border-box;
    padding:20px 40px;
    border-radius: 15px;
    margin-bottom: 20px;

    h4{
      font-size: 16px;
      color:#333333;
      margin: 7px 0;
    }

    p, a{
      font-size: 15px;
      color:#858585;
      font-weight: 300;
      position: relative;

      &.cardNumber{

        // &::before{
        //   content:url('../../../assets/icon-visa--logo.svg');
        //   position: relative;
        //   left:-5px;
        // }
      }
    }

    .row{
      width: 100%;
      height: 25px;
      line-height: 28px;
      display: flex;
      justify-content: space-between;
    }

    
    &.cardDetails{
      transition: .5s all ease-in-out;
      height: 380px;
      overflow: hidden;
      position: relative;

      h4{
        cursor: pointer;
      }

      h4{
        display: flex;
        position: relative;

        .chevron{
          position: relative;
          top:2px;
          display: inline;
          height:20px;
          width: 20px;
          margin-left: 20px;
          background: url('../../assets/icon-chevron-black.png') no-repeat center;
          cursor: pointer;
          transition: .5s all ease-in-out;

          &.open{
            transform:rotate(180deg)
          }
        }
      }

      .form{
        margin-top:25px;

        label, input{
          display: block;
        }
        label{
          padding-left: 15px;
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 5px;
          margin-top: 15px;
        }

        input{
          font-family: 'DM Sans', sans-serif;
          box-sizing:border-box;
          padding-left:20px;
          height: 35px;
          line-height: 35px;
          width: 100%;
          border-radius: 6px;
          border:1px solid #cacaca;


        }
        input[name="number"]{
          background:url('../../assets/icon-visa--card.svg') no-repeat 10px;
          padding-left: 50px;
        }

        input[type=number]::-webkit-inner-spin-button, 
        input[type=number]::-webkit-outer-spin-button { 
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            margin: 0; 
        }

        .bottom{
          display: flex;
          justify-content: space-between;
          label{
            display: block;
          }
          div{
            width: 47%;

          }
        }
      }
      .buttons{
        display:flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top:15px;
        position: relative;

        img{
          height:40px;
          width:40px;
          object-fit: contain;
          position: absolute;
          right:195px;
          top: 0px;
        }
        .conf{
          color:$main-green;
          position: absolute;
          right:195px;
          top: -6px;
          font-size: 14px;
        }
        .error{
          color:red;
          position: absolute;
          right:195px;
          top: -4px;
          font-size: 13px;
        }

        .cancel{
          color:#858585;
          font-size: 14px;
          font-weight: 300;
          cursor: pointer;
          // margin-right: 20px;
        }
        .btn{
          height:35px;
          width:180px;
          text-align: center;
          line-height: 35px;
          cursor: pointer;
          transition: .3s all ease-out;
          border-radius: 8px;
          border:1px solid $main-blue--bright;
          color:white;
          background-color: $main-blue--bright;
          position: relative;
          
          &:hover{
            color:$main-blue--bright;
            background-color: white;
            }
          }
      }
    }
  }
}