@import '../../../shared.scss';

.container{

  position: fixed;
  bottom:15px;
  left:90px;
  z-index: 700;

  *{
    font-family: 'Sofia pro';
    text-align: left;
    box-sizing:border-box;
  }

  .popup{
   
    width:380px;
    height: 250px;
    border-radius: 10px;
    overflow: hidden;
    background-color: white;
    transition: all .4s ease-in-out;
    // -webkit-box-shadow: 1px 1px 8px 2px rgba(3, 175, 255, 0.35);
    // -moz-box-shadow: 1px 1px 8px 2px rgba(3, 175, 255, 0.35);
    // box-shadow: 1px 1px 8px 2px rgba(3, 175, 255, 0.35);
    position: relative;
    bottom: 3px;

    &.closed{
      height: 0 !important;
      -webkit-box-shadow:none;
      -moz-box-shadow:none;
      box-shadow:none;
    }

    h3{
      font-size: 18px;
      color: black;
      font-weight: 500;
    }

    p{
      font-size: 14px;
      color: black;
      font-weight: 300;
      margin:0;

      span.checkMail{
        color:$main-orange;
        font-weight: 700;
      }
    }

    i{
      font-size: 12px;
      font-weight: 300;
      line-height: 18px;
    }

    .inner{
      width:380px;
      height: 250px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      box-sizing:border-box;
      padding:20px 30px;
      position: relative;



      .email{
        width: fit-content;
        background-color: #f2f2f2;
        padding:3px 10px;
        border-radius: 5px;
        position: relative;
        left:-8px;

        p{ 
          font-size: 14px;
          max-width: 260px;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
        }
    
          span.changeEmail{
            position: absolute;
            right: -76px;
            color: $E_blue;
            font-size: 11px;
            bottom:9px;
            cursor: pointer;

            &:hover{
              text-decoration: underline;
            }
    
          }

      }


      .sentModal{
        height:100%;
        width:100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        transition: .3s all ease-in-out;
      }
    }

    .buttons{
      display: flex;
      justify-content: space-between;
      position: relative;
      top:5px;

      .resendBtn{
        @include mainButton($E_blue, white, 14px, 35px, 125px);
        line-height:32px;
      }
      // .sendBtn{
      //   @include solidButton(white, $E_blue, 14px, 35px, 190px);
      //   line-height:32px;
      // }
    }
  }

  .popper{
    height:35px;
    width:180px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #094B60;
    border-radius: 8px;
    position: relative;
    cursor: pointer;

    img{
      position: relative;
      right: 8px;
      transform: rotate(0deg);
      height:13px;
      width:13px;
      object-fit: contain;
      transition: .3s all ease-in-out;
    }

    &.closed > img{
        transform: rotate(180deg);
    }

    
    h4{
      position: relative;
      bottom:1px;
      color:white;
      font-size: 16px;
    }
  }

}