@import '../../../../shared.scss';

.container{

  transition: .25s all ease-in-out;

  h1{
    font-size: 27px;
    color: #333333;
  }
  h2{
    font-size: 22px;
    font-weight: 500;
    color: #333333;
  }

  .unavailable{
    opacity: .7;
    pointer-events: none !important;
  }


  &.init{
    overflow: hidden;
    height: 510px;
    width: 970px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .loaderDiv{
      margin-top: 220px;
      img{height: 70px}
    }

    .titleBar{
      overflow: hidden;
      width:540px;
      height: 55px;
      border-radius: 10px;
      -webkit-box-shadow: 1px 1px 8px 2px rgba(3, 19, 255, 0.2);
      -moz-box-shadow: 1px 1px 8px 2px rgba(3, 19, 255, 0.2);
      box-shadow: 1px 1px 8px 2px rgba(3, 19, 255, 0.2);
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      top: 3px;
      box-sizing:border-box;
      padding:0 30px;

      h2{
        position: relative;
        bottom:-2px;
        max-width: 82%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height:30px;
        // border:1px solid chartreuse;
      }

      .colorTab{
        position: absolute;
        left:-5px;
        top:0;
        bottom:0;
        margin:auto 0;
        content:'';
        width:10px;
        height:30px;
        border-radius: 10px;
        
        &.story{
          background-color: $main-green;
        }
        &.journal{
          background-color: $main-yellow;
        }
        &.assignment{
          background-color: $main-yellow;
        }
        
      }

      .typePill{
        font-size: 14px;
        color:white;
        height: 20px;
        line-height: 18px;
        width: 80px;
        border-radius: 20px;
        text-align: center;
        
        &.story{
          background-color: $main-green;
        }
        &.journal{
          background-color: $main-yellow;
        }
        &.assignment{
          background-color: $main-yellow;
        }
        
      }
    }

    .options{
      text-align: left;
      width: 900px;
      height: 350px;
      margin-top: 20px;
      // border:1px solid chartreuse;
      h1{
        padding-left: 30px;
      }
      .radios{
        display: flex;
        align-items: flex-start;
        justify-content: space-evenly;
        width: 100%;
        margin-top: 20px;
        // border:1px solid chartreuse;
        height: 300px;

        label{
          position: relative;
        }

        label>.bestOption{
          position: absolute;
          top:-17px;
          right:-21px;
          z-index: 100;
        }

        label>input+div.option{
          input{display: none}
          display: flex;
          flex-direction: column;
          align-items: center;
          overflow: hidden;
          height: 230px;
          width: 204px;
          border-radius: 10px;
          -webkit-box-shadow: 1px 1px 8px 2px rgba(3, 19, 255, 0.2);
          -moz-box-shadow: 1px 1px 8px 2px rgba(3, 19, 255, 0.2);
          box-shadow: 1px 1px 8px 2px rgba(3, 19, 255, 0.2);
          cursor: pointer;
          margin: 3px;
          transition: .3s height ease-in-out;

        

          .imageDiv{
            margin-top: 15px;
            height: 190px;
            width: 150px;
            // border:1px solid chartreuse;
            display: flex;
            align-items: center;
            justify-content: center;
          } 
          img{
            position: relative;
            bottom:5px;

            &.story{
              margin: 15px 0 10px 0;
            }
          }
          
          .price{
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            bottom:10px;
            width: 85%;
            // border:1px solid chartreuse;

            h3{
              color: $main-blue;
              font-size: 18px;

              &:last-of-type{
                font-size: 25px;
                position: relative;
                bottom:2px;
                // color: red;
              }
            }
          }

          .copies{
            opacity: 0;;
            position: relative;
            right: 3px;
            bottom: 5px;
            height: 30px;
            width: 150px;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            transition: .1s all linear;


            p{
              color:$main-blue;
              font-size: 13px;
              font-weight: 300;
            }

            .copyCount,
            .button{
              height:26px;
              width:26px;
              display: flex;
              line-height: 20px;
              justify-content: center;
              border-radius: 5px;
            }

            .copyCount{
              color:$main-blue;
              font-size: 13px;
              font-weight: 300;
              border:1px solid #cacaca;
              line-height: 25px;
            }

            .button{
              background-color: $main-blue;
              font-size: 30px;
              color: white;
            }

          }
        }

        label>input:checked+div.option{
          border:3px solid $main-green;
          margin: 0;
        }

        label:nth-of-type(1)>input+div.option{  //Digital option
          
          img{
            margin: 20px 0 35px 0;

            &.story{
              margin: 40px 0 40px 0;
            }
          }
          .price{
            width: 70%;
          }
        }

        label:nth-of-type(2)>input+div.option{ //paperback option
          img{
            top: 5px;
            right: 10px;

            &.story{
              top: -5px;
            }
          }
        }
        label:nth-of-type(2)>input:checked+div.option{ //paperback option, checked
          height: 265px;

          .copies{
            opacity: 1;
          }
        }
        label:nth-of-type(3)>input:checked+div.option{ //bundle option, checked
          height: 265px;

          .copies{
            opacity: 1;
          }
        }
        label:last-of-type>div.option{ // bundle option
          width: 380px;

          img{
            position: relative;
            left: 10px;
            top:5px;
            &.story{
              left: -15px;
              top:0;
            }
          }
        }
      }
    }

    .textRow{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      
      h4.smallPrint{
        font-size: 15px;
        font-weight: 300;
        color: #858585;
        text-align: left;
        background: url('../../../../assets/icon-warning--grey.svg') no-repeat left center;
        padding-left: 25px;
        position: relative;
        left:50px
      }

      h4.download{
        font-size: 16px;
        font-weight: 500;
        color: #858585;
        text-align: right;
        padding-left: 25px;
        padding-right: 70px;

        span{
          font-size: 18px;
          color: $main-blue--bright;
          text-decoration: underline;
          cursor: pointer;
        }

      }

    }

    .nextBtn{
      background: url('../../../../assets/icon-arrow--white.svg') no-repeat right 15px center;
      padding-right: 5px;
      margin: 10px 0 5px 0;
      position: relative;
      top:6px;

      &:hover{
        background: url('../../../../assets/icon-arrow--blue.svg') no-repeat right 15px center;

      }
      @include solidButton(white, $main-blue--bright, 17px, 40px, 150px);
    }
  }

  &.delivery{
    width: 520px;
    height: 420px;
    padding: 0px 30px;
  }

  .form{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 300px;
    margin-top: 25px;;

    label{
      display: block;
      font-size: 14px;
      color:#333333;
      margin:5px 0;
      padding-left: 5px;
    }

    input{
      width:100%;
      border:1px solid #cacaca;
      border-radius: 4px;
      box-sizing:border-box;
      padding:0 0 0 10px;
      height: 40px;
      line-height: 42px;
      font-size: 14px;
    }
    select{
      margin: 0 !important
    }
  }

  .form+.buttons{

    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    position: relative;
    // border:1px solid chartreuse;
    top:10px;

    
    p.error{
      line-height: 25px;
      color:#D00000;
      font-size: 12px;
      position: absolute;
      right:135px;
      top:6px;
    }

    h4{
      cursor: pointer;
      position: relative;
      top:1px;
      // position: absolute;
      // bottom:7px;
      // left:20px;
      color:$main-blue--bright;

      &:hover{
        text-decoration: underline;
      }
    }


    .nextBtn{
      background: url('../../../../assets/icon-arrow--white.svg') no-repeat right 15px center;
      padding-right: 5px;
      margin: 10px 0 5px 0;
      position: relative;
      // top:15px;
  
      &:hover{
        background: url('../../../../assets/icon-arrow--blue.svg') no-repeat right 15px center;
  
      }
      @include solidButton(white, $main-blue--bright, 17px, 40px, 110px);
    }

  }
  
  &.confirm{

    box-sizing:border-box;
    padding:10px 30px;
    width: 520px;
    height: 420px;
    overflow: hidden;
    transition: all .3s ease-in-out;
    
    &.paymentConfirmation{
      height: 240px;
      width: 420px;
    }
    
    .loaderDiv{
      height: 400px;
      width:100%;
      display: flex;
      align-items: center;
      justify-content: center;
      img{height: 70px}
    }

    h1{
      font-size: 27px;
      color: #333333;
    }

    h3{
      font-size: 18px;
      color: #333333;
    }
    .summary{
      height: 180px;
      width: 100%;
      // border:1px solid chartreuse;

      .confirmTopBar{
        margin: 25px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing:border-box;
        padding:0 20px;
        position: relative;
        overflow: hidden;
        left: -5%;
        height: 65px;
        border-radius: 10px;
        width: 110%;
        -webkit-box-shadow: 1px 1px 8px 2px rgba(3, 19, 255, 0.2);
        -moz-box-shadow: 1px 1px 8px 2px rgba(3, 19, 255, 0.2);
        box-shadow: 1px 1px 8px 2px rgba(3, 19, 255, 0.2);

        h4{
          font-size: 16px;
          color: #858585;

          &:last-of-type{
            font-weight: 300;
          }
        }

        .colorTab{
          position: absolute;
          left:-9px;
          top:0;
          bottom:0;
          margin:auto 0;
          content:'';
          width:15px;
          height:40px;
          border-radius: 10px;
          background-color: $main-blue--bright;
          
        }
      }

      .address{
        margin-top: 5px;
        display: flex;
        align-items: center;
        p{
          font-size: 15px;
          color:#858585;
          font-weight: 300;

          &.edit{
            margin-left: 10px;
            cursor: pointer;
            color:$main-blue--bright;

            &:hover{
              text-decoration: underline;
            }
          }

        }
      }
    }

    .confirmBtn{
      @include solidButton(white, $main-blue--bright, 17px, 40px, 360px);
      margin: 0 auto;
      position: relative;
      top:5px;
    }


  .errorMsg{
    margin:7px 0 0 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color:red;
    font-weight: 300;
    font-size: 14px;
  }

  .loadingOverlay{
    position: absolute;
    width: 520px;
    height: 420px;
    top:30px;
    left:60px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
    visibility: hidden;

    &.show{
      visibility: visible;
    }
    p{
      margin: 0;
      position: relative;
      top:-10px;
    }
  }

  .confMsg{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 15px;
    height:100%;
    width:100%;
    position: absolute;
    top:0;
    left:0;
    background-color: white;
    visibility: hidden;

    img{
      height: 60px;
      margin-bottom: 10px;
    }

    h2{
      font-size: 26px;
      font-weight: 500;
    }

    p{
      font-size: 16px;
      font-weight: 500;
      margin: 15px 0 0 0;

      span{
        color:$main-blue--bright;
        text-decoration: underline;
        cursor: pointer;
      }

      &:last-of-type{
        margin-bottom: 60px;
      }
    }

    .closeBtn{
      @include solidButton(white, $main-blue--bright, 15px, 35px, 150px);
      position: absolute;
      bottom: 25px;
    }
    
      &.show{
    visibility: visible;
  }
}
  //   .confMsg{
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     flex-direction: column;
  //     border-radius: 15px;
  //     height:520px;
  //     width:100%;
  //     position: absolute;
  //     top:0;
  //     left:0;
  //     background-color: #F0F4F6;
  //     visibility: hidden;

  //     h4{
  //       font-size: 22px;
  //       margin:8px 0;
  //     }
  //     p{
  //       margin:8px 0;
  //     }
      
  //       &.show{
  //     visibility: visible;
  //   }
  // }
  }
}