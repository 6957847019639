@import '../../../shared.scss';

.container{
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.823);
  position: absolute;
  top:0;
  left:0;
  // pointer-events: none;
  z-index: 100;

  &.contained{
    height: 100%;
    width: 100%;
    border-radius: 15px;
  }

  &.transparant{
    background-color: transparent;
  }

  img{
    position: relative;
    left: -5px;
    width: 110px;
    .sml{
      width: 130px;
    }
  }

  h5{
    margin:0;
    position: relative;
    bottom: 12px;
    font-weight: 300;
    font-family: 'Sofia pro';

    &.blue{
      color: $E_blue;
    }
  }


  &.blueBg{
    background-color: #E5F2F6;

    h5{
      color: $E_blue;
    }
  }
}