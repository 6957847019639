@import '../../../../shared.scss';

.container{
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;

  &:hover{
    background-color: #E6F2F6;

    .column{
      .openDiv{
        opacity: 1 !important;
      }
    }
  }

  .column{
    display: flex;
    justify-content: center;
    width: 11%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.wide{
      width:15%
    }

    .commentIcon{
      height:25px;
      width:25px;
      margin-left: 20px;
      background: url('../../../../assets/comment_new_grey.svg') no-repeat center/contain;
    }

    h5{
      font-weight: 300;
      &.res{
        display: none;
      }
    }

    &:first-of-type{
      width: 45%;
      justify-content: flex-start;
      box-sizing:border-box;
      padding-left: 20px;
      cursor: pointer;
      position: relative;

      &:hover{
        background-color: #BADAE5;
      }

      img{
        height: 55px;
        margin-right: 10px;
      }

      .openDiv{
        display: flex;
        align-items: center;
        height: 20px;
        position: absolute;
        right: 30px;
        opacity: 0;
        transition: .15s all ease-out;

        p{
          margin: 0 5px 0 0;
          font-size: 14px;
          color:#074B60;
        }
        .openIcon{
          width: 20px;
          height: 20px;
          background: url('../../../../assets/entry_feather.svg') no-repeat center;
        }
      }
    }

    img.clip{
      margin-left: 5px;
      height: 20px;
    }

    .statusDot{
      height: 18px;
      width: 18px;
      border-radius: 50%;

      &.approved{
        background-color: #97D318;
      }
      &.pendingRes{
        background-color: $main-pink;
      }
      &.submitted{
        background-color: $main-blue--light;
      }
      &.started{
        background-color: $main-yellow;
      }
      &.notStarted{
        background-color: $grey-twotwo;
      }
    }
    
    .approvedSlider{
      height: 22px;
      width: 45px;
      border-radius: 14px;
      background-color: #d2d2d2;
      position: relative;
      cursor: pointer;

      &.disabled{
        pointer-events: none;
        opacity: .6;
      }

      .handle{
        height: 18px;
        width: 18px;
        border-radius: 10px;
        background-color: white;
        position: relative;
        top: 2px;
        left: 2px;
        z-index: 5;
      }
      

      .yes,
      .no{
        font-size: 11px;
        text-transform: uppercase;
        font-weight: 700;
        position: absolute;
        top: 5px;
        z-index: 1;
      }

      .yes{
        left: 4px;
        color: white;
        opacity: 0;
      }

      .no{
        right: 6px;
        color: #858585;
      }



      &.approved{
        background-color: #97D318;

        .handle{
          left: 25px;
        }

        .yes{
          opacity: 1;
        }

        .no{
          opacity: 0;
        }
      }

      *{
        transition: .15s all linear;
      }
    }

    .voted{
      height: 20px;
      width: 20px;
      background: url('../../../../assets/voted_no.svg') no-repeat center;
      
      &.yes{
        background: url('../../../../assets/voted_yes.svg') no-repeat center;
      }
    }

    .noGrade{
      color: #858585;
      font-size: 14px;
    }

    .gradePill{
      height: 25px;
      width: 35px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border:2px solid transparent;

      p{
        margin: 0;
        font-weight: 500;
      }


      &.one{
       border-color: #d00000;
        p{
          color:#d00000;
        }
      }
      &.two{
       border-color: #FF8258;
        p{
          color:#FF8258;
        }
      }
      &.three{
       border-color: #97D318;
        p{
          color:#97D318;
        }
      }
      &.four{
       border-color: #75972D;
        p{
          color:#75972D;
        }
      }
      &.inactive{
        pointer-events: none;
        opacity: .4;
      }
    }    
  }

  @media (max-width:660px){
    box-sizing:border-box;
    padding:0 10px;

    h5{
      margin:0 !important
    }

    .openDiv{
      display: none !important;
    }

    .column{
      padding-left: 0 !important;
      text-align: center;
      width: 22%;
      justify-content: space-evenly !important;


      &:first-of-type{
        width: 35%;
      }

      h5{
        &.hideMobile{
          display: none;
        }
        &.res{
          display: initial;
          white-space: nowrap;
        }
      }

      &:hover{
        background-color: white !important;
      }
    }
    
  }
  &:hover{
    background-color: white !important;
  }


  &.synod{
    .column{
      width: 100px;
      &.hide{
        display: none !important;
      }

      &:first-of-type{
        width: calc(100% - 300px);
      }
  
    }
  }
}