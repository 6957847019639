

  .container{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(128, 128, 128, 0.727);
    z-index: 25;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;

    .imgWrapper{
      height:95%;
      max-width:85%;
      position: relative;
      max-height: 550px;

      .close{
        position: absolute;
        top: -10px;
        right:-10px;
        height: 25px;
        width:25px;
        border-radius: 50%;
        background: url('../../../../../assets/cross_circle_teal.svg') no-repeat center;
        background-size: contain;
        cursor: pointer;
      }

      img{
        height:100%;
        max-width:100%;
        width:auto;
        object-fit: contain;
      }


      .arrow{
        height: 100px;
        width: 35px;
        background-color: rgba(255, 255, 255, 0.344);
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(180deg);
        left: -35px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        cursor: pointer;
        transition: .15s all ease-out;
        -webkit-backface-visibility: hidden;

        &.hidden{
          opacity: 0;
          pointer-events: none;
        }

        .icon{
          height: 20px;
          width: 20px;
          background: url('../../../../../assets/arrow_white.svg') no-repeat center;
          background-size: contain;
          transition: .15s all ease-out;
        }

        &.right{
          right: -35px;
          left: initial;
          transform: translateY(-50%) rotate(0deg);

        }

        &:hover{
          height: 105px;
        }

      }
    }

  }