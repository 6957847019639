@import '../../../../shared.scss';

.container{
  width: 100%;
  height: 100%;
  background-color: #F9F9F9;
  position: relative;

  .leaderboardDiv{
    height: 230px;
    width: 100%;
  }

  .filtersBar{
    background-color: white;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #cacaca;
    width: 100%;
    height: 70px;
    box-sizing:border-box;
    padding:0 10px;
    position: relative;

    .search{
      height: 35px;
      width: 35px;
      border-radius: 20px;
      overflow: hidden;
      background-color: #F2F2F2;
      align-items: center;
      cursor: pointer;
      border:2px solid white;
      transition: .15s all ease-out;
      transition: .4s width ease-in-out;
      position: relative;
      display: flex;
      align-items: center;
      

      .inner{
        height:calc(100% - 2px);
        width:calc(100% - 2px);
        background-color: white;
        border:1px solid  #cacaca;
        border-radius: 20px;
        overflow: hidden;
        position: absolute;
        z-index: 1;
        right: 0;
        display: flex;

      }
      input[type=text]{
        width:calc(100% - 45px);
        height: 30px;
        line-height: 30px;
        border:none;
        position: absolute;
        z-index: 5;
        right: 10px;
      }
      
      .lupa{
        min-height: 33px;
        max-height: 33px;
        min-width: 33px;
        max-width: 33px;
        border-radius: 50%;
        background: url('../../../../assets/icon-lupa.png') no-repeat center;
        transition: .15s all ease-out;
        position: relative;
        z-index: 10;
      }

      &:hover{
        border:2px solid #CDDFFA;

        .inner{
          border-color: #065FE5;
        }
      }



      &.open{
        width: 300px;
        border:2px solid #CDDFFA;
        background-color: white;

        .lupa{
          border-color: white !important;
          background-color: white !important;
        }
        .inner{
          border-color: #065FE5;
        }
      }
    }

    .filterRadios{
      display: flex;
      align-items: center;
      margin-left: 20px;
      width: calc(100% - 185px);
      height: 40px;
      overflow-x: auto;



      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
        height: 7px;
      }
      &::-webkit-scrollbar-track {
        background-color: $E_blue_light;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: $E_blue_light2;
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
      }

      label>input{
        display: none;
      }

      .radio{
        height: 24px;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing:border-box;
        padding:0 10px;
        border:1px solid chartreuse;
        margin-right: 15px;
        cursor: pointer;
        transition: .2s all ease-out;
        background-color: white;

        p{
          margin: 0;
          font-size: 13px;
          font-weight: 500;
          position: relative;
          white-space: nowrap;

          &.count{
            margin-right: 8px;
            font-weight: 700;
            min-width: 10px;
            position: relative;
            top: 1px;
          }
        }

        &.all{
          border-color:$E_blue;
          *{
            color:$E_blue;
          }

          &:hover{
            background-color: #CDE4EC;
          }
        }

        &.approved{
          border-color: #97D318;
          *{
            color: #97D318;
          }

          &:hover{
            background-color: #E9F6D1;
          }
        }

        &.pending{
          border-color: #F26283;
          *{
            color: #F26283;
          }

          &:hover{
            background-color: #FCDFE6;
          }
        }

          &.submitted{
            border-color: #077CA0;
            *{
              color: #077CA0;
            }
  
            &:hover{
              background-color: #CDE4EC;
            }
          }
  

        &.started{
          border-color: #FFA826;
          *{
            color: #FFA826;
          }

          &:hover{
            background-color: #FFE6DE;
          }
        }

        &.not{
          border-color: #858585;
          *{
            color: #858585;
          }

          &:hover{
            background-color: #E6E6E6;
          }
        }
      }

      input:checked + .radio{
        &.all{
          background-color:$E_blue;
        }
        &.approved{
          background-color:#97D318;
        }
        &.pending{
          background-color:#F26283;
        }
        &.submitted{
          background-color:#077CA0;
        }
        &.started{
          background-color:#FFA826;
        }
        &.not{
          background-color:#858585;
        }
        p{
          color: white;
        }
      }
    }

    .approveAll{
      border:1px solid chartreuse;
      height: 40px;
      width: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 10px;
      background-color: #CDE4EC;
      border:1px solid white;
      border-radius: 8px;
      cursor: pointer;
      transition: .2s all ease-out;
      opacity: .6;
      pointer-events: none;

      &.enabled{
        opacity: 1;
        pointer-events: initial;
      }

      p{
        color: #077CA0;
        position: relative;
        bottom: 1px;
      }

      &:hover{
        background-color: #B5D8E3;
      }
    }


    &.synod{
      justify-content: space-between;

      .filterRadios{
        width: fit-content;
      }
    }
  }

  .tableHeader{
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    background-color: #f2f2f2;
    border-bottom: 1px solid #CACACA;

    .column{
      color: #858585;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      width: 11%;
      line-height: 30px;
      font-size: 15px;
      font-weight: 700;
      letter-spacing: 1.04px;
      box-sizing:border-box;
      padding:3px 0 0 0;

      &.wide{
        width:15%
      }

      &:first-of-type{
        width: 45%;
        justify-content: flex-start;
        box-sizing:border-box;
        padding-left: 80px;
      }
    }

    &.synod{
      .column{
        width: 100px;
        &.hide{
          display: none !important;
        }
  
        &:first-of-type{
          width: calc(100% - 300px);
        }
    
      }
    }
  }

  .tableRows{
    background-color: white;
    width: 100%;
    height: calc(100% - 100px);
    overflow: scroll;


    .roomGroup{
      width: 100%;
      display: flex;

      .roomTab{
        width: 30px;
        min-height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .15s all ease-out;
        cursor: pointer;


        p{
          transform: rotate(-90deg);
          color: white;
          white-space: nowrap;
          font-size: 14px;
        }

      }


      &:hover{
        .roomTab{
          width: 35px;
        }
      }

      .rows{
        width: calc(100% - 30px);
      }
    }
    
    &.withLeaderboard{
      height: calc(100% - 263px);
      overflow: visible;
    }
  }


  @media (max-width:660px){
    height: 100%;
    margin-top: 5px !important;

    .filtersBar{
      display: none;
    }

    .tableRows{
      height: calc(100% - 28px);
    }
    .tableHeader{
      border:none;
      .column{
        padding-left: 0 !important;
        font-size: 10px;
        text-align: center;
        justify-content: center !important;
        width: 22%;
  
        &:first-of-type{
          width: 35%;
        }
      }
    }
  }
}