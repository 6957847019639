@import '../../../../shared.scss';

.container{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height:90%;
  position: relative;
  // bottom:5px;
  // padding: 15px 0;;




  input[type='email']{
    width:70%;
    height:40px;
    box-sizing:border-box;
    padding:0 15px;
    border-radius: 8px;
    outline:none;
    // -moz-appearance: none;
    // -webkit-appearance: none;
    border:1px solid #cacaca;
    font-size: 14px;
  }

  .send{
    height:40px;
    width:120px;
    border:1px solid $E_blue;
    border-radius: 8px;
    color: white;
    background-color: $E_blue;
    transition: .3s all ease-out;
    cursor: pointer;
    line-height: 40px;
    text-align: center;
    margin-top: 5px;

    &:hover{
      background-color:$E_blue_dark;
    }
  }
}

  p.error, p.success{
    position: absolute;
    width:250px;
    text-align: center;
    bottom:12px;
    font-weight: 300 !important;
    left:-17px;
    right:0;
    margin: 0 auto;
    font-family: 'Sofia pro';
    font-size: 14px;
  }

  .success{color:$main-green}
  .error{color:red}