@import '../../../../shared.scss';

.container{
  display: flex;
  flex-direction: column;
  align-items: center;

  *{font-family: 'Sofia pro'}

  h1{
    font-size: 30px;
    margin-bottom: 20px;
  }

  p{
    font-size: 16px;
    font-style: italic;
    color:#333333;
    font-weight: 500;
    text-align: center;
    line-height: 23px;
  }
  form{
    width:90%;
    margin:0 auto 20px auto;
  }

  label{
    font-size: 16px;
    font-weight: 500;
    color:#333333;
    width: 100%;
    text-align:left;
    margin-bottom: 4px;
    padding-left: 6px;
  }

  input[type="text"], input[type="email"]{
    width: 100%;
    height:35px;
    line-height: 30px;
    border-radius: 8px;
    border:1px solid #cacaca;
    margin-bottom: 12px;
    box-sizing: border-box;
    padding:5px 0 8px 20px;

  }

  .error{
    position: absolute;
    bottom:50px;
    color: red;
    font-weight: 300;
    font-style: initial;
    font-size: 14px;
    line-height: 30px;
  }

  .btn{
    margin-top: 25px;
    background-color: $main-blue--bright;
    border:1px solid $main-blue--bright;
    color: white;
    height:40px;
    width:250px;
    line-height: 40px;
    text-align: center;
    border-radius: 8px;
    transition: .3s all ease-out;
    cursor: pointer;

    &:hover{
      background-color: white;
      color:$main-blue--bright;
    }
  }

}