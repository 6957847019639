@import '../../shared.scss';

*{
  font-family: "DM Sans", sans-serif ;
}

.sophiaimg{
  object-fit: contain;
  z-index: 10;
}



.arithmetic{
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 0;
}

.arithmeticxx{
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 5%;
  top: 20%;
}

.caution{
  text-align: right;
  color: red !important;
}

.total{
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  font-weight: 700;
  margin-right: 5rem;
  font-size: 1.2rem;
  position: absolute;
  right: 80px;
  color: #333333;
  @media screen and (max-width: 768px) {
      margin: 0;
  }
  span{
      margin-left: 1rem;
  }
}

.between{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
}

.note{
  text-align: left;
  color: #064149;
  margin-top: 2rem;
}

.tophead{
  text-align: left;
  color: #333333;
  margin: 1rem 0;
  font-weight: 600;
}

.totalheader{
  color: #333333;
  font-size: 1.5rem;
}

.price_options{
  padding: 10px;
  color: $faith-blue;
  background: #fff;
  border: 1px solid $faith-blue;
  border-radius: 5px;
  cursor: pointer;
  transition: all;
  font-size: 12px;
  font-weight: 600;
  &:hover{
    background: $faith-blue;
    color: #fff;
  }
}

.edit{
  background: rgba(253, 125, 115, 0.2);
  color: #FD7D73;
  padding: 1rem;
  font-weight: 500;
  border-radius: 10px;
  text-transform: capitalize;
  font-size: 12px;
  cursor: pointer;
  &:hover{
    background: rgba(253, 125, 115, 0.7);
  }
}

.header{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.escribg{
  background-color: #E6F2F6;
}
.fjBg{
  background-color: #FCF2EA;

}
.container{
  box-sizing:border-box;
  padding: 2.6rem 1.4rem;
  padding-bottom: 40px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 0 auto;
  user-select: none;


  svg{
    cursor: pointer;
  }

  .instructions_link{
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin: 1rem 0;
  }


  .table_container{
    background: #fff;
    border-radius: 10px;
    margin-top: 2rem;
    padding: 1rem;
    position: relative;
    .column{
      border: none;
    }
  }

  &::-webkit-scrollbar{
    width: 5px;
  }

  *{
    font-family: 'DM Sans', sans-serif;
  }

  .leftPadding{
    padding-left: 50px !important;
  }

  .titleBar{
    margin-bottom: 20px;
    .heading{
      display: flex;
      align-items: center;
      justify-content: space-between;

      h1{
        margin:0;
      }

      img{
        height: 30px;
        margin-right: 15px;
      }
    }

    p{
      span.price{
        color: $E_blue;
        font-weight: 500;
        font-size: 15px;
      }
    }
  }

  .bottomTable{
    display: flex;
    gap: 2rem;

  }

  .subheading{
    width: 100%;
    p{
      font-weight: 300;
      color: #d50000;
      font-size: 12px;
      margin-top: 35px;
      text-align: left !important;
      width: 86%;
    }
  }

  .block{
    width: 100%;
    border-radius: 10px;
    box-sizing:border-box;
    padding:15px 20px;
    border: 1px solid #E9E9E9;
    background: #fff;
    margin-bottom: 30px;

    h4{
      color: #333333;
      font-size: 14px;
      margin-bottom: 20px;
      text-align: left !important;
    }

    p{
      margin:15px 0;
      font-weight: 300;
      color: #858585;
      font-size: 14px;

      &.italic{
        font-style: italic;
        text-align: left !important;
      }

      &.heavy{
        font-size: 14px;
        margin: 5px 0;
        font-weight: 300;
        color: #333333;
      }

      &.light{
        font-size: 12px;
        margin-top: 5px;
        font-weight: 300;
        color: #858585;
      }

      &.cardNumber{
        color: #333333;
        font-size: 15px;

        span{
          position: relative;
          bottom: 3px;
        }
      }

      &.error{
        margin: 0;
        color: red;
        margin-left: 10px;
      }
      &.ending{
        margin: 0;
        color: red;
        margin-left: 10px;
        font-style: italic;
        text-align: left !important;
      }
      &.confirmation{
        margin: 0;
        color: #97D317;
        margin-left: -5px;
      }
    }
  }
  .left{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 220px;
  }
  .acceptBtn{
    width: 100%;
    background-color: #97D317;
    color: #fff;
    padding: 12px 32px;
    font-size: 14px;
    text-transform: capitalize;
    border-radius: 5px;
    text-align: center;
    display: flex;
    justify-content: baseline;
    align-items: baseline;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover{
      background: none;
      color: #97D317;
      border: 2px solid #97D317;
      transition: all;
    }
  }
  .disabledBtn{
    width: 100%;
    background-color: #808080;
    color: #fff;
    padding: 12px 32px;
    font-size: 14px;
    text-transform: capitalize;
    border-radius: 5px;
    text-align: center;
    display: flex;
    justify-content: baseline;
    align-items: baseline;
    cursor: not-allowed;
    transition: all 0.3s ease;
  }

  .table{
    width: 100%;
    .number{
      position: relative;
    }
    .details{
        background-color: #F2F2F2;
        color: #858585;
        height: 35px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        border-radius: 8px;
    }
    .tableHeader{
      height: 35px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      border-radius: 8px;
      background-color: $faith-blue;
      color: #fff;

      .column{
        h5{
          color: #fff;
          margin:0;
          text-transform: uppercase;
          font-size: 12px;
        }
      }
    }

    .sophia{
      background: #1E9AAA;
    }


    .tableRow{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 2rem;
      

      input{
        border: none;
        font-size: 16px;
        text-align: center;
        color: #1E9AAA;
        width: 80px;
        background: none;
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
             -webkit-appearance: none;
              margin: 0;
      }

      input[type=number] {
          -moz-appearance: textfield;
      }
      img{
        width: 60px;
      }
      .sophiahead{
        color: #1E9AAA;
        font-weight: 600;
        text-transform: capitalize !important;
        margin-right: 20px;

      }

      p{
        color: #858585;
        font-size: 16px;
        font-weight: 300;
        margin:15px 0;

        &.price{
          font-size: 17px;
          color: $E_blue;
          font-weight: 700;

          &.light{
            font-weight: 500;
            font-size: 15px;
          }
        }
      }
    }


    &.current{
      .details,
      .tableHeader,
      .tableRow{
        .column{
          width: 15.5%;
          display: flex;
          justify-content:center;
          
          &:first-of-type{
            justify-content:flex-start;
            box-sizing:border-box;
            padding:0 0 0 20px;
            width: 30%;
          }
         
        }
        .input{
          border: 1px solid #111;
          border-radius: 5px;
          height: 40px;
          width: 100px;
          margin: 0 auto;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
        }
        .input_work{
          height: 40px;
          width: 100px;
          margin: 0 auto;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    &.history{
      .placeholder{
        width: 100%;
        text-align: center;
        line-height: 45px;
        margin:5px 0 0 0;
        color: #cacaca;
        font-weight: 300;
      }
      .tableHeader{
        background-color: #e6e6e6;
        color: #858585  !important;
      }

      .tableHeader,
      .tableRow{
        .column{
          width: 15%;
          display: flex;
          justify-content:center;
          h5{
            color: #858585;
          }

          
          &:first-of-type{
            justify-content:flex-start;
            box-sizing:border-box;
            padding:0 0 0 20px;
            width: 55%;
          }
        }
      }
      .tableRow{
        border-bottom: 1px solid #f2f2f2;
      }
    }

    .statusPill{
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      font-size: 12px !important;
      font-weight: 500 !important;
      margin:0;
      padding:5px 10px;
      border-radius: 5px;
      text-transform: uppercase;

      &.pending{
        color: #FF8258;
        background-color: #FFE6DE;
      }

      &.paid{
        color: #97D318;
        background-color: #EAF6D1;
      }

      &.error{
        color: #FB3333;
        background-color: #FED6D6;
      }
    }
  }

  .totalfooter{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
    color: #858585;
    font-weight: 500;
  }


  .accountSettings{
    background: #fff;

    h4{
      margin-bottom: 5px !important;
    }

    p.italic{
      margin-top: 10px !important;
    }

    .contentSwitch{
      position: relative;
      bottom: 8px;
      height: fit-content;
      min-height: 50px;
      transition: .3s all ease-out;

      .nameRow{
        display: flex;
        align-items: center;
        width: 300px;
        position: absolute;
        top: 0;
        left: 0;
        transition: .3s all ease-out;

        &.hidden{
          opacity: 0 !important;
          pointer-events: none !important;
        }
        
      }
      .inputsDiv{
        margin-top: 25px !important;
        height: fit-content;
        max-height: 200px;
        width: 100%;
        transition: .3s all ease-out;
        text-align: left !important;
        label{
          display: block;
          font-size: 14px;
          margin-bottom: 5px;
          font-weight: 500;
          padding-left: 5px;
          text-align: left !important;

        }
        input{
          width: 500px;
          line-height: 40px;
          border:1px solid #cacaca;
          border-radius: 6px;
          margin-bottom: 15px;
          box-sizing:border-box;
          padding:0 10px !important;
          font-weight: 300;

          @media (max-width:660px){
            width: 100%;
          }
        }

        &.hidden{
          max-height: 0;
          opacity: 0;
          overflow: hidden;
          pointer-events: none;
        }
      }
    }
  }
  .buttons{
    display: flex;
    align-items: center;
    .actionBtn{
      width: fit-content;
      padding:8px 22px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $E_blue_light;
      transition: .15s all ease-out;
      cursor: pointer;
      margin-right: 5px;

      img{
        height: 15px;
        margin-right: 8px;
        position: relative;
        bottom: 1px;
        left: 2px;
      }

      p{
        margin:0;
        font-size: 14px;
        color: $E_blue;
        font-weight: 500;
      }

      &:hover{
        background-color: $E_blue_light2;
      }

      &.update{
        background-color: $E_blue;
        p{
          color: white;
        }
        &:hover{
          background-color: $E_blue_dark;
        }

        &.hidden{
          opacity: 0;
          pointer-events: none;
        }
      }
    }
  }

  .paymentDetails{
    position: relative;
    background: #fff;
    .warningDiv{
      display: flex;
      align-items: center;

      img{
        height: 25px;
        margin-right: 5px;
      }

      p{
        color: red;
      }
    }

    .stripeLogo{
      height: 25px;
      position: absolute;
      top: 10px;
      right: 10px;
    }


    .contentSwitch{
      position: relative;
      bottom: 8px;
      height: fit-content;
      min-height: 50px;
      transition: .3s all ease-out;

  
      .cardNumberDiv{
        display: flex;
        align-items: center;
        box-sizing:border-box;
        padding:0 0 0 5px;
        margin: -10px 0 10px 0;
        position: absolute;
        top: 0;
        left: 0;
        
        img{
          height: 18px;
          margin-right: 10px;
        }
        &.hidden{
          opacity: 0 !important;
          pointer-events: none !important;
        }
      }
      
      .inputsDiv{
        margin-top: 25px !important;
        height: fit-content;
        width: 500px;
        max-height: 200px;
        transition: .3s all ease-out;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        overflow: hidden;

        .smallInputs{
          width: 100%;
          flex-wrap: wrap;
          display: flex;
          justify-content: space-between;

          :first-child{
            margin-right: 15px;
          }

          label{
            width: 100%;
          }
        }

        label{
          display: block;
          font-size: 14px;
          margin-bottom: 5px;
          font-weight: 500;
          padding-left: 5px;
          width: 100%;
          text-align: left !important;
        }

        input{
          width: 130px;
          line-height: 40px;
          border:1px solid #cacaca;
          border-radius: 6px;
          margin-bottom: 15px;
          box-sizing:border-box;
          padding:0 10px !important;
          font-weight: 300;

          &.fullWidth{
            width: 500px;

            @media (max-width:660px){
              width: 100%;
            }
          }
        }

        &.hidden{
          max-height: 0;
          opacity: 0;
          overflow: hidden;
          pointer-events: none;
        }


        @media (max-width:660px){
          width: 100%;
        }
      }
    }
  }

  .cancelBtn{
    font-size: 14px;
    padding:10px 15px;
    border-radius: 8px;
    cursor: pointer;
    color: #FB3333;
    font-weight: 300;
    background-color: #FED6D6;
    width: fit-content;
    margin-bottom: 30px;
    transition: .2s all ease-out;

    &:hover{
      background-color: #ffbbbb;
    }
  }

  .footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "DM Sans", sans-serif;
  }
  
  @media screen and (max-width: 960px ) {
    p{
      font-size: 14px;
    }
  }
  @media (max-width:660px){
    .column{
      width: 33% !important;

      justify-content: center !important;
      
      &.leftPadding{
        padding-left: 0px !important;
      }

      h5{
        font-size: 10px !important;
      }

      p{font-size: 13px !important;}
      *{
        text-align: center !important;
      
      }
      &.hideRes{
        display: none !important;
      }
    }
  }
}

a{
  transition: all 0.3s ease;
  &:hover{
    font-weight: 800;
  }
}

