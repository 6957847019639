@import '../../../shared.scss';

.filtersBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 80px;

  .left {
    display: flex;
    align-items: center;

    h2 {
      font-size: 26px;
      margin-right: 20px;
      width: 120px;
    }

    .inputDiv {
      height: 35px;
      width: 235px;
      display: flex;
      align-items: center;
      background-color: #f2f2f2;
      border-radius: 20px;
      box-sizing: border-box;
      padding: 0 10px;
      position: relative;

      .clear {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        height: 13px;
        width: 13px;
        background:url('../../../assets/close_circle.svg') no-repeat center;
        cursor: pointer;
        background-size: contain;
      }

      .lupa {
        height: 17px;
        width: 20px;
        background: url('../../../assets/lupa_grey.svg') no-repeat center/contain;
      }

      input {
        height: 25px;
        line-height: 25px;
        padding: 0 8px;
        border: none;
        background-color: transparent;
      }
    }
  }

  .filters {
    display: flex;
    align-items: center;
    margin-left: 25px;

    .filtersIcon {
      background: url('../../../assets/filters_black.svg') no-repeat center/contain;
      height: 20px;
      width: 20px;
      margin-right: 10px;
    }

    .filterBlock {
      margin-left: 15px;
      padding: 12px 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      background-color: #f2f2f2;
      transition: .3s all ease;
      cursor: pointer;
      position: relative;

      h5{
        margin: 0;
      }

      .remove {
        height: 16px;
        width: 16px;
        position: absolute;
        top: -4px;
        right: -4px;
        background:url('../../../assets/close_circle.svg') no-repeat center;
      }

      .dropdown {
        position: absolute;
        top: 40px;
        left: 50%;
        transform: translate(-50%);
        width: 220px;
        z-index: 10;
        border-radius: 8px;
        background-color: white;
        box-sizing: border-box;
        padding: 0 5px;
        max-height: 0;
        opacity: 0;
        pointer-events: none;
        transition: .3s all ease;
        -webkit-box-shadow: 0px 0px 21px 7px rgba(0,0,0,0.05); 
        -moz-box-shadow: 0px 0px 21px 7px rgba(0,0,0,0.05);
        box-shadow: 0px 0px 21px 7px rgba(0,0,0,0.05);

        &.show {
          opacity: 1;
          pointer-events: all;
          max-height: 500px;
        }

        .dropRow {
          width: 100%;
          height: 40px;
          line-height: 40px;
          font-size: 13px;
          box-sizing: border-box;
          padding: 0 15px;
          margin: 5px 0;
          cursor: pointer;
          transition: .3s all ease;

          &:hover {
            background-color: #f6f6f6;
          }

          &.selected {
            background-color: #dedede;
            color: white;
            pointer-events: none;
          }
        }
      }

      &:hover {
        background-color: #e7e7e7;
      }

      h5 {
        color: #858585;
        margin-right: 10px;
      }

      .icon {
        height: 20px;
        width: 20px;

        &.interval {
          background: url('../../../assets/calendar2_grey.svg') no-repeat center;
        }
      }



      &.active {
        background-color:$E_blue_dark;

        h5 {
          color: white;
        }

        .icon {
          &.interval {
            background: url('../../../assets/calendar2_white.svg') no-repeat center;
          }

        }
      }
    }
  }

}