@import '../../../../../shared.scss';

.container{
  height: fit-content;
  width: calc(100vw - 10px);
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  position: relative;

  *{
    font-family: 'Sofia pro';

  }

  .topBar{
    height: 45px;
    width: 100%;
    display: flex;
    align-items: center;
    box-sizing:border-box;
    padding:0 10px;
    position: absolute;
    top: 0;
    left: 0;

    h5{
      color: #333333;
      font-weight: 500;
      font-size: 15px;
      position: relative;
      bottom: 1px;
    }

    img{
      height: 20px;
      width: 20px;
      margin-right: 10px;
      object-fit: contain;
    }

    .backDiv{
      display: flex;
      align-items: center;
      height: 40px;

      h5{
        margin: 0;
        color: $E_blue;
        font-size: 15px;
      }
    }
  }

  .sectionBar{
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: $E_blue;
    box-sizing:border-box;
    padding:0 10px;
    .title{
      display: flex;
      align-items: center;

      h5{
        color: white;
        font-size: 15px;
        position: relative;
        top: 1px;
      }
      
      .circle{
        min-height: 22px;
        max-height: 22px;
        min-width: 22px;
        max-width: 22px;
        border-radius: 50%;
        background-color: #3996B3;
        color: white;
        margin-right: 10px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .mainSwitch{
    height: fit-content;
    width: 100%;

  }

}