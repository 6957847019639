@import '../../../../shared.scss';

.container{
  height:350px;
  width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing:border-box;
  padding:15px 30px;

  *{font-family:'Sofia pro'}


  .warning{
    height:fit-content;
    height: max-content;
    width:435px;
    background: url('../../../../assets/info.svg') no-repeat 20px center;
    background-size: 35px;
    background-color: #f2f2f2;
    border-radius: 15px;
    margin-top: 25px;
    box-sizing:border-box;
    padding:13px 10px 15px 70px;

    p{
      margin: 0;
      line-height: 21px;
      font-size: 16px;
      font-style: italic;
      color: $main-orange;
    }
  }


  .details{
    text-align: left;
    margin-top: 30px;
    // border:1px solid chartreuse;
    width: 350px;
    overflow: visible;
    position: relative;
    left: 20px;
    
    p{
      font-weight: 300;
      color:#858585;
      font-size: 16px;
      margin: 2px 0;
      max-width: 420px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

  }

  .tip{
    display: flex;
    height: 140px;
    align-items: center;
    margin-top: 30px;

    img{
      height: 100px;
      width: 100px;
      object-fit: contain;
      margin-right: 25px;
    }

    .text{
      height: 120px;
      width: 80%;
      h6{
        margin: 2px 0;
        font-size: 14px;
        color:#333333;
        font-weight: 700;
      }
      p{
        margin: 2px 0;
        font-size: 14px;
        color:#333333;
        font-weight: 300;
      }
    }

  }

  .buttons{
    display: flex;
    margin-top:30px;
    width: 300px;
    justify-content: space-between;

    .cancelBtn{
      @include mainButton( $main-blue--bright, white, 14px, 35px, 140px);
    }

    .sendBtn{
      @include solidButton(white, $main-blue--bright, 14px, 35px, 140px)
    }
  }
}