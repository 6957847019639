@import '../../../../../../shared.scss';

.container{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  box-sizing:border-box;
  padding:30px 40px;

  .titleBar{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0px;

    img{
      margin-right: 10px;
      width: 25px;
      position: relative;
      bottom: 2px;
    }
  }


  .datesDiv{
    position: relative;
    top: 15px;
    z-index: 10;
    background-color: white;
    border-radius: 10px;

    .text{
      display: flex;
      margin-bottom: 5px;
      h6{
        width: 50%;
        box-sizing:border-box;
        padding:0 0 0 30px;
        text-align: left;
        font-size: 16px;
        color: #333333;
        font-weight: 300;
      }
    }
    
    .box{
      border:1px solid #cacaca;
      border-radius: 10px;
      height: 60px;
      width: 658px;
      display: flex;

      .start,
      .end{
        display: flex;
        align-items: center;
        width: 50%;
        height: 100%;
        position: relative;
        border-radius: 10px;


        .icon{
          height: 25px;
          width: 25px;
          background: url('../../../../../../assets/calendarEnd.svg') no-repeat center;
          margin: 0 15px 0 30px;
        }

        .padlock{
          height: 25px;
          width: 25px;
          background: url('../../../../../../assets/padlock_grey.svg') no-repeat center;
          position: absolute;
          right: 20px;
          cursor: pointer;
          display: none;
          transition: .3s all ease-out;

          .tooltip{
            width: 170px;
            height: 78px;
            border-radius: 10px;
            background-color: white;
            // border:1px solid chartreuse;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            align-items: center;
            transition: .3s all ease-out;
            pointer-events: none;
            opacity: 0;
            -webkit-box-shadow: 1px 1px 10px 0px rgba(0,0,0,0.19);
            -moz-box-shadow: 1px 1px 10px 0px rgba(0,0,0,0.19);
            box-shadow: 1px 1px 10px 0px rgba(0,0,0,0.19);

            h5{
              font-size: 13px;
              color: #858585;
              box-sizing:border-box;
              padding:10px 15px;
              // overflow: hidden;
              // text-overflow: ellipsis;
              // white-space: nowrap;
              // max-width: 100%;
              text-align: center;

              strong{
                color: #333333;
              }
            }
          }

          &:hover{
            background: url('../../../../../../assets/padlock_black.svg') no-repeat center;

            .tooltip{
              opacity: 1;
              top: calc(100% + 10px);
            }
          }
        }
        h5{
          font-weight: 300;
          color:#4e4e4e;
        }


        &.blocked{
          background-color: #F2F2F2;

          .padlock{
            display: initial;
          }
        }
      }
      
      .start{
        border-right: 1px solid #cacaca;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        .icon{
          background: url('../../../../../../assets/calendarStart.svg') no-repeat center;
        }
      }

    }
  }

  .calendarDiv{
    height: 350px;
    border:1px solid #cacaca;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 660px;
    display: flex;
    box-sizing:border-box; 
    position: relative;
    z-index: 5; 

    &.openEnded{
      opacity: .3;
      pointer-events: none;
    }
  }

  .bottomRow{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    top: 15px;


    .sliderDiv{
      // height: 150px;
      display: flex;
      align-items: center;
      box-sizing:border-box;
      padding:0 30px 0 0;

      p.deadlineSliderTxt{
        color: #858585;
        font-size: 15px;
        width: 90px;
        text-align: center;
        transition: .2s all ease-out;

      }

      .slider{
        height: 35px;
        width: 65px;
        border-radius: 20px;
        background-color: #d2d2d2;
        margin: 0 10px;
        position: relative;

        .sliderInner{
          height: 31px;
          width: 31px;
          border-radius: 50%;
          background-color: white;
          position: absolute;
          left: 2px;
          top:2px;
          transition: .2s all ease-out;
          cursor: pointer;

          &.active{
            left:32px;
          }
        }

        &.active{
          background-color: $main-green;
        }
        
      }
    }



    .saveBtn{
      background-color: #9CCBD9;
      height: 40px;
      width: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 8px;
      // margin-top: 10px;
      transition: .2s all ease-out;
      cursor: pointer;
      // opacity: .4;
      pointer-events: none;
      // position: absolute;
      // right:20px;
      // bottom:20px;
      z-index: 10;
  
      &.enabled{
        pointer-events: all;
        background-color: $main-blue--light;
      }
  
      &:hover{
        background-color: $main-blue--dark;
      }
  
      p{
        color: white;
      }
  
    }
  }



}