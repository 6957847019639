@import '../../../../shared.scss';

.container{
  width: calc(90vw - 100px);
  height: 85vh;
  max-height: 800px;
  max-width: 1350px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  // overflow: hidden;
  position: relative;




  .arrow{
    height: 125px;
    width: 45px;
    background-color: rgba(255, 255, 255, 0.344);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    left: -45px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    cursor: pointer;
    transition: .15s all ease-out;
    -webkit-backface-visibility: hidden;

    .icon{
      height: 25px;
      width: 25px;
      background: url('../../../../assets/arrow_white.svg') no-repeat center;
      transition: .15s all ease-out;
    }

    &.right{
      right: -45px;
      left: initial;
      transform: translateY(-50%) rotate(0deg);

    }

    &:hover{
      height: 135px;
    }

  }

  @media (max-width:660px){
    display: none;
  }

}