@import '../../../../shared.scss';

.container {
  width:500px;
  box-sizing:border-box;
  padding:0 20px 15px 20px;
  background-color: $faith-grey-pink;
  color: $main-blue--dark;
  border-radius: 10px;

  h3 {
    margin-top: 35px;
    font-size: 1.6rem;
    line-height: 1.5;
    color: $main-blue--dark;
  }

  .line {
    margin: 5px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;

    p {
      font-size: 0.9rem;
    }

    .actionBtn {
      height: 20px;
      min-width: 100px;
      max-width: 100px;
      padding: 8px 10px;
      border-radius: 8px; 
      text-align: center;
      white-space: nowrap;

      &.noColorBtn {
        border: 1px solid #858585;
        background-color: transparent;
      }

      &.noColorNoBorderBtn {
        border: none;
        background-color: transparent;
        color: $E_blue;
      }

      &.blueDarkBtn {
        background-color: $E_blue;
        color: white;
      }

      &.blueLightBtn {
        background-color: $E_blue_light2;
        color: $E_blue;
      }
    }
  }
}