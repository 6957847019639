@import '../../../../shared.scss';

.top{
  height:80px;
  width: 100%;
  // border:1px solid chartreuse;
  display:flex;
  align-items: center;
  justify-content: center;

  .half{
    width:50%;
    height: 100%;
    display: flex;
    align-items: center;
    box-sizing:border-box;
    padding:10px 5px;

    &:first-of-type{
      justify-content: flex-end;
    }
    &:last-of-type{
      justify-content: flex-start;
    }

    img{
      width:200px;
      height:80%;
      object-fit: contain;
    }
  }
}

.main{
  height: 80%;
  width: 100%;
  display:flex;
  align-items: center;
  justify-content: space-between;
  box-sizing:border-box;
  padding:10px 10px 0 10px;

  .half{
    position: relative;
    height:100%;
    width:48%;
    border-radius: 15px;
    border:1px solid rgba(128, 128, 128, 0.3);
    box-sizing:border-box;
    padding:20px 30px;

    h2{
      font-size: 26px;
      box-sizing:border-box;
      padding-left:20px;
    }
  }
  
  .firstClassDiv{
    height:170px;
    width: 100%;
    background-color: $grey-light2;
    border:1px solid $grey-three;
    border-radius: 10px;
    display: flex;
    margin: 15px 0;
    border-radius: 15px;

    .firstClassInner{

      height:100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      box-sizing:border-box;
      padding:15px;

      h4{
        font-size: 16px;
        font-weight: 500;
      }
      img{
        height:125px;
        width:120px;
        object-fit: contain;
        margin-bottom: 3px;
      }

      &:first-of-type{
        width:40%
        
      }
      &:last-of-type{
        width: 60%;
        align-items: flex-start;
        justify-content: space-evenly;

        h2{
          padding-left: 3px;
        }

        .classBtn{
          @include borderButton(white, $main-blue--bright, 16px);
          font-weight: 700;
          box-sizing:border-box;
          padding:10px 25px;
          margin:0;
        }
      }
    }
  }

  .text{
    width:330px;

    p{
      font-size: 14px;
      color: $main-orange;
      margin: 9px 0;
    }
  }

  .menuDetails{
    display:flex;
    position: absolute;
    width:90%;
    bottom: 30px;
    align-items: center;
    justify-content: space-between;

    img{
      // border:1px solid chartreuse;
      object-fit: contain;
      height:100px;
      width:180px;
    }

    .switchDiv{
      width:300px;
      height: 60px;
      border-radius: 10px;
      background-color: $grey-light2;
      border:1px solid $grey-three;
      // margin: 0 0 0 20px;
      display: flex;
      align-items: center;
      box-sizing:border-box;
      padding:10px;

      img{
        height:35px;
        width:35px;
        object-fit: contain;
        margin-right: 10px;
      }
      p{
        font-size: 12px;
        color: $main-orange;
        margin: 9px 0;
      }
    }

  }

  .detailsDiv{
    margin: 15px 0;
    width:100%;
    height:315px;
    display: flex;
    justify-content: space-between;

    .third{
      width:33%;
      height:100%;
      box-sizing:border-box;
      padding:2px;

      .innerTop{
        width:100%;
        height:10%;
        display: flex;
        align-items: center;
        justify-content: center;

        h6{
          font-size: 10px;
          color: $grey-dark;
        }
        img{
          margin-right: 5px;
        }
        

      }

      .innerBottom{
        width:100%;
        height:fit-content;
        height: -moz-max-content;
        min-height: 215px;
        background-color: $grey-light2;
        border:1px solid $grey-three;
        border-radius: 15px;
        box-sizing:border-box;
        padding:10px 5px;

        .heading{
          display: flex;
          align-items: center;
          height:40px;
          img{
            margin:0 2px;
          }
          h3{
            overflow-x: hidden;
            font-size: 15px;
            font-weight: 700;
            color: black;
            width:60%;
            text-align: center;
          }
        }

        p{
          box-sizing:border-box;
          padding:0px 8px 0 8px;
          font-size: 13px;
          line-height: 18px;
          text-align: left;
          color: $grey-two;
          margin: 15px 0;
        }
      }
    }
  }

  .bottomButtons{
    position: absolute;
    bottom:10px;
    right:5px;
    display: flex;
    justify-content: flex-end;
    height:40px;
    width: 100%;

    .button{
      @include borderButton($main-blue--bright, white, 16px);
      font-weight: 700;
      box-sizing:border-box;
      padding:10px 25px;
      margin:0 4px;
    }
    .button:last-of-type{
      background-color: $main-blue--bright;
      color: white;
    }
  }
}
.bottom{
  height:40px;
  width:100%;
  display: flex;
  align-items: flex-end;
  box-sizing:border-box;
  padding-left:20px;

  label{
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;;
    color: $grey-twotwo;
    display: flex;
    align-items: center;

    input{
      margin-right: 10px;
    }
  }
}

@media (max-width:1400px){

  // .detailsDiv {
  // .third {
  //   &:nth-child(2) {
  //     .innerBottom {
  //       .heading {
  //         h3 {
  //           font-size: 12px;
  //           color: chartreuse;
  //         } } } } } }

  .detailsDiv .third:nth-child(2) .innerBottom .heading h3{
    font-size: 12px;
  }

  .main .detailsDiv .third .innerTop h6{
    font-size: 8px;
  }

  .main .firstClassDiv .firstClassInner:last-of-type .classBtn{
    font-size: 14px;
  }
  // .main {
  //   .firstClassDiv {
  //     .firstClassInner:last-of-type {
  //       .classBtn {
  //         font-size: 14px;
  //       } } } }
}

@media (max-width:1100px) {

  .main {

    .half {
      h2 {
        font-size: 20px;
      }
    }

    .detailsDiv {
      height: 80%;
      flex-direction: column;

      .third {
        width: 100%;
        height: 33%;

        .innerTop {
          display: none;
        }

        .innerBottom {
          height: 115px;
          min-height: initial;
          margin: 10px 0;

          .heading {
            height: 30px;
            justify-content: center;
            padding-right: 35px;

            h3{
              font-size: 20px;
              width: initial;
            }
            img{
              margin-right:15px
            }
          }

            p {
              margin: 15px 0;
              text-align: center;
            }
        }


      }
    }

    .firstClassDiv {
      height:155px;
      .firstClassInner {

        img {
          height: 100px
        }

        h4 {
          font-size: 12px;
        }

        &:first-of-type {
          padding-right: 0;
        }

        &:last-of-type {
          .classBtn {
            font-size: 12px;
            padding: 10px 12px;
          }
        }

      }
    }

    .text{
      width: 95%;

      p{
        font-size: 14px;
      }
    }

    .menuDetails{
      flex-direction: column;
    }
    .bottomButtons {
      justify-content: center;
      bottom: 20px;

      .button {
        font-size: 12px;
        padding: 10px 17px;
      }
    }
  }

}

@media (max-width:900px){
  .main{
    flex-direction: column;
    overflow-y: auto;

    .half{
      width:100%;
      height:600px;

      &:first-of-type{
        margin-bottom: 20px;
      }
    }

    .menuDetails{
      flex-direction: initial;
      position: initial;
      width: 100%;;

      .switchDiv{
        width: 275px;
      }
    }
  }
}

@media (max-width:600px){
  .main{
    .firstClassDiv{
      height: 240px;
      flex-direction: column;

      .firstClassInner{
        flex-direction: column;
        height: 240px;
        
        &:first-of-type{
          position: relative;
          top:5px;
          width: 100%;
          padding:5px 0 0 0;
        }
        &:last-of-type{
          position: relative;
          bottom:10px;
          width: 100%;
          align-items: center;
          height: 150px;
          padding:0;
        }
      }
    }

    .detailsDiv{
      .third{
        height:115px;
        margin:10px 0;
      }
    }

    .bottomButtons{
      position: initial;
    }

    .menuDetails{
      flex-direction: column;
    }
  }

}
@media (max-width:450px) {

  .top {
    flex-direction: column;

    .half {
      padding:0;

      &:first-of-type {
        height: 15px;

        h1 {
          font-size: 18px;
          margin: 0 auto;
        }
      }

      &:last-of-type{
        width: 100%;
        img{
          margin: 0 auto;
        }
      }
    }
  }

  .main {
    .half{
      height: 625px;
    }
    .detailsDiv{
      height: 76%;

      .third{
        .innerBottom{
          height: 120px;

          .heading{
            height: 14px;
            padding-right: 0;
            img{
              display: none;
            }
          }
        }
      }
    }

    .menuDetails{
      .switchDiv{
        width: 260px;;
      }
    }

    .bottomButtons {
      display: flex;
      flex-direction: column;
      height:70px;

      .button {
        width: 95%;
        text-align: center;
        margin: 5px auto;
      }
    }
  }

  .bottom{
    label{
      font-size: 14px;
    }
  }
}