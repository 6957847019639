@import '../../../../shared.scss';

.container{
  max-height: 90px;
  width: 340px;
  transition: all .5s ease-in-out;
  padding: 20px 15px 10px 20px;
  overflow: hidden;
  background-color:$E_blue;
  border-radius:10px;

  


  link{
    display: initial !important;
  }

  .title{
    display: flex;
    height: 25px;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    left: 5px;

    img{
      height: 23px;
      object-fit: contain;
      margin-right: 15px;
    }
  }

  h2{
    color:white;
    font-size: 20px;
    font-weight: 500;
    position: relative;
    bottom:3px;
  }

  &.open{
    max-height: 800px;
    overflow: hidden;
  }

  .inner{
    margin-top: 15px;
    height: fit-content;
    max-height: 755px;
    width: 100%;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 6px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: #c4c4c4;
    }

    .loaderDiv{
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;

      h4{
        color:white;
      }

      img{
        height: 45px;
        object-fit: contain;
        position: relative;
        bottom:5px;
      }
    }

  }


  .partyContainer,
  .featureContainer{
    position: relative;
    height: fit-content;
    height: -moz-max-content;

    .btns{
      width: 100%;
      justify-content: flex-end;
      height: 35px;
      position: relative;

      .checkoutBtn{
        @include solidButton(white, $main-green, 16px, 35px, 160px);
        margin-right: 4px;

        &.wide{
          width: 240px;
        }
        
        &.orange{
          @include solidButton(white, $main-orange, 14px, 30px, 140px);
          position: absolute;
          right: 0;
        }
      }

      h5{
        margin:3px 0;
        cursor: pointer;
        color:$main-blue--bright;
        font-size:16px;
      }
    }
  }
}

.partyContainer{
  flex-direction: column;
}


.sq{
  width:100%;
  height:250px;
  margin: 3px;
  background-color: chartreuse;
}

.partyContainer,
.studentContainer,
.teacherContainer,
.featureContainer,
.submissionContainer{
  width: 99%;
  height: 170px;
  border-radius: 5px;
  background-color: white;
  box-sizing:border-box;
  padding:15px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-bottom: 8px;


  .responsiveContent{
    display: none;
  }

  .titleBar{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 45px;

    img{
      margin-right: 10px;
      height: 45px;
      object-fit: contain;
    }

    h4{
      font-size: 18px;
      color:$main-blue;
    }
  }

  p{
    line-height: 18px;
    font-size: 15px;
    font-weight: 300;
    color: #858585;

    &.submissionP{
      position: relative;
      bottom: 5px;
    }

    strong{
      font-weight: 500;
    }
  }

  .btns{
    display: flex;
    height: 35px;
    position: relative;
    .goToBtn{
      height: 35px;
      line-height: 35px;
      width: 140px;
      font-size: 14px;
      color: $E_blue_dark;
      background-color: $E_blue_light;
      text-align: center;
      border-radius: 8px;
      transition: .2s all ease-out;
      cursor: pointer;

      &:hover{
        background-color: $E_blue_light2
      }
    }
    .approveBtn{
      @include solidButton(white, $main-green, 14px, 30px, 90px);
      margin-right: 4px;
      margin-right: 4px;
    }

    .rejectBtn{
      @include solidButton(#d00000, #d0000029, 14px, 30px, 90px);

      &:hover{
        color: white !important;
      }
    }
  }
  @media (max-width:660px){
    padding:5px 10px !important;
    height: fit-content;
    border-bottom: 1px solid #cacaca;
    .hideOnMobile{
      display: none !important;
    }

    .responsiveContent{
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      // align-items: center;

      img{
        height: 80px;
        width: 70px;
        margin-right: 25px;
        position: relative;
        left: 8px;
      }

      .mainContent{
        height: 90px;
        width: calc(100% - 100px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        p{
          font-size: 15px;
          color: #858585;
          font-weight: 300;
          text-align: left;
          line-height: 22px;
  
          strong{
            font-weight: 300;
            color: #333333;
            // font-size: 17px;
          }
        }
      }



      // .btns{
      //   width: 100%;
      //   justify-content: center;
      //   margin-top: -15px;
      //   margin-bottom: 5px;
      // }
    }
  }
}