@import '../../../shared.scss';

.files {
  margin: 30px 0 20px 0;
  text-align: center;

  .items {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;

    .documentWrapper,
    .pageWrapper {
      margin: 0 auto;
    }
  }

}