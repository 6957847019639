@import '../../../../shared.scss';

.container{
  width: 65%;
  min-height: 650px;
  height: 90vh;
  background-color: white;
  border-radius: 10px;
  position: absolute;
  left: 2.5%;
  z-index: 20;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing:border-box;
  padding:30px 20px;
  overflow-y: auto;


  .close{
    height: 30px;
    width: 30px;
    background: url('../../../../assets/close_square_grey.svg') no-repeat center;
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 15px;
    
    &:hover{
      background: url('../../../../assets/close_square_grey_hover.svg') no-repeat center;

    }
  }

  .topBar{
    display: flex;

    .searchDiv{
      height:35px;
      width: 300px;
      display: flex;
      align-items: center;
      box-sizing:border-box;
      padding:0 10px;
      background-color: #f2f2f2;
      border:1px solid #cacaca;
      border-radius: 50px;
      margin-left: 15px;

      input{
        background-color: transparent;
        border: 1px solid transparent;
        position: relative;
        left: 5px;
      }
    }

    @media (max-width:1250px){
      flex-direction: column;
      align-items: center;

      .searchDiv{
        margin-top: 15px;
      }
    }
  }

  .filters{
    display: flex;
    align-items: center;
    justify-content: center;
    margin:25px 0;
    width: 75%;
    flex-wrap: wrap;

    .filterRadio,
    .filterRadio__pink{
      padding: 5px 15px;
      border:1px solid $E_blue;
      color:$E_blue;
      font-size: 13px;
      border-radius: 30px;
      margin-right: 8px;
      margin-bottom: 8px;
      cursor: pointer;
      white-space: nowrap;
      
      &.checked{
        background-color: $E_blue;
        color: white;
      }

      &__pink{
        border-color: $E_pink;
        color: $E_pink;

        &.checked{
          background-color: $E_pink;
        }
      }
    }
  }

  .items{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    position: relative;
    flex-wrap: wrap;
    box-sizing:border-box;
    padding:0 20px;
    height: 100%;

    &.marginTop{
      margin-top: 40px;
    }

    h5.noItems{
      width: 100%;
      text-align: center;
      margin-top: 100px;
      color: $E_blue;
      font-weight: 300;

      &.story{
        color: $E_pink;
      }
    }
  }

}