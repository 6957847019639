@import '../../shared.scss';

.container {
  // position: fixed;
  top: 0;
  z-index: 1500;
  color: white;
  box-sizing: border-box;
  padding: 10px 0;
  height: 100vh;
  min-height: 700px;
  min-width: 70px;
  max-width: 70px;
  background-color: $main-teal--dark;
  display: flex;
  flex-direction: column;
  justify-content: space-between !important;
  -webkit-box-shadow: 3px 0px 5px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 3px 0px 5px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 3px 0px 5px 1px rgba(0, 0, 0, 0.2);
  transition: .3s all ease-in-out;

  *{
  font-family: 'DM Sans', sans-serif;
}

  // &.faithJournal{
  //   background-color: #563B12 !important;
  // }

  &.navOpen{
    min-width: 200px;
    max-width: 200px;
  }


  // &.navOpen{
  //   .navItem{
  //     flex-direction: row;
  //     justify-content: flex-start;
  //     box-sizing:border-box;
  //     padding:0 0 0 15px;

  //     p{
  //       font-size: 14px;
  //       margin:0 0 0 10px;
  //     }
  //   }
  // }

  .top,
  .bottom{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .navItem{
    height: 65px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .3s all ease-out;
    position: relative;

    .colorTab{
      position: absolute;
      right: -5px;
      top: 50%;
      opacity: 0;
      transform: translateY(-50%);
      height: 35px;
      width: 10px;
      overflow: hidden;
      transition: .3s all ease-out;
      

      &:after{
        content: "";
        height: 35px;
        width: 10px;
        border-radius: 4px;
        background-color: $E_blue2;
        right: -5px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }


      &.faithJournal{
        &::after{
          background-color: $F_pink_deep;
        }
      }
    }


    img{
      margin-bottom: 2px !important;
      width: 23px;
    }

    &.profile{
      overflow: visible !important;
    }

    &.tutorials{
      img{
        width: 30px;
      }
    }
    
    img{
      opacity: .5;
      transition: .3s all ease-out;
      -webkit-backface-visibility: hidden;
    }

    img.logo{
      height: 45px !important;
      width: 30px !important;
      opacity: 1 !important;
      margin-left: 10px;
      margin-right: 15px;
      position: relative;
      bottom: -1px;

      &.bw{
        bottom: 2px;
        width: 40px !important;
      }
    }
    
    p{
      margin: 8px 0 0;
      font-size: 11px;
    }

    .tooltip{
      position: absolute;
      left: calc(100% - 20px);
      color: $E_blue_dark;
      padding:10px;
      border-radius: 8px;
      background-color: white;
      opacity: 0;
      transition: .3s all ease-out;
      -webkit-box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.2); 
      box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.2);
      pointer-events: none;
    }

    &:hover{
      .tooltip{
        opacity: 1;
        left: calc(100% - 10px);
      }
    }
    

    &:hover, &.solid{
     img{
       opacity: 1;
     }
    }

    &.activeItem{
      background-color: rgba(255, 255, 255, 0.241);
      pointer-events: none;
      img{
        opacity: 1;
      }
      .colorTab{
        opacity: 1;
        right: 0px;
      }
    }

    .unreadIndicator{
      border-radius: 50%;;
      height:17px;
      line-height:17px;
      width:17px;
      text-align: center;
      background-color: red;
      position: absolute;
      top:5px;
      right:13px;
      z-index: 10;
      font-size: 12px;
      box-sizing:border-box;
      padding:0 0 0 2px;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    .new{
      height:10px;
      width: 10px;
      border-radius: 50%;
      position: absolute;
      top:15px;
      right:23px;
      background-color: $main-yellow;
    }
  }



  .popupMenu{
    z-index: 1500;
    left:calc(100% + 5px);
    bottom:0px;
    position: absolute;
    height:fit-content;
    height: -moz-max-content;
    width:180px;
    border-radius: 15px;
    background-color: $main-teal--dark;
    display: inline;
    box-sizing:border-box;
    padding:6px 10px;
    
    .block{
      height: 100%;
      width: 20px;
      background-color: transparent;
      position: absolute;
      left: -10px;
      top: 0px;
    }

    // &.faithJournal{
    //   background-color: #563B12;
      
    //   &::before{
        
    //     border-color: transparent #563B12 transparent transparent;
    //   }
    // }
    
    
    &::before{
      content: '';
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 10px 15px 10px 0;
      border-color: transparent $main-teal--dark transparent transparent;  
      display: inline-block;
      position: absolute;
      left:-8px;
      bottom:30px;
      opacity: inherit;
    }

    .row{
      cursor: pointer;
      height:30px;
      width: 100%;
      text-align: left;
      line-height: 30px;
      border-radius: 5px;
      display: block;
      box-sizing:border-box;
      padding:0 5px;

      &:hover{
        background-color: #cacaca54;

      h6{color:white}
      }
    }

    &.hidden{
      display: none;
      .row{
        display: none;
      }
      // left:-70px;
    }

    .popupRow{
      font-size: 14px;
      padding-left: 30px;
      line-height: 30px;
      border:2px solid transparent;

      &:hover{
        color:$main-green;
        border:2px solid #ffffff41;
        background-color: #ffffff12;
      }
    }
    a>.popupRow.admin{
      background: url('../../assets/icon-navPopup3.svg') no-repeat 3px center;
      background: url('../../assets/icon-navPopup3.svg') no-repeat 3px center;
      background-size: 20px;
      &:hover{
        background: url('../../assets/icon-navPopup3--green.svg') no-repeat 3px center;
        background-size: 20px;
        background-color: #ffffff12;
      }
    }
    a>.popupRow.parent{
      background: url('../../assets/icon-navPopupParent.svg') no-repeat 3px center;
      background-size: 20px;
      &:hover{
        background: url('../../assets/icon-navPopupParent--green.svg') no-repeat 3px center;
        background-size: 20px;
        background-color: #ffffff12;
      }
    }

    a>.popupRow.teacher{
      background: url('../../assets/icon-navPopup1.svg') no-repeat 3px center;
      background-size: 20px;
      &:hover{
        background: url('../../assets/icon-navPopup1--green.svg') no-repeat 3px center;
        background-size: 20px;
        background-color: #ffffff12;
      }
    }
    .popupRow.mini{
    background: url('../../assets/icon-navPopup3.svg') no-repeat -2px center;
    background-size: 28px;
    &:hover{
      background: url('../../assets/icon-navPopup3--green.svg') no-repeat -2px center;
      background-size: 28px;
      background-color: #ffffff12;
    }
  }
    a>.popupRow.settings{
      background: url('../../assets/icon-navPopup2.svg') no-repeat 3px center;
      background-size: 20px;
      &:hover{
        background: url('../../assets/icon-navPopup2--green.svg') no-repeat 3px center;
        background-size: 20px;
        background-color: #ffffff12;
      }
    }
    a>.popupRow.sub{
      background: url('../../assets/icon-navPopupSub.svg') no-repeat 3px center;
      background-size: 20px;
      &:hover{
        background: url('../../assets/icon-navPopupSub--green.svg') no-repeat 3px center;
        background-size: 20px;
        background-color: #ffffff12;
      }
    }
    .popupRow.logout{
      background: url('../../assets/icon-navPopup5.svg') no-repeat 6px center;
      background-size: 18px;
      &:hover{
        background: url('../../assets/icon-navPopup5--green.svg') no-repeat 6px center;
        background-size: 18px;
        background-color: #ffffff12;
      }
    }
  }

}
