.quill{
  height: 100%;
  background-color:white;


  button.ql-image,
  button.ql-video{
    position: absolute !important;
    display: none !important;
  }

  img,
  iframe{
    display: block !important;
    margin: 25px 10px !important;
  }

  img{
    width: 300px !important;
    border-radius: 15px !important;
  }
  iframe{
    width: 400px !important;
    height: 225px !important;
  }

  .ql-tooltip{
    z-index: 500 !important;
    position: fixed !important;
    top: 50vh !important;
    left: 50% !important;
    padding: 20px 30px !important;
    border:none !important;
    border-radius: 8px !important;
    -webkit-box-shadow: 1px 1px 5px 2px rgba(0,0,0,0.39) !important;
    -moz-box-shadow: 1px 1px 5px 2px rgba(0,0,0,0.39) !important;
    box-shadow: 1px 1px 5px 2px rgba(0,0,0,0.39) !important;

    &:before{
      margin-right: 15px !important;
    }

    input[type=text]{
      height: 37px !important;
      line-height: 37px !important;
    }

    .ql-action{
      margin-left: 15px !important;
      padding: 10px 20px !important;
      background-color: #077CA0 !important;
      color: white !important;
      cursor: pointer !important;
      border-radius: 5px ;

      &:after{
        margin-left: 0 !important;
      }
    }

  }

  &.activityDetails{
    // background-color: #f2f2f2 !important;
    min-height: 30vh;
    height: fit-content;

    .ql-toolbar.ql-snow{
      background-color: white !important;
    }

    .ql-container{
      height: calc(100% - 75px) !important; 

      .ql-editor{
        position: relative;
        top: 20px;
        padding:10px 20px 25px 20px !important;
      }
    
    }
  }

  &.activityDetailsResponsive{
    background-color: transparent;
    // border:1px solid red;
    height: calc(100% - 55px) !important;
    position: relative;

    .ql-editor{
      padding: 10px 20px 20px 20px !important;

      *{
        font-size: 14px !important;
        color: #858585 !important;
      }
    }

    &.functional{
      overflow: hidden;
      height: 100% !important;

      .ql-toolbar.ql-snow{
        left: -30px !important;
        bottom: initial !important;
        top: calc(100% - 55px) !important;
        height: 50px !important;
        width: calc(100vw + 40px) !important;

      }
    }
  }
}


.ql-container{
  height: calc(100% - 10px) !important; 
  border:1px solid transparent !important; 

}

.ql-editor{
  box-sizing:border-box !important;
  padding:30px 20px 25px 20px !important;
  p, li{
    font-size: 15px !important;
    font-weight: 300 !important;
    color: #333333 !important;
  }
  p{
    margin-bottom: 5px !important;
  }
}

.quill > .ql-container > .ql-editor.ql-blank::before{
  font-size: 33px !important;
  color: #e2e2e2 !important;
  font-style: initial;
  font-weight: 700;
  position: absolute;
  top: 10px;
  left: 20px;
  
  @media (max-width:660px){
    top: 10px;
    font-weight: 500;
    font-size: 20px !important;
    color: #C1C3C3 !important;
  
  }
}

.approved{
  .ql-toolbar.ql-snow{
    opacity: .4 !important;
    pointer-events: none !important;
  }
}

.ql-toolbar.ql-snow{
  border:1px solid transparent !important;
  width: 500px;
  border-radius: 8px;
  margin-left: 10px;
  -webkit-box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.1); 
  box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.1);
  position:relative;
  top:10px;
}

button{
  padding: 5px !important; 
  box-sizing: content-box !important;
  display: grid !important;
  place-items: center !important;

  svg{
    height: 18px;
    width: 18px;
  }
  &.ql-active{
    background-color: #CDDFFA !important;
    border-radius: 8px;

    path{
      stroke: #065FE5 !important;
      color: #065FE5 !important;
    }
  }
}


.ql-picker-label{
  padding-right:25px !important;
}


.ql-toolbar.ql-snow .ql-formats {
  margin-right: 5px !important; 
}



// ENTRT TEXT EDITOR VERSION
.entryTextEditor{ 

  .quill .ql-container .ql-editor{
    padding:15px 0px !important;
  }

  .ql-toolbar.ql-snow{
    position:relative;
    top:5px;
    left: -10px;
  }
}


//shareResource modal version

.shareResourceModal{
  .quill .ql-container .ql-editor{
    padding: 10px !important;
  }
}


@media (max-width:865px){
  .ql-container{
    height: calc(100% - 60px) !important;
  }
}

@media (max-width:768px){

  .ql-toolbar.ql-snow{
    transform: scale(.9);
    position: absolute;
    z-index: 10;
    left: -20px;
  }

  .ql-editor{
    padding-top: 55px !important; 
  }
}

@media (max-width:720px){
  .ql-toolbar.ql-snow{
    transform: scale(.75);
    left: -60px;
  }
}


//MOBILE VERSION MEDIA QUERIES

@media (max-width:660px){ 

  .ql-container{
    height: 100% !important;
    border:1px solid chartreuse;

  }

  .withToolbar{
    .ql-container{
      height: calc(100% - 60px) !important;
    }
  }
  
  .ql-toolbar.ql-snow{
    transform: scale(1);
    position: absolute;
    left: -45px !important;
    bottom: 0;
    width: calc(100vw + 10px) !important;
    -webkit-box-shadow: none; 
    box-shadow: none;
    border: 1px solid #cacaca !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0 !important;

    .ql-formats:nth-of-type(3){
      display: none;
    }
  }

  .ql-editor{
    padding-top: 5px !important; 

    *{
      font-size: 15px;
    }
  }


  .ql-picker-options{
    top:-440% !important;
  }
}

@media (max-width:336px){
  .ql-toolbar.ql-snow{
    width: 110vw !important;
    min-width: 348px !important;
    transform: scale(0.95);
  }
}