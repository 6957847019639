@import '../../../../shared.scss';

.container{

  height:300px;
  width: 600px;
  transition:.6s all cubic-bezier(0.32,-0.15, 0.26, 0.99);
  
  &.confirmation{
    height:300px;
    transition:.4s all cubic-bezier(0.32,-0.15, 0.26, 0.99);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.open{
    height: 660px;
  }


  .topBar{
    height: 90px;
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(12, 167, 216);
    background: linear-gradient(156deg, rgba(12, 167, 216, 1) 35%, rgba(46, 102, 174, 1) 76%);

    .totalCredits{

      width: 100px;
      h5{
        margin:0;
        font-size: 12px;
        color:white;
        width: 70px;
        padding-left: 20px;
        background: url('../../../../assets/credit-small-coin.svg') no-repeat left 1px;
      }
      h1{
        font-size: 40px;
        color: white;;
      }
    }

    h3{
      color:white;
      font-size: 18px;
    }
  }


  .optionRadios{
    width:300px;
    margin:0px auto;
    height: 190px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    label>input{
      display: none;
    }
    label>input+div.creditOption{
      height: 120px;
      width: 130px;
      border-radius: 10px;
      border:1px solid #cacaca;
      background-color: #f9f9f9;
      display:flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      bottom: 0px;
      cursor: pointer;
      transition: all .1s ease-in-out;
      padding:1px;

      &:hover{
        bottom:4px;
      }
      
      h4{
        color:#333333;
        font-size: 16px;
        font-size: 700;
        margin-top: 10px;
      }

      img{
        position: relative;
        top:5px;
      }
    }
    label>input:checked+div.creditOption{
      border:2px solid $main-green;

      &:after{
        content: '';
        background: url('../../../../assets/teacher/icon-circleTick--small.svg') no-repeat center;
        background-size: contain;
        font-size: 15px;
        position: absolute;
        height: 25px;
        width: 25px;
        top: -10px;
        right: -10px;
        color: chartreuse;
      }
    }
  }

  .transactionDiv{
    height: 345px;
    width: 541px;
    margin: 0 auto;
    border:2px solid $main-green;
    background: #f9f9f9;
    border-radius: 15px;
    opacity: 0;
    transition: .7s all linear;
    display: flex;
    align-items: center;
    justify-content: center;

    &.showDiv{
      opacity: 1;
    }

    .spendCreditsSub,
    .spendCreditsBooks{
      height: 330px;
      width: 510px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      opacity: 0;
      transition: .4s all linear;
      position: relative;

      &.spendCreditsInnerShow{
        opacity:1;
      }
  
      p{
        color:#333333;
        font-size: 16px;
        font-weight: 500;
        margin:10px 0 20px 0;
        line-height: 22px;
        box-sizing:border-box;
        padding:0 10px;
      }
      
      .subSummary{
        width: 100%;
        border:1px solid #cacaca;
        background-color: white;
        border-radius: 10px;
        box-sizing:border-box;
        padding:20px;

        &.warning{
          border-color: red;
        }

        &.books{
          padding:15px 10px;
        }
  
        p{
          margin:0;
          font-size: 15px;
          color: #858585;
          font-weight: 500;
        
          &.summaryHeader{
            color:#333333;
            margin-bottom: 15px;
          }


          &.books{
            color:#333333;
            line-height: 18px;
            margin: 5px 0 15px 0;
          }
        }
  
        .subSummaryRow{
          display: flex;
          justify-content: space-between;
          height: 30px;
          line-height: 30px;
          position: relative;
          

  p.warning{
    position: absolute;
    bottom:-13px;
    color: $main-blue--bright;
    font-size: 11px;
    line-height: 16px;
    // width: 350px;
    font-size: 300;
    left:30px;
  }
        }
      }

      label{
        width: 250px;
        box-sizing:border-box;
        text-align: left;
        font-size: 13px;
        font-weight: 300;
        color: #858585;
        height: 25px;
        line-height: 25px;
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-top: 5px;

        input[type='checkbox']{
          height: 25px;
          margin: 5px;
        }
      }

      .bottomDiv{
        display: flex;
        height: 35px;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-top: 18px;

        input[type="text"]{
          height: 35px;
          line-height: 35px;
          box-sizing:border-box;
          padding:0 20px;
          width: 330px;
          border:1px solid #cacaca;
          background-color: #33333319;
          border-radius: 5px;
        }

        .proceedBtn {
          @include solidButton(white, $main-blue--bright, 14px, 35px, 160px);
        }
      }


      .buttonDiv{
        position: absolute;
        z-index: 100;
        bottom:5px;
        right:0px;
        display: flex;
        align-items: center;
        height:35px;

        p.errorMsg{
          color: #d00000;
          font-size: 12px;
          font-weight: 500;
          margin:0px;
        }

        .redeemBtn {
          @include solidButton(white, $main-blue--bright, 14px, 35px, 160px);
        }
      }
  
    }

  }



  .loadingOverlay{
    // position: absolute;
    height:300px;
    width: 600px;
    // top:0px;
    // left:0px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
    visibility: hidden;

    &.show{
      visibility: visible;
    }
    p{
      margin: 0;
      position: relative;
      top:-10px;
    }
  }

  .confMsg{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 15px;
    height:100%;
    width:100%;
    position: absolute;
    top:0;
    left:0;
    background-color: white;
    visibility: hidden;

    img{
      height: 60px;
      margin-bottom: 10px;
    }

    h2{
      font-size: 26px;
      font-weight: 500;
    }

    p{
      font-size: 16px;
      font-weight: 500;
      margin: 15px 0 0 0;

      &:last-of-type{
        margin-bottom: 60px;
      }
    }

    .closeBtn{
      @include solidButton(white, $main-blue--bright, 15px, 35px, 150px);
      position: absolute;
      bottom: 45px;
    }
    
      &.show{
    visibility: visible;
  }
}

  
}