@import '../../../../shared.scss';

.container{

  h1{
    text-align: center;
    margin:0 0 10px 0;
  }

  h2{
    margin:15px 0 0px 0;
  }
  h2 + p{
    margin-top:5px;
  }
  

  p{
    font-size: 14px;
    line-height: 20px;
    color:#858585;
  }
}
