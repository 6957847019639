@import '../../../../../shared.scss';

.container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  border-bottom: 1px solid #cacaca;
  padding: 0 15px;

  .studentBlock{
    display: flex;
    align-items: center;
    width: 33%;
    // border:1px solid purple;

    img{
      height: 60px;
      position: relative;
      top:1px;
    }

    p{
      font-size: 14px;
      margin: 0 0 0 15px;
      font-weight: 500;
      color: #074B60;
    }
  }

  .progressDots{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 260px;
    height: 55px;
    position: relative;

    .line{
      height: 2px;
      width: calc(100% - 50px);
      position: absolute;
      top: 16px;
      left: 25px;
      background-color: #E9E9E9;
      z-index: 1;

      .inner{
        height: 2px;
        position: absolute;
        left: 0;
        top:0;
        width: 0%;
        background-color: #097CA0;
        transition: .3s all linear;
        
        &.two{
          width: 33%;
        }
        
        &.three{
          width: 66%;
        }
        
        &.four{
          width: 100%;
        }
        &.five{
          width: 0%;
        }
      }
    }

    .step{
      height: 45px;
      width: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      
      .dot{
        height: 25px;
        min-height: 25px;
        max-height: 25px;
        width: 25px;
        min-width: 25px;
        max-width: 25px;
        border-radius: 50%;
        border:2px solid #E9E9E9;
        background-color: #E9E9E9;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;
        margin-bottom: 5px;

        p{
          margin:0;
          font-size: 13px;
          font-weight: 500;
          color: #858585;
        }

        &.complete{
          border-color: #333333;
          background-color: #097CA0;

          p{
            color: white;
          }
        }
      }
      
      h5{
        font-size: 11px;
        margin: 0;
        font-weight: 500;
        color: #074B60;
        color: #858585;
      }

      .complete + h5{
        color: #4e4e4e;
      }
    }

  }

  .btnDiv{
    width: 33%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    
    .timelineBtn{
      width: 170px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #E6F2F6;
      border-radius: 8px;
      cursor: pointer;
      transition: .2s all ease-out;
      position: relative;
      z-index: 15;
  
      &:hover{
        background-color: #B5D8E3;
      }
  
      p{
        color: #077CA0;
        font-size: 16px;
        margin:0;
      }

      img{
        position: relative;
        left: -10px;
      }

      @media (max-width:1170px){
        width: fit-content;
        box-sizing:border-box;
        padding:0 15px;
        img{
          display: none;
        }

        p{
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;
          white-space: nowrap;
        }
      }
    }

    .timelineDiv{
      width: 240px;
      max-height: 500px;
      background-color: white;
      border-radius: 10px;
      position: absolute;
      top: 35px;
      z-index: 10;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing:border-box;
      padding:10px;
      -webkit-box-shadow: 1px 1px 10px 5px rgba(0,0,0,0.07);
      -moz-box-shadow: 1px 1px 10px 5px rgba(0,0,0,0.07);
      box-shadow: 1px 1px 10px 5px rgba(0,0,0,0.07);
      opacity: 0;
      transition: .2s all ease-out;


      .eventRow{
        width: 220px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 50px;
        box-sizing:border-box;
        padding:10px;
        margin-bottom: 10px;

        &:last-of-type{
          margin-bottom: 0;
        }

        p{
          font-size: 15px;

          &.placeholder{
            color: #a9a9a9;
            width: 100%;
            text-align: center;
          }
        }

        .dateDiv{
          width: 65px;
          hanging-punctuation: 40px;
          border-radius: 8px;
          background-color: #e9e9e9;
          display: flex;
          align-items: center;
          justify-content: center;

          p{
            color: #4e4e4e;
          }
        }
      }
    }


    &.open{

      .timelineBtn{
        background-color: #074B60;

        p{
          color:white;
        }
      }

      .timelineDiv{
        opacity: 1;
        top: 70px;
      }
    }
  }


  &.synod{
    .progressDots{
      display: none;
    }
  }

}