
@import '../../../../shared.scss';

.container{
  height:calc(100% - 100px);
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  
}
.inner{
  // position: absolute;
  z-index: 5;
  bottom:0;
  height:100%;
  width:calc(100% - 205px);
  background-color: white;
  border-radius: 15px;
  border-bottom-right-radius: 0px;
  border:1px solid $grey-unselected;
  display: flex;
  flex-direction: column;
}

.filterBtns{
  width:185px;
  height: fit-content;
  height: -moz-max-content;
  min-height: 150px;  
  border-radius: 15px;
  box-sizing:border-box;
  padding:20px 25px;
  background-color: #f2f2f2;
  border:1px solid #cacaca;

  input{display:none}

  input+.filterBtn{
    color:white;
    height:30px;
    width:130px;
    line-height: 30px;
    text-align: center;
    border-radius: 16px;
    color: $main-blue--light;
    background-color: white;
    border:1px solid $main-blue--light;
    margin:5px 0;
    font-size: 14px;
    cursor: pointer;
    transition: all .3s ease-in-out;

    // &:hover{
    //   height: 34px;
    //   line-height: 34px;
    // }
  }

  input:checked+.filterBtn{
    pointer-events: none;
    background-color: $main-blue--light;
    border:1px solid $main-blue--light;
    color: white;
  }
}

.top{
  display:flex;
  justify-content: flex-start;
  width:95%;
  min-height: 20px;
  margin:20px auto !important;
  position: relative;
  z-index: 10;

  .delete{
    position: absolute;
    right:-15px;
    height:40px;
    width:40px;
    background: url('../../../../assets/icon-delete.svg') no-repeat center;
    background-size: 40%;
    cursor: pointer;
    transition: all .3s ease-in;
    border-radius: 5px;

    .delTooltip{
      position: absolute;
      height:25px;
      line-height: 25px;
      text-align: center;
      width:135px;
      border-radius: 5px;
      bottom:-30px;
      left:-47px;
      background-color: #858585;
      color:white;
      display: none;
      font-size: 13px;
      transition: .3s all ease-out;


      &::after{
        content:'';
        position: absolute;
        top: -10px;
        left:0;
        margin-left: calc(50% - 5px);
        border-width: 5px;
        border-style: solid;
        border-color:transparent transparent #858585 transparent; 
      }
    }

    &:hover{
      background-color: #e9e9e9;

      .delTooltip{
        display: initial;
      }
    }


  }
}


.container input[type="text"]{
  width:50%;
  height:35px;
  margin:0 15px 0 0;
  border: 1px solid $grey-unselected;
  font-size: 16px;
  padding:0 45px;
  -webkit-appearance: none; 
  -moz-appearance: none;
  border-radius: 8px;
  background: url('../../../../assets/manage/lupa.png') no-repeat 15px 7px;
  background-color: $main-bluegrey;
}


.addTeacher{
  cursor: pointer;
  display:flex;
  align-items: center;
  justify-content: flex-start;
  width:140px;
  height:35px;
  border-radius: 8px;
  background-color: $main-blue--bright;
  border:1px solid $main-blue--bright;
  transition: all .3s ease;
  box-sizing:border-box;
  padding:5px 10px;
  transition: .3s all ease-in-out;
  margin-right: 10px;


  h3{
    color: white;
    margin:0;
    line-height: 38px;
    font-size: 14px;
    transition: .3s all ease-in-out;
    width: 85%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  h1{
    color: white;
    margin:0 10px 0 0;
    box-sizing:border-box;
    line-height: 35px;
    font-size: 35px;
    font-weight: 300;
    position: relative;
    bottom: 3px;
    transition: .3s all ease-in-out;
  }
  &:hover{
   background-color: white;
   h1,h3{
     color:$main-blue--bright;
   }
  }
}

.addTeacherExisting{
  cursor: pointer;
  display:flex;
  align-items: center;
  justify-content: flex-start;
  width:190px;
  height:35px;
  border-radius: 8px;
  border:1px solid $main-blue--bright;
  transition: all .3s ease;
  box-sizing:border-box;
  padding:5px 10px;
  transition: .3s all ease-in-out;
  margin-right: 30px;


  h3{
    color:$main-blue--bright;
    margin:0;
    line-height: 38px;
    font-size: 14px;
    transition: .3s all ease-in-out;
    width: 85%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  h1{
    color:$main-blue--bright;
    margin:0 10px 0 0;
    box-sizing:border-box;
    line-height: 35px;
    font-size: 35px;
    font-weight: 300;
    position: relative;
    bottom: 3px;
    transition: .3s all ease-in-out;
  }
  &:hover{
   background-color: $main-blue--bright;
   h1,h3{
     color:white;
   }
  }
}

.tableHeader{
  display: flex;
  min-height:30px;
  width:100%;
  background-color: $grey;
  border: 1px solid $grey-unselected;
  border-width: 1px 0 1px 0;

  *{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .photo{
    margin-left: 20px;
    width:10%;
    text-align: center;
  };
  .name{
    width:30%;
    text-align: left;
    // padding-left: 5px;
  };
  .email{
    width:25%;
    text-align: left;
  };
  .subject{
    width:15%;
    text-align: center;
  };
  .grade{
    width:10%;
    text-align: center;
  };
  .lastLogin{
    width:10%;
    text-align: center;
  };
  h5{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 10px;
    margin:0;
    line-height: 30px;
    text-transform: uppercase;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.4);
  }
}

.tableRows{
  overflow-y: scroll;
  flex-grow: 1;
  border-bottom-left-radius: 15px;


  .loaderDiv{
    width: 100%;
    text-align: center;
    margin-top: 10%;
  }
}



@media (max-width:1100px) {

  .container {

  }
}

@media (max-width:600px){
  .container{
    height:80%;
  }
  .inner{
    border-top-left-radius: 0;
    height: 94%;
  }
  .top{
    flex-direction: column;
    min-height: 90px;

    input{
      box-sizing: border-box;
      width: 100%;
      height: 40px;
    }
    .addTeacher{
      width: 100%;
      height: 40px;
      h1{
        position: absolute;
        right:10px;
      }
    }
  }
}

@media (max-width:380px){
  .container{
    height: 75%;
  }
}