@import '../../../../shared.scss';

.container{

  height:650px;
  width:1060px;
  box-sizing:border-box;
  padding:30px 60px;
  overflow: hidden;
  z-index: 2000;


  h1{
    font-weight: 500;
    color:#333333;
    font-size: 27px;
  }

  p{
    font-size: 16px;
    color:#858585;
    font-weight: 300;
    margin: 8px 0;
  }

  .playerDiv{
    width:100%;
    height:100%;
    opacity:0;
    position:absolute;
    top:-100%;
    left:0;
    transition:.4s all ease-out;
    z-index: 100;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow:hidden;
    background: url('../../../../assets/spinner-white-sm.svg') no-repeat center;
    background-size: 65px;


    .closePlayer{
      position: absolute;
      cursor: pointer;
      top:15px;
      right: 25px;

      img{
        width: 25px;
        height: 25px;
        object-fit: contain;
      }
    }

    &.show{
      top:0;
      height:100%;
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.8);
      display: flex;
      flex-direction: column;
      transition:.4s all ease-in-out;

      p{
        width:100%;
        text-align: center;
        color:white;
        cursor: pointer;
      }
    }
  }

  .filters{

    display: flex;
    height: 50px;
    align-items: center;

    .search{
      text-align: left;
      display: flex;
      // justify-content: space-between;

      input[type='text'] {
        background: url('../../../../assets/icon-lupa.png') no-repeat 5px center;
        min-height: 35px;
        max-height: 35px;
        width:255px;
        border: 1px solid $grey-three;
        border-radius: 5px;
        padding-left: 35px;
      }
      .submit {
        color: white;
        background-color: $main-blue--bright;
        height: 33px;
        line-height: 33px;
        text-align: center;
        width: 110px;
        // padding: 8px 20px;
        border-radius: 5px;
        margin-left: 10px;
        border:1px solid $main-blue--bright;
        transition: .3s all ease-out;
        cursor: pointer;

        &:hover{
          background-color: white;
          color:$main-blue--bright;
        }
      }
    }

    .radios{
      display: flex;
      align-items: center;
      margin-left: 15px;

      .tutorialRadio{
        height: 20px;
        width: 95px;
        border-radius: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        background-color: white;
        border:1px solid;
        margin-right: 5px;
        cursor: pointer;

        &.all{
          color:$main-blue;
          border-color:$main-blue;
        }

        &.basic{
          color:$main-blue--light;
          border-color:$main-blue--light;
        }

        &.tips{
          color:$main-purple;
          border-color:$main-purple;
        }

        &.bee{
          color:$main-yellow;
          border-color:$main-yellow;
        }

        &.onboarding{
          color:#D00000;
          border-color:#D00000;
        }
      }

      input:checked+.tutorialRadio{

        color:white;

        &.all{
          background-color:$main-blue;
        }

        &.basic{
          background-color:$main-blue--light;
        }

        &.tips{
          background-color:$main-purple;
        }

        &.bee{
          background-color:$main-yellow;
        }

        &.onboarding{
          background-color:#D00000;
        }
      }
    }
  }

  .tutorialDivs{
    width:100%;
    height: fit-content;
    max-height: 420px;
    display: flex;
    margin-top: 10px;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-x: overlay;
    position: relative;

  }
}

  .tutorialDiv{
    cursor: pointer;
    height: 175px;
    width: 220px;
    border-radius: 10px;
    border: 1px solid #cacaca;
    background: url('../../../../assets/bg-tutorial.svg') no-repeat top;
    background-color: #f9f9f9;
    box-sizing:border-box;
    padding:15px 20px;
    transition: .3s all ease-in-out;
    position: relative;
    margin: 5px 5px 15px 5px;
    overflow: hidden;


    &:hover{
      bottom: 3px;
    }


    h3{
      position: relative;
      z-index: 50;
      color:white;
      font-size: 16px;
      height: 60px;
    }
    
    img{
      position: relative;
      z-index: 50;
      margin-top: 5px;
      height: 30px;
    }

    .tags{
      margin-top: 15px;
      display: flex;
      height: 20px;
      align-items: center;

      p{
        font-size: 14px;
        color:#333333;
        margin-right: 7px;
      }

      .tagPill{
        height: 20px;
        width: 95px;
        border-radius: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: white;
        border:1px solid;
        margin-right: 5px;
        cursor: pointer;

        &.basic{
          background-color:$main-blue--light;
          border-color:$main-blue--light;
        }

        &.tips{
          background-color:$main-purple;
          border-color:$main-purple;
        }

        &.writing{
          background-color:$main-yellow;
          border-color:$main-yellow;
        }

        &.onboarding{
          background-color:#D00000;
          border-color:#D00000;
        }
      }
      
    }

    .bg{
      height: 130px;
      width: 220px;
      overflow: hidden;
      position: absolute;
      top:-5px;
      left:0;
      z-index: 1;

      img,div{
        height: 100%;
        width: 100%;
        position: absolute;
        top:0;
        left:0;
      }

      img{
        object-fit: cover;
        z-index: 5;
      }

      div{
        z-index: 10;
        background-color: #077CA0;
        opacity: .80;
      }
    }
  }
  
  
      .closeBtn{
        @include solidButton(white, $main-blue--bright, 18px, 35px, 180px);
        position: absolute;
        bottom:15px;
        right:35px;
      }