
.container{
  width: fit-content;
  background-color: white;
  -webkit-box-shadow: 0px 0px 10px -2px rgba(6, 0, 0, .3);
  -moz-box-shadow:    0px 0px 10px -2px rgba(6, 0, 0, .3);
  box-shadow:         0px 0px 10px -2px rgba(6, 0, 0, .3);
  border-radius: 5px;
  position: absolute !important;
  top:-5px;
  opacity: 0;
  transition: .2s all ease-in-out;
  left:50%;
  transform: translateX(-50%);
  z-index: 15;
  pointer-events: none;
  display: flex;
  justify-content: center;

  p{
    width: fit-content;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    font-weight: 300;
    margin:0 !important;
    color: #333333;
  }

}