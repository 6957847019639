@import '../../../../shared.scss';

.container{
  display: none;
  width: calc(100vw - 15px);
  height: calc(var(--viewportHeight) - 50px);
  background-color: white;
  border-radius: 10px;
  box-sizing:border-box;
  padding:10px;
  overflow: hidden;



  p{
    margin:0;
    &.sml{
      font-size: 12px;
      font-weight: 300;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width:100%;
      white-space: nowrap;
    }

    &.count{
      color: #858585;
      font-size: 12px;
      font-weight: 300;
      margin-left: 25px;
      font-style: italic;
    }
  }

  h6{
    font-size: 14px;
    font-weight: 500;
    color: #333333;
    margin:0;
  }
  

  
  .topBar{
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;

    h5{
      font-size: 14px;
      font-weight: 500;
      color: $E_blue_dark;
    }

    img{
      height: 60px;
      width: 60px;
      border-radius: 50%;
      object-fit: contain;
    }
  }
  
  .detailsBar{
    box-sizing:border-box;
    padding:0 10px;
    
    h3{
      font-size: 16px;
      font-weight: 500;
      color: #4e4e4e;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: calc(100% - 5px);
      white-space: nowrap;
      margin-top: 5px;
      
      span{
        display: inline-flex;
        background-color: #CDDBDF;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        position: relative;
        bottom: 2px;
        margin-right: 10px;
        p{
          font-size: 12px;
          margin:0
        }
      }
    }
  }
  
  .editorDiv{
    width: 100%;
    height: calc(100% - 250px);
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;


    .privateModeOverlay{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(255, 255, 255, 0.515);
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
      z-index: 20;

      *{
        text-align: center;
      }

      img{
        height: 65px;
      }

      h1{
        font-size: 20px;
        font-weight: 700;

        span{
          font-size: 14px;
          color: white;
          background-color: $E_green;
          padding:5px 10px 3px 10px;
          border-radius: 10px;
          font-weight: 700;
        }
      }

      p{
        width: 70%;
        color: #858585;
        font-weight: 300;

      }

    }
  }
  
  .wordbank{
    margin-top: 10px;

    .titleBar{
      display: flex;
      align-items: center;
      
      img{
        height: 30px;
        width: 30px;
        object-fit: contain;
        margin-right: 10px;
      }
    }
    .itemsDiv{
      width: 100%;
      display: flex;
      overflow-x: auto;
      // flex-wrap: wrap;
      margin-top: 10px;
      margin-bottom: 5px;
      height: 40px;
      overflow: auto;

      .wordBlock{
        height: 30px;
        padding:0 15px;
        border-radius: 8px;
        line-height: 30px;
        font-size: 12px;
        margin-right: 5px;
        margin-bottom: 5px;
        background-color: $E_blue_light;
        color: $E_blue;
        font-weight: 300;

        &.used{
          padding-right: 30px;
          color: white;
          background: url('../../../../assets/tick_grey.svg') center right 10px no-repeat;
          background-color: $main_green;
        }
      }
    }
  }

    
  .filesDiv{
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    box-sizing:border-box;
    padding:0 10px 0 0;
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom: 5px;

    .fileDiv{
      height: 80px;
      min-width: 100px;
      max-width: 100px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // overflow: hidden;
      margin-right: 10px;
      cursor: pointer;
      position: relative;

      *{
        border-radius: 10px;
      }

      &.pdfFile{
        border:2px solid #077CA0;
        box-sizing:border-box;
        padding:8px 0 0 0;
      }
      
      &.placeholder{
        border:1px solid #077ca075;
        background-color: rgba(128, 128, 128, 0.05);

        img{
          height: 80px;
          object-fit: contain;
        }
      }

      p{
        font-weight: 500;
        margin-top: 3px;
        font-size: 13px;
        color:#077CA0;
      }

      img{
        height: 100%;
        width: 100%;
        object-fit: cover;

        &.pdfFile{
          height: 30px;
          object-fit: contain;
        }
      }

      .hoverDiv{
        height: 100%;
        width: 100%;
        position: absolute;
        top:0;
        left:0;
        opacity: 0;
        
      }
    }
  }

  .imagePreviewOverlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(58, 58, 58, 0.7);
    z-index: 1550;
    display: flex;
    align-items: center;
    justify-content: center;

    .imgDiv{
      max-height: 50%;
      max-width: calc(100% - 40px);
      position: relative;

      .close{
        height: 20px;
        width: 20px;
        position: absolute;
        top: -5px;
        right: -5px;
        background: url('../../../../assets/cross_circle_teal.svg') no-repeat center;
        background-size: contain;
        border-radius: 50%;
      }

      img{
        height: 100%;
        width: 100%;
      }
    }
  }



  .buttons{
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .right{ 
      display: flex;       
      
      .editBtn,
      .approveBtn{
        height: 35px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #E5F2F6;
        box-sizing:border-box;
        padding:0 9px;
        cursor: pointer;
        transition: .2s all ease-out;
        border-radius: 5px;
        position: relative;
        z-index: 20;

        &:hover{
          background-color: #B5D8E3;
        }

        p{
          font-size: 13px;
          color: #077CA0;
          position: relative;
          bottom: 1px;
        }

        img{
          height: 18px;
          width: 18px;
          position: relative;
          top: -2px;
        }

        &.inactive{
          opacity: .4;
          pointer-events: none;
        }
      }
      
      .approveBtn{
        width: 90px;
        background-color: #077CA0;
        margin-left: 10px;
        justify-content: space-between;
        &.disabled{
          opacity: 1 !important;
          background-color: #c6e6f0;
          pointer-events: none;
        }
        
        p{
          color: white;
        }

        &:hover{
          background-color: rgb(8, 94, 121);
        }
      }
    }
  }
  
  
  @media (max-width:660px){
    display: flex;
    // align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }
}