@import '../../../../shared.scss';

.container{
  width: 840px;
  height: 620px;
  box-sizing:border-box;
  padding:10px 20px;
  position: relative;
  transition: all .3s ease-in-out;

  &.conf{
    height: 320px;
    width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing:border-box;
    padding:30px 0 0 0;

    h1{
      margin-top: 10px;
      font-size: 30px;
    }
  }



  input::placeholder,
  textarea::placeholder{
    color: #858585;
    font-weight: 300;
    font-size: 14px;
  }

  .inner{
    width: 600px;
    margin: 0 auto;
  }

  .header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 600px;
    margin: 0 auto 20px auto;

    img:first-of-type{
      height: 80px;
      position: relative;
      bottom:13px;
    }
    img:last-of-type{
      height:30px
    }
  }

  .inputs{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    input{
      width: 49%;
      height: 40px;
      box-sizing:border-box;
      padding:0 10px;
      border:1px solid #cacaca;
      border-radius: 3px;
      margin-bottom: 10px;
    }
  }

  p.subhead{
    margin-bottom: 30px;
  }

  h1{
    
    text-align: center;
    font-size: 30px;
    color:#333333;
  }

  p, label{
    font-size: 16px;
    margin:8px 0;
    color:#4b4a4a;
  }

  label{
    display: block;
    padding-left: 10px;
  }


  textarea{
    margin-top: 7px;
    background-color: transparent;
    border:1px solid #cacaca;
    height: 110px;
    border-radius: 3px;
  }

  .submitBtn{
    @include solidButton(white, $main-blue--bright, 15px, 40px, 200px);
    // position: absolute;
    // bottom:-5px;
    // right:20px;
    margin: 10px auto;
  }

  .disabledBtn{
    pointer-events: none;
    opacity: .6;
  }
}

