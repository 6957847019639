@import '../../../shared.scss';

.container{
  width: 100%;
  height:100%;
  background-color: white;

  *{
    font-family: 'DM Sans', sans-serif;
  }

  .topBar{
    background-color: white;
    -webkit-box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.1); 
    box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.1);

    .row{
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing:border-box;
      padding:0 15px;
      position: relative;

      &.center{
        justify-content: center;
      }

      &.marginTop{
        margin-top: 20px;
      }

      &.leftAlign{
        justify-content: flex-start;



        .classMates{
          display: flex;
          align-items: center;

          img,
          .moreStudents{
            height: 40px;
            width: 40px;
            object-fit: contain;
            border-radius: 50%;
            border:2px solid $E_blue;
            position: relative;
            background-color: white;
            cursor: pointer;
          }

          .moreStudents{
            line-height: 40px;
            border-radius: 30px;
            text-align: center;
            color: white;
            background-color: $E_blue;
            font-weight: 700;
            font-size: 20px;
            // left: -20px;
            cursor: pointer;
            transition: .3s all ease-out;
            
            span{
              position: absolute;
              top:50%;
              left:50%;
              transform: translate(-50%, -50%);
              transition: .15s all ease-out;
              white-space: nowrap;

              &.oval{
                opacity: 0;
              }
            }
            &:hover,
            &.viewAll{
              width: 80px;

              span{
                &.oval{
                  opacity: 1;
                  font-size: 14px;
                }
                &.circle{
                  opacity: 0;
                }
              }
            }
          }
          .avatars{
            display: flex;
            margin-left: 15px;
            align-items: center;
            height: 100%;
  
            a{
              position: relative;
              height: 40px;
              bottom: 2px;
              .tooltip{
                position: absolute;
                top: 25px;
                background-color: white;
                font-size: 12px;
                font-weight: 300;
                white-space: nowrap;
                left:50%;
                transform: translateX(-50%);
                padding:0px 10px;
                pointer-events: none;
                opacity: 0;
                border-radius: 5px;
                transition: .15s all ease-out;
                -webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.2);
                -moz-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.2);
                box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.2);
              }
  
              &:hover{
                .tooltip{
                  opacity: 1 !important;
                  top: 45px !important;
                }
              }
            }
          }

        }

        @media (max-width:660px){
          display: none;
        }
      }

      .rowButtons{
        height:40px;
        position: absolute;
        right: 20px;
        display: flex;
        align-items: center;
        top: -3px;

        .btn{
          height: 30px;
          width: 130px;
          border-radius: 5px;
          border: 1px solid $E_blue;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          margin-left: 8px;
          transition: .3s all ease;

          &:hover{
            background-color: #eaf9fd;
          }

          .icon{
            height:15px;
            width:15px;

            &.teacher{
              background: url('../../../assets/cap_blue.svg') no-repeat center;
              background-size: 14px;
            }
            &.student{
              background: url('../../../assets/icon_add_student.svg') no-repeat center;
              background-size: 16px;
            }
          }

          p{
            color: $E_blue;
            font-size: 14px;
          }
        }

        @media (max-width:1050px){
          .btn{
            width: 35px;
            p{
              display: none;
            }
          }
        }

        @media (max-width:670px){
          display: none;
        }
      }

      .codeDiv{
        border-radius: 10px;


        &.one{
          background-color: #97D318;
        }

        &.two{
          background-color: #F26283;
        }

        &.three{
          background-color: #FF8258;
        }

        &.four{
          background-color: #FFA826;
        }

        &.grey{
          background-color: #c4c4c4;
        }

        .codeInner{
          margin: 8px;
          border-radius: 8px;
          padding:3px 15px;
          background-color: rgba(255, 255, 255, 0.3);
          color: white;
        }
      }

      h5.classTitle{
        color: #333333;
        margin-left: 15px;
        font-size: 17px;
        
        &.synod{
          font-size:25px;
          margin-left: 5px;
        }
      }

      .leftItems{
        display: flex;
        align-items: center;
        height: 50px;
        width: 500px;
        position: relative;
        left: -15px;
        bottom: 1px;

        .back{
          height: 30px;
          width: 30px;
          border-radius: 5px;
          background: url('../../../assets/Icons/arrow_square2.png') no-repeat center;
          cursor: pointer;
          margin-right: 5px;
          position: relative;
          margin-right: 10px;
    
          &:hover{
            background: url('../../../assets/Icons/arrow_square_hover2.png') no-repeat center;
          }

          a.btn{
            height: 30px !important;
            width: 30px !important;
            position: absolute;
            left: 0;
          }
        }

        h3{
          color: #333333;
          font-weight: 500;
          font-size: 17px;
          margin-right: 15px;
        }
      }

      .rightItems{
        display: flex;
        align-items: center;
        position: relative;
        top: 10px;

        .teacherPic{
          height: 40px;
          width: 40px;
          border-radius: 50%;
          overflow: hidden;
          margin-left: 10px;

          img{
            height: 40px;
            width: 40px;
            object-fit: cover;
          }
        }

    .search{
      height: 40px;
      width: 40px;
      border-radius: 20px;
      overflow: hidden;
      background-color: #F2F2F2;
      align-items: center;
      cursor: pointer;
      border:2px solid white;
      transition: .15s all ease-out;
      transition: .4s width ease-in-out;
      position: relative;
      display: flex;
      align-items: center;
      

      .inner{
        height:calc(100% - 2px);
        width:calc(100% - 2px);
        background-color: white;
        border:1px solid  #cacaca;
        border-radius: 20px;
        overflow: hidden;
        position: absolute;
        z-index: 1;
        right: 0;
        display: flex;

      }
      input[type=text]{
        width:calc(100% - 50px);
        height: 30px;
        line-height: 30px;
        border:none;
        position: absolute;
        z-index: 5;
        right: 10px;
        top: 2px;
        z-index: 10;
        background-color: transparent;
      }
      
      .lupa{
        min-height:40px;
        max-height:40px;
        min-width:40px;
        max-width:40px;
        border-radius: 50%;
        background: url('../../../assets/icon-lupa.png') no-repeat 11px 10px, #F2F2F2;
        background-size: 17px;
        transition: .15s all ease-out;
        position: relative;
        z-index: 10;
      }

      &:hover{
        border:2px solid #CDDFFA;

        .inner{
          border-color: #065FE5;
        }
      }



      &.open{
        width: 300px;
        border:2px solid #CDDFFA;
        background-color: white;

        .lupa{
          border-color: white !important;
          background-color: white !important;
        }
        .inner{
          border-color: #065FE5;
        }
      }
    }

      }



    }


    .radios{
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      left: 20px;

      .radioDiv{
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 120px;
        cursor: pointer;
        transition: .3s all ease-out;
        position: relative;

        .dot{
          max-height: 6px;
          min-height: 6px;
          max-width: 7px;
          min-width: 7px;
          border-radius: 50%;
          background-color: red;
          position: absolute;
          top: 13px;
          right: 7px;
        }

        &.noClick{
          pointer-events: none;
        }

        p{
          font-size: 14px;
          // text-transform: uppercase;
          font-weight: 300;
          letter-spacing: 1px;
          color: $E_blue;
          white-space: nowrap;
          transition: .2s all ease-out;
        }

        &.grey{
          p{
            color: #858585;
          }
        }



        &:hover{
          // background-color: $E_blue_light2;
          p{
            color: #4e4e4e;
          }
        }
        
      }

      .line{
        width: 120px;
        height: 3px;
        background-color: $E_blue;
        position: absolute;
        top: 38px;
        left: 0;
        transition: .2s all ease-out;

        &.students{
          left: 120px;
        }

        &.teachers{
          left: 240px;
        }

        &.reports{
          left: 360px;
        }

      }
    }

  }

  .mainSwitch{
    height: calc(100% - 120px);
    width: 100%;
    box-sizing:border-box;
    overflow: auto;

    &.activities{
      background-color: #E5F2F6;
    }
  }

  @media (max-width:660px){
    overflow: hidden;
    height: calc( var(--viewportHeight) - 65px);

    .topBar{
      .search{
        display: none !important;
      }
    }

    .mainSwitch{
      overflow-x: hidden;
      height: calc(100% - 70px);
    }
  }

  &.synod{
    height: var(--viewportMinus) !important;
  }

}