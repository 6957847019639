@import '../../../../../shared.scss';

.container{
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}


.commentBtn{
  height: 40px;
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #E5F2F6;
  box-sizing:border-box;
  padding:0 15px;
  cursor: pointer;
  transition: .2s all ease-out;
  border-radius: 5px;
  position: relative;
  z-index: 10;

  .dot{
    position: absolute;
    height:13px;
    width:13px;
    border-radius: 50%;
    background-color: red;
    top: -3px;
    right: -3px;
  }


  &:hover{
    background-color: #B5D8E3;
  }

  p{
    font-size: 15px;
    color: #077CA0;
    position: relative;
    left: 13px;
  }

  &.disabled{
    opacity: .4;
    pointer-events: none;
  }

  img{
    position: absolute;
    left:17px;
    top: 12px;
    height: 18px;
  }


  &.open{
    background-color: #077CA0;
    // width: 140px !important;

    p{
      color: white;
      left: -5px;
    }

    img{
      position: absolute;
      left:initial;
      right: 25px;
      top: 18px;
      height: 6px;
    }

    &:hover{
      background-color: rgb(8, 94, 121);
    }
  }


  &.green{
    width:180px;
    background-color: #CBE98B;
    
    p{
      color: #5D820E;
    }


    img.chevron{
      transform: rotate(180deg);
    }
    
    &.open:hover{
      background-color: #d6eda6;

    }
  }

}

.dynamicBlock{
  max-height: 0px;
  height: fit-content;
  min-height: 0;
  // width: 540px;
  width: 40vw;
  max-width: 800px;
  border-radius: 10px;
  position: absolute;
  opacity: 0;
  bottom: 20px;
  transition: .2s all ease-out;
  z-index: 5;
  background-color: #83BDD0;
  box-sizing:border-box;
  padding:10px;

  .commentsDiv{
    width: 100%;
    display: flex;
    flex-direction: column;

  .commentContainer{
    width: calc(100% - 10px);;
    min-height: 65px;
    padding:5px 10px;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: white;
    // display: flex;
    // align-items: center;
    margin-bottom: 10px;
    border-radius: 10px 10px 10px 0px;

    
    .dateTimeDiv{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 6px;
      
      p.dateTime{
        margin: 5px 0 0 0;
        font-size: 10px;
        color: #858585;
      }
    }

    &.studentComment{
      background-color: $E_green_light;
      border-radius: 10px 10px 0px 10px;
      align-self: flex-end;

      *{
        // color: white;
        background-color: transparent;
      }
    }

    textarea.finalInput{
        min-height:80px;
        height: auto;
        width:calc(100% - 6px);
        background-color: white;
        border: 1px solid #077CA0;
        border-radius: 6px;
        position: relative;
        padding: 0;
        line-height: 16px;
        // top: 1px;
        // left: 2px;
        font-weight: 300;
        font-size: 15px;
        margin:4px !important;
      
      color: #97D318;
      font-size: 15px;
      border:none;
      width: 100%;

    }

    h5{
      font-size: 15px;
      font-weight: 500;
      color: #074B60;
      margin: 0;

      &.revision{
        color:#FB3333
      }

      &.final{
        color: #97D318;
        width: 100%;
        border:1px solid cyan;
        cursor: text;
      }

      background-color: white ;
    }

    // &:last-of-type{
    //   margin-bottom: 0;
    // }
  }    
  }

  .inputDiv{
    height: fit-content;
    width: 100%;
    background-color: white;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing:border-box;
    padding:0 5px;
    margin-top: 10px;

    &.blocked{
      display: none;
    }

    &.noMargin{
      margin-top: 0px;
    }

    .inputContainer{
      width:calc(100% - 115px);
      min-height: 45px;
      height: fit-content;
      border:3px solid #CDE1E8;
      background-color: #CDE1E8;
      border-radius: 8px;
      display: flex;
      align-items: center;
      position: relative;
      margin:4px 0;

      textarea{
        min-height:45px;
        height: auto;
        width:calc(100% - 6px);
        background-color: white;
        border: 1px solid #077CA0;
        border-radius: 6px;
        position: relative;
        padding: 15px 100px 5px 8px;
        // top: 1px;
        // left: 2px;
        font-weight: 300;
        font-size: 15px;
        margin:4px !important;
      }

      input[type=checkbox]{
        display: none;
      }

      .checkboxDiv{
        width: 85px;
        position: absolute;
        right: 5px;
        top: 8px;
        height: calc(100% - 10px);
        

        label{
          display: flex;
          align-items: center;
          height: 100%;
        }

        h5{
          font-size: 12px;
          margin:0;
          width: 80px;
          position: relative;
          left: 25px;
        }

        .checkboxContainer{
          height: 40px;
          width: 80px;
          position: absolute;
          display: flex;
          align-items: center;
          z-index: 10;
          cursor: pointer;
        }

        .checkbox{
          height: 10px;
          width: 10px;
          border:2px solid #D2D2D2;
          border-radius: 50%;
          margin-right: 8px;
          cursor: pointer;
          position: absolute;
        }
      
        input:checked + .checkboxContainer .checkbox{
          border-color: $main-blue--bright;
          position: relative;

          &:after{
            content: "";
            min-height: 6px;
            max-height: 6px;
            min-width: 6px;
            max-width: 6px;
            border-radius: 50%;
            background-color: $main-blue--bright;
            position: absolute;
            bottom:2px;
            left: 2px;
          }
        }
        
      }

      &.revision,
      &.final{
        input[type=text]{
          box-sizing:border-box;
          padding:0 95px 0 10px;
          width:calc(100% - 6px);
        }
      }

      &.revision{
        .checkboxDiv{
          label h5{
            color: #FB3333 !important;
          }
                
        input:checked + .checkboxContainer .checkbox{
          border-color:#FB3333 !important;

          &:after{
            background-color:#FB3333 !important;
          }
        }

        } 
      }

      &.final{
        .checkboxDiv{
          label h5{
            color: #97D318 !important;
          }
                
        input:checked + .checkboxContainer .checkbox{
          border-color:#97D318 !important;

          &:after{
            background-color:#97D318 !important;
          }
        }

        } 
      }

    }


  }

  &.open{
    max-height: 700px;
    // min-height: 150px;
    bottom: calc(100% + 10px);
    opacity: 1;
  }


  .sendBtn{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 45px;
    border-radius: 10px;
    background-color: #83BDD0;
    cursor: pointer;
    transition: .2s all ease-out;

    p{
      color: white;
      font-weight: 500;
      margin-right: 10px;
    }
    &:hover,
    &.active{
      background-color: #077CA0;
    }
  }


  @media (max-width:650px){
    width: calc(100vw - 35px);

    .inputDiv{
      .inputContainer{

        .checkboxDiv{
          display: none !important;
        }
        textarea{
          padding: 15px 8px 5px 8px !important;
        }
      }
    }
  }
}