@import '../../../../shared.scss';


.top{
  height:10%;
  width:100%;
  
  h1.title{
    text-align: right;
    padding-right: 60px;
  }
}

.main{
  height:40%;
  width:100%;
  box-sizing:border-box;
  padding:10px 0;
  // border:1px solid chartreuse;

  .inner{
    height: 100%;
    background-color: $grey-light2;
    border:1px solid $grey-three;
    border-radius: 15px;
    box-sizing:border-box;
    padding:10px 15px;
    display: flex;
    align-items: center;

    p{
      margin:0;
      width: 60%;
      font-size: 14px;
      color: $main-orange;
      box-sizing:border-box;
      padding:0 10px;
      line-height: 20px;
    }

    img{
      height:174px;
      width: 40%;
      position: relative;
      top: 10px;
      object-fit: contain;
      // border:1px solid chartreuse;
    }
  }

}


.details{
  height: 35%;
  width: 100%;
  display:flex;
  // position: absolute;
  // bottom: 30px;
  align-items: center;
  justify-content: space-between;

  img{
    // border:1px solid chartreuse;
    object-fit: contain;
    height:100px;
    width:180px;
  }

  .switchDiv{
    width:320px;
    height: 80px;
    border-radius: 10px;
    background-color: $grey-light2;
    border:1px solid $grey-three;
    // margin: 0 0 0 20px;
    display: flex;
    align-items: center;
    box-sizing:border-box;
    padding:10px;

    img{
      height:35px;
      width:35px;
      object-fit: contain;
      margin-right: 10px;
    }
    p{
      font-size: 12px;
      color: $main-orange;
      margin: 9px 0;
    }
  }

}

.details{
  height: 35%;
  width: 100%;
  // border:1px solid chartreuse;
}

.bottom{
  height:15%;
  width: 100%;
  text-align: right;
  .gotIt{
    @include borderButton(white, $main-blue--bright, 16px);
    padding:7px 50px;
    font-weight: 700;
  }
  // border:1px solid chartreuse;
}

@media (max-width:600px){

  .top{
    height: 5%;

    h1.title{
      padding:0;
      font-size: 21px;
      text-align: center;
    }
  }

  .main{

    height:50%;
    .inner{
      flex-direction: column;

      img{
        height:150px;
        width: 50%;
      }
      p{
        width: 100%;
        text-align: center;
      }
    }
  }

  .details{
    height: 25%;
    flex-direction: column;
  }

  .bottom{
    height: 10%;
    text-align: center;

    .gotIt{
      padding:10px 80px;
    }
  }
}