
@import '../../../../shared.scss';

.container{
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding:15px 20px 30px 20px;
  position: relative;

  &.archived{
    background-color: #f2f2f2;

    h5.noArchive{
      color: #858585;
      font-weight: 300;
      margin-top: 200px;
    }

    p.goBack{
      font-size: 12px;
      font-weight: 300;
      cursor: pointer;
      color: $E_blue;
      margin-top: 5px;
      &:hover{
        text-decoration: underline;
      }
    }
  }


  h5.noActivitiesText{
    color: $E_blue;
    font-weight: 300;
    margin-top: 200px;
  }
  


    .radios{
      display: flex;
      align-items: center;
      position: relative;
      left: 20px;
      position: relative;
      bottom: 15px;
      left: -0px;
      width:100%;
      justify-content: flex-start;

      .radioDiv{
        height: 43px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        cursor: pointer;
        transition: .3s all ease-out;

        &.noClick{
          pointer-events: none;
        }

        p{
          font-size: 13px;
          font-weight: 300;
          letter-spacing: 1px;
          color: $E_blue;
          transition: .2s all ease-out;
        }

        &.grey{
          p{
            color: #858585;
          }
        }



        &:hover{
          p{
            color: #4e4e4e;
          }
        }
        
      }

      .line{
        width: 80px;
        height: 2px;
        background-color: $E_blue;
        position: absolute;
        top: 38px;
        left: 0;
        transition: .2s all ease-out;

        &.archived{
          left: 80px;
        }
      }
    }

  .noActivities{
    width: 500px;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    position: relative;

    h5,h3.main{
      color: $E_blue;
    }

    h5.main{
      font-size: 15px;
      margin:10px 0;
    }
    h3.main{
      font-size: 25px;
      font-weight: 700;
      margin: 30px 0;
    }

    img.arrow{
      position: absolute;
      right: -50px;
      top: 260px;
    }

    .arrowRes{
      display: none;
    }

    .btnDiv{
      width: 350px;
    }

    @media (max-width:660px){
      height: calc(100vh - 150px);
      margin-top: 0;
      justify-content: flex-start;

      img{
        height: 80px;
      }

      img.arrow{
        display: none;
      }
      img.arrowRes{
        display: initial;
        position: absolute;
        top: 150px;
        left: 47%;
        transform: translateX(-50%);
        height: calc(var(--viewportHeight) - 400px);
        object-fit: contain;
      }

      h5{
        font-size: 14px !important;
      }
      h3{
        font-size: 22px !important;
      }
    }
  }

  .loaderDiv{
    min-height: calc(100vh - 200px);
    width:100%;
    position:relative
  }

  .listTopBar{
    width: 70vw;
    min-width: 500px;
    max-width: 800px;
    height: 40px;
    margin: 50px 0 15px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    // border:1px solid cyan;

    .newBtn{
      height: 40px;
      width: 160px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      background-color: $E_blue;
      cursor: pointer;
      transition: .3s all background-color;
      color: white;

      .icon{
        height:20px;
        width:20px;
        background: url('../../../../assets/plus_white.svg') no-repeat center;
      }

      &:hover{
        background-color: #067394;
      }
    }

    .archiveBtn{
      height:35px;
      width:35px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid transparent;
      cursor: pointer;
      transition: .3s all ease;
      background: url('../../../../assets/archive_blue.svg') no-repeat center;
      border-radius: 5px;
      position: relative;
      margin: 5px 5px 0 0;

      .tooltip{
        font-size: 11px;
        padding: 5px 15px;
        background-color: white;
        border-radius: 5px;
        white-space: nowrap;
        position: absolute;
        top: 5px;
        font-weight: 300;
        z-index: 501;
        color: #333333;
        -webkit-box-shadow: 1px 2px 4px 0px rgba(0,0,0,0.15);
        -moz-box-shadow: 1px 2px 4px 0px rgba(0,0,0,0.15);
        box-shadow: 1px 2px 4px 0px rgba(0,0,0,0.15);
        transition: .25s all ease;
        pointer-events: none;
        opacity: 0;

      }
      &:hover{
        border-color: $E_blue;
        .tooltip{
          top: 40px;
          opacity: 1;
        }
      }
    }

    .back{
      height:35px;
      width:35px;
      position: absolute;
      left: 0;
      
      height: 30px;
      width: 30px;
      border-radius: 5px;
      background: url('../../../../assets/Icons/arrow_square2.png') no-repeat center;
      cursor: pointer;
      margin-right: 5px;
      
      &:hover{
        // opacity: .7;
        background: url('../../../../assets/Icons/arrow_square_hover2.png') no-repeat center;
      }
    }

    h5{
      width: 100%;
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      color: #cacaca;
    }


    @media (max-width:670px){
      width: 80vw;
      min-width: initial;
      max-width: 800px;
    }
  }

  .newActivityDiv{
    height: 45px;
    width: 180px;
    border-radius: 10px;
    // border:2px dashed  #608E9D;
    stroke-width: 5;
    stroke-dashoffset: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .1s background-color ease-out;
    z-index: 30;
    position: relative;
    background-color: $E_blue;
    margin: 0 auto;
    // box-sizing:border-box;
    // padding:0 50px 0 0;


    &:hover{
      background-color: $E_blue_light2;
    }

    h5{
      font-size: 15px;
      color:white;
      // width: 70%;
      text-align:left;
      font-weight: 500;
      white-space: nowrap;

    }

    .plus{
      height: 15px;
      width: 15px;
      background: url('../../../../assets/plus_white.svg') no-repeat center;
      background-size: cover;
      margin: 0 10px 0 0px;
    }

    &.big{
     
      height: 110px;
      width: calc(100% - 10px);
      left: 5px;
      border-radius: 10px;
      border:2px dashed  #608E9D;
      stroke-width: 5;
      stroke-dashoffset: 10;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: .1s background-color ease-out;
      z-index: 30;
      position: relative;
      background-color: transparent;

      &:hover{
        background-color: $E_blue_light2;
      }

      .plus{
        height: 20px;
        width: 20px;
        background: url('../../../../assets/plus_blue_light.svg') no-repeat center;
        background-size: contain;
        margin: 0 10px
      }

      h5{
        color: #608E9D;
        font-size: 22px;
        margin:0;
        font-weight: 500;
        position: relative;
        bottom: 1px;
        // left: 5px;
      }

      &.hidden{
        opacity: 0;
        pointer-events: none;
      }
    }

    @media (max-width:660px){
      display: none;
    }
  }
  .newGroupBtn{
    height: 45px;
    width: 140px;
    border-radius: 10px;
    stroke-width: 5;
    stroke-dashoffset: 10;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: .1s background-color ease-out;
    background-color: $E_blue;
    z-index: 30;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    &:hover{
      background-color: $E_blue_dark;
    }

    .plus{
      height: 25px;
      width: 25px;
      background: url('../../../../assets/plus_white.svg') no-repeat center;
      margin: 0;
    }

    p{
      color: white;
      font-size: 15px;
      margin:0;
      font-weight: 500;
      position: relative;
      bottom: 1px;
      // left: 5px;
    }
  }

  .newActivityBtnRes{
    position: fixed;
    right: 15px;
    bottom: 90px;
    height: 50px;
    width: 50px;
    border-radius: 10px;
    background: #0CA7D8;
    background:url('../../../../assets/icon-add-white.svg') no-repeat center, linear-gradient( 15deg , #0CA7D8 12%, #2E66AE 86%);
    z-index: 501;
    display: none;

    h5{
      display: none;
    }

    &.big{
      width: 140px;
      background:url('../../../../assets/icon-add-white.svg') no-repeat left 10px center, linear-gradient( 15deg , #0CA7D8 12%, #2E66AE 86%);

      h5{
        display: initial;
        color: white;
        font-size: 15px;
        font-weight: 500;
        padding-left: 20px;
        line-height: 50px;
      }
    }

    @media (max-width:660px){
      display: initial;
    }
    
  }

}