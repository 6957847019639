@import '../../../../../../shared.scss';

    
.container{
  width: 100%;
  height: 100%;
  border-radius: 15px;
  box-sizing:border-box;
  padding:5px 15px;
  position: relative;
  transition: .3s ease-in-out all;

  p.header{
    font-size: 17px;
    color: #333333;
    padding-left: 15px;
    margin-top: 0;
  }


  .pills{
    height: fit-content;
    display: flex;
    width: 95%;
    margin: 0 auto;
    justify-content: flex-start;
    flex-wrap: wrap;
    transition: all .3s ease-in-out;
    position: relative;
    // box-sizing:border-box;
    // padding:0 20px;
    // border:1px solid chartreuse;

    @media (max-width:1350px) {
      label {
        min-width: initial !important;
        input+.row {
          p {
            font-size: 12px !important;
            font-size: 12px;
            max-width: 70%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }

    input[type='radio']{display:none};

    label>input+.row{
      // height:19.5%;
      width: 100%;
      min-width: 120px;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: .3s all ease-out;
      padding: 0 5px;

      p{
        text-align: center;
        font-size: 14px;
        color: black;
        margin:11px 8px;
      }
      .number{
        height:22px;
        width:22px;
        line-height: 23px;
        border-radius: 50%;
        font-size: 14px;
        text-align: center;
        color: white;
        margin-right: 2px;
        box-sizing:border-box;
        padding:0 0 0 1px;
      } 
    }

    label{
      min-width: 110px;
      margin-right: 15px;

      input+.row{
        width: 100%;
        height: 23px;
        border-radius: 13px;
        background-color: #d2d2d2;
        margin:3px 0;
        border:1px solid transparent;
        // border:1px solid chartreuse;

        p{
          font-size: 13px;
          font-weight: 300;
          color: #4e4e4e;
          margin:0;
          transition: none;
        }
        .number{
          color: #4e4e4e;
          margin-left: 5px;
        }
        &:hover{
          p, 
          .number{
            color: white
          }
        }
      }

      input:checked+.row{
        color: white;
        p, 
        .number{
          color: white
        }
      }

      &:nth-of-type(1) { //PASSIVE VOICE
        
        input+.row {
          &:hover {
            border-color: #f26283;
            background-color: #f262844a;
          }
        }

        input:checked+.row{
          background-color: #f26283;
        }
      }


      &:nth-of-type(2) { //INITIAL PRONOUNS

        input+.row {
          &:hover {
            border-color: #ffa826;
            background-color: #ffa8264a;
          }
        }

        input:checked+.row{
          background-color: #ffa826;
        }
      }

      &:nth-of-type(3) { //GRAMMAR

        input+.row {
          &:hover {
            border-color: #8e00f0;
            background-color: #8e00f04a;
          }
        }

        input:checked+.row{
          background-color: #8e00f0;
        }
      }


      &:nth-of-type(4) { //OVERUSE

        input+.row {
          &:hover {
            border-color: #077ca0;
            background-color: #077ca04a;
          }
        }

        input:checked+.row{
          background-color: #077ca0;
        }
      }

      &:nth-of-type(5) { //CONSITENCY

        input+.row {
          &:hover {
            border-color: #FF8258;
            background-color: #FF82584a;
          }
        }

        input:checked+.row{
          background-color: #FF8258;
        }
      }

    }

    .row.inactive{
      display: flex;
      align-items: center;

      .number{
        background-color: #d2d2d2;
        width: 20px;
        min-width: 20px;
        height: 20px;
        border-radius: 50%;
        text-align: center;
      }
      p{
        opacity: .5;
        border:none;
        color: grey;
        background-color: transparent;
      }
    }

    .generateSecondBtn{
      @include solidButton(white, $main-yellow, 12px, 25px, 130px);
      position: absolute;
      right: 0;
      bottom: 0px;

      span.res{
        display: none;
      }

      @media (max-width:1250px){
        width: 80px;
        span{
          display: none;
        }
        span.res{
          display: initial;
        }
      }
    }

  }


  div.entryText {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #858585;
    font-size: 14px;
    font-weight: 300;;
    box-sizing:border-box;
    padding:0px 15px;
    text-align: justify;
    align-items: stretch;
    resize: none;
    width: 100%;
    height: calc(100% - 150px);
    flex:1;
    border-radius: 15px;
    background-color: white;
    margin-top: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
    transition: .3s ease-in-out all;
    border:1px solid $grey-light;

    &::-webkit-scrollbar {
      width:6px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: #cacaca;
      border:1px solid white;
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }

    &::-webkit-scrollbar-track:start {
      background-color: transparent;
      margin-top: 30px;
    }

    &::-webkit-scrollbar-track-piece:start {
      margin-top: 10px;
    }

    &::-webkit-scrollbar-track:end {
      background-color: transparent;
      margin-bottom: 30px;
    }

    &::-webkit-scrollbar-track-piece:end {
      margin-bottom: 10px;
    }
  }
}
