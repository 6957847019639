@import '../../shared.scss';

.innerContainer{
  height: 100%;
  width: 100%;
  box-sizing:border-box;
  padding:15px 40px 10px 40px;
  text-align: left;
  overflow: auto;


  h6{
    font-size: 16px;
    color:#333333;
    font-weight: 300;
    margin: 0;
  }

  p{
    font-size: 12px;
    color:#858585;
    font-weight: 300;
    line-height: 20px;
    margin:15px 0 !important;
  }

  .newEmail{
    height: fit-content;
    height: -moz-max-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing:border-box;
    padding:10px 0 10px 0;
    // padding:10px 0 50px 0;
    // border-bottom: 1px solid #cacaca;
    margin-bottom: 20px;
    position: relative;
  }
  input[type="email"]{
    height:40px;
    line-height: 40px;
    box-sizing:border-box;
    padding:0 0 0 15px;
    -webkit-appearance: none;
    border:1px solid #cacaca;
    border-radius: 8px;
    font-size: 14px;
  }

  .checkBoxes{
    height:fit-content;
    height: -moz-max-content;
    border:1px solid #cacaca;
    border-radius: 8px;
    margin-bottom: 30px;
  }

  label{
    display: flex;
    height: 65px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border-bottom:1px solid #cacaca;

    &:last-of-type{border:none}

    h6, p{margin:0 !important}

    input[type="checkbox"]{margin:0 auto}

    .prefCheckText{
      width: 90%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .strong{
    font-weight: 700;
    margin-top: -10px !important;
  }

  .btn{
    @include solidButton(white, $E_blue, 14px, 40px, 100%);
    margin:0px 0;

    &.unedited{
      opacity: .4;
      pointer-events: none;
    }

    &.revert{
      pointer-events: none !important;
      background-color: $E_blue !important;
    }
    
    img{
      height:40px;
      width: 40px;
      object-fit: contain;
    }
  }


  @media (max-width:660px){
    height: fit-content;
    padding:10px 10px 40px 10px;


    h6{
      color:#858585;
      font-size: 14px;
      font-weight: 300;
      padding: 8px 0;
      line-height: 25px;
      text-transform: uppercase;;
      margin: 20px 0 10px 0;

      &:first-of-type{
        margin: 10px 0 10px 0;
      }
    }
    
  }
}

.error{
  font-size: 12px;
  font-weight: 300;
  color: red;
  position: absolute;
  bottom:-13px;
  left:3px;

}

.success{    
  font-size: 14px;
  font-weight: 300;
  color: $E_blue;
  position: absolute;
  bottom: 15px;
  line-height: 18px;
  width: 91%;

}


.hidden{
  display: none;
}


.resMessage{
  font-size: 14px !important;
  font-weight: 300;

}