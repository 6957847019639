

    
        
        .carousel {
            width: 730px;
            height: 450px;
            img{
                width: 100%;
                height: auto;
            }
        }
    


    .closeModal {
        position: absolute;
        top: -20px;
        right: 40px;
        width: 40px;
        height: 40px;
        font-size: 30px;
        line-height: 36px;
        display: flex;
        align-items: center;
        color: #ffffff;
        background: #c01313;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        z-index: 2;
        p{
            margin-top:4px
        }
    }
