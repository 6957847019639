@import '../../../../shared.scss';

.container{
  box-sizing:border-box;
  padding:15px 60px;

  h1{
    box-sizing:border-box;
    padding:0 0 0 50px;
    background: url('../../../../assets/newStudent-individual.svg') no-repeat left center;
  }

  h2{
    font-size: 18px;
    margin-top: 15px;
  }

  .name{
    display: flex;

    .first,
    .second{
      height:90px;
      width: 50%;
      position: relative;


      input[type=text]{
        -webkit-appearance: none;
        outline: none;
        border:1px solid #cacaca;
        border-radius: 5px;
        width: 280px;
        height: 30px;
        box-sizing:border-box;
        padding:0 15px;
        margin-top: 5px;
      }
    }
  }

  .grade{
    height:80px;

    .radios{
      margin-top: 15px;
      display: flex;
      align-items: center;
    }
    input[type=radio]{
      display: none;
    }

    label > input + .pill{
      cursor: pointer;
      height:35px;
      width:35px;
      line-height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border:1px solid $main-blue--bright;
      color: $main-blue--bright;
      font-size: 14px;;
    }

    // label > input:not(:checked) + .pill{

    // }
    label > input:checked + .pill{
      color: white;
      background-color: $main-blue--bright;
      border:1px solid transparent
    }
  } 

  .invite{
    position: relative;

    p{
      box-sizing:border-box;
      padding:0 55px 0 0;
      font-size: 14px;
      color:#858585;
    }
          
    .mailIcon{ 
      cursor: pointer;
      position: absolute;
      right:10px;
      top:90px;       
      background: url('../../../../assets/icon-addEmail.svg') no-repeat center;
      height:40px;
      width:50px;
    }
    .emails{
      display:flex;
      flex-wrap: wrap;
      min-height: 45px;
      max-height: 80px;
      overflow-y: auto;
      height: fit-content;
      height: -moz-max-content;

      .first,
      .second{
        width:45%;
        height:40px;
        // border:1px solid chartreuse;
        margin-right: 5px;
        position: relative;

       

        input[type=text]{
          -webkit-appearance: none;
          outline: none;
          border:1px solid #cacaca;
          border-radius: 5px;
          width: 100%;
          height: 30px;
          box-sizing:border-box;
          padding:0 15px;
          margin-top: 5px;
        }
      }
    }
  }

  .classes{

    .classBlocks{
      height:120px;
      overflow-y: auto;
      display: flex;
      flex-wrap: wrap;
      box-sizing:border-box;
      padding:10px 0 0 0;

      label > input[type=checkbox]{
        display: none;
      }

      label > input + .classBlock{
        cursor: pointer;
        margin:0 15px 10px 0;
        width:130px;
        height:30px;
        border-radius: 5px;
        border:1px solid #cacaca;
        line-height: 30px;
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        text-align: left;

        p{
          font-size: 14px;
          box-sizing:border-box;
          padding:0 0 0 15px;
          margin: 0;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

        }
      }
      label > input:checked + .classBlock{
        border:3px solid #97D318;
        -webkit-box-shadow: 0px 1px 4px 0px #00000025; 
        box-shadow: 0px 1px 4px 0px #00000056;
        width:126px;
        height:26px;
        line-height: 26px;
        position: relative;

        
        p{
          padding-left: 13px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &:after{
          content: '';
          background: url('../../../../assets/teacher/icon-circleTick--small.svg');
          font-size: 15px;
          position: absolute;
          height: 15px;
          width: 15px;
          top: -10px;
          right: -10px;
          color: chartreuse;
        }
      }
    }
  }

  .buttons{
    display:flex;
    margin-top: 30px;

    .btn{
      height:30px;
      line-height: 30px;
      width:120px;
      text-align: center;
      border-radius: 5px;
      margin-right: 10px;
      cursor: pointer;
      transition:.4s all ease-out;

      &:first-of-type{
        color:white;
        background-color: $main-blue--bright;
        border:1px solid transparent;

        &:hover{
          background-color: white;
          color:$main-blue--bright;
          border:1px solid $main-blue--bright;
        }
      }
      &:last-of-type{
        color:$main-blue--bright;
        border:1px solid $main-blue--bright;

        &:hover{
          background-color: $main-blue--bright;
          color:white;

        }
      }
    }
    p{
      margin:0;
      font-size: 14px;
      font-weight: 500;
      color: red;
      line-height: 30px;
    }
  }

  input+.tickDiv{
    top:54px;
    right:22px;
    height:25px;
    width:25px;
    background: url('../../../../assets/teacher/icon-circleTick.svg')center no-repeat;
    background-size: contain;
    position: absolute;
  }

  .grade{
    .tickDiv{
      // top:7px;
      // right:5px;
      margin-left: 5px;
      height:25px;
      width:25px;
      background: url('../../../../assets/teacher/icon-circleTick.svg')center no-repeat;
      background-size: contain;
      // position: absolute;
    }
  }

  .invite{
    input+.tickDiv{
      top:7px;
      right:5px;
      height:25px;
      width:25px;
      background: url('../../../../assets/teacher/icon-circleTick.svg')center no-repeat;
      background-size: contain;
      position: absolute;
    }
  }
}