@import '../../../shared.scss';

.copyLinkBtn{
  height: 40px;
  width: 175px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-left: 15px;
  box-sizing:border-box;
  padding:0 10px;
  background-color: white;
  border: 2px solid #cacaca;
  border-radius: 5px;
  transition: .3s all ease;
  cursor: pointer;
  position: relative;
  left: 15px;

  .icon{
    height: 20px;
    width: 20px;
    background: url('../../../assets/share_grey.svg') no-repeat center;
    background-size: contain;
    margin-right: 10px;
  }

  p{
    margin:0;
    color: #858585;
    width: 105px;
  }



  .tooltip{
    position: absolute;
    top:35px;
    height:50px;
    min-width: 190px;
    box-sizing:border-box;
    padding:5px 8px;
    border-radius: 5px;
    color: #858585;
    background-color: white;
    -webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
    -moz-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
    box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
    font-size: 12px;
    text-align: center;
    // line-height: 20px;
    z-index: 1000;
    pointer-events: none;
    bottom: 0%;
    left:50%;
    transition: .2s all ease-out;
    opacity: 0;
    transform: translate(-50%, -15px);
  }


  &:hover{
    background-color: #cacaca;

    .tooltip{
      bottom: 55px;
      transform: translate(-50%, -90px);
      opacity: 1;
    }
  }

  @media (max-width:1100px){
    width: 45px;
    left: 8px;
    padding:0;
    display: flex;
    justify-content: center;
    border-width: 1px;

    .icon{
      margin:0 2px 0 0;
    }

    p{
      display: none;
    }
  }
}
