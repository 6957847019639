@import '../../../../shared.scss';

.container{
  display: flex;
  justify-content: space-between;
  position: relative;

  .left{
    background-color: white;
    height:660px;
    width:280px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 1px 4px 0px #00000025; 
    box-shadow: 0px 1px 4px 0px #00000056;
    box-sizing:border-box;
    padding:10px 15px;

    h2{
      color:#333333;
      margin: 0;
    }

    h4{
      margin:0 3px 10px 0;
    }
  }

  .studentSearch{
    background: url('../../../../assets/icon-lupa.png') 6px center no-repeat;
    background-size: 18px;
    border:1px solid #cacaca;
    background-color: #f2f2f2;
    height:30px;
    width:100%;
    border-radius: 8px;
    box-sizing:border-box;
    padding:0 0 0 30px;
  }

  .buttons{
    margin: 10px 0;
    height:35px;
    display:flex;
    justify-content: space-between;
    align-items: center;

    .btn{
      height:30px;
      width:120px;
      line-height: 30px;
      text-align: center;
      border-radius: 5px;
      color:white;
      box-sizing:border-box;
      padding:0 0 0 20px;
      font-size: 14px;
      font-weight: 700;
      transition: all .3s ease-out;
      cursor: pointer;

      &.disabled{
        pointer-events: none;
        opacity: .4;
      }

      &:first-of-type{
        background:url('../../../../assets/icon-randomize.svg') 10px center no-repeat;
        background-color: $main-blue--light;
        border:1px solid $main-blue--light;
        
        &:hover{
          background:url('../../../../assets/icon-randomize--active.svg') 10px center no-repeat;
          color:$main-blue--light;
          background-color: white;
          border:1px solid $main-blue--light;

        }
      }
      
      &:last-of-type{
        background:url('../../../../assets/icon-pair.svg') 10px center no-repeat;
        background-color: $main-blue--bright;
        border:1px solid $main-blue--bright;

        &:hover{
          background:url('../../../../assets/icon-pair--active.svg') 10px center no-repeat;
          color: $main-blue--bright;
          background-color: white;
          border:1px solid $main-blue--bright
        }   

        
      }
    }
  }

  .students{
    width:100%;
    height:calc(100% - 150px);
    margin-bottom: 10px;
    // border:1px solid chartreuse;
    overflow-y: auto;



    label{
      cursor: pointer;
      position: relative;
      height:50px;
      width:100%;
      border:1px solid #cacaca;
      border-radius: 8px;
      margin:0 0 5px 0;
      display: flex;
      align-items: center;
      box-sizing:border-box;
      padding:2px 2px 2px 32px;
      -webkit-box-shadow: 0px 1px 4px 0px #00000025; 
      box-shadow: 0px 1px 4px 0px #00000056;
    }

    p{
      width:150px;
      // border:1px solid chartreuse;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .studentDiv{
    position: relative;

    img{
      width:40px;
      margin:0 10px 0 0px;
      height:90%;
      object-fit: contain;
    }
  }

  input[type="checkbox"]{
    position: absolute;
    left:10px;
    top:15px;
  }
  input[type="checkbox"]:checked+label{
    padding:0 0 0 30px;
    border:3px solid $main-green;
  }

  .bottomBtns{
    height:40px;
    width:100%;
    position: absolute;
    bottom:-45px;
    display: flex;
    justify-content: flex-end;

    .btn{
      height:40px;
      width:150px;
      line-height: 40px;
      text-align: center;
      color:white;
      border-radius: 10px;
      transition: .3s all ease-out;
      font-size: 18px;
      font-weight:700;
      cursor: pointer;
      -webkit-box-shadow: 0px 1px 4px 0px #00000025; 
      box-shadow: 0px 1px 4px 0px #00000056;

      &:first-of-type{
        background-color: white;
        color:$main-blue--bright;
        border:1px solid $main-blue--bright;
        margin-right: 10px;

        &:hover{
          background-color: $main-blue--bright;
          color:white;
        }
      }

      &:last-of-type{
        background-color: $main-blue--bright;
        border:1px solid $main-blue--bright;

        &:hover{
          background-color: white;
          color:$main-blue--bright;
        }
      }
    }
  }

  .right{
    // border:1px solid chartreuse;
    display:flex;
    flex-wrap: wrap;
    align-items: flex-start;
    height: fit-content;
    max-height:660px;
    width:calc(100% - 300px);
  }

  .disabled{
    pointer-events: none !important;
    opacity: .4 !important;
  }  
}