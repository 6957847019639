@import '../../../../shared.scss';

.container{
  box-sizing:border-box;
  padding:100px 0 0 0;
  height: 100vh;
  width: 100%;
  background-color: grey;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: center;

  .inner{
    height: calc(100vh - 90px);
    width: calc(100vw - 10px);
    background-color: white;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    .activities{
      width: calc(100% - 20px);
      height: calc(100% - 60px);
      overflow-y: auto;
      box-sizing:border-box;
      padding:5px 15px 0 15px;
      margin-top: 25px;
    }
  }


}