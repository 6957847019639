@import '../../../shared.scss';

.container{
  height: 400px;
  width: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  // border:1px solid chartreuse;


  .downloadBtn{
    // border:1px solid chartreuse;
    @include mainButton($main-blue--bright, white, 15px, 35px, 200px);
  }

  @media (max-width:650px){
    width: 100%;
    height: 100px;
    .renderWrapper{
      display: none;
    }
  }
}

.renderWrapper{
  // border:1px solid chartreuse;
  transform: scale(0.9);
  height: 510px;
  width: 600px;
  overflow-y: scroll;
  object-fit: contain;
  -webkit-appearance: none !important; 
  -moz-appearance: none !important; 

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    background-color: #cacaca;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #858585;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }

      
  &.safari_only{
    height: 450px;
    position: relative;
    bottom: 40px;
  }

  &.content{
    height: 670px;
    width: 430px;
    
    &.safari_only{
      height: 620px;
      position: relative;
      bottom: 70px;
    }
  }
}

.chapter_title_text{
  color:chartreuse;
  font-size: 50px;
}








// .renderWrapper{
//   border:1px solid chartreuse;
//   height: 450px;
//   width: 600px;
//   overflow-y: scroll;
//   object-fit: contain;
//   position: relative;
//   bottom: 50px;

//   &::-webkit-scrollbar {
//     -webkit-appearance: none;
//     width: 7px;
//   }
//   &::-webkit-scrollbar-track {
//     background-color: #cacaca;
//   }
//   &::-webkit-scrollbar-thumb {
//     border-radius: 4px;
//     background-color: #858585;
//     -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
//   }

//   &.content{
//     height: 630px;
//     width: 430px;
//     box-sizing:border-box;
//     position: relative;
//     bottom: 200px;

//   }
// }