@import '../../../../shared.scss';

.container{
  width:calc(100vw - 70px);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #F3F7FE;
  font-family: 'Sofia pro';
  box-sizing:border-box;
  padding:0 0 90px 0;
  

  .banner{
    height:150px;
    width:1000px;
    border-radius: 15px;
    background: rgb(255,168,38);
    background: linear-gradient(170deg, rgba(255,168,38,1) 0%, rgba(255,130,88,1) 100%);
    -webkit-box-shadow: 1px 1px 5px 0px rgba(4, 35, 172, 0.39);
    -moz-box-shadow: 1px 1px 5px 0px rgba(4, 35, 172, 0.39);
    box-shadow: 1px 1px 5px 0px rgba(4, 35, 172, 0.39);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-sizing:border-box;
    padding:10px 50px 10px 30px;
    margin-bottom: 30px;

    *{
      color:white; 
      text-align: left;
    }

    .text{
      height: 110px;
      margin-left: 20px;
    }

    h1{
      line-height: 40px;
      font-size: 30px;
    }
    p{
      line-height: 20px;
      font-size: 17px;
      font-weight: 300;

      span{
        font-weight: 700;
      }
    }
  }


  .main{
    width:1000px;
    height:300px;
    background-color: white;
    border-radius:15px;
    box-sizing:border-box;
    padding:40px 30px;
    -webkit-box-shadow: 1px 1px 5px 0px rgba(4, 35, 172, 0.39);
    -moz-box-shadow: 1px 1px 5px 0px rgba(4, 35, 172, 0.39);
    box-shadow: 1px 1px 5px 0px rgba(4, 35, 172, 0.39);
    
    .pills{
      display: flex;
      width: 60%;
      margin:0 auto;
      height:40px;
      justify-content: space-around;

      .pill{
        height: 40px;
        width:130px;
        border-radius: 10px;
        background-color: blue;
        display: flex;
        align-items: center;
        transition: .3s all ease-out;
        position: relative;
        cursor: pointer;

        &:hover{
          transform: scale(1.04);
        }

        &>button{
          height: 40px;
          width: 130px;
          position: absolute;
          left: 0;}

        &:nth-child(1){background-color: #4267B2;}

        &:nth-child(2){background-color: #3DA9F5;}
        // &:nth-child(2)>button{
        //   height: 40px;
        //   width: 130px;
        //   position: absolute;
        //   left: 0;}

        // &:nth-child(3){background-color: #065FE5;}
        &:nth-child(3){background-color: #4CAF50;}
        // &:nth-child(3){background-color: #065FE5;}
        // &:nth-child(4)>button{
        //   height: 40px;
        //   width: 130px;
        //   position: absolute;
        //   left: 0;}
        &:nth-child(4){background-color: #2B4E7B;}
        &:nth-child(4)

        img{
          height:22px;
          width:22px;
          object-fit: contain;
        }

        h5{
          font-size: 16px;
          width:80px;
          text-align: center;
        }
      }
    }

      .link{
      width:500px;
      margin:0 auto;
      height:90px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;

      h3{
        color:#333333;
        font-size: 16px;
        padding-left: 20px;
        margin-bottom:5px;
        font-weight: 700;

      }

      .linkBox{
        height:40px;
        width:100%;
        display: flex;
        align-items: center;

        input[type='text']{
          height:38px;
          width:410px;
          border:1px solid #cacaca;
          border-radius: 5px;
          font-family: 'Sofia pro';
          color:#858585;
          box-sizing:border-box;
          padding:0 0 0 15px;
          font-size: 16px;
          line-height: 38px;
          font-weight: 300;
        }

        .linkBtn{
          height:38px;
          width:50px;
          border-radius: 5px;
          background-color: $main-blue--bright;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 10px;
          cursor: pointer;
          position: relative;

          // &:hover >.tooltip{
          //   visibility: visible;
          // }

          p{
            color:white;
            font-size: 12px;
          }

          .tooltip{
            // visibility: hidden;
            position: absolute;
            top:42px;
            left:-18px;
            height:20px;
            width:90px;
            border-radius: 5px;
            color: #858585;
            background-color: white;
            -webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
            -moz-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
            box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
            font-size: 12px;
            text-align: center;
            line-height: 20px;
      
            &::after{
              content:'';
              position: absolute;
              top: -10px;
              left:0;
              margin-left: calc(50% - 5px);
              border-width: 5px;
              border-style: solid;
              border-color:transparent transparent white transparent; 
          }
        }

        }
      }
    }


    .bottom{
      width:560px;
      margin:0 auto;
      height:90px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top:15px;

      .box{
        height:80px;
        width:130px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #ffa82655;
        margin-left: 20px;
        
        h4, h1{
          font-weight:300;
          color: #FFA826;
        }
        h1{
          font-size: 40px;
        }
        h4{
          font-size: 16px;
        }

      }

      .text{
        width:400px;

        p{
          font-size: 16px;
          color:#333333;
          line-height: 25px;
          font-weight: 300;
        }
      }
    }

  }
}