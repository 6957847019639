.container{
  width:760px;
  height: 428px;
  background-color: transparent;
  background: url('../../../../assets/spinner-sml--fast.svg') no-repeat center;
  background-size: 65px;
  position: relative;


  .closePlayer{
    position: absolute;
    cursor: pointer;
    top:-10px;
    right: -15px;

    img{
      width: 25px;
      height: 25px;
      object-fit: contain;
    }
  }

  p{
    font-size: 15px;
    color:white;
    position: absolute;
    bottom: -45px;
    left: 48%;
    transition: translateX(-50%);
    cursor: pointer;
    font-weight: 500;
    font-family: 'Sofia pro';

  }
}