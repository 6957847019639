@import '../../../../shared.scss';

.container{
  box-sizing:border-box;
  padding:40px;
  width: 90%;
  max-width: 1100px;
  height: 650px;
  background-color: white;
  border-radius: 8px;

  *{
    font-family: 'Sofia pro';
  }

  h1{
    box-sizing:border-box;
    padding:0 0 0 50px;
    background: url('../../../../assets/newStudent-google.svg') no-repeat left center;
    height:50px;
    line-height: 50px;
    position: absolute;
    top: 30px;
  }

  h3{
    color: #333333;
    font-size: 20px;
  }

  p{
    font-size: 15px;
    color: #858585;
    margin:4px 0;
    line-height: 30px;
  }

  .blue, a{
    color: $E_blue;
  }

  .yellow{
    color:$E_orange
  }

  a{
    cursor: pointer;
    line-height: 50px;
    &:hover{
      color: $E_blue_dark !important;
    }
  }

  .codePillDiv{
    margin-top: 20px;
    display: flex;
    align-items: center;

    h3{
      font-size: 17px;
      margin-right: 10px;
    }
  }

  .half{
    height: 50%;
    width: 100%;
    display: flex;
    // border:1px solid cyan;

    .left,
    .right{
      height: 100%;
      // border:1px solid chartreuse;
    }
    .left{
      width: 60%;
      box-sizing:border-box;
      padding:0 40px 0 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .right{
      width: 40%;
      box-sizing:border-box;
      padding:20px;
      display: flex;
      align-items: center;
      justify-content: center;
      // border:1px solid cyan;

      img{
        max-height: 100%;
      }
    }

    &:first-of-type{
      .left{
        display: initial;
        box-sizing:border-box;
        padding:60px 40px 0 0 !important;
      }
      .right{
        box-sizing:border-box;
        padding:30px 0 0 0;
      }
    }

    &:last-of-type{
      .left{
        box-sizing:border-box;
        padding:0 160px 0 0;
      }
      .right{
        display: flex;
        flex-direction: column;
        justify-content: center;
        img{
          max-height: 80%;
        }
      }
    }
  }

  @media (max-width:650px){
    height:calc(100% - 50px);

    h1, h3{
      font-size: 16px;
    }

    .half{
      height: fit-content !important;
      &:last-of-type{
        margin-top: 30px;
      }
      &:first-of-type{

        .left{
          padding:60px 0 0 0 !important;
        }
      }
    }

    p{
      font-size: 15px;
      line-height: 20px;
    }

    .left{
      width: 100% !important;
      padding:0 !important;
    }
    .right{
      display: none !important;
    }
  }
}