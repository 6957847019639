@import '../../../shared.scss';

.container {
  flex-basis: 100%;
  z-index: 100;

  .contents {
    width: 100%;
    box-sizing: border-box;
    background-color: #FCF2EA;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin: 8px auto 8px 0;
    padding: 10px 20px;
    display: flex;
    gap: 5px;
    flex-flow: column nowrap;
    transition: .3s all ease-in-out;
    z-index: 100;
    
    &.expanded {
      height: max-content;
    }

    .topRow {
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 10px;

      img {
        height: 25px;
      }

      h3 {
        color: $F_pink_deep;
        font-size: 1.2rem;
        line-height: 1.4rem;
        white-space: nowrap;
      }

      .forDate {
        margin: 0 -5px;
        font-style: italic;
        font-size: 0.9rem;
        line-height: 1.1rem;
        color: #858585;
        white-space: nowrap;
      }

      .previous {
        height: 30px;
        width: 30px;
        cursor: pointer;
        background: url('../../../assets/arrow_square_chevron.png') no-repeat center;
        transform: rotate(90deg);
        opacity: 0.5;

        &:hover{
          background: url('../../../assets/arrow_square_chevron_hover.png') no-repeat center;
          opacity: 0.9;
        }
      }

      .next {
        height: 30px;
        width: 30px;
        position: relative;
        top: -1px;
        cursor: pointer;
        background: url('../../../assets/arrow_square_chevron.png') no-repeat center;
        transform: rotate(-90deg);
        opacity: 0.5;

        &:hover{
          background: url('../../../assets/arrow_square_chevron_hover.png') no-repeat center;
          opacity: 0.9;
        }
      }

      .expand {
        margin-left: auto;
        min-height: 30px;
        max-height: 30px;
        min-width: 30px;
        max-width: 30px;
        color: red;
        background: url('../../../assets/arrow_square_chevron.png') no-repeat center;
        transition: .3s cubic-bezier(0.2,-0.1, 0.2, 2.2) bottom;
        cursor: pointer;
        pointer-events: all;

        &.open{
          background: url('../../../assets/arrow_square_chevron_hover.png') no-repeat center;
          transform: rotate(180deg);
        }
        
        &:hover{
          background: url('../../../assets/arrow_square_chevron_hover.png') no-repeat center;
        }
      }
    }

    .quoteBody {
      color: #4e4e4e;
      font-size: 1rem;
      line-height: 1.4rem;
      text-align: left;
      padding: 0;
      margin-bottom: 20px;
      display: none;

      .sectionTitle {
        font-weight: bolder; 
        margin-top: 10px;

        span {
          margin-left: 10px;
          font-size: 0.7rem;
          font-style: italic;
        }
      }

      p {
        font-size: 1rem;
        font-weight: 300;
        margin-bottom: 5px;
      }

      &.visible {
        display: block;
      }

      em {
        font-style: italic;
        font-size: 0.7rem;
      }
    }

    button {
      background-color: $E_blue;
      color: white;
      font-size: 0.9rem;
      display: block;
      border-radius: 5px;
      border: none;
      width: 160px;
      cursor: pointer;
      margin-top: auto;
      padding: 18px 8px;

      &:hover {
        background-color: $E_blue_dark;
      }
    }
  }

  @media (min-width: 820px) {
    flex-basis: 60%;
  }

  @media (min-width: 1000px) {
    // width: calc(100vw - 280px);
    flex-basis: 70%;

    .contents {
      width: 430px;
      margin-left: auto;

      &.expanded {
        width: 430px;
        height: max-content;
      }
    }
  }
}

