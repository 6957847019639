@import '../../../../shared.scss';

.container{
  width:100%;
  height:100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing:border-box;
  padding:0 30px 15px 30px;

  *{
    font-family: 'Sofia pro';
  }


  .show{
    height:115px !important;
    visibility: visible !important;
    margin-bottom: 15px !important;
  }

  h2{
    font-size: 18px;
    box-sizing:border-box;
    padding-left:35px;
    background: url('../../../../assets/icon-pdfGen.svg') left center no-repeat;
    background-size: 25px;
    height:50px;
    line-height: 55px;
  }

  p{
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 300;
    color:#858585;
  }

  .studentBlocks{
    width:100%;
    height:fit-content;
    height: -moz-max-content;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    box-sizing:border-box;
    padding:10px 0 0 0;
    margin-bottom: 15px;
    &::-webkit-scrollbar {
      width: 6px;
    }
     
    &::-webkit-scrollbar-track {
      background-color: $grey-light;
    }
     
    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      outline: 1px solid slategrey;
    }
  }
  

  .contentSelection{
    overflow: hidden;
    visibility: hidden;
    height: 0px;
    transition: .4s all ease-in-out;
    p{
      margin-top: 0;
    }    
      .contentBlocks{
        height:80px;
        height: -moz-max-content;
        margin:0 auto;
        width:97%;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        overflow-y: scroll;
        padding:8px 0 0 0;
        
          &::-webkit-scrollbar {
            width: 6px;
          }
           
          &::-webkit-scrollbar-track {
            background-color: $grey-light;
          }
           
          &::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            outline: 1px solid slategrey;
          }
      }
  }

  .elementsSelection{
    overflow: hidden;
    visibility: hidden;
    height: 0px;
    transition: .4s all ease-in-out;
    p{
      margin-top: 0;
    }  
    
    &.show{
      height:120px !important;
    }
      .elementBlocks{
        height:100px;
        margin:0 auto;
        width:fit-content;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        box-sizing:border-box;
        padding:10px;
        overflow-y: auto;
        padding:8px 0 0 0;

        label > input + div{
          width:160px;
        }


        label>input+div{
          width:250px !important;
        }


        &::-webkit-scrollbar {
        width: 6px;
      }
      
        &::-webkit-scrollbar-track {
        background-color: $grey-light;
      }
      
        &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid slategrey;
      }
      }
  }


  label > input[type=checkbox]{
    display: none;
  }

  label > input + div{
    cursor: pointer;
    margin:0 5px 5px 0;
    width:160px;
    height:30px;
    border-radius: 5px;
    border:1px solid #cacaca;
    line-height: 30px;
    font-size: 14px;
    font-weight: 500;
    color: #333333;
    text-align: center;
    box-sizing:border-box;
    padding:0 5px;

    p{
      font-size: 14px;
      box-sizing:border-box;
      color: #333333;
      box-sizing:border-box;
      padding:0 15px;
      text-align: left;
      // padding:0 0 0 15px;
      margin: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

    }
  }
  label > input:checked + div{
    margin:0 7px 5px 0;
    border:3px solid #97D318;
    -webkit-box-shadow: 0px 1px 4px 0px #00000025; 
    box-shadow: 0px 1px 4px 0px #00000056;
    width:158px;
    height:30px;
    line-height: 26px;
    position: relative;
    box-sizing:border-box;
    padding:0 5px;
    line-height: 26px;

    
    p{
      // padding-left: 13px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &:after{
      content: '';
      background: url('../../../../assets/teacher/icon-circleTick--small.svg');
      font-size: 15px;
      position: absolute;
      height: 15px;
      width: 15px;
      top: -10px;
      right: -10px;
      color: chartreuse;
    }
  }
  .buttons{
    display: flex;
    margin-top:10px;

    .okBtn,
    .cancelBtn{
      height:40px;
      width:150px;
      border:1px solid;
      border-radius: 10px;
      line-height: 40px;
      font-size: 16px;
      text-align: center;
      margin:0 8px;
      cursor: pointer;
      transition: .4s all ease-out;

    
    }

    .cancelBtn{
      border-color: $main-blue--bright;
      color: $main-blue--bright;
      background-color: white;

      &:hover{
        background-color: $main-blue--bright;
        color:white;
      }
    }
    .okBtn{
      border-color: $main-blue--bright;
      background-color: $main-blue--bright;
      color: white;
      border:1px solid transparent;

      &:hover{
        background-color: white;
        color:$main-blue--bright;
        border:1px solid $main-blue--bright
      }
    }
  }


  .loaderDiv{
    visibility: hidden;
    z-index: 100;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height:100%;
    width:100%;
    background-color: white;

    &.showLoader{
      visibility: visible;
    }

    .loaderInner{
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .readyMsg{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin:0 auto;
      p{
        color: #333333;
        margin:25px 0;
      }
    }

    .btn{
      height:40px;
      width:150px;
      border:1px solid;
      border-radius: 10px;
      line-height: 40px;
      font-size: 16px;
      text-align: center;
      margin:0 8px;
      cursor: pointer;
      transition: .4s all ease-out;
      border-color: $main-blue--bright;
      background-color: $main-blue--bright;
      color: white;
      border:1px solid transparent;
  
      &:hover{
        background-color: white;
        color:$main-blue--bright;
        border:1px solid $main-blue--bright
      }
  }
  }

  @media (max-width:650px){
    width: 100%;
    padding: 0 15px 15px 15px;
    height: fit-content;
    // overflow-y: auto;
    overflow-x: hidden;

    .contentSelection,
    .elementSelection{
      display: none !important;
    }


    h2{
      display: none;
    }

    .studentBlocks{
      height: fit-content !important;
      overflow-y: visible !important;
      overflow-x: hidden;
      border:1px solid purple;
      padding-right: 8px;
      label{
        width: 100%;
        height: 40px;
        margin-bottom: 10px;

        input + div{
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          p{
            width: 100%;
            text-align: center;
          }
        }
      }

    }
  }
}