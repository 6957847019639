@import '../../../shared.scss';

.container{
  width: 260px;
  border-radius: 15px;
  margin: 0 8px;
  box-sizing:border-box;
  height: fit-content;
  font-family: "DM Sans", sans-serif;

  *{
     font-family: "DM Sans", sans-serif;
  }




  .cardHead{
    -webkit-box-shadow: 5px 5px 1px 2px rgba(0,0,0,0.1); 
    box-shadow: 5px 5px 1px 2px rgba(0,0,0,0.1);
    padding: 1rem;
    border-radius: 15px;
    width: 240px;
    display: flex;
    flex-direction: column;
    gap: 1rem;


    img{
      margin-top: 8px;
    }
    &.blue{
      background-color: #1C315C;
  
      *{
        color: white;
      }
      .space{
        color: #01C0D9;
      }
      span{
        color: #01C0D9;
      }
    }
  
    &.pink{
      background-color: #FD7D73;
      *{
        color: white;
      }
      h6{
        color: $faith-blue;
      }

    }

    &.light{
      background-color: #FCF2EA;
      h4, h3 {
        color: $faith-blue;
      }
      h6{
        color: #FD7D73;
      }
    }
  }

  h4{
    font-size: 24px;
    line-height: 26px;
    margin: 0;
  }
  .flex{
    display: flex;
    align-items: center;
    gap: 1rem;
    img{
      object-fit: contain;
      width: 60px;
    }
  }
  h6{
    font-size: 20px;
    margin: 25px 0 20px 0;
    font-weight: 700;
  }


  .priceDiv{
    display: flex;
    align-items: center;
    h3{
      font-size: 2.5rem;
    }
    h5{
      margin-left: 15px;
    }

    .space{
      margin-left: 8px;
      display: flex;
      flex-direction: column;
      align-items: baseline;
      font-weight: 700;
      span{
        display: block;
      }
    }

    *{
      margin: 0;
    }
  }

  .benefitsList{
    margin: 30px 0;
    
  }

  p.listItem{
    margin: 20px 0;
    position: relative;
    line-height: 25px;
    font-size: 16px;
    padding-left: 30px;
    font-weight: 400;
    background: url('../../../assets/tick_circle_green.svg') no-repeat center left;
    
    &.light{
      background: url('../../../assets/tick_circle_darkgreen.svg') no-repeat center left;
    }
    &.pink{
      background: url('../../../assets/tick_circle_pink.svg') no-repeat center left;
    }
    &.blue{
      background: url('../../../assets/check_circle_black.svg') no-repeat center left;
    }
  }

  .pinkBtn{
    padding: 12px 20px;
    border-radius: 8px;
    background-color: $faith-pink;
    cursor: pointer;
    color: white;
    width: fit-content;
    margin-top: 35px;
    
  }

  .greenBtn {
    padding: 12px 20px;
    border-radius: 8px;
    background-color: $faith-blue;
    cursor: pointer;
    color: white;
    width: fit-content;
    margin-top: 35px;
    
  }
  .blueBtn {
    padding: 12px 20px;
    border-radius: 8px;
    background-color: #1C315C;
    cursor: pointer;
    color: white;
    width: fit-content;
    margin-top: 35px;
    
  }
  .grey{
    color: #858585;
    font-size: 1rem;
    font-weight: normal;
  }

  .button{
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    justify-content: center;

    img{
      object-fit: contain;
    }
  }

  @media (max-width:1150px){


  }

  @media screen and (max-width: 768px){
    padding: 20px 20px 30px 20px;
  }
}

button{
  border: none;
}