@import '../../../../shared.scss';

.container{
  box-sizing:border-box;
  padding:50px 70px;
  position: relative;
  height: 100%;
  width: 1000px;
  height: 600px;

  *{
    font-family: 'Sofia pro';
  }

  h1{
    box-sizing:border-box;
    padding:0 0 0 50px;
    background: url('../../../../assets/icon_csv_green.svg') no-repeat 11px 0px;
    background-size: 30px;
    height:40px;
    font-size: 23px;
    margin-bottom: 30px;

  }
  h3{
    margin-top: 35px;
    font-size: 18px;
    color:#333333;
    font-weight: 700;
    margin-bottom: 25px;
  }

  p{
    font-style: 14px;
    font-weight: 500;
    color:#333333;
  }

  .btns{
    display: flex;
    // position: absolute;
    width: 100%;
    justify-content: flex-end;
    margin-top: 15px;

    .btn{
      height:40px;
      width:130px;
      line-height: 40px;
      text-align: center;
      border-radius: 8px;
      border:1px solid;
      cursor: pointer;
      transition: .3s all ease-in-out;

      &:first-of-type{
        color:$E_blue;
        background-color: $E_blue_light;
        border-color: $E_blue_light;
        margin-right: 15px;

        &:hover{
          background-color: $E_blue_light2;
        }
      }

      &:last-of-type{
        color:white;
        background-color: $E_blue;
        border-color: $E_blue;

        &:hover{
          background-color:$E_blue_dark;
        }
      }
    }
  }

  p.optional{
    // font-style: italic;
    font-size: 14px;
    color:#FB3333 !important;
    position: relative;
    width: 80px;
    box-sizing:border-box;
    padding:0 0 0 12px;
    margin-top: 30px;
    position: absolute;
    bottom: -40px;
    right: 0;

    &::before{
      content:'*';
      position: absolute;
      top:5px;
      left:-3px;
      color: #FB3333;
      font-size:22px;
      font-weight: 300;
    }
  }

  .table{
    height:150px;
    width:680px;
    border:1px solid #e9e9e9;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin:30px 0;
    position: relative;
    // -webkit-box-shadow: 0px 1px 9px 0px #00000066; 
    // box-shadow: 0px 1px 9px 0px #00000646;

    .header{
      height:30px;
      background-color: #e9e9e9;
      overflow: hidden;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      display: flex;
      .column{
        line-height: 30px;
        text-align: left;
        width:25%;
        // border:1px solid chartreuse;
        color: #858585;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-size: 10px;
        font-weight: 700;
        box-sizing:border-box;
        padding:0 0 0 15px;
        position: relative;

        &:nth-of-type(1){
          width:20%;
          &::after{
            content:'*';
            position: absolute;
            top:5px;
            right:45px;
            color: #FB3333;
            font-size:22px;
            font-weight: 300;
          }
        }
        &:nth-of-type(2){
          text-align: center;
          width:20%;
          &::after{
            content:'*';
            position: absolute;
            top:5px;
            right:15px;
            color: #FB3333;
            font-size:22px;
            font-weight: 300;
          }
        }
        &:nth-of-type(3){
          text-align: center;
          width:10%;
          padding:0;
        }
        
      }
    }
    .row{
      height:40px;
      display: flex;
      border-top:1px solid #e9e9e9;

      .column{
        line-height: 40px;
        text-align: left;
        width:25%;
        // border:1px solid chartreuse;
        color: #333333;
        font-family: 'Museo';
        font-size: 14px;
        font-weight: 500;
        box-sizing:border-box;
        padding:0 0 0 15px;

        &:nth-of-type(1){
          width:20%;
        }
        &:nth-of-type(2){
          text-align: center;
          width:20%;
        }
        &:nth-of-type(3){
          text-align: center;
          width:10%;
          padding:0;
        }
      }
    }

    &.synod{
      .column{
        width: 30% !important;

        &::after{
          right: 0% !important;
          left: 45% !important;
        }

        &:last-of-type{
          width: 40% !important;
        }
      }
    }
  }

  .upload{
    // position: absolute;
    width:530px;
    padding:20px 10px;
    border-radius: 15px;
    border:1px solid #cacaca;
    top:40px;
    right:70px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    box-sizing:border-box;

    .img{
      height:50px;
      width:50px;
      background: url('../../../../assets/sidebarIcons/sidebar_upload_blue.svg') no-repeat center;
      background-size: contain;
      // border:1px solid chartreuse;
    }

    h3{
      font-size:16px;
      margin: 15px 0;
      font-weight: 500;
    }

    .btn{
      height:35px;
      line-height: 35px;
      width:140px;
      text-align: center;
      border:1px solid $E_blue;
      background-color: $E_blue;
      color: white;
      border-radius: 5px;
      cursor: pointer;
      position: relative;
      bottom:0;
      transition: .4s all ease;

      &:hover{
        color: white;
        background-color: $E_blue_dark;
      }
    }
  }

  .containerStep{
    h3{
      margin:8px 0 16px 0;
    }
    p{
      font-size: 14px;
      line-height: 20px;
      color:#333333;
      font-weight: 300;
      margin:20px 0;
    }

  .loader{
    
    text-align: center;
    height:100%;
    margin-top: 150px;
    position: relative;

    h6{
      font-size: 14px;
      position: relative;
      bottom:40px;
    }
  }
  }

  .noAccountsCreated{
    height:calc(100% - 100px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    box-sizing:border-box;
    padding:0 0 100px 0;

    h2{
      font-size: 25px;
      margin-bottom: 40px;
    }

    p{
      color: #858585;
      margin:10px 0;
    }

    .pillDiv{
      margin-top: 40px;
      transform: scale(1.3);
    }

    .btnDone{
      height:35px;
      width:130px;
      line-height: 35px;
      text-align: center;
      border-radius: 8px;
      border:1px solid;
      cursor: pointer;
      transition: .15s all ease-in-out;
      color: white;
      border-color: $E_blue;
      background-color: $E_blue;
      position: absolute;
      bottom: -40px;

      &:hover{
        background-color: $E_blue_dark;
      }

    }
  }

  .timeline{
    width:180px;
    margin:0px auto 15px auto;
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);

    .line{
      height:2px;
      width:85%;
      left:15px;
      top:12px;
      background-color: #c4c4c4;
      position: absolute;

      .blueLine{
        height: 100%;
        width:33%;
        background-color: $E_blue;

        &.stageThree{
          width: 48%;
          // width: 67%;
        }

        &.stageFour{
          width: 100%;
        }
      }
    }
  }

  .step{
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    h6{
      color:#858585;
      font-size: 12px;
      font-weight: 500;
      margin:8px 0;
    }

    .currentStage ~ h6{
      color: red !important;
    }


    .circle{
      position: relative;
      z-index: 10;
      height:25px;
      width:25px;
      border-radius: 50%;
      // background-color: white;
      border:1px solid #c4c4c4;
      background-color: #c4c4c4;
      display: flex;
      align-items: center;
      justify-content: center;

      h5{
        margin:0;
        color: white;
        position: relative;
        top: -1px;
        font-weight: 500;
        font-size: 15px;
        color: #858585;
      }

      &.currentStage{
        background-color: $E_blue;
        border-color: $E_blue;

        h5{
          color: white;
        }
      }

     
        &.completed{
          border: none;
          height: 25px;
          width: 25px;
          background: url('../../../../assets/icon_tick_teal.svg') no-repeat center;
          background-size: contain;

          h5{
            display: none;
          }
        }
      
    }
  }

  .csvTable{
    border: 1px solid #e9e9e9;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    overflow: hidden;
    
    .headers{
      display: flex;
      border-bottom: 1px solid #e9e9e9;
      justify-content: space-between;
      background-color: #f2f2f2;

      .headerDropdown{
        position: relative;
        width:195px;
        height:30px;
        border-radius: 5px;
        // border:1px solid #858585;
        color:#858585;
        font-size: 15px;
        line-height: 30px;
        text-align: left;
        box-sizing:border-box;
        padding:0 20px;
        cursor: pointer;


        //added to deactivate dropdown due to design change
        pointer-events: none;
        background: none;
        // background: url('../../../../assets/icon-chevron--grey.png') no-repeat 95% 10px;
        
        &.static{
          pointer-events: none;
          background: none;
        }

        .rows{
          background-color: white;
          position: absolute;
          top:30px;
          left:0;
          width:192px;
          border-radius: 3px;
          border:1px solid #cacaca;

          input[type="radio"]{display: none}

          label>input+.row{
            box-sizing:border-box;
            padding:0 20px;
            transition: .3s all ease-in-out;
            cursor: pointer;

            &:hover{
              background-color: #f2f2f2;;
              // color:white;
            }
          }

          label>input:checked+.row{
            background-color: #cacaca;
            color: white;
          }
          

        }
      }
    }

    .dataRows{
      // margin-top:10px;
      height:215px;
      overflow-y: auto;
      .dataRow{
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e9e9e9;

        // &.error{
        //   position: relative;
        //   overflow-x: visible;
        //   &::before{
        //     content:url('../../../../assets/icon-cross--red.svg');
        //     position: absolute;
        //     left:-10px;
        //     top:5px;
        //   }

        // }
        .dataColumn{
          border-radius: 5px;
          font-size: 14px;
          width:192px;
          height:40px;
          line-height: 40px;
          text-align:left;
          box-sizing:border-box;
          padding:0 20px;
          display: flex;
          align-items: center;
          // background-color: #f2f2f2;
          color:#858585;

          &.error{
            border:1px solid  #d00000 !important;
          }
        }
      }
    }
  }

  .infoDivs{
    height:60px;
    display: flex;
    align-items: center;
    margin-top: -10px;
  }

    .infoDiv{
      width:280px;
      height:35px;
      border-radius: 5px;
      line-height: 37px;
      box-sizing:border-box;
      padding:0 0 0 40px;
      text-align: left;
      color: $E_orange !important;

      font-size: 16px;
      font-style: italic;
      font-weight: 500;

      span{
        font-weight: 700;
        font-style: initial;
        color: $E_orange;
      }

      &:first-of-type{
        color:#ff8258;
        margin-right: 10px;
        background: url('../../../../assets/icon-account--orange2.svg') no-repeat 10px center;
        background-color: #FFEED4;
      }

      &:nth-of-type(2){
        color:#d00000;
        background: url('../../../../assets/icon-cross--red.svg') no-repeat 13px center;
        background-color: #f2f2f2;
      }

      &.green{
        margin-right: 10px;
        background: url('../../../../assets/icon-account--green.svg') no-repeat 10px center;
        background-color: $E_green_light;
        color:$E_green_dark !important;

        span{
          color: $E_green_dark;
        }
      }
    }

  .csvTableFinal{
    width:100%;
    margin:0 auto;
    height: fit-content;
    max-height:280px;
    border:1px solid #c4c4c4;
    // overflow:hidden;
    border-radius: 10px;

    .header{
      height:40px;
      width: 100%;
      display: flex;
      justify-content: center;
      background-color: #f2f2f2;
      color: #858585;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      font-size:10px;
      text-transform: uppercase;
      font-weight: 700;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      .headerColumn{
        width:20%;
        height: 40px;
        line-height: 40px;
        box-sizing:border-box;
        padding:0 0 0 20px;
        text-align: left;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #c4c4c4;

        &:nth-of-type(4),
        &:nth-of-type(5){
          text-align: center;
          box-sizing:border-box;
          padding:0 0 0 60px;
        }

        .icon{
          height: 20px;
          width: 20px;
          position: relative;
          background: url('../../../../assets/icon-question-light.svg') no-repeat center;
          bottom: 2px;
          cursor: pointer;

          .tooltip{
            position: absolute;
            opacity: 0;
            top: -20px;
            width: 170px;
            padding:5px 10px;
            border-radius: 10px;
            background-color: white;
            z-index: 15;
            left: 50%;
            transform: translateX(-50%);
            line-height: 20px;
            font-size: 11px;
            font-weight: 300;
            text-transform: none;
            -webkit-box-shadow: 0px 1px 9px 0px #00000066; 
            box-shadow: 0px 1px 9px 0px #00000646;
            transition: .3s all ease-out;
            pointer-events: none;
          }

     
        }
        &:hover{
          .icon > .tooltip{
            opacity: 1;
            top: 15px;
          }
        }
      }
    }

    .dataRows{
      height: fit-content;
      max-height:239px;
      overflow-y: auto;

      .dataRow{
        display: flex;
        align-items: center;
        // border-top: 1px solid #c4c4c4;
        border-bottom: 1px solid #c4c4c4;
        font-size:13px;
        font-weight: 300;
        
        &:last-of-type{
          border-bottom: 1px solid transparent;
        }

        .dataColumn{
          height:40px;
          line-height: 40px;
          box-sizing:border-box;
          padding:0 0 0 20px;
          width: 20%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: flex;
          align-items: center;

          .copyBtn{
            display: initial;
            margin-top: 3px;
            height:35px;
            min-width:120px;
            line-height: 35px;
            border-radius: 5px;
            box-sizing:border-box;
            padding:4px 8px 4px 30px;
            color:#858585;
            text-align: center;
            cursor: pointer;
            background: url('../../../../assets/icon-copy--grey.svg') no-repeat 10px center;
            transition: .3s all ease-in-out;
            
            &:last-child{
              padding:4px 28px 4px 45px;
              // border:1px solid blue;
            }
            p{
              font-size:14px;
            }
            
            &:hover{
              color:$main-blue--bright;
              background: url('../../../../assets/icon-copy--blue.svg') no-repeat 10px center;
              background-color: #e9e9e9;
            }

            &.hide{
              display: none;
            }
          }

          .copyMsg{
            color:red;
            text-align: center;
            font-size: 10px;
            display: initial;

            &.hide{
              display: none;
            }
          }
        }
      }
    }
  }

  .btnsFinal{
    position: absolute;
    bottom: 20px;
    left: 0;
    box-sizing:border-box;
    padding:0 40px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    display: flex;

    .btnFinal{
      height:40px;
      width:130px;
      line-height: 40px;
      text-align: center;
      border-radius: 8px;
      border:1px solid;
      cursor: pointer;
      transition: .3s all ease-in-out;

      &:first-of-type{
        color:$E_blue;
        background-color: $E_blue_light;
        border-color: $E_blue_light;
        margin-right: 15px;

        &:hover{
          background-color: $E_blue_light2;
        }
      }

      &:last-of-type{
        width:250px;
        color:white;
        background: url('../../../../assets/download_arrow_white.svg') left 20px center no-repeat;
        background-color: $E_blue;
        border-color: $E_blue;
        box-sizing:border-box;
        padding:0 0 0 25px;

        &:hover{
          background-color: $E_blue_dark;
        }
      }
    }
  }

}