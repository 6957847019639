@import '../../../../shared.scss';

input[type="checkbox"]{
  cursor: pointer;
}

.container{
  display: flex;
  align-items: center;
  position: relative;
  height:50px;
  width:100%;
  background-color: white;
  border-bottom: 1px solid $grey-unselected;
  border-width: 1px 0 1px 0;
  overflow: visible;
  transition: all .5s ease;


  *{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 300;
    margin-bottom: 0;
    margin-top: 0;
  }

  input[type="checkbox"]{
    position: relative;
    left:15px;
  }
  h5{
    max-width: 95%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    // margin-left: 4%;
    color: rgba(0, 0, 0, 0.8);
  }
  .photo{
    width:10%;
    height: 100%;
    text-align: center;
    margin: 5px 0 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    img{
      object-fit: contain;
      height: 40px;
      width:40px;
      border-radius: 50%;
    }
  };
  .name{
    font-size: 18px;
    width:30%;
    text-align: left;
  };
  .email{
    width:25%;
    text-align: left;
  };
  .grade{
    width:10%;
    text-align: center;
    margin-left: 5px;
  };
  .lastLogin{
    width:10%;
    margin-left: 10px;
    text-align: center;
  };

        
  .dots{
    cursor: pointer;
    position: absolute;
    bottom:10px;
    right:10px;
    margin-left: 0px;
    height:30px;
    width:30px;
    border-radius: 5px;
    background:url('../../../../assets/threeDots.png') no-repeat center;
    cursor: pointer;
    overflow: visible;

    &:hover{
      background-color: #f2f2f2;
    }

    .dotsDropdown{
      position: absolute;
      z-index: 1000;
      bottom:-42px;
      left:-130px;
      height:40px;
      width: 165px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      -webkit-box-shadow: 0px 0px 5px 4px rgba(0,0,0,0.12);
      -moz-box-shadow: 0px 0px 5px 4px rgba(0,0,0,0.12);
      box-shadow: 0px 0px 5px 4px rgba(0,0,0,0.12);
      border-radius: 5px;
      overflow: visible;


      &::after{
        content:'';
        position: absolute;
        top: -10px;
        right:15px;
        // left:0;
        // margin-left: calc(50% - 5px);
        border-width: 5px;
        border-style: solid;
        border-color:transparent transparent white transparent; 
      }
    
      
      .dropdownRemove{
        height:31px;
        line-height: 28px;
        text-align: center;
        box-sizing:border-box;
        padding:0 0 0 25px;
        font-size: 14px;
        width:155px;
        border-radius: 5px;
        background: url('../../../../assets/icon-delete.svg') no-repeat 10px center;
        color:#858585;
        background-size: 13px;


        &:hover{
          background-color: #e9e9e9;
        }
      }
    }
  }
}
