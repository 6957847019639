@import '../../../../shared.scss';

.container{


  box-sizing:border-box;
  padding:10px 30px;
  width: 500px;
  min-height: 480px;
  overflow: hidden;
  transition: .3s all ease-in-out;

  &.complete{
    height: 220px;
    min-height: 220px;
  }


  .initLoadDiv{
    height: 400px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img{
      height: 60px;
      object-fit: contain;
    }

  }

  *{font-family:'Sofia pro'}

  h1{
    font-size: 27px;
    color: #333333;
  }

  h3{
    font-size: 18px;
    color: #333333;
  }

  p{
    font-size:16px;
    color:#858585;
    margin-top: 5px;
    font-weight: 300;

    span.planCount{
      font-size: 14px;
      font-weight: 300;
    }
  }
    .confirmTopBar{
      margin: 25px 0;
      display: flex;
      flex-direction: column;
      
      align-items: center;
      justify-content: space-between;
      box-sizing:border-box;
      padding:10px 20px;
      position: relative;
      overflow: hidden;
      left: -5%;
      height: 120px;
      border-radius: 10px;
      width: 110%;
      -webkit-box-shadow: 1px 1px 8px 2px rgba(3, 19, 255, 0.2);
      -moz-box-shadow: 1px 1px 8px 2px rgba(3, 19, 255, 0.2);
      box-shadow: 1px 1px 8px 2px rgba(3, 19, 255, 0.2);

      h4{
        color: #333333 !important;
        text-align: left !important;
        width: 100%;
        font-weight: 900 !important;
        margin-bottom: 4px;
        font-size: 16px !important;
      }

      p.noSelect{
        font-size: 14px;
        margin: 0;
        position: relative;
        bottom:22px;
      }

      .planRow{
        display: flex;
        height: 25px;
        justify-content: space-between;
        width: 100%;
        p, i{
          color: #858585;
          font-size: 15px;
          margin: 0px 0;
          font-weight: 300;
        }

        img.priceSpinner{
          height: 25px;
          object-fit: contain;
          margin-right: 12px;
        }

        i{
          font-size: 13px;
          margin-top: 3px;
        }
      }

      h4{
        font-size: 16px;
        color: #858585;

        &:last-of-type{
          font-weight: 300;
        }
      }

      .colorTab{
        position: absolute;
        left:-9px;
        top:0;
        bottom:0;
        margin:auto 0;
        content:'';
        width:15px;
        height:80px;
        border-radius: 10px;
        background-color: $main-orange;
        
      }
    }

    p.childrenTitle{
      width: 57%;
      text-align: left;
      margin: 0 auto 5px auto;
      font-size: 15px;
    }

    .children{
      width: 65%;
      max-height: 150px;
      overflow-y: scroll;
      border:1px solid #f2f2f2;
      margin:0 auto 10px auto;
      padding-right: 5px;  
      position: relative; 

      
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 6px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 6px;
        background-color: #cacaca;
        border:1px solid #cacaca;
      }
      
      .childrenInner{
        width: 100%;
        height: fit-content;
        position: relative;
        
          label{
            cursor: pointer;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 50px;
    
            img{
              height: 50px;
              object-fit: contain;
            }
            h6{
              margin:0;
              font-size: 14px;
              font-weight: 300;
            }
    
            input[type="checkbox"]{
              position: absolute;
              right: 5px;
            }
          }

      }
    }

  .details{
    padding:5px 0px;
    height: 130px;
    // width: 600px;
    position: relative;

    h4{
      font-size: 18px;
    }
    p{
      margin: 15px 0 5px 0;
      text-align: left;
      font-weight: 300;
      font-size: 16px;
      margin-bottom: 13px;;
    }

    .cardElement{
      padding: 10px 8px;
      border:1px solid #cacaca !important; 
      background-color: #f6f6f6;
    }
    
  }
  
  .confirmBtn{
    @include solidButton(white, $main-blue--bright, 17px, 40px, 360px);
    margin: 0px auto;
    position: relative;
    top:5px;
  }

  .loadingOverlay{
    position: absolute;
    height:100%;
    width:100%;
    top:0;
    left:0;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
    visibility: hidden;

    &.show{
      visibility: visible;
    }
    p{
      margin: 0;
      position: relative;
      top:-10px;
    }
  }
    .confMsg{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-radius: 15px;
      height:100%;
      width:100%;
      position: absolute;
      top:0;
      left:0;
      background-color: white;
      visibility: hidden;

      img{
        height: 60px;
        margin-bottom: 10px;
      }

      h2{
        font-size: 26px;
        font-weight: 500;
      }

      p{
        font-size: 16px;
        font-weight: 500;
        margin: 10px 0 50px 0;

      }

      .closeBtn{
        @include solidButton(white, $main-blue--bright, 15px, 35px, 150px);
        position: absolute;
        bottom: 30px;
      }
      
        &.show{
      visibility: visible;
    }
  }

  .errorMsg{
    margin:7px 0 0 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color:red;
    font-weight: 300;
    font-size: 14px;
  }
}
