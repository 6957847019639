@import '../../../../shared.scss';

.container{
  width:100%;
  height:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing:border-box;
  padding:30px 20px 20px;

  h1{
    font-size: 22px;
    font-weight: 700;
    position: relative;
    top: 20px;
  }

  *{
    font-family: 'Sofia Pro';
  }

  .warning{
    display: flex;
    align-items: center;
    height:70px;
    width:425px;
    background: url('../../../../assets/info2.svg') no-repeat 20px center;
    background-size: 35px;
    background-color: #f2f2f2;
    border-radius: 15px;
    margin: 45px 0;
    box-sizing:border-box;
    padding:13px 10px 15px 70px;

    p{
      margin: 0;
      line-height: 21px;
      font-size: 16px;
      font-style: italic;
      color: $main-orange;
    }
  }

  .buttons{
    display: flex;
    width: 100%;
    justify-content: flex-end;

    .okBtn,
    .cancelBtn{
      height:35px;
      width:130px;
      border:1px solid;
      border-radius: 6px;
      line-height: 35px;
      font-size: 15px;
      text-align: center;
      margin-left:8px;
      cursor: pointer;
      transition: .4s all ease-out;

      img{
        height: 40px;
        widows: 40px;
        object-fit: contain;
        position: relative;
        bottom: 2px;
      }
    }

    .cancelBtn{
      border-color: #077CA0;
      color: white;
      background-color: #077CA0;

      &:hover{
        background-color: #074B60;
      }
    }
    .okBtn{
      background-color: white;
      color: #077CA0;
      border:1px solid transparent;

      &:hover,
      &.loading{
        border:1px solid #077CA0
      }

      &.loading{
        pointer-events: none;
      }
      
    }
  }

  &.revised{

    .warning{
      background: none;
      padding:0;
      text-align: center;
      display: flex;
      justify-content: center;
      height: 40px;

      p{
        color: #858585;
        margin: 10px 0;
        font-style: normal;
        font-weight: 300;
      }
    }

    .buttons{
      justify-content: center;
      position: relative;
      flex-direction: row-reverse;
      bottom: 20px;

      .okBtn{
        background-color: #FC7070;
        border-color: #FC7070;
        color: white;
        transition: .15s all ease-out;

        &:hover{
          border-color: #ee4848;
          background-color: #ee4848;
        }

      }

      .cancelBtn{
        background-color: $E_blue_light;
        border-color: $E_blue_light;
        color: $E_blue;
        transition: .15s all ease-out;

        &:hover{
          background-color: $E_blue_light2;
          border-color: $E_blue_light2;
        }

      }
      
    }
  }

  @media (max-width:660px){
    background-color: white;
    width: 90vw;
    border-radius: 10px;

    .warning{width: 100%;}
  }
}