@import '../../shared.scss';

// .container{
//   width:calc(100vw - 70px);
//   height: 100%;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   background-color: #F3F7FE;
//   font-family: 'Sofia pro';
//   box-sizing:border-box;
//   padding:0 0 30px 0;


  .main{
    width:100%;
    height:fit-content;
    height: -moz-max-content;
    box-sizing:border-box;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing:border-box;
    padding:10px 50px;
    position: relative;


    h6{
      color:#858585;
      font-size: 14px;
      font-weight: 300;
      padding: 8px 0;
      line-height: 25px;
      text-transform: uppercase;;
      margin: 20px 0 10px 0;
      display: none;

      &:first-of-type{
        margin: 10px 0 10px 0;
      }

      @media (max-width:660px){
        display: block;
      }
    }
    

    @media (max-width:660px){
      padding:10px;
    }

    .row{
      width: 100%;
      height:100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      h3{
        display: block;
        font-size: 14px;
        color:#333333;
        margin:5px 0;
        padding-left: 5px;
      }
      
      input{
        min-height:40px;
        color:#333333;
        width:100%;
        border:1px solid #cacaca;
        border-radius: 5px;
        font-size: 15px;
        box-sizing:border-box;
        padding:0 10px;
        font-family: 'DM Sans', sans-serif;
      }

      p{
        font-size: 14px;
        margin:3px 0;
        color:#858585;
        padding-left: 5px;
      }

      &:first-of-type p{ 
        color:$E_blue;
        cursor: pointer;
        &:hover{
          color: $main-blue;
        }
      }
    }
    
  }

  .btnPass{
    @include solidButton(white, $E_blue, 14px, 40px, calc(100% - 20px));
    margin-bottom: 10px !important;
    position: relative;
    left: 10px;

    img{
      height: 45px;
      position: relative;
      bottom: 2px;
    }
  }
// }


.error{
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  color: red;
  // position: absolute;
  // bottom:-80px;

}
.success{
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  color: $main-green;
  // position: absolute;
  // bottom:20px;

}

.revert{
  pointer-events: none;
  background-color: $E_blue;
}


.hidden{
  display: none;
}