@import '../../../../shared.scss';

.container{
  width: 280px;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  border-right: 1px solid #cacaca;
  position: relative;
  z-index: 30;
  border-right:none;

  .modalContainer {
    position: absolute;
    width: 500px;
    left: 260px;
    bottom: 10%;
    height: fit-content;
    background-color: white;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    -webkit-box-shadow: 1px 1px 20px 5px rgba(0,0,0,0.2);
    -moz-box-shadow: 1px 1px 20px 5px rgba(0,0,0,0.2);
    box-shadow: 1px 1px 20px 5px rgba(0,0,0,0.2);
    border-radius: 10px;

    &.display{
      left: 230px;
      opacity: 1;
      transition: .4s all ease-out;
    }
  
    .closeBtn{
      position: absolute;
      top: 10px;
      right: 10px;
      width: 30px;
      height: 30px;
      background: url('../../../../assets/icon-close.png') no-repeat center;
      background-size: contain;
      cursor: pointer;
      z-index: 100;
      opacity: .8;
  
      &:hover{
        opacity: 1;
      }
    }
  }

  .titleDiv{
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-sizing:border-box;
    padding:15px;

    h3{
      font-size: 22px;
      color: #333333;
      max-width: calc(100% - 20px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 60px;
      line-height: 60px;
    }
  }


  .spinner{
    height: 100%;
    width: 100%;
    box-sizing:border-box;
    padding:150px 0 0 0;

    img{
      height: 100px;
    }
  }

  .activityDetails{
    display: flex;
    align-items: center;
    height: 80px;
    width: 100%;
    padding:10px 0;
    
    .iconDiv{
      width: 80px;
      height:80px;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon{
        width: 42px;
        height: 60px;

        &.one{
          background: url('../../../../assets/journal_blue.svg') no-repeat center;
        }
        &.two{
          background: url('../../../../assets/journal_orange.svg') no-repeat center;
        }
        &.three{
          background: url('../../../../assets/journal_pink.svg') no-repeat center;
        }
        &.four{
          background: url('../../../../assets/journal_green.svg') no-repeat center;
        }

        &.grey{
          background: url('../../../../assets/journal_grey.svg') no-repeat center;
        }
      }
    }

    .text{
      width: calc(100% - 80px);
      position: relative;
      left: -10px;

      .codeDiv{
        display: flex;

        h6{
          // border:1px solid purple;
          text-align: left;
          padding: 0 5px;
          position: relative;
          top: -0px;
          font-size: 13px;
          color: #858585;
        }
        
        .query{
          height:15px;
          width:15px;
          background: url('../../../../assets/query.svg') no-repeat center/contain;
          cursor: pointer;
          transition: .3s all ease;
          opacity: 1;
          position: relative;

          &:hover{
            .tooltip{
              top: 20px;
              opacity: 1;
            }
          }

          .tooltip{
            transition: .2s all ease;
            pointer-events: none;
            border-radius: 10px;
            background-color: white;
            padding: 15px 10px;
            display: flex;
            align-items: center;
            height: fit-content;
            position: absolute;
            top:10px;
            opacity: 0;
            left: -10px;
            z-index: 30;
            -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.39);
            -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.39);
            box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.39);

            .book{
              height:70px;
              width:80px;
              margin-right: 5px;
              background: url('../../../../assets/cover_journal.svg') no-repeat center/contain;
            }

            p{
              width: 280px;
              font-size: 12px;
              text-align: left;
              color: #858585;
            }
          }

          // &:hover{
          //   opacity: 1;
          // }
        }

      }


      .typePill{
        padding:3px 10px;
        border-radius: 5px;
        background-color: $E_blue;
        width: fit-content;
        margin-bottom: 5px;

        &.assignment{
          background-color: $E_pink;
        }

        &.journal,
        &.faith{
          background-color: $E_orange;
        }

        p{
          font-size: 12px;
          color: white;
          font-weight: 500;
          margin: 0;
          line-height: 14px;
        }
      }
      h3{
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        max-width: 220px;
        text-align: left;
        line-height: 20px;

      }

    .nameDiv{
      width:100%;
      height: 30px;
      position: relative;
      top: 2PX;
      cursor: pointer;
      pointer-events: all !important;
      z-index: 25 !important;
      // padding: 0 30px 0 15px;
      display: flex;
      align-items: center;
      

      input[type=text]{
        height: 100%;
        width: 100%;
        // box-sizing:border-box;
        padding:0 5px;
        position: relative;
        left: 0px;
        z-index: 15 !important;
        pointer-events: all !important;
        font-size: 16px;
        color: #333333;
        border:1px solid #f0f0f0;
      }

      .editHover{
        position: absolute;
        height: 30px;
        width: 100%;
        cursor: pointer;
        bottom: 35px;
        z-index: 15;
        border-radius: 5px;
        pointer-events: all !important;
        top: 0;
        left:0;
        
        &:hover{
          background: url('../../../../assets/icon-pencil--black.png') no-repeat center right 5px, #f2f2f2c5;

          //class passed to <Tooltip/> component through props
          .tooltipHover{
            opacity: 1 !important;
            top: 35px !important;
          }
        }
 


      }


      h5{
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
        // letter-spacing:1.02px;
        color: #333333;
        // width: ;
        position: relative;
        top: 4px;
        left: 6px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-align: left;
        z-index:  20 !important;
      }

    }
    }
  }

  .sections{
    height: fit-content;
    max-height: calc(100% - 240px);
    overflow-y: auto;
    width: 100%;
    box-sizing:border-box;
    padding:3px 15px 0 10px;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 7px;
      background-color: $E_blue_light2;
      border:1px solid white;
    }

    .placeholder{
      width: 100%;
      height: 35px;
      margin-bottom: 8px;
      border:1px solid #E9E9E9;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      background-color: #F2F2F2;
      

      img{
        height: 28px;
      }

    }
 
  }  


  .newSectionBtn{
    height: 40px;
    width: calc(100% - 30px);
    margin: 10px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border:1px solid $E_blue;
    cursor: pointer;
    border-radius: 5px;
    box-sizing:border-box;
    padding:0 20px 0 0 ;
    transition: .15s all ease-out;

    img{
      height: 15px;
    }
    p{
      color: $E_blue;
      margin-left: 15px;
    }

    &.inactive{
      pointer-events: none !important;
      opacity: .7;
    }

    &:hover{
      background-color: $E_blue_light2;
      border-color: $E_blue_light2;
    }
  }

  .activitySuggestions {
    width: 85%;
    background-color: $faith-grey-pink;
    margin: auto auto 80px auto;
    font-weight: 300;
    padding: 5px;
    border-radius: 10px;
    border: 1px solid #ddd;

    h4 {
      color: $E_blue_dark;
      font-size: 0.8rem;
      margin: 10px 5px;
    }

    .shortcutArea {
      color: $E_blue;
      font-size: 0.9rem;
      border-radius: 8px;
      padding: 5px 8px;
      display: flex;
      align-items: center;

      .openQuestions {
        box-sizing:border-box;
        margin-left: auto;
        padding: 5px;
        border-radius: 8px;
        height: 35px;
        width: 35px;
        background: url('../../../../assets/white_arrow_tip.svg') no-repeat center;
        transform: rotate(-90deg);
        background-color: #7fbacc;
        transition: 0.3s ease-in-out background-color;
        cursor: pointer;
        pointer-events: all;  

        &:hover {
          background-color: $E_blue;
        }
      }

      .verseOfTheDay {
        box-sizing:border-box;
        margin-left: auto;
        padding: 10px;
        border-radius: 8px;
        height: 35px;
        width: 35px;
        background: url('../../../../assets/icon_copy_white2.svg') no-repeat center;
        background-color: #7fbacc;
        transition: 0.3s ease-in-out background-color;
        cursor: pointer;
        pointer-events: all;  

        &:hover {
          background-color: $E_blue;
        }
      }
    }
  }
}


.sectionDiv{
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .2s all ease-out;


  &.checked{
    width: calc(100% + 10px);
  }
  

  .wrapper{
    width: 100%;
    height: 35px;
    border:1px solid #E9E9E9;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    // margin-bottom: 6px !important;
    background-color: white;
    cursor: pointer;
    transition: .2s all ease-out;
    box-sizing:border-box;
    padding:0 10px;
    position:relative;
      .dot{
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color: red;
        position: absolute;
        top: -4px;
        right: -4px;
      }
    
    
      *{
        position: relative;
        z-index: 1;
        pointer-events: none;
      }
    
      input[type=text]{
        margin-left: 5px;
        box-sizing:border-box;
        padding:3px 5px;
        font-weight: 500;
        color: #858585;
        border:1px solid #cacaca;
        border-radius: 2px;
        pointer-events:  all !important;
        width: calc(100% - 25px);
        z-index: 10;
      }
    
    
      h5{
        font-size: 14px;
        font-weight: 500;
        color: #858585;
        max-width: calc(100% - 80px);
        margin-left: 10px;
        text-align: left;
        position: relative;
        top: 1px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 30px;
        line-height: 30px;
      }
    
      .numberCircle{
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background-color: $E_blue_light3;
        display: flex;
        align-items: center;
        justify-content: center;
    
        p{
          margin:0;
          font-size: 12px;
          color: #858585;
        }
      }
    
      .tick{
        height: 13px;
        width: 13px;
        margin-left: 10px;
        background: url('../../../../assets/tick_green.svg') no-repeat center;
      }
    
      .calendar{
        height: 18px;
        width: 18px;
        margin-left: 10px;
        background: url('../../../../assets/calendar_blue.svg') no-repeat center;
        background-size: contain;
        position: relative;
        bottom: 1px;
      }
    
      .pencil{
        margin-left: 10px;
        background: url('../../../../assets/pencil_grey.svg') no-repeat center;
        height: 25px;
        width: 25px;
        border-radius: 5px;
        cursor: pointer;
        position: absolute;
        right: 5px;
        opacity: 0;
        pointer-events: none;
        transition: .2s all ease-out;
        z-index: 10;
        
        &:hover{
          background: url('../../../../assets/pencil_grey.svg') no-repeat center, #d2d2d2;
          background: url('../../../../assets/pencil_grey.svg') no-repeat center, $E_blue_light2;
    
          //class passed to <Tooltip/> component through props
          .tooltipHover{
            opacity: 1 !important;
            top: 28px !important;
    
            p{
              color: #858585 !important;
            }
          }
        }
    
        &.inactive{
          display: none;
          pointer-events: none;
        }
      }
    
      .clickHandle{
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top:0;
        z-index: 1;
        pointer-events: all !important;
    
      }
    
      &:hover{
        background-color: $E_blue_light3;
        border-top-left-radius: $E_blue_light3;
    
        h5{
          color: $E_blue;
        }
    
        .numberCircle{
          background-color: $E_blue_light2;
        }
        .pencil{
          opacity: 1;
          pointer-events: all;
        }
      }
    
      //<---------------- PHASE-DEPENDANT CLASSES
    
      &.draft{
        background-color: #F2F2F2;
        border-color: #E9E9E9;
        
        .numberCircle{
          background-color: #D2D2D2;
        }
        
        &:hover{
          background-color: #e6e6e6;
          h5{
            color: #858585;
          }
        }
      }
    
      &.completed{
        background-color: $E_green_light;
        border-color: $E_green_light;
    
    
        .numberCircle{
          background-color: transparent;
          p{
            font-weight: 500;
            font-size: 16px;
          }
        }
    
        h5, p{
          color: $E_green_dark;
        }
    
        .pencil{
          &:hover{
            background-color: $E_green_light;
        }
        }
    
        &:hover{
          background-color: $E_green_light2;
        }
      }
    
      &.storyStart{
        background-color: $E_orange_light;
        border-color: $E_orange_light;
    
    
        .numberCircle{
          background-color: transparent;
          p{
            font-weight: 500;
            font-size: 16px;
          }
        }
    
        h5, p{
          color: #FFA826;
        }
    
        .rocket{
          height: 20px;
          width: 20px;
          background: url('../../../../assets/rocket_yellow.svg') no-repeat center;
          margin-left: 10px;
        }
    
        &:hover{
    
          &.storyStart{
            background-color: $E_orange_light2;
          }
        }
      }
    
      &.checked{
        background-color: $E_blue !important;
        border-color: $E_blue !important;
        // width: calc(100% + 10px);
    
        .numberCircle{
          background-color: #3190ad;
        }
    
        p, h5{
          color: white;
        }
    
        .pencil{
          background: url('../../../../assets/pencil_white.svg') no-repeat center;
          
          &:hover{
            background: url('../../../../assets/pencil_white.svg') no-repeat center, #3190ad;
          }
        }
    
        &.scheduled{
          .calendar{
            background: url('../../../../assets/calendar_white.svg') no-repeat center !important;
          }
        }
    
        &.completed{
          .tick{
            background: url('../../../../assets/tick_white.svg') no-repeat center;
          }
        }
    
        &.storyStart{
          .rocket{
            background: url('../../../../assets/rocket_white.svg') no-repeat center;
          }
        }
    
        &:hover{
          h5{
            color: white;
          }
        }
      }
  }
}

  //<---------------- PHASE-DEPENDANT CLASSES
 