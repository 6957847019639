@import '../../../shared.scss';


.singleItem,
.multipleItem,
.noItem{
  position: relative;
  height:30px;
  line-height: 30px;
  text-align: center;
  width: 85%;
  box-sizing:border-box;
  padding:0 10px 0 35px;
  border-radius: 10px;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  p{
    line-height: 30px;
    margin:0;
    font-size: 12px;
    // color: $main-green;
  }
  h5{
    margin:0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover{
    border: 1px solid #cacaca;
  }
}

.singleItem{
  background: url('../../../assets/icon-mail--single.svg') no-repeat 10px center;
  background-color: #f2f2f2;


  &:hover{
    border: 1px solid transparent;
  }
  
  &.copyable{
    padding:0 40px 0 35px;
    &:hover{
      background: url('../../../assets/icon-mail--single.svg') no-repeat 10px center,
      url('../../../assets/icon-copy.svg') no-repeat 90% center;
      background-color: #f2f2f2;
    }
  }

}
.multipleItem{
  padding:0 40px;
  background: url('../../../assets/icon-mail--multiple.svg') no-repeat 10px center,
  url('../../../assets/icon-chevron--grey.png') no-repeat 92% center;
  background-color: #f2f2f2;

  &
  .emailsDropdown{
    box-sizing:border-box;
    padding:5px;
    width:100%;
    height: fit-content;
    height: -moz-max-content;
    background-color: white;
    // border: 1px solid #cacaca;
    border-radius: 10px;
    text-align: center;
    position: absolute;
    left:0%;
    z-index: 100;
    -webkit-box-shadow: 0px 1px 9px 0px #00000026; 
    box-shadow: 0px 1px 9px 0px #00000026;
    .singleItem,
    .multipleItem,
    .noItem{
      width: 100%;
      border-radius: 0px;
      background-color: transparent;

      &:hover{
        border:1px solid transparent;
        background-color: #f2f2f2;
        border-radius: 5px;
      }
  }
  }
}

.noItem{
  background: url('../../../assets/icon-mail--add.svg') no-repeat 10px center;
  background-color: #f2f2f2;

}