@import '../../../../shared.scss';

.container{
  position: absolute;
  bottom: 65px;
  left: 0;
  height:(calc(var(--viewportHeight) - 65px));
  width: 100vw;
  background-color: rgba(7, 7, 7, 0.515);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;


  &.open{
    opacity: 1;
    pointer-events: all;
  }

  .notificationsInner{
    height:(calc(var(--viewportHeight) - 100px));
    width: calc(100vw - 20px);
    box-sizing:border-box;
    background-color: white;
    border-radius: 10px;
    transition: .2s all ease-in-out;
    position: relative;
    top: 100vh;

    &.open{
      top: 0;
    }

    .topBar{
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing:border-box;
      padding:0 10px 0 20px;
      border-bottom: 1px solid #cacaca;

      h5{
        font-size: 16px;
        font-weight: 500;
        color: #333333;
      }

      .close {
        cursor: pointer;
        opacity: 1;
        background-image: url('../../../../assets/icon-close.png');
        height: 35px;
        width: 35px;
        z-index: 1500;
        transition: .3s all ease-out;
        pointer-events: initial;
        background-repeat: no-repeat;
    
        &:hover {
          opacity: 0.7;
          transform: scale(1.05);
        }
      }
    }

    .mainDiv{
      width: 100%;
      height: calc(100% - 125px);
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;


      h6{
        color: #858585;
        font-size: 14px;
        font-weight: 300;
        margin-top: 60px;
      }
      

      .itemWrapper{
        width: 100%;
        min-height: 90px;
        max-height: 90px;
        border-bottom:1px solid #cacaca;
        display: flex;
        align-items: center;
        transition: .12 all linear;
        cursor: pointer;
        
        &.hide{
          opacity: 0;
          min-height: 0px;
          max-height: 0px;
          border-bottom:1px solid transparent;

        }

        .avatarDiv{
          height: 90px;
          min-width: 110px;
          max-width: 110px;
          
          img{
            height: 90px;
            width: auto;
            object-fit: contain;
            position: relative;
            top: 5px;
          }
        }

        .text{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;

          h6{
            font-size: 16px;
            color: #333333;
            font-weight: 300;
            margin:0;
    
            span{
              font-size: 13px;
              color: #858585;
              font-weight: 300;
            }
          }

          p{
            font-size: 15px;
            color: #858585;
            font-weight: 300;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: calc(100vw - 150px);
    
            span{
              color: #333333;
            }
          }
        }

      }
    }

    .markAsReadBtn{
      width: 150px;
      height: 40px;
      border-radius: 5px;
      line-height: 40px;
      text-align: center;
      color: $E_blue;
      background-color: $E_blue_light;
      font-size: 14px;
      margin:10px auto;
      opacity: .5;
      pointer-events: none;
      
      &.active{
        opacity: 1;
        pointer-events: all;
      }
    }

  }
  // height: 0;

}