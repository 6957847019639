@import '../../../../shared.scss';

.container{
  width:100%;
  height:fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing:border-box;
  padding:0 15px;

  h1 {
    color: #064149;
    font-size: 1.5rem;
  }

  p {
    font-size: .8rem;
    color: #858585;
    text-align: center;
  }

  .buttons{
    display: flex;
    margin-top:20px;

    .upgradeBtn,
    .cancelBtn{
      height:40px;
      width:150px;
      border-radius: 10px;
      line-height: 40px;
      font-size: 16px;
      text-align: center;
      margin:0 8px;
      cursor: pointer;
      transition: .4s all ease-out;
    }

    .cancelBtn{
      color: #FB3333;
      background-color: #FFE5E3;
  
      &:hover{
        background-color: #fad2cf;
      }
    }
    .upgradeBtn{
      background-color: #FD7D73;
      color: #FFFFFF;      

      &:hover{
        background-color: #f6695f;
      }
    }
  }
}