@import '../../../../../shared.scss';

.container{
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 55;
  background-color: #4f4e4e8c;

  .inner{
    height: 90%;
    min-height: 600px;
    width: 90%;
    max-width: 1300px;
    background-color: white;
    border-radius: 10px;
    box-sizing:border-box;
    position: relative;

    .topBar{
      width: 100%;
      position: relative;


      .titleRow{
        box-sizing:border-box;
        padding:0 20px 15px 20px;
        display: flex;
        align-items: flex-end;
        height: 55px;
        width: 100%;
        border-bottom: 1px solid #cacaca;
        position: relative;

        .rocketDiv{
          height: 62px;
          width: 62px;
          display: none;
          background: url('../../../../../assets/rocket_yellow.svg')no-repeat center;
          background-size: 30px;
          position: relative;
          bottom: 5px;
          margin-left: 5px;
        }
  

        p.start{
          color: $E_orange;
          position: absolute;
          top: 5px;
          left: 55px;
          display: none;
        }

        &.story{
          box-sizing:border-box;
          padding:10px 0 0 0;
        }

        h3{
          color: #333333;
          font-size: 18px;
        }

        p{
          margin-left: 10px;
          color: #858585;
          font-size: 14px;
          position: relative;
          top: 2px;
        }

        .close{
          height: 30px;
          width: 30px;
          background: url('../../../../../assets/close_square_grey.svg') no-repeat center;
          cursor: pointer;
          position: absolute;
          right: 10px;
          
          &:hover{
            background: url('../../../../../assets/close_square_grey_hover.svg') no-repeat center;

          }
        }
      }


      &.story{
        display: flex;

        .titleRow{
          height: 65px;
          display: flex;
          align-items: center;

          .rocketDiv{
            display: initial;
          }

          p.start{
            display: initial;
          }

        }
        .sections{
          display: none;
        }

      }
    }

    .sections{
      width: 100%;
      height: 55px;
      display: flex;
      align-items: center;
      box-sizing:border-box;
      padding:0 20px;
      border-bottom: 1px solid #cacaca;

      .sectionDiv{
        height: 40px;
        width: 40px;
        border-radius: 8px;
        border:1px solid #cacaca;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;
        background-color: #e9e9e9;
        margin-right: 5px;

        p{
          color: #858585;
          position: absolute;
          right: 5px;
          bottom: 0px;
        }

        &.checked{
          border-color: $E_blue;
          background-color: $E_blue;
          pointer-events: none;

          p{
            color: white;
          }
        }
      }
    }

    .instructionsDiv{
      height: calc(100% - 170px);
      overflow-y: auto;
    }

    .selectBtn{
      padding:7px 20px;
      background-color: $E_blue;
      cursor: pointer;
      color: white;
      transition: .2s all ease-out;
      position: absolute;
      bottom: 15px;
      right: 15px;
      border-radius: 5px;
      font-size: 17px;

      &:hover{
        background-color: $E_blue_dark;
      }
    }

  }

}