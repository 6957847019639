@import '../../../../shared.scss';


.container{
  border-radius: 10px;
  background-color: white;
  transition: all .4s ease-in-out;
  width:530px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  // &.init{
  //   width:530px;
  //   height: 350px;
  // }

  &.sent{
    width:300px;
    height: 220px;
  }

  *{
    text-align: left;
    box-sizing:border-box;
  }



  h1{
    width: 100%;
    text-align: center;
    font-size: 28px;
    font-weight: 500;
  }

  p{
    font-size: 14px;
    color: black;
    font-weight: 300;

  }

  i{
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
  }



  .email{
    width: fit-content;
    background-color: #f2f2f2;
    padding:3px 10px;
    border-radius: 5px;
    position: relative;
    // left:-8px;
    margin:5px 0;

    p{ 
      margin: 0;
      font-size: 14px;
      max-width: 360px;
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
    }

      span.changeEmail{
        position: absolute;
        right: -76px;
        color: $main-blue--bright;
        font-size: 11px;
        bottom:9px;
        cursor: pointer;

        &:hover{
          text-decoration: underline;
        }

      }

  }

  .warning{
    display: flex;
    align-items: center;
    height:70px;
    width:445px;
    background: url('../../../../assets/info.svg') no-repeat 20px center;
    background-size: 35px;
    background-color: #f2f2f2;
    border-radius: 15px;
    margin: 15px 0 5px 0;
    box-sizing:border-box;
    padding:13px 10px 15px 70px;

    p{
      margin: 0;
      line-height: 21px;
      font-size: 16px;
      font-style: italic;
      color: $main-orange;
    }
  }


  .sentModal{
    height:100%;
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    transition: .3s all ease-in-out;
  }


  .buttons{
    display: flex;
    justify-content: flex-start;
    position: relative;
    top:5px;
    width: 100%;
    margin: 5px auto 10px auto;

    .resendBtn{
      @include mainButton($main-blue--bright, white, 16px, 40px, 150px);
      line-height:38px;
    }
    // .sendBtn{
    //   @include solidButton(white, $main-blue--bright, 16px, 40px, 210px);
    //   line-height:38px;
    // }
  }

}