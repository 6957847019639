  $main-blue: rgb(43, 78, 123);
  $main-blue--bright: rgb(6, 95, 229);
  $main-blue--light: rgb(7, 124, 160);
  $main-blue-brighter: rgb(55, 159, 247);
  $main-orange: rgb(255, 130, 88);
  $main-orange--bright: rgb(255, 168, 38);
  $main-green: rgb(151, 211, 24);
  $main-pink: rgb(242, 98, 131);
  $main-yellow: rgb(255, 168, 38);


  .bookPurchaseCardDetails{
    overflow: hidden;
    height: 115px;
    width: 110%;
    position: relative;
    left: -5%;

    .StripeElement{
      padding: 12px;
      border:1px solid #cacaca;
      border-radius: 5px;
      margin:3px 0;
    }
  }
  
  #googleClassIcon{
    background: url('../assets/teacher/additionalButton3.svg') no-repeat center;
    // background-color: red;
    background-size: contain;
    position: relative;
    // overflow: hidden;

    .hoverBox{
      height: 30px;
      width: 32px;
      position: absolute;
      top:-2px;
      left:-4px;
      border-radius: 5px;
      background-color: #e9e9e9;
      z-index: -1;
      visibility: hidden;
    }

    
  
    :first-child{ //hidden Google NPM element
    
      opacity: .01;
      position: absolute;
      z-index: 0;
    
      &:hover ~ #googleToolTip {
        visibility: visible;
      }
      &:hover ~ .hoverBox {
        visibility: visible;
      }
    }
  }
  #googleClassIconNonPlus{
    background: url('../assets/teacher/additionalButton3.svg') no-repeat center;
    // background-color: red;
    background-size: contain;
    position: relative;
    right:-20px !important;
    bottom:10px;
    // overflow: hidden;
  
    :first-child{ //hidden Google NPM element
    
      opacity: .01;
      position: absolute;
      z-index: 0;
    
      &:hover ~ #googleToolTip {
        visibility: visible;
      }
    }
  }

  #googleToolTip{
    width: 190px;
    left:-80px;
    &::after{
      left:0;
    }
  }

  // country picker

  select[name="rcrs-region"],
  select[name="rcrs-country"]{
    width:100%;
    height: 40px;
    border-radius: 8px;
    border:1px solid #cacaca;
    padding-left: 10px;
    cursor: pointer;
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
    background: url('../assets/icon-chevron--down--black.png') no-repeat right 10px center !important;
    background-size: 20px !important;

    &:focus{
      outline: none;
    }
  }


  // elastic carousel arrows 
  
  .rec-arrow{
    background-color: rgba(255, 255, 255, 0.4) !important;
    
    &:focus{
      background-color: rgba(255, 255, 255, 0.4) !important;
      color: #333 !important;
    }
    &:hover, &:active{
      background-color: $main-blue--bright !important;
      color: white !important;
    }

  }

  /// AUTO-FEEDBACK CLASSES

   
  .auto_grammar{
    border:1px solid  #8e00f0;
    background-color: #8e00f04a;
    height:24px;
    line-height: 20px;box-sizing:border-box;
    padding:1px 5px 3px 7px;
    border-radius: 30px;
  }
   
  .auto_consistency{
    border:1px solid  #FF8258;
    background-color: #FF82584a;
    height:24px;
    line-height: 20px;box-sizing:border-box;
    padding:1px 5px 3px 7px;
    border-radius: 30px;
  }
   
  .auto_overused{
    border:1px solid  #077ca0;
    background-color: #077ca04a;
    height:24px;
    line-height: 20px;box-sizing:border-box;
    padding:1px 5px 3px 7px;
    border-radius: 30px;
  }

  .auto_initial{
    border:1px solid  #ffa826;
    background-color: #ffa8264a;
    height:24px;
    line-height: 20px;box-sizing:border-box;
    padding:1px 5px 3px 7px;
    border-radius: 30px;
  }

  .auto_passive{
    border:1px solid  #f26283;
    background-color: #f262834a;
    height:24px;
    line-height: 20px;box-sizing:border-box;
    padding:1px 5px 3px 7px;
    border-radius: 30px;
  }
  

  .feedback{
    cursor: pointer;

    .tooltipWrapper{
      position: absolute;
      visibility: hidden;
     left: calc(50% - 100px);
    }
    
    .tooltip{
      position: absolute;
      top:15px;
      left: 0;
      right: 0;
      // left: 50%;
      // transform: translateX(-50%);
      height:fit-content;
      height:-moz-max-content;
      width:200px;
      width:200px;
      min-width: 160px;
      box-sizing:border-box;
      padding:10px;
      border-radius: 5px;
      color: #858585;
      background-color: white;
      -webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
      -moz-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
      box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
      font-size: 12px;
      text-align: center;
      line-height: 20px;
      z-index: 1000;

      // &::after{
      //   content:'';
      //   position: absolute;
      //   top: -10px;
      //   left:0;
      //   margin-left: calc(50% - 5px);
      //   border-width: 5px;
      //   border-style: solid;
      //   border-color:transparent transparent white transparent; 
      // }
    }

    .sugg_grammar{
      font-size: 12px;
      font-weight: 500;
      color: #8e00f0;
      .suggHead{
        font-size: 12px;
        font-weight: 700;
        display: block;
        color:#8e00f0;
      }
    }

    .sugg_consistency{
      font-size: 12px;
      font-weight: 500;
      color: #FF8258;
      .suggHead{
        font-size: 12px;
        font-weight: 700;
        display: block;
        color:#FF8258;
      }
    }

    .sugg_overused{
      font-size: 12px;
      font-weight: 500;
      color: #077ca0;
      .suggHead{
        font-size: 12px;
        font-weight: 700;
        display: block;
        color:#077ca0;
      }
    }

    .sugg_passive{
      font-size: 12px;
      font-weight: 500;
      color: #f26283;
      .suggHead{
        font-size: 12px;
        font-weight: 700;
        display: block;
        color:#f26283;
      }
    }

    .sugg_initial{
      font-size: 12px;
      font-weight: 500;
      color: #ffa826;
      .suggHead{
        font-size: 12px;
        font-weight: 700;
        display: block;
        color:#ffa826;
      }
    }


    
    &:hover >.tooltipWrapper{
      visibility: visible;
    }

  }

  ///MASONRY GRID

.masonry-grid {
  display: -webkit-box; 
  display: -ms-flexbox; 
  display: flex;
  justify-content: flex-start;
  margin-left: -30px; 
  padding:0 0 0 0px;
  width: 100%;

  &.synod{
    margin-left: 0 !important;
  }

  @media (max-width:1080px){
    padding: 0;
  }

  @media (max-width:800px){
    width: 100%;
  }
}
.masonry-grid_column {
  padding-right: 8px; /* gutter size */
  background-clip: padding-box;
  
  &.pendingRes{
    // width: 33% !important; 
  }

  @media (max-width:660px){
    padding-right: 0;
  }
}
.masonry-grid_column > div {
  margin-bottom: 15px !important;
}


  ////CALENDAR STYLES
  .fc-media-screen.fc-direction-ltr.fc-theme-standard{
    width:calc(90vw - 260px) !important;
    // width:1040px !important;
    margin-left: 10px;
  }
  
  .fc-toolbar.fc-header-toolbar{
    width:calc(90vw - 260px) !important;
  
  }
  
  //top bar (month, arrows)
  .fc-toolbar-title {
    font-size: 1.75em !important;
    font-weight: 300 !important;
  }
  .fc-toolbar.fc-header-toolbar{
    height:70px !important;
    display: flex !important;
    align-items: center !important;
    box-sizing:border-box;
    padding:0 0 0 30px;
    margin: 0 !important;
  } 
  
  //default buttons (hide, replace with custom btns)
  .fc-button-group, .fc-today-button{
    display: none !important;
  }
  
  //table header
  .fc-scrollgrid-section-header {
    position: absolute;
    width: calc(90vw - 260px) !important;
    *{border:0px !important}
  }
  .fc-col-header {
    width: calc(90vw - 260px) !important;
  }
  // table header day cell
  .fc-col-header-cell.fc-day{
    text-align: left;
    border-bottom: none;
  }
  
  // day block
    //number text
  .fc-daygrid-day-number {
    padding: 3px 7px !important;
    color: #333333 !important;
    font-size: 14px;
  }
    // day text
  .fc-col-header-cell-cushion {
    display: inline-block;
    padding: 7px 10px !important;
    color: #858585 !important;
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
  }
    // day text TODAY
  .fc-day-today{
    background-color: white !important;
    .fc-daygrid-day-number {
      width: 20px;
      text-align: center;
      background-color: rgb(6, 95, 229);
      border-radius: 50%;
      color: white !important;
      padding: 0px 3px !important;
      margin: 3px 5px 0 0;
    }
  }
  
  //day block 
  .fc-daygrid-day-frame{
    height:120px !important;
  }

  .fc-daygrid-block-event .fc-event-time, .fc-daygrid-block-event .fc-event-title {
    // color:chartreuse;
    position: relative;
    bottom:2px;

  }
  
  // event 
  .fc-daygrid-event{
    cursor: pointer;
    height: 25px !important;
    border-color: white !important;
    color: white !important;
    margin-top: 5px !important;
    
    *{ height: 24px !important; }
  }
    //event title
    .fc-event-title-container{
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: calc(100% - 80px);
      display: flex;
      align-items: center;
  
      span{
        color:white !important;
        font-size: 14px !important;
        font-weight:300 !important;
        margin-left: 30px !important;
        // position: relative;
        // top:6px;
        height: fit-content !important;
      }
    }
    .fc-event-title{
      line-height: 24px;
      font-size: 14px;
      padding-left: 30px !important;
    }
    .fc-daygrid-event-dot+.fc-event-title{
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: calc(100% - 80px);
  
    }
    // end date marker (unwanted)
    .fc-daygrid-event-dot{
      display: none;
    }
    .fc-daygrid-day-events{
      height: 75%;
    }
    // 'more' marker
    .fc-daygrid-more-link{
      position: absolute !important;
      bottom: 0 !important;
      right: 3px !important;
      color: #858585 !important;
    }
  
    //popover
    .fc-more-popover{
      width: 400px;
    }
    .fc-popover-header{
      background-color: white !important;
      .fc-popover-title{
        font-weight: 300;
        color: #333333;
      }
    }
  
  