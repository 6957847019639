@import '../../../../shared.scss';

.container{
  height: fit-content;
  width: calc(100% - 30px);
  margin: 30px auto 20px auto !important;


  .titleBar{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing:border-box;
    padding:0 15px;
    margin-bottom: 10px;
  }

  .main{
    min-height: 150px;
    height: fit-content;
    box-sizing:border-box;
    padding:5px;
    border-radius: 8px;
    // -webkit-box-shadow: 0px 4px 10px 5px rgba(0, 99, 192, 0.2);
    // -moz-box-shadow:    0px 4px 10px 5px rgba(0, 99, 192, 0.2);
    // box-shadow:         0px 4px 10px 5px rgba(0, 99, 192, 0.2);
    
    .loaderDiv{
      height: 150px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  
      .spinner{
        height: 100px;
        width: 100px;
        background: url('../../../../assets/spinner_teal.svg') no-repeat;
        background-size: contain;
        position: relative;
        bottom: 0px;
      }
  
      p{
        font-size: 12px;
        color: #858585;
        font-weight: 300;
        position: relative;
        bottom: 20px;
      }
    }

    .viewAllBtn{
      width: 100%;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-radius: 8px;
      background-color: $E_blue_light2;
      color: $E_blue;
      font-weight: 500;
      font-size: 14px;
      margin-top: 15px;

      &:active{
        background-color: $E_blue_light;
      }
    }
  }

}