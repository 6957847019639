@import '../../shared.scss';


.container{
  width:calc(100vw - 70px);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // background-color: #F3F7FE;
  background-color: white;
  font-family: 'Sofia pro';
  box-sizing:border-box;
  padding:0 0 30px 0;


  *{font-family: 'Sofia pro'}

  .loaderDiv{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
  }

  h4{
    font-size: 18px;
    font-weight: 300;
    color:#333333;
  }

  .resContainer{
    display: none;
  }



  input[type="file"]{display:none}

  .top{
    height:60px;
    width:100%;
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: left;
    box-sizing:border-box;
    padding:0 0 0 25px;
  }



  .back{
    font-size:14px;
    color:#858585;
    cursor: pointer;
    transition: .3s all ease-out;
    margin-bottom: 2px;

    &:hover{
      color: #666666;
    }
  }

  .main{
    height:calc(100vh - 100px);
    width:calc(100vw - 120px);
    display: flex;
    justify-content: space-between;

    .photoBlock{
      height:310px;
      width: 290px;
      border-radius: 5px;
      border:1px solid #cacaca;
      box-sizing:border-box;
      padding:15px;

      .photo{
        margin: 0 auto;
        height: 220px;
        width: 220px;
        margin-bottom: 15px;

        img{
          min-height: 220px;
          min-width: 220px;
          max-height: 220px;
          max-width: 220px;
          // object-fit: contain;
          margin: 0 auto;
          border:1px solid #CACACA;
          border-radius: 50%;

          &.loader{
            box-sizing:border-box;
            padding:30%;
          }
        }
      }

      .btns{
        margin-top: 10px;
        display: flex;
        justify-content: space-between;

        label>input+.btn{
          @include solidButton(white, $E_blue, 14px, 40px, 160px)
        }

        label+.btn{
          font-size: 14px;
          height: 40px;
          line-height: 40px;
          width: 85px;
          border-radius: 10px;
          text-align: center;
          transition: .15s all ease-in-out;
          cursor: pointer;
          color: $E_blue;
          background-color: $E_blue_light;
          
          &:hover{
            background-color:$E_blue_light2;
          }
        }

      }
    }

    .settingsBlock{
      height:100%;
      width: calc(100% - 310px);
      border-radius: 5px;
      border:1px solid #cacaca;
      display: flex;

      .tabs{
        width:195px;
        height: 100%;
        box-sizing:border-box;
        padding:5px;
        border-right:1px solid #cacaca;

        input[type="radio"]{
          display: none;
        }

        input+.tabRadio{
          width: 100%;
          height: 40px;
          line-height: 40px;
          margin-bottom: 5px;
          text-align: left;
          box-sizing:border-box;
          padding:0 0 0 45px;
          font-size: 14px;
          font-weight: 300;
          color:#333333;
          cursor: pointer;
          border-radius: 8px;
          border:1px solid transparent;
          transition: .3s all ease-in-out;
          
          &:hover{
            border:1px solid #CDDFFA;
          }

        }

        label:first-of-type>.tabRadio{
          background: url('../../assets/icon-profileEdit.svg') no-repeat 15px center;
          background-size: 12%;
        }
        label:first-of-type>input:checked+.tabRadio{
          background: url('../../assets/icon-profileEdit--blue.svg') no-repeat 15px center;
          background-size: 12%;
          background-color: #CDDFFA;
        }

        label:nth-of-type(2)>.tabRadio{
          background: url('../../assets/icon-profilePassword.svg') no-repeat 15px center;
        }
        label:nth-of-type(2)>input:checked+.tabRadio{
          background: url('../../assets/icon-profilePassword--blue.svg') no-repeat 15px center;
          background-color: #CDDFFA;
        }

        label:nth-of-type(3)>.tabRadio{
          background: url('../../assets/icon-profileEmail.svg') no-repeat 15px center;
        }
        label:nth-of-type(3)>input:checked+.tabRadio{
          background: url('../../assets/icon-profileEmail--blue.svg') no-repeat 15px center;
          background-color: #CDDFFA;
        }

        label:nth-of-type(4)>.tabRadio{
          background: url('../../assets/icon-profileFamily.svg') no-repeat 12px center;
          background-size: 13%;
        }
        label:nth-of-type(4)>input:checked+.tabRadio{
          background: url('../../assets/icon-profileFamily--blue.svg') no-repeat 12px center;
          background-size: 13%;
          background-color: #CDDFFA;
        }

        input:checked+.tabRadio{
          background-color: #CDDFFA;
          color: $E_blue;
        }
      }
    }
  }

  .settingsInner{ width:calc(100% - 195px) }

  .innerContainer{
    height: 100%;
    width: 100%;
    box-sizing:border-box;
    padding:0px 40px 10px 40px;
    text-align: left;
    overflow: auto;

    h5{
      color:#858585;
      font-size: 14px;
      font-weight: 300;
      padding: 8px 0;
      line-height: 25px;
      border-bottom: 1px solid #858585;
      margin: 20px 0 10px 0;

      &:first-of-type{
        margin: 10px 0 10px 0;
      }
    }

    label{
      display: block;
      font-size: 14px;
      color:#333333;
      margin:5px 0;
      padding-left: 5px;
    }

    input{
      width:100%;
      border:1px solid #cacaca;
      border-radius: 8px;
      box-sizing:border-box;
      padding:0 0 0 10px;
      height: 40px;
      line-height: 42px;
      font-size: 14px;

      
    }

    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px #CDDFFA inset !important;
  }

    .btn{
      @include solidButton(white, $E_blue, 14px, 40px, 160px);
      margin-top:15px;

      &.unedited{
        opacity: .4;
        pointer-events: none;
      }

      &.revert{
        pointer-events: none !important;
        background-color: $E_blue !important;
      }


      img{
        height:40px;
        width: 40px;
        object-fit: contain;
      }
    }
  }

  

  @media (max-width:660px){
    height: calc(var(--viewportHeight) - 65px);
    width: 100vw;
    overflow-y: auto !important;
    overflow-x: hidden;
    flex-direction: column;
    justify-content: flex-start;

    .innerContainer{
      padding: 0 10px 40px 10px!important;
      height: fit-content !important;

      select[name=rcrs-country]{
        margin-left: 0 !important; 
      }

      .btn{
        width: 100%;
      }

      h5{
        margin:20px 0 0 0 !important;
        padding: 0 !important;
        border: none !important;

        &:first-of-type{
          margin-top: 5px !important;
        }
      }
    }

    .top{
      display: none;
    }

    .photoBlock{
      display: flex;
      width: 100vw !important;
      justify-content: flex-start !important;

      .photo{
        height: 100px !important;
        width: 100px !important;
        margin:0 20px 0 0 !important;

        
        img{
          height: 100px !important;
          min-height: 100px !important;
          max-height: 100px !important;
          width: 100px !important;
          min-width: 100px !important;
          max-width: 100px !important;
        
        }
      }

      .btns{
        height: 90px;
        flex-direction: column;
        justify-content: center;
      }
    }


    .main{
      width: 100vw;
      height: 100% !important;
      overflow: visible !important;
      flex-direction: column;

      >*{
        border: none !important;
      }


      .resContainer{
        width: 100vw;
        display: initial;
        overflow: visible;
        min-height: calc(100vh - 100px );
      }

    }

    .settingsBlock{
      width: 100vw !important;
      height: initial !important;
      flex-direction: column;
      display: none !important;
    }

      .tabs,
      .settingsInner{
        width: 100vw !important;
      }

      .tabs{
        display: flex;
        align-items: center;
        justify-content: center;

        .tabRadio{
          width: 33vw !important;
          overflow: hidden;
        }
      }
  }

}