@import '../../../../../../shared.scss';

.container{
  width: 100%;
  box-sizing:border-box;
  padding:0 5px;
  position: relative;


  .placeholderDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 80px 40px 0;
    width: calc(100% - 80px);
    text-align: center; 

    h2{
      font-weight: 500;
    }
    
    p{
      font-size: 17px;
      color: #858585;
      font-style: italic;
      width: 90%;
      margin-top: 20px;
    }
  }

  .spinnerDiv{
    width: 100%;
    box-sizing:border-box;
    padding:80px 0 0 0;
    display: flex;
    align-items: center;
    flex-direction: column;

    img{
      height:70px;
      position: relative;
      top: 25px;
    }

    h6{
      font-weight: 500;
      color: $E_blue_dark;
    }
  }
  
  .topBar{
    width: calc(100% - 35px);
    // border:1px solid purple;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing:border-box;
    padding:2px 5px;

    .left{
      display: flex;
      align-items: center;
      width: fit-content;
    }

    .refreshBtn{
      display: flex;
      align-items: center;
      height: 40px;
      background-color: $E_blue_light;
      box-sizing:border-box;
      padding:0 15px;
      border-radius: 8px;
      cursor: pointer;
      transition: .3s all ease-out;

      &:hover{
        background-color: $E_blue_light2;
      }
      

      img{
        height: 16px;
        margin-right: 10px;
      }
      p{
        margin:0;
        font-size: 14px;
        color: $E_blue;
      }
    }

    img{
      height: 65px;
    }

    .dropdown{
      width: 140px;
      height: 45px;
      display: flex;
      align-items: center;
      border:1px solid transparent;
      position: relative;
      box-sizing:border-box;
      padding:0 10px;
      border-radius: 5px;
      
      &.chevron{
        cursor: pointer;
        background: url('../../../../../../assets/chevron_black.svg') no-repeat center right 10px;

        &:hover{
          border:1px solid #f2f2f2;
        }
      }

      p{
        margin:0;
        font-size: 13px;
        max-width: 105px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .dropdownItems{
        height: 0;
        width: 140px;
        transition: .3s all ease-out;
        overflow: hidden;
        opacity: 0;
        background-color: white;
        border-radius: 5px;
        position: absolute;
        top: 45px;
        left:0;
        border:1px solid #f2f2f2;
        pointer-events: none;
        box-sizing:border-box;
        padding:0;

        .dropdownItem{
          height: 35px;
          width: 100%;
          display: flex;
          align-items: center;
          margin: 0;
          box-sizing:border-box;
          padding:0 10px;

          &:hover{
            background-color: #f2f2f2;
          }
          
          &.selected{
            background-color: #fafafa;
          }
        }
      }

      &.open{
        background: none;
        .dropdownItems{
          height: 70px;
          opacity: 1;
          pointer-events: all;
        }
      }
    }
  }

  .entryText{
    width: calc(100% - 35px);
    height: calc(100% - 50px);
    font-size: 15px;
    font-weight: 300;
    box-sizing:border-box;
    padding:0 0 0 10px;
  }

  .roomTab{
    height: 35px;
    display: flex;
    align-items: center;
    position: absolute;
    right: -40px;
    top: 37px;
    transform: rotate(-90deg);
    background-color: $main-yellow;
    padding: 0px 15px;

    p{
      color: white;
      font-size: 14px;
    }


    .icon{
      background: url('../../../../../../assets/sidebarIcons/sidebar_rooms_white.svg') no-repeat center;
      height: 21px;
      width: 21px;
      background-size: contain;
      margin-right: 5px;
    };
  }
  
}