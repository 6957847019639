@import '../../../shared.scss';

.container{
  height:calc(100% - 2px);
  width:calc(100% - 2px);
  background-color: $E_blue_light;
  display: flex;
  align-items: center;
  justify-content: center;

  .inner{
    width: calc(100% - 100px);
    max-width: 1300px;
    height: calc(100% - 100px);
    // border:1px solid cyan;
    text-align: left;

    h1{
      color: $E_blue_dark;
      font-weight: 500;
      font-size: 32px;
    }

    .links{
      margin-top: 50px;
      width: 100%;
      min-height: 500px;
      display: flex;
      flex-wrap: wrap;
    }

    .linkBlock{
      height: 350px;
      width: 350px;
      background-color: white;
      border-radius: 15px;
      margin: 0 20px 20px 0;
      overflow: hidden;
      cursor: pointer;

      .imgWrapper{
        width: 100%;
        height: 190px;
        overflow: hidden;
        position: relative;
  
        img.preview{
          width: 100%;
          transition: .4s all ease;
          transform: scale(1);
        }

        img.play{
          width: 65px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          opacity: 0;
          transition: .5s all ease;
        }
      }

      .text{
        box-sizing:border-box;
        padding:10px 25px;

        h2{
          height: 90px;
          line-height: 28px;
        }

        .bottom{
          display: flex;
          align-items: center;
          justify-content: space-between;
          .duration{
            display: flex;
            align-items: center;
  
            img{
              width: 25px;
              margin-right: 8px;
            }
            p{
              font-size: 15px;
              font-weight: 300;
              color: #858585;
            }
          }

          .watchBtn{
            padding: 8px 15px;
            background-color: $E_blue_light;
            color: $E_blue_dark;
            border-radius: 8px;
            cursor: pointer;
            transition: .5s all ease;
            font-weight: 500;
          }
        }
      }

      &:hover{
        .imgWrapper{
          img.preview{
            transform: scale(1.03);
          }
          img.play{
            opacity: 1;
          }
        }
        .watchBtn{
          color: $E_blue_light !important;
          background-color: $E_blue_dark !important;
        }
      }
    }
  }
}