@import '../../../../shared.scss';

.container{
  width:100%;
  height:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing:border-box;
  // padding:35px 50px;

  *{font-family: 'Sofia pro'}

  .header{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;

    img{
      height: 70px;
      object-fit: contain;
      margin-right: 15px;
    }
  }

  .warning{
    height:fit-content;
    width:100%;
    border-radius: 15px;
    margin: 15px 0;
    box-sizing:border-box;

    p{
      margin: 0;
      line-height: 24px;
      font-size: 16px;
      font-weight: 500;
      color: #858585;
    }
  }

    i.subText{
      font-size: 15px;
      color:#333333;
      width: 90%;
      margin: 0 auto;
    }


  .buttons{
    display: flex;
    margin-top:10px;

    .cancel,
    .startVoting{
      height:40px;
      width:170px;
      border:1px solid;
      border-radius: 10px;
      line-height: 40px;
      font-size: 16px;
      text-align: center;
      margin:0 8px;
      cursor: pointer;
      transition: .3s all ease-in-out;

    }

    .cancel{
      border-color: $main-blue--bright;
      color: $main-blue--bright;
      background-color: white;
      &:hover{
        color: white;
        background-color: $main-blue--bright;
      }
    }
    .startVoting{
      border-color: $E_blue;
      background-color: $E_blue;
      color: white;
      transition: .15s all ease-out;

      &:hover{
        border-color: $E_blue_dark;
        background-color: $E_blue_dark;
      }
    }
  }

  @media (max-width:660px){
    width: 90vw;

    p{
      text-align: center !important;
      font-weight: 300 !important;
    }
  }
}