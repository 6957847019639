.container{
  margin: 1rem 0;
.check{
  position: relative;
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  color: #858585;
}
.custom_checkbox {
  appearance: none;
  width: 25px;
  height: 25px;
  border: 2px solid #333;
  border-radius: 4px;
  margin-right: 8px;
  cursor: pointer;
  position: relative;
  margin-bottom: 1rem;
}

.custom_checkbox:checked {
  background-color: #97D318;
  border-color: #97D318;
  color: #fff;
  fill: #fff;
}

.right{
  margin-left: 2rem;
  margin-bottom: 1rem;
}

.instruction_name{
  border: 1px solid #858585;
  padding: 10px;
  border-radius: 5px;
  text-transform: capitalize;
  width: 300px;
}

  .institute{
    color: #858585;
    margin-top: 2rem;
    .header{
      background: #E9E9E9;
      padding: 0 20px;
      height: 40px;
      margin: 2rem 0;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      display: flex;
      align-items: center;
      h6{
        text-transform: uppercase;
        color: #858585;
        font-size: 1rem;
      }
    }
    .table{
      .item{
        display: flex;
        gap: 1rem;
        align-items: center;
        margin-right: 1rem;
        div{
          width: 100%;
          input{
            width: 90%;
            height: 40px;
            border-radius: 5px;
            padding: 5px 10px;
            border: 1px solid #D2D2D2;
          }
        }
        .addBtn{
          background: rgba(7, 124, 160, 0.2);
          color: #077CA0;
          width: 60%;
          text-align: center;
          padding: 1rem 0;
          border-radius: 5px;
          cursor: pointer;
          font-weight: bold;
          font-size: 1.1rem;
        }
      }
    }
  }

  .inviteBtn{
    // color: ;
    text-transform: capitalize;
    color: #e27070;
    font-weight: bold;
    cursor: pointer;
    font-size: 1rem;
    margin: 1rem 0;
    transition: all 0.3s ease;
    padding: 1rem;
    width: fit-content;
    border-radius: 5px;
    &:hover{
      background: #efb8b8;
      color: #fff;
    }
  }

}